import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';


function StackedColumnChart(props){

  var titleIcon 
    if(props.titleIcon === "gender"){
        titleIcon = "fa fa-user"
    }else if(props.titleIcon === "maritalStatus"){
        
        titleIcon = "fa-solid fa-rings-wedding"
    }else if(props.titleIcon === "children"){
        titleIcon = "fa fa-baby"
    }else{
        titleIcon = ""
    }
    const options = {
        chart: {
          type: 'column'
        },
        title: {
          // text: 'Stacked column chart'
          align: 'left',
            useHTML: true,
            text:  `<i class="${titleIcon}"> </i>  ${props.title || "Column chart"}`
        
        },
        xAxis: {
          categories: props.categories,
          title: {
            text: props.xAxisTitle || "X axis title"
          },
        },
        yAxis: {
          min: 0,
          labels: {
            formatter: function() {
                return props.yAxisLabels ? this.value + props.yAxisLabels : this.value;
            }
          },
          title: {
            text: props.yAxisTitle || "Y axis title"
          },
          stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold',
                color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
                ) || 'gray'
              }
            }
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            }
          }
        },
        series: props.data,
        credits: {
          enabled: false
          },
      };

      return(
        <div>
<HighchartsReact highcharts={Highcharts} options={options} />
        </div>
          )
      
}


StackedColumnChart.propsTypes = {
  titleIcon: PropTypes.oneOf(["gender","maritalStatus","children"]),
  title: PropTypes.string.isRequired,
  xAxisTitle:PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisTitle: PropTypes.string,
  yAxisLabels:PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories:PropTypes.array

}


export default StackedColumnChart;
