import React from 'react';
import Map from '../../components/MapBuilder/MapBuilder';
import { LAYER_IDS } from '../../constants/mapBuilder';
import MapBuilderHelper from '../../common/MapBuilderHelper/mapBuilderHelper';

import tokyuLogo from '../../img/tokyu_store_logo.png';




const HeatMap = (props) => {
    const {
        storePosition,
        onMoveEnd=false,
        mapCells = {},
        currentFormValues = {},
        selectedMeshIds = [],
        enableCellClick = false,
        setSelectedMeshIds,
        dragedPosition=false,
        popupText = false,
        valuePostfix = false
    } = props;

    const [selectedView, setSelectedView] = React.useState(storePosition);
   

    const interactiveLayerIds = [LAYER_IDS.cell];
    const markerIcon = (
        <img
            src={tokyuLogo}
            style={{
                height: 24,
                backgroundColor: '#e60213',
                borderRadius: '50%',
                border: 'solid 2px #fff'
            }}
        />
    );

    const updateSelectedCellArray = (selectedId, cellArray) => {
        if (cellArray.includes(selectedId)) {
            return cellArray.filter((p) => p !== selectedId);
        }
        return [...cellArray, selectedId];
    };

    const handleCellSelection = (cellId) => {
        if (!setSelectedMeshIds) return;
        const newSelectedCells = cellId ? updateSelectedCellArray(cellId, selectedMeshIds) : [];
        setSelectedMeshIds(newSelectedCells);
        currentFormValues.current.mapMeshIds = [...newSelectedCells];
    };

    const handleDraw = (cellId, altKey) => {
        if (!setSelectedMeshIds) return;
        if (altKey) {
            const newSelectedCells = selectedMeshIds.filter((p) => p !== cellId);
            setSelectedMeshIds(newSelectedCells);
            currentFormValues.current.mapMeshIds = [...newSelectedCells];
        } else {
            if (!selectedMeshIds.includes(cellId)) {
                const newSelectedCells = [...selectedMeshIds, cellId];
                setSelectedMeshIds(newSelectedCells);
                currentFormValues.current.mapMeshIds = [...newSelectedCells];
            }
        }
    };

    React.useEffect(() => {
        setSelectedView(storePosition);
    }, [storePosition]);

    currentFormValues.current.mapMeshIds  = selectedMeshIds;
    const colorIntervals = MapBuilderHelper.calculatePeopleFlowPaintStops(mapCells);
    return (
       
        <Map
            mapboxAccessToken="pk.eyJ1Ijoid2lsbGpvZTYwNCIsImEiOiJjbDFmenR3c2owN2NmM2twYmxuYnFla2l6In0.vA1UWByc2KhyjfUpU8rioQ"
            initialViewState={storePosition[0]}
            style={{
                width: '100%',
                height: '100%',
            }}
            mapStyle="mapbox://styles/willjoe604/cl3dqv3p4000014n4qxfvc8f4"
            selectedView={selectedView}
            cellValues={mapCells}
            controlledSelection={selectedMeshIds}
            interactiveLayerIds={interactiveLayerIds}
            handleControlledCellSelection={handleCellSelection}
            handleDraw={handleDraw}
            enableMapClickEvents={enableCellClick}
            comparisonMap
            scrollZoom
            markerIcon={markerIcon}
            colorIntervals={colorIntervals}
            onMoveEnd={onMoveEnd}
            dragedPosition={dragedPosition}
            popupText = {popupText}
            valuePostfix = {valuePostfix}
        />
    );
};

export default HeatMap;


