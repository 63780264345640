import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RakutenPointLogo from '../../common/Logo/RakutenPointLogo.jsx';
import Button from '../../common/Button/Button.jsx';

const useStyles = makeStyles((theme) => ({
    root : (props) =>({
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        width  : '100%',
        height : '100%',
        flex : 1,
    }),
    container : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        flexDirection : 'column',
    },
    message : {
        fontSize : 16,
        color : '#7f8c8d',
        padding : '12px 0 24px 0',
        '& .far' : {
            fontSize : '90%',
        }
    }
}));
export default function NotFound(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography className={classes.message}>
                    <i className="fas fa-door-closed"></i> 現在利用できません
                </Typography>
            </div>
        </div>
        
    )
}