import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";
//import HC_exporting from 'highcharts/modules/exporting'

HighchartsMore(Highcharts);
//HC_exporting(Highcharts);
Highcharts.setOptions({
    global: {
        useUTC: false,
    },
    lang: {
        decimalPoint: '.',
        thousandsSep: ',',
    },
});
function ColumnChart(props){
    const {
        highchartsRef=null,
    } = props;
    const options = {
        chart: {
            type: 'boxplot',
            zoomType : 'x',
            backgroundColor : 'transparent',
            resetZoomButton : {
                position : {
                    y : -10,
                }
            }
        },
        title: {
            text: '',
        },
        xAxis: {

        },
        yAxis: {

        },
        tooltip : {
            shared : true,
            split : true,
            useHTML : true,
        },
        plotOptions : {
            line : {
                tooltip : {
                    valueDecimals: 2,
                    valueSuffix : '%'
                }
            },
            boxplot : {
                tooltip : {
                    pointFormat : `<span style="color:{point.color}">●</span>
                    <b> {series.name}</b>
                    <div style="display : flex;justify-content : 'space-between'">
                        <div style="width: 60px">Max</div>
                        <div style="flex : 1;text-align: right;">{point.high}</div>
                    </div>
                    <div style="display : flex;justify-content : 'space-between'">
                        <div style="width: 60px">q3</div>
                        <div style="flex : 1;text-align: right;">{point.q3}</div>
                    </div>
                    <div style="display : flex;justify-content : 'space-between'">
                        <div style="width: 60px">Median</div>
                        <div style="flex : 1;text-align: right;">{point.median}</div>
                    </div>
                    <div style="display : flex;justify-content : 'space-between'">
                        <div style="width: 60px">q1</div>
                        <div style="flex : 1;text-align: right;">{point.q1}</div>
                    </div>
                    <div style="display : flex;justify-content : 'space-between'">
                        <div style="width: 60px">Min</div>
                        <div style="flex : 1;text-align: right;">{point.low}</div>
                    </div>`
                },
            }
        },
        series: props.data,
        credits: {
            enabled: false,
            sourceWidth  : 800,
            sourceHeight : 480,
            buttons : {
                contextButton : {
                    menuItems : ['downloadPNG']
                }
            }
            
        },
        ...props.options,
    }
    return(
        <HighchartsReact 
            ref={highchartsRef}
            highcharts={Highcharts} 
            options={options} 
            allowChartUpdate={true}
            containerProps={{
                style: {
                    height: "100%", 
                    width : '100%'
                }
            }}
        />
    )
}


export default ColumnChart;
