import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import UserConsentDialog from "./UserConsentDialog";


const useStyles = makeStyles((theme) => ({
    root: {
        height: 120, marginTop: 5
    },
    title: {
        fontWight: 400,
    },
    textField: {
        margin: "5px",
        display: "block"
    },
    saveButton: {
        float: "right", margin: "2px"
    }
}));

function AddNewFavourite(props) {
    const classes = useStyles();
    const { 
        listOfFavouritesNames= null,
        updateOldFavouriteName=null,
        createNewfavouriteName=null,
        updating
    } = props;
    const [newNameInputError, setNewNameInputError] = useState(null);
    const [newFavouriteName, setNewFavouriteName] = useState(null);
    const [openUserConsentDialog, setOpenUserConsentDialog] = useState(false);

    const handleSaveClick = () => {
        if (!newFavouriteName) {
            setNewNameInputError("Please give a name !");
            return
        } else if (newFavouriteName?.trim().length === 0) {
            setNewNameInputError("Please give a valid name !");
            setNewFavouriteName(null);
            return;
        } else if (newFavouriteName?.trim().length > 30) {
            setNewNameInputError("Name lenght should not be more than 30 !");
            return;
        } else if (listOfFavouritesNames?.includes(newFavouriteName?.trim())) {
            setOpenUserConsentDialog(true);
            return
        }
        createNewfavouriteName(newFavouriteName);
    };

    const handleUpdateClick = ()=>{
        setOpenUserConsentDialog(false);
        updateOldFavouriteName(newFavouriteName?.trim());
    };

    const handleCancelClick = ()=>{
        setOpenUserConsentDialog(false);
    };

    return (
        <Container className={classes.root}>
            <div className={classes.title} > 今の条件をお気に入りに保存</div>
            <TextField
                className={classes.textField}
                disabled={updating}
                id="outlined-basic"
                label="条件の名前"
                variant="outlined"
                size="small"
                value={newFavouriteName || ""}
                onChange={(event) => setNewFavouriteName(event.target.value)}
                error={newNameInputError}
                helperText={newNameInputError}
            />
            <Button
                className={classes.saveButton}
                disabled={updating ||  !newFavouriteName?.trim()}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                onClick={handleSaveClick}
            >保存</Button>
            <UserConsentDialog open={openUserConsentDialog} aboutToDeleteOrUpdateFavouriteName={newFavouriteName} close={handleCancelClick} handleUpdateClick={handleUpdateClick} isUpdateOldFavouriteName={true}  />
        </Container>
    )
}

export default React.memo(AddNewFavourite);
