import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function TimeInput(props) {
    const {
        id='time-input',
        label='time',
        minTime=null,
        maxTime=null,
        selectTime='00:00',
        onChange=null,
        isSelectTime,
        addEndLabel=[]
    } = props;
    const minHour = minTime ? Number(minTime.split(':')[0]) : -1;
    const maxHour = maxTime ? Number(maxTime.split(':')[0]) : 24;
    // One time slot every 30 minutes.
    const timeSlots = Array.from(new Array(24))
        .map((_, i) => `${i < 10 ? `0` : ''}${i}:00`)
        .filter(time => {
            const hour = Number(time.split(':')[0]);
            return minHour < hour && maxHour > hour;
        })
        // 00:00 to 23:00
        .concat(addEndLabel);
    const timeSlotOptions = timeSlots;
    return (
        <Autocomplete
            id={id}
            options={timeSlotOptions}
            value={selectTime}
            renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined" />
            )}
            onChange={(event, value)=>{
                onChange && onChange(value);
            }}
            debug={true}
            onOpen={()=>{
                isSelectTime.current = true;
            }}
            onClose={()=>{
                isSelectTime.current = false;
            }}
        />
    );
}


