import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PropTypes, defaultProps} from 'prop-types';
import rakutenPointLogoSrc from '../../img/rakuten_point_logo.png';
const useStyles = makeStyles({
    logoImage : {
        width : 60,
    },
});

export default function UI_Logo(props) {
    const classes = useStyles();
    const {
        alt,
    } = props;
    React.useEffect(()=>{

    }, [])
    return (
        <img 
            src={rakutenPointLogoSrc} 
            className={clsx(classes.logoImage, props.className)}
            alt={alt}
            {...props}
        />
    )
}
UI_Logo.propTypes = {
    
}
UI_Logo.defaultProps = {
    alt : '',
};