import React, { useContext, useEffect, useState }  from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

//import Login from './pages/Login/Login.js';
import Login from './pages/Login/Login.jsx';
import Main from './Main.jsx';

import { AuthContext, AuthProvider } from './AuthenticationContext.js';
import NotFound from './pages/NotFound/NotFound.jsx'
import SnackBar from './components/SnackBar/SnackBar.jsx';
import Alert from './components/Alert/Alert.jsx';
import moment from 'moment';
import 'moment/locale/ja'; // 日本語ローカライズ

import { createIDBDatabase, getIDBMasterData, deleteDb } from './indexedDb.js';
import { 
    API_URL, 
    getNdJsonFetch,
} from './utility/fetchUtility.js';
import targetFilter from './json/targetFilter.json';
import aggregationTarget from './json/aggregationTarget.json';
import differentOccupation from './json/differentOccupation.json';
import appMenuStatic from './json/AppMenu.json';
import {config} from './config.jsx';

const BASE_PATH     = config.BASE_PATH;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    loadingContainer : {
        position : 'fixed',
        top : 0,
        bottom : 0,
        right : 0,
        left : 0,
        backgroundColor : 'rgba(0, 0, 0, 0.75)',
        color : '#ecf0f1',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        zIndex : 9999,
    }
}));
export default function Dashboard(props) {
    const {
        range={},
    } = props;
    const classes = useStyles();
    const appPath = [
        '/',
        '/sales', 
        '/assortment', 
        '/pricing',
        '/favoriteEdit',
        '/editProfile',
        '/editMember',
        '/screenTerminology',
        '/peopleflow',
        '/heatmap',
        '/market-area-summary',
        '/debug',
    ];
    const [alert, setAlert] = React.useState([]);
    const {
        token, 
        user,
        userProfile,
        updateSelections
    } = useContext(AuthContext);

    const location = useLocation();
    const history = useHistory();
    const [isInitMasterData, setIsInitMasterData] = React.useState(false);
    // const [masterData, setMasterData] = React.useState(null);
    const [appMenu, setAppMenu] = React.useState(null);

    const currentFormValues = React.useRef({
        isTab : true,
        isIndicator : true,
        searchText : '',
        categoryLevel : 4,
        conditions : {
            averageCustomerSpend : {
                title      : '顧客単価 : ',
                type       : '',
                value      : 0,
                comparison : 'below',
                unit       : '円',
                selectedStore : null,
            },
            walletShare : {
                title      : 'ウォレットシェア : ',
                type       : '',
                value      : 0,
                comparison : 'below',
                unit       : '%',
                selectedStore : null,
            }
        },
        range: 1,
        mapMeshIds: [],
        meshRadius: 5,
        tradeArea:1,
    });
    const filterMenuScrollTop = React.useRef({});
    const addAlert = (options) => {
        if(options.id === alert?.id || '') return;
        setAlert(options);
    }
    const removeAlert = () => {
        setAlert(null);
    }
    const checkApiError = (response) => {
        if(response.severity){
            addAlert(response);
            return [];
        }
        return response;
    }
    const getMasterData = async (token, history, user) => {
        const currentTime = new Date().getTime();
        const cacheMasterData  = await getIDBMasterData();
        const expirationTime   = (window.localStorage.getItem('expirationTime') || null);
        //const cacheMasterData  = JSON.parse(window.localStorage.getItem('masterData'));
        // const cacheAppMenuData = JSON.parse(window.localStorage.getItem('appMenuData'));
        const oldGroupId = window.localStorage.getItem("groupID")
        const newGroupId = user?.displayName?.slice(9);
        const appMenuData = appMenuStatic?.[newGroupId] || {};
        window.localStorage.setItem('appMenuData', JSON.stringify(appMenuData));
        setAppMenu(appMenuData);
        if(user && cacheMasterData && expirationTime > currentTime && oldGroupId == newGroupId){
            // //console.log(`use localStorage & IndexedDB 😎
            //             expirationTime : ${cacheMasterData.expirationTime} (${moment(cacheMasterData.expirationTime).format('YYYY-MM-DD HH:mm:ss')})
            //             currentTime    : ${currentTime} (${moment(currentTime).format('YYYY-MM-DD HH:mm:ss')})
            //             `)
            /*
            const _masterAllData = {
                store           : cacheMasterData.store,
                segment         : cacheMasterData.segment,
                target          : cacheMasterData.target,
                categories      : cacheMasterData.categories,
                competingStores : cacheMasterData.competingStores,
            }
            */
            //const _masterData = convertMasterData(_masterAllData);
            // setAppMenu(cacheAppMenuData);
            //setMasterData(_masterData);
            //setMasterData(convertMasterData(cacheMasterData));
            setIsInitMasterData(true);
            return;
        }
        console.log("new data is downloading :",)
        window.localStorage.removeItem('hasMasterDB');
        //API_URL
        const categoriesData = await getNdJsonFetch(token, history, `https://${API_URL}/categories`);
        //const competitorData = await getNdJsonFetch(token, history, `https://${API_URL}/competitor-list`);
        const ownStoreData   = await getNdJsonFetch(token, history, `https://${API_URL}/own-stores`);
        const segmentsData   = await getNdJsonFetch(token, history, `https://${API_URL}/segments`);
        
        const _masterAllData = {
            store           : checkApiError(ownStoreData),
            segment         : checkApiError(segmentsData),
            target          : targetFilter,
            categories      : checkApiError(categoriesData),
            differentOccupation : differentOccupation,
            //competingStores : checkApiError(competitorData),
            competingStores : [],
            aggregationTarget: aggregationTarget, 
        }
    
        if(!ownStoreData || !segmentsData || !categoriesData){
            return;
        }
        const indexedDdTables = [
            {
                tableName : 'store',
                keyPath   : null,
                rows      : [..._masterAllData.store],
            },
            {
                tableName : 'segment',
                keyPath   : null,
                rows      : [..._masterAllData.segment],
            },
            {
                tableName : 'target',
                keyPath   : null,
                rows      : [..._masterAllData.target],
            },
            {
                tableName : 'categories',
                keyPath : null,
                rows    : [..._masterAllData.categories]
            },
            {
                tableName : 'competingStores',
                keyPath : null,
                rows    : [..._masterAllData.competingStores],
            },
            {
                tableName : 'aggregationTarget',
                keyPath : null,
                rows    : [..._masterAllData.aggregationTarget],
            },
            {
                tableName : 'differentOccupation',
                keyPath : null,
                rows    : [..._masterAllData.differentOccupation],
            }
        ]
    
    
        // The saving process takes time, so save slowly on the back end
        createIDBDatabase('masterDB', indexedDdTables);
        /*
        const appMenuData = await fetch(`${BASE_PATH}json/AppMenu.json`)
            .then(res => res.json())
            .then(json => {
                return json;
            })
            .catch(err => {
                return appMenuStatic;
            });
        */
        // expiration date setting (1day)
        window.localStorage.setItem("groupID", newGroupId)
        //window.localStorage.setItem('masterData', JSON.stringify(masterData));
    
        //setMasterData(masterData);
        setIsInitMasterData(true);
    }

    const Loading = () => {
        return (
            <div className={classes.loadingContainer}>
                <Typography component="div" style={{
                    display : 'flex',
                    alignItems : 'center',
                }}>
                    <CircularProgress style={{
                        width : 16, 
                        height : 16, 
                        marginRight : 12,
                        color : 'rgba(255, 255, 255, 0.75)'
                    }} />マスターデータ初期化中...
                </Typography>
            </div>
        )
    }

    React.useEffect(async ()=>{
        // Change from AuthContext to token
        // AuthContext always returns an object and cannot be used to determine
        if(!token){
            setIsInitMasterData(false);
        }
        if(user && token && location.pathname === '/'){
            // if(user?.displayName?.slice(9)== 659 ){
            //     history.push("/assortment/competitor")
            //     return
            // }
            history.push('/sales');
            return;
        }
        if(token && user && location.pathname !== '/' && !isInitMasterData){
            getMasterData(token, history, user);
        }
    }, [token && user , location.pathname]);


    React.useEffect(() => {
        if(location.pathname.includes('result')){
            history.replace(location.pathname.split('/result')[0])
        }
    }, [])

    const isLogin = location.pathname !== '/';

    const AuthRoot = () => {
        if(token) {
            return (
                <Switch>
                    <Route path={appPath} render={() => {
                        return <Main 
                            currentFormValues={currentFormValues} 
                            filterMenuScrollTop={filterMenuScrollTop}
                            appMenu={appMenu}
                            addAlert={addAlert}
                            alert={alert}
                        />
                    }} />
                    <Route component={() => <NotFound />} />
                </Switch>)
        }else{
            return <Redirect to="/"/>
        }
    }

    return (
        <div className={classes.root}>
        <CssBaseline />
            <Switch >
                <Route exact path='/' render={()=>{
                    return <Login addAlert={addAlert} />
                }} />
                  <AuthRoot />
            </Switch>
            {
                alert && (<SnackBar key={`alert-${alert.id}`} action={()=>setAlert(null)} {...alert} />)
            }
            {
                isLogin && !isInitMasterData && (<Loading />)
            }
        </div>
    );
}
