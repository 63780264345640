import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);
Highcharts.setOptions({
    global: {
        useUTC: false,
    },
    lang: {
        decimalPoint: '.',
        thousandsSep: ',',
    },
});

function LineChart(props){
    const {
        highchartsRef=null,
    } = props;
    const options = {
        chart: {
            type: 'line',
        },
        title: {
            text: props.title || "",
            align:"left"
        },
        xAxis: {
            title : {
                text : '',
            }
        },
        yAxis: {
            title : {
                text : '',
            }
        },
        plotOptions: {
            pie: {
                dataLabels : {
                    distance: -20,
                }
            }
        },
        credits: {
            enabled: false
        },
        legend : {
            enabled : false,
        },
        ...props.options,
    }

    return(
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}

export default LineChart;
