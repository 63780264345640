import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({
    Link : {
        color : '#3f51b5',
        textDecoration : 'none',
        '&:hover' : {
            textDecoration : 'underline',
        }
    }
});

export default function UI_Link(props) {
    const classes = useStyles();
    const {
        children=null,
    } = props;
    React.useEffect(()=>{

    }, [])
    return (
        <Link
            {...props}
            className={clsx(classes.Link, props.className)}
        >
            {children}
        </Link>
    )
}
UI_Link.propTypes = {
    to: PropTypes.string.isRequired,
}
UI_Link.defaultProps = {

};