let triggerWindowResizeTimer = null;
const triggerWindowResize = (timer=750) => {
    clearTimeout(triggerWindowResizeTimer);
    triggerWindowResizeTimer = setTimeout(()=>{
        window.dispatchEvent(new Event('resize'));
    }, timer);
}
const clearTriggerWindowResize = () => {
    clearTimeout(triggerWindowResizeTimer);
}
export {
    triggerWindowResize,
    clearTriggerWindowResize,
}