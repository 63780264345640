import React from 'react';
import Map from '../../components/MapBuilder/MapBuilder';
import { cellValueComparisonDictionary } from '../../api/mock/cellValueComparisonDictionary';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { LAYER_IDS, INITIAL_VIEW } from '../../constants/mapBuilder';

const updateSelectedCellArray = (selectedId, cellArray) => {
  if (cellArray.includes(selectedId)) {
    return cellArray.filter((p) => p !== selectedId);
  }
  return [...cellArray, selectedId];
};

const interactiveLayerIds = [LAYER_IDS.cell];

const PeopleFlowAnalysis = () => {
  const [controlledSelection, setControlledSelection] = React.useState([]);
  const [cellValues, setCellValues] = React.useState(cellValueComparisonDictionary);
  const [selectedView, setSelectedView] = React.useState(INITIAL_VIEW);

  const handleChange = (event) => {
    if (event.target.value === 'miyazakidai') {
      setSelectedView({
        longitude: 139.592315,
        latitude: 35.587508,
      });
    } else {
      setSelectedView({
        longitude: 139.691653,
        latitude: 35.689173,
      });
    }
  }

  const handleCellSelection = (cellId) => {
    const newSelectedCells = updateSelectedCellArray(cellId, controlledSelection);
    console.log('CONTROLLED CELL SELECTION:', newSelectedCells)
    setControlledSelection(newSelectedCells);
  };


  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* SELECT MENU FOR DEVELOPMENT PURPOSES ONLY! REMOVE BEFORE DEPLOYMENT! */}
      <Select
        style={{ width: '120px' }}
        defaultValue={'miyazakidai'}
        onChange={handleChange}
      >
        <MenuItem value="miyazakidai">Miyazakidai</MenuItem>
        <MenuItem value="tocho">Tocho</MenuItem>
      </Select>

      <Map
        mapboxAccessToken="pk.eyJ1Ijoid2lsbGpvZTYwNCIsImEiOiJjbDFmenR3c2owN2NmM2twYmxuYnFla2l6In0.vA1UWByc2KhyjfUpU8rioQ"
        initialViewState={INITIAL_VIEW}
        style={{ width: '100%', height: 'calc(100% - 32px)' }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        selectedView={selectedView}
        cellValues={cellValues}
        controlledSelection={controlledSelection}
        interactiveLayerIds={interactiveLayerIds}
        handleControlledCellSelection={handleCellSelection}
        enableMapClickEvents={false}
        comparisonMap
      />
    </div>
  )
}

export default PeopleFlowAnalysis;