import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
const themeColor = {
  'primary' : 'rgb(63 81 181)',
  'secondary' : 'rgb(220 1 78)',
}
const useStyles = makeStyles((theme) => ({
  root : (props) => ({
    display : 'inline-flex',
    opacity : props.disabled ? 0.5 : 1,
    '& .MuiSwitch-thumb' :  {
      color : themeColor[props.color] || 'rgb(63 81 181)',
    },
    '& .MuiSwitch-track' : {
      backgroundColor: 'rgba(127, 140, 141,1.0)',
    }
  }),
    label: {
        color : '#7f8c8d',
        paddingLeft : 8,
    },
    darkLabel : {
        color : '#bdc3c7',
        paddingLeft : 8,
    },
    checkedLabel : (props) => ({
      fontWeight : 600,
      color : themeColor[props.color] || 'rgb(63 81 181)',
    }),
    unCheckedLabel : {
      opacity : .5,
    }
}));
export default function ToggleSwitch(props) {
    
    const {
        checked = true,
        onChange,
        isDarkTheme=false,
        labels=[null, null],
        legend=null,
        disabled=false,
        color="primary"
    } = props;
    const classes = useStyles({
      disabled,
      color,
    });
    const handleChange = (event) => {
        onChange(event.target.checked);
    };

    return (
      <div className={classes.root}>
        <Grid 
          component="label" 
          container 
          alignItems="center" 
          spacing={1}
          className={isDarkTheme ? classes.darkLabel : classes.label}
        >
          {
            legend && (
              <Grid 
                item 
              ><Typography style={
                {
                  opacity : 0.75,
                  display : 'flex',
                  alignItems : 'center',
                }
              }>{legend}</Typography></Grid>
            )
          }

          {
            labels[0] && (<Grid 
              item 
              className={!checked ? classes.checkedLabel : classes.unCheckedLabel}
            >{labels[0]}</Grid>)
          }
          <Grid item>
          <Switch
            checked={checked}
            onChange={handleChange}
            name="Unit"
            //color={checked ? 'default' : 'primary'}
            color='default'
            disabled={disabled}
            />
          </Grid>
          <Grid 
            item 
            className={checked ? classes.checkedLabel : classes.unCheckedLabel}
          >{labels[1]}</Grid>
        </Grid>
      </div>
    );
}