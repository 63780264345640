import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import * as dfd from "danfojs";
import moment from 'moment';
import Button from '../../common/Button/Button.jsx';
import html2canvas from "html2canvas";
const useStyles = makeStyles((theme) => ({

}));

export default function DownloadImageButton(props) {
    const {
        currentFormValues=null,
        containerRef=null,
        chartOptions=null,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const downloadLinkRef = React.useRef(null);
    const getAppPath = (menus) => {
        return menus.map(menu => {
            return menu.child ? getAppPath(menu.child) : menu.link ? menu : null
        }).flat().filter(menu => menu);
    }
    const dateFormat = (date, format='YYYY_MM_DD') => {
        return moment(date).format(format);
    }
    const getPeriod = () => {
        const {
            start_date = null,
            end_date   = null,
        } = currentFormValues?.current || {};
        if(!start_date || !end_date){
            return '';
        }
        return ` - ${dateFormat(start_date)}-${dateFormat(end_date)}`;
    }
    const getAppName = () => {
        const undefinedName = 'Marketing_View_Premium';
        const appMenuJson = JSON.parse(window.localStorage.getItem('appMenuData') || null);
        if(!appMenuJson) return undefinedName;
        const path    = location.pathname.replace(/\/result$/, '');
        const menus   = getAppPath(appMenuJson);
        const appName = menus?.find(menu => menu.link === path)?.label || undefinedName;
        return appName;
    }
    const setUpImage = () => {
        const container = containerRef?.current?.container?.current || null;
        const link = downloadLinkRef.current || null;
        if(!container || !link) return;
        html2canvas(container).then((canvas) => {
            const imageURL   = canvas.toDataURL("img/png");
            const appName    = getAppName();
            const period     = getPeriod();
    
            const file = `${appName}${period}.png`;
            link.setAttribute('href', imageURL);
            link.setAttribute('download', file);
            link.click();
        });
    }
    return (
        <>
        <Button 
            color="primary"
            variant="contained"
            size="large"
            disabled={!chartOptions}
            startIcon={<i className="fas fa-chart-bar"></i>}
            onClick={setUpImage}
        >PNG ダウンロード</Button>
        <a ref={downloadLinkRef} />
        </>
    );
}