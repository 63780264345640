import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/ja'; // 日本語ローカライズ

import Toolbar from '../../components/Toolbar/Toolbar.jsx';
import FilterList from '../../components/FilterList/FilterList.jsx';
import ToolFooter from '../../components/ToolFooter/ToolFooter.jsx';
import Result from '../../components/Result/Result.jsx';
import Condition from '../../components/Condition/Condition.jsx';
import { triggerWindowResize, clearTriggerWindowResize } from '../../utility/triggerEvent.js';
import { isUseRangePath } from '../../utility/getResult.js';
const useStyles = makeStyles((theme) => ({

}));
export default function Assortment(props) {
    const {
        routerParams=null,
        currentFormValues,
        filterMenuScrollTop,
        addAlert,
        alert,
    } = props;
    const {
        major,
        minor=null,
    } = routerParams;

    const location = useLocation();
    const [isShowFilter, setIsShowFilter] = React.useState(true);
    const [isShowResult, setIsShowResult] = React.useState(false);

    const [useTab, setUseTab] = React.useState(true);
    const [useIndicator, setUseIndicator] = React.useState(true);
    const [submitValues, setSubmitValues] = React.useState({});
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const [isTab, setIsTab] = React.useState(currentFormValues.current.isTab);
    const [isIndicator, setIsIndicator] = React.useState(currentFormValues.current.isIndicator);
    const [isPostBodyType, setIsPostBodyType] = React.useState(currentFormValues.current.isPostBodyType);
    const [isReload, setIsReload] = React.useState(true);
    let showExtraLable =  Boolean(window.localStorage.getItem("groupID") == '569')
    const [categoryLevelOptions, setCategoryLevelOptions] = React.useState([{
        label : `レベル2${showExtraLable ? "(DPT枝番)" : ''}`,
        value : 2,
        selected : false,
    },{
        label : `レベル3${showExtraLable ? "(ライン)" : ''}`,
        value : 3,
        selected : false,
    },{
        label : `レベル4${showExtraLable ? "(クラス)" : ''}`,
        value : 4,
        selected : true,
    }].map(option => {
        return {
            ...option,
            selected : option.value === (currentFormValues?.current?.categoryLevel || 4),
        }
    }));
    const [tradeAreaOptions, setTradeAreaOptions] = React.useState([{
        label : '3km',
        value : 3,
        selected : false,
    },{
        label : '5km',
        value : 5,
        selected : false,
    },{
        label : '7km',
        value : 7,
        selected : false,
    },{
        label : '10km',
        value : 10,
        selected : true,
    }].map(option => {
        return {
            ...option,
            selected : option.value === (currentFormValues?.current?.tradeArea || 10),
        }
    }));
    const [selectedStores, setSelectedStores] = React.useState(currentFormValues?.current?.['store']?.filter(d => d.checked) || []);
    const [mapCells, setMapCells] = React.useState(null);
    const [isShowChart, setIsShowChart] = React.useState(false);
    const [conditionUpdate, setConditionUpdate] = React.useState(false);
    const classes = useStyles({
        isShowResult,
    });

    const hasSingleTable = ['assortment/competitor'];
    const masterKeys = [
        'store',
        'aggregationTarget',
        'segment',
        'target',
        'categories',
        'competingStores',
    ];
    const toggleIsTab = () => {
        setIsTab(!isTab);
        currentFormValues.current.isTab = !isTab;
    }
    const toggleIsIndicator = () => {
        setIsIndicator(!isIndicator);
        currentFormValues.current.isIndicator = !isIndicator;
    }
    const toggleIsPostBodyType = () => {
        setIsPostBodyType(!isPostBodyType);
        currentFormValues.current.isPostBodyType = !isPostBodyType;
    }


    const checkUnderDevelopment = () => {
        const currentPath = `${major}/${minor}`;
        const devPath = [
/*            'assortment/singleItem', 
            'assortment/basket', 
            'pricing/summary', 
            'pricing/competitor', 
            'pricing/singleItem'*/
        ];
        if(devPath.includes(currentPath)){
            addAlert({
                id : currentPath,
                severity : 'info',
                message : 'Result screen is under development 🙇🏻‍♂️'
            });
        }
    }

    const toggleShowFilter = (flag) => {
        setIsShowFilter(flag);
    }
    const changeSubmitValues = (params) => {
        setSubmitValues({...submitValues, ...params});
    }

    const getPath = (major, minor) => {
        return [major, minor].filter(p => p !== null).join('/');
    }
    const getSingleTable = (major, minor) => {
        const path = getPath(major, minor);
        return hasSingleTable.includes(path);
    }
    const getTabCondition = (major, minor) => {
        if(major === 'sales'){
            minor = null;
        }

        const useTabKeys = {
            'sales' : [null],
            'assortment' : ['summary', 'singleItem', 'basket'],
            'pricing' : ['singleItem'],
            'marketArea' : [],
        }
        const useIndicatorKeys = {
            'sales' : [null],
            'assortment' : ['summary'],
            'pricing' : [],
            'marketArea' : [],
        };
        return {
            useTab : useTabKeys[major]?.includes(minor) || false,
            useIndicator : useIndicatorKeys[major]?.includes(minor) || false,
        }
    }
    const getFilterOptions = (major, minor) => {
        if(major === 'debug'){
            return ['categories'];
        }
        if(major === 'sales'){
            return masterKeys.filter(key => !['categories', 'competingStores', 'aggregationTarget'].includes(key));
        }
        if(major === 'marketArea' && minor === 'profile'){
            return masterKeys.filter(key => !['categories', 'competingStores', 'target'].includes(key));
        }
        if(major === 'marketArea' && minor === 'peopleFlow'){
            return masterKeys.filter(key => ['store', 'segment', 'target'].includes(key));
        }
        if((major === 'marketArea' && minor === 'share') || (major === 'marketArea' && minor === 'heatMap')){
            return masterKeys.filter(key => ['store', 'segment', 'target', 'competingStores'].includes(key));
        }
        if(['assortment', 'marketArea'].includes(major) && ['summaryByPopulation', 'summary'].includes(minor)){
            return masterKeys.filter(key => !['categories', 'competingStores', 'aggregationTarget'].includes(key));
        }
        return masterKeys.filter(key => !['target', 'aggregationTarget'].includes(key));
        
    }
    const getFilterCondition = (major, minor) => {
        if(major === 'sales'){
            minor = null;
        }

        const path             = getPath(major, minor);
        const isSingleTable    = getSingleTable(major, minor);
        const resultURL        = `/${path}/result`;
        const tabCondition     = getTabCondition(major, minor);
        const filterOptions    = getFilterOptions(major, minor);
        const useCategoryLevel = filterOptions.includes('categories');
        const useTradeArea     = major === 'marketArea' && minor === 'summary';
        const useRangeSlider   = isUseRangePath.includes(path);
        const isShowCondition  = Boolean(path.indexOf('pricing/competitor') > -1);
        const useConditionBox  = !submitDisabled && isShowCondition && isShowFilter;
        const showChainAndSegmentSwitch = major === 'marketArea' && minor === "share";
        const showPerSegmentSwitch = major === 'marketArea' && minor ==='heatMap';
        return {
            path,
            isSingleTable,
            resultURL,
            filterOptions,
            tabCondition,
            useCategoryLevel,
            useTradeArea,
            useRangeSlider,
            useConditionBox,
            showChainAndSegmentSwitch,
            showPerSegmentSwitch
        }
    }

    const {
        path,
        isSingleTable,
        resultURL,
        filterOptions,
        tabCondition,
        isGroupCheckEnabled,
        useCategoryLevel,
        useTradeArea,
        useRangeSlider,
        useConditionBox,
        showChainAndSegmentSwitch,
        showPerSegmentSwitch
    } = getFilterCondition(major, minor);
    const getFlatList = (lists) => {
        return lists.filter(list => list.visible).map(list => {
            return list.child ? getFlatList(list.child) : list;
        }).flat();
    }
    const checkSubmitDisabled = () => {
        const keys = getFilterOptions(major, minor);
        let isCurrentPageHaveMeshIds = ['marketArea/peopleFlow', 'marketArea/profile'].includes(
            `${major}/${minor}`
        );
        const distanceKm = currentFormValues.current.range * 1000;
        const nullData = keys
            .map((key) => {
                const flatLists = getFlatList(currentFormValues.current?.[key] || []);
                const checkedLength =
                    flatLists?.filter((list) =>
                        list?.competitor_store_distance
                            ? (isCurrentPageHaveMeshIds ? (list.checked || list.selected) : list.checked) &&
                              list?.competitor_store_distance <= distanceKm
                            : isCurrentPageHaveMeshIds
                            ? (list.checked || list.selected)
                            : list.checked
                    )?.length || 0;
                return checkedLength || null;
            })
        if(isCurrentPageHaveMeshIds) {nullData.push(currentFormValues.current?.mapMeshIds?.length || null)}
        let filteredNullData =  nullData.filter((data) => data == null);
        const _submitDisabled = Boolean(filteredNullData.length);
        setSubmitDisabled(_submitDisabled);
    };
    const toggleIsReload = () => {
        setIsReload(!isReload);
    }
    const changeCategoryLevelOptions = (value) => {
        setCategoryLevelOptions(categoryLevelOptions.map(option => {
            return {
                ...option,
                selected : option.value === value,
            }
        }));
        currentFormValues.current.categoryLevel = value;
    }
    const changeTradeAreaOptions = (value) => {
        setTradeAreaOptions(value);
        currentFormValues.current.tradeAreaSlider = value;
    }
    const changeConditionUpdate = () => {
        setConditionUpdate(!conditionUpdate);
    }
    React.useEffect(()=>{
        setUseTab(tabCondition.useTab);
        setUseIndicator(tabCondition.useIndicator);
        checkUnderDevelopment();
        //setCurrentMajor(major);
        //setCurrentMinor(minor);
    }, [major, minor]);
    React.useEffect(()=>{
        triggerWindowResize();
        return () => {
            clearTriggerWindowResize();
        }
    }, [isShowFilter]);
    React.useState(() => {
        checkSubmitDisabled();
    }, []);
    return (
        <>
            
            {
                isShowFilter && (
                    <>
                        <Toolbar 
                            //range={range}
                            isShowFilter={isShowFilter}
                            isShowResult={isShowResult}
                            setIsShowResult={setIsShowResult}

                            showDateRange={true}
                            showTabSwitch={useTab}
                            showIndicatorSwitch={useIndicator}
                            showCategoryLevel={useCategoryLevel}
                            showChainAndSegmentSwitch={showChainAndSegmentSwitch}
                            showPerSegmentSwitch={showPerSegmentSwitch}
                            path={path}
                            // Initial version does not use UI
                            // Will be used by product version
                            showTradeArea={useTradeArea}

                            toggleShowFilter={toggleShowFilter}
                            changeSubmitValues={changeSubmitValues}
                            currentFormValues={currentFormValues}

                            isTab={isTab}
                            isIndicator={isIndicator}
                            isPostBodyType={isPostBodyType}
                            toggleIsTab={toggleIsTab}
                            toggleIsIndicator={toggleIsIndicator}
                            toggleIsPostBodyType={toggleIsPostBodyType}
                            toggleIsReload={toggleIsReload}
                            categoryLevelOptions={categoryLevelOptions}
                            changeCategoryLevelOptions={changeCategoryLevelOptions}
                            tradeAreaOptions={tradeAreaOptions}
                            changeTradeAreaOptions={changeTradeAreaOptions}
                            submitDisabled={submitDisabled}
                        />
                        <FilterList
                            major={major} 
                            minor={minor}
                            isShowResult={isShowResult}
                            useConditionBox={useConditionBox}
                            currentFormValues={currentFormValues}
                            changeSubmitValues={changeSubmitValues}
                            filterOptions={filterOptions}
                            filterMenuScrollTop={filterMenuScrollTop}
                            checkSubmitDisabled={checkSubmitDisabled}
                            setSelectedStores={setSelectedStores}
                            mapCells={mapCells}
                            setMapCells={setMapCells} 
                            selectedStores={selectedStores}
                            showRangeSlider={useRangeSlider}
                            changeConditionUpdate={changeConditionUpdate}
                            //updateFilterSelected={updateFilterSelected}
                        />
                    </>
                )
            }
            {
                useConditionBox && (
                    <Condition 
                        currentFormValues={currentFormValues}
                        isUpdate={conditionUpdate}
                    />
                )
            }
            
            {
                !isShowResult && (
                    <ToolFooter 
                        to={resultURL}
                        text='データ作成'
                        submitDisabled={submitDisabled}
                    />
                )
            }

            <Route path={resultURL} render={() => {
                return (
                    <Result 
                        major={major}
                        minor={minor}
                        isShowResult={isShowResult}
                        isShowFilter={isShowFilter}
                        toggleShowFilter={toggleShowFilter}
                        currentFormValues={currentFormValues}
                        getFilterOptions={getFilterOptions}
                        mapCells={mapCells}
                        setMapCells={setMapCells}
                        isTab={isTab}
                        isIndicator={isIndicator}
                        isPostBodyType={isPostBodyType}
                        toggleIsTab={toggleIsTab}
                        toggleIsIndicator={toggleIsIndicator}
                        toggleIsPostBodyType={toggleIsPostBodyType}
                        addAlert={addAlert}
                        alert={alert}
                        isReload={isReload}
                        tabCondition={tabCondition}
                        isShowChart={isShowChart}
                        setIsShowChart={setIsShowChart}
                        showChainAndSegmentSwitch={showChainAndSegmentSwitch}
                    />
                )
            }} />
        </>
    );
}
