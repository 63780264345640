import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
    root: {
        display : 'flex',
        justifyContent : 'center',
        alignItems: 'center',
        width: '100%',
        height : '100%',
        position : 'relative',
        zIndex : 1,
    },
}));

export default function LoadingCircle(props) {
    const classes = useStyles();
    const {
        style=null,
    } = props;

    return (
        <div className={classes.root} style={style}>
            <CircularProgress />
        </div>
    );
}
