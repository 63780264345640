import ndjson from 'fetch-ndjson';
import { getAuth } from 'firebase/auth';
const API_ENV = process.env.REACT_APP_ENV || "dev";
const API_HASH = ["demo","staging","dev"].includes(API_ENV) ? "4b4hcxda" : "3nrcsvs6"
const API_URL = `marketing-view-premium-${API_ENV}-${API_HASH}.an.gateway.dev`;
const API_RETRY_COUNT_LIMIT = 500;
const validateResponse = (type='json') => (res) => {
    if(res.status === 200){
        return type === 'json' ? res.json() : res.text();
    }
    throw new Error(res.statusText);
}
/* const getKeyInCookie = (key) => {
    const cookies = document.cookie;
    const parseCookie = cookies
        .split(';')
        .map(v => v.split('='))
        .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
            return acc;
        }, {});
    return parseCookie[key] || null;
} */
const getToken = async () =>{
    return getAuth().currentUser.getIdToken();
}
const getNdJsonFetch = async (token, history, url, dataBody=null, limitCount=0, prevJobId=null) => {
    
    let latestToken = await Promise.resolve(getToken()).then(res=>res)
    const api_token = latestToken || token /* || getKeyInCookie('jwt') */ || null;
    if(!api_token){
        console.log('token invalid...');
        return null;
    }
    const method  = dataBody === null || prevJobId ? 'GET' : 'POST';
    const sendURL = prevJobId ? `https://${API_URL}/query_job_stats/${prevJobId}` : url;
    
    const {
        status='error',
        message='Job execution failed',
        job_id=null,
        download_url=null,
    } = await fetch(sendURL, {
        method: method,
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_token}`,
        },
        //body : JSON.stringify(dataBody),
        body : method === 'POST' ? dataBody && JSON.stringify(dataBody) : null,
        
    })
        .then(validateResponse())
        .then(json => {
            return json
        })
        .catch(err => {
            //console.log('err', err);
            //history.push(`/?back=${window.location.pathname}`);
            return {
                status : 'error',
            };
        });

    limitCount++;

    if(status === 'error' || limitCount > API_RETRY_COUNT_LIMIT){
        return {
            id : `getDownloadUrl_${new Date().getTime()}`,
            severity : 'error',
            status : 'error',
            message : message || 'API call limit'
        }
    }
    if(status !== 'success'){
        const isDone    = status === 'DONE';
        const jobId     = isDone ? null :  job_id || prevJobId;
        const sleepTime = isDone ? 0 : 750;
        await sleep(sleepTime);
        return getNdJsonFetch(token, history, url, dataBody, limitCount, jobId);
    }
    if(download_url){
        const res    = await fetch(download_url);
        if(res.status !== 200){
            return {
                id : `getDownloadUrl_${new Date().getTime()}`,
                severity : 'error',
                status : 'error',
                message : res?.statusText,
            }
        }
        const reader = res.body.getReader();
        const gen    = ndjson(reader);
        const data   = [];

        while (true) {
            const { done, value } = await gen.next();
            value && data.push(value);
            if (done) {
                //console.log('data', data);
                return data;
            }
        }
    }
}
const sleep = (msec) => {
    return new Promise(resolve => setTimeout(resolve, msec));
}
export {
    API_URL,
    API_ENV,
    validateResponse,
    // getKeyInCookie,
    getNdJsonFetch,
    sleep,
}