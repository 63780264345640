export const LAYER_IDS = {
  cell: 'h3-cell',
  selected: 'h3-selected',
  query: 'h3-query',
};

export const CELL_LAYER_CONFIG = {
  id: LAYER_IDS.cell,
  source: LAYER_IDS.cell,
  type: 'fill',
  paint: {
    'fill-opacity': 0.5,
  },
};

export const SELECTED_LAYER_CONFIG = {
  id: LAYER_IDS.selected,
  source: LAYER_IDS.selected,
  type: 'line',
  paint: {
    'line-color': 'rgb(24, 144, 248)',
    'line-width': 3,
    'line-offset': 3,
  },
};

export const INITIAL_VIEW = {
  longitude: 139.592315,
  latitude: 35.587508,
  zoom: 14
};

export const COLOR_SCALE = [
'rgb(0, 150, 80)',
'rgb(0, 186, 56)',
'rgb(123, 202, 13)',
'rgb(196, 222, 0)',
'rgb(255, 244, 0)',
'rgb(255, 193, 0)',
'rgb(255, 142, 0)',
'rgb(255, 90, 0)',
'rgb(255, 0, 6)',
];

export const COMPARISON_MAP_COLORS = [
  'rgb(24, 144, 248)',
  'rgb(255, 244, 0)',
  'rgb(255, 142, 0)',
  'rgb(195, 195, 195)'
]
