import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import FilterMenu from './FilterMenu/FilterMenu.jsx';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    flexContainer : (props) => ({
        display  : 'flex',
        position : 'relative',
        zIndex   : 1,
        height   : props.isShowResult ? props.showResultHeight : `calc(100vh - ${props.marginHeight}px)`,
        transition : 'height .2s linear',
    }),
    flexBox : {
        maxHeight : 'calc(100vh - 64px - 32px - 72px)',
        overflowY : 'auto',
        overflowX : 'hidden',
        padding   : '0 8px',
        flex      : 1,
    },
}));
export default function FilterList(props) {
    const {
        isShowResult,
        updateFilterSelected,
        major, 
        minor,
        masterDataCache,
        currentFormValues,
        filterOptions,
        filterMenuScrollTop,
        checkSubmitDisabled,
        setSelectedStores,
        selectedStores,
        showRangeSlider=false,
        useConditionBox=false,
        changeConditionUpdate,
        mapCells,
        setMapCells,
    } = props;
    let showResultHeight = 180;
    let marginHeight = 236;
    if(useConditionBox){
        marginHeight += 70;
        showResultHeight += 120;
    }
    const classes = useStyles({
        isShowResult,
        showResultHeight,
        marginHeight,
    });
    React.useEffect(()=>{
        const scrollRefs = {};
        Object.keys(filterOptions).map(key => {
            scrollRefs[key] = 0;
        });
        filterMenuScrollTop.current = scrollRefs;
    });
    const isAssortmentSummary = ['assortment/summary'].includes(`${major}/${minor}`)
    return (
        <div className={classes.flexContainer}>
            {
                filterOptions.map(key => {
                    // TODO : This redundant flag management could be a bit more organized
                    const isSalesAnalysisSummaryTarget    = ['sales'].includes(`${major}`)    && key === 'target';
                    const isAssortmentSummaryTarget    = ['assortment/summary'].includes(`${major}/${minor}`)    && key === 'target';
                    const isMarketAreaSummaryTarget    = ['marketArea/summary'].includes(`${major}/${minor}`)    && key === 'target';
                    const isMarketAreaShareTarget      = ['marketArea/share'].includes(`${major}/${minor}`)      && key === 'target';
                    const isMarketAreaPeopleFlowTarget = ['marketArea/peopleFlow'].includes(`${major}/${minor}`) && key === 'target';
                    const isMarketAreaHeatMap = ['marketArea/heatMap'].includes(`${major}/${minor}`) && key === 'target';
                    return (
                        <FilterMenu 
                            key={`filter-menu-${key}`}
                            masterDataKey={key}
                            currentFormValues={currentFormValues}
                            filterMenuScrollTop={filterMenuScrollTop}
                            checkSubmitDisabled={checkSubmitDisabled}
                            isAssortmentSummaryTarget={isAssortmentSummaryTarget}
                            isMarketAreaSummaryTarget={isMarketAreaSummaryTarget}
                            isMarketAreaShareTarget={isMarketAreaShareTarget}
                            isMarketAreaPeopleFlowTarget={isMarketAreaPeopleFlowTarget}
                            isSalesAnalysisSummaryTarget={isSalesAnalysisSummaryTarget}
                            isMarketAreaHeatMap={isMarketAreaHeatMap}
                            filterOptions={filterOptions}
                            setSelectedStores={setSelectedStores}
                            selectedStores={selectedStores}
                            major={major} 
                            minor={minor}
                            showRangeSlider={showRangeSlider}
                            changeConditionUpdate={changeConditionUpdate}
                            mapCells={mapCells}
                            setMapCells={setMapCells}
                        />
                    )
                })
            }
        </div>
    );
}