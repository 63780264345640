import { useState,useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../AuthenticationContext";
import { collection, query, where, getDocs, updateDoc, doc,getDoc } from "firebase/firestore"; 
import { Col, Input , Row, Spinner} from "reactstrap";
import { Button, Divider} from "@material-ui/core";
import './EditScreenTerminology.scss'

export default function EditScreenTerminology (){
    const [loading, setLoading] = useState(true);
    const {user,db, userProfile} = useContext(AuthContext);
    const [terms, setTerms] = useState(null)
    const [docId, setDocId] = useState(null);
    const [fetchError, setFetchError] = useState(null)

    const termsRef = collection(db, "terminology");

    async function fetchTerms(){
        if(user){
            setLoading(true)
            fetchError && setFetchError(null);
            const q = query(termsRef, where("group_id", "==", userProfile?.group_id));
            await getDocs(q).then( async(snapshot)=>{
                if(!snapshot.empty){
                    let terms = {}
                    snapshot.forEach((data)=>{
                        terms =  {...data.data().dictionary,...terms} ;
                        setDocId(data.id)
                    })
                    setTerms(terms)
                    const default_term = doc(db, "terminology", "terminology_default");
                    await getDoc(default_term).then((snapshot)=>{
                        if(snapshot.exists()){
                            let defaultTerms = snapshot.data().dictionary;
                            const orderedTerms = Object.keys(defaultTerms).sort().reduce(
                                (obj, key) => {  obj[key] = defaultTerms[key];  return obj; },  {} );
                            let termsInList = Object.entries(orderedTerms).map((term)=>{
                                return {"field":term[0],"defaultTerm":term[1],"userTerm":terms[term[0]]}
                            })
                            setTerms(termsInList)
                        }else{
                            setFetchError("No default terms !")
                            setTerms(null)
                        }
                    }).catch((error)=>{
                        setFetchError(error.code)
                    })
                }else{
                    setTerms(null)
                }
            }).catch((error)=>{
                setFetchError(error.code)
            })
            .finally(()=>{
                setLoading(false)
            })
            }
        }

    useEffect(()=>{
        fetchTerms()
    }, [])

    const LoadingTerms = useMemo(() =>{
        return ()=>{
            return (
                <div style={{position:"relative", top:"40%",textAlign:"center"}} >
                     <Spinner/>
                </div>
            )
        }
       
    },[])

    const NotFoundMessage = useMemo( () =>{
        return()=>{
            return(
                <div style={{position:"relative", top:"40%",textAlign:"center" }} >
                    You dont have any screen terms to see or edit, contact your admin. 
                </div>
            )
        }
       
    },[])
    return<>
    <h3>Screen Terminology</h3>
    {loading ? <LoadingTerms/> : 
        !terms || (terms && terms.length === 0) ? <NotFoundMessage/> :
            terms ? (userProfile?.role_id  === "admin" ? <EditTerms terms={terms} setTerms={setTerms} docId={docId}  /> : <ListOfTerms terms={terms}/>) :
                fetchError && <div>{fetchError}</div>  }
    </>
}

function EditTerms(props){

    const terms  = props?.terms
    const docId = props?.docId

    const Terms = () => {return terms.map((term,i) =>{return <SingleTermComponent terms={terms} docId={docId} {...term}  key={i}/> })}

    return <>
    
    {terms && <Terms/>}
    </>
     
}


function SingleTermComponent(props){
    const [newValue, setNewValue] = useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [invalidMessage, setInvalidMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [edit,setEdit] = useState(false)
    const docId = props?.docId
    const defaultTerm = props?.defaultTerm;
    const [userTerm, setUserTerm] = useState(props?.userTerm);
    const {db} = useContext(AuthContext)
    const field  = props?.field;

    const onClickEdit = () =>{
        setEdit((prevState) => {
            newValue && setNewValue(null);
            successMessage && setSuccessMessage(null);
            invalidMessage && setInvalidMessage(null);
            return !prevState
        })
    }


    const updateTermValue = async() =>{
        let termDocRef = doc(db,"terminology",docId)
        let fieldMap = {};
        let dicField = `dictionary.${field}`;
        fieldMap[dicField] = newValue
        successMessage && setSuccessMessage(null);
        setSubmitting(true)
        await updateDoc(termDocRef, {...fieldMap}).then(async (response)=>{
            await updateDoc(termDocRef,{...fieldMap}).then((updateResponse)=>{
                setUserTerm(newValue)
                setNewValue(null)
                setSuccessMessage("Value updated Successfully.")
                console.log(response)
            }).catch((error)=>{
                setInvalidMessage(error.code)
                console.log(error)
            })
        }).catch((error)=>{
            setInvalidMessage(error.code)
            console.log(error)
        }).finally(()=>{
            setSubmitting(false);
        })
    }

    const onClickSubmit = ()=>{
        invalidMessage && setInvalidMessage(null)
        if(newValue && newValue.trim().length !== 0 ){
            updateTermValue();
            return
        }
        setInvalidMessage("New term required to submit.")
    }

    let userTermHeight = () =>{return !userTerm ? "calc(1.5em + 0.75rem + 2px)" :""  }
    let defaultTermHeight = () =>{return !defaultTerm ? "calc(1.5em + 0.75rem + 2px)" :""  }

    return(
        <>
   <Row>
        <Col md={11} style={{}}>
            <Row style={{marginTop:"5px"}} >
                <Col md={5}>
                    <div className="termCard" style={{ height:defaultTermHeight()}} > {defaultTerm}</div>  
                </Col>
                <Col md={5}>
                    <div className="termCard" style={{ height:userTermHeight() }}> {userTerm}</div> 
                </Col>
                <Col md={1}>
                    <Button disabled={submitting} style={{marginTop:"8px"}} size="small" variant="contained" onClick={onClickEdit} color={!edit ? "primary" : "default"} >
                        { !edit ? "Edit" : "Cancel" }
                    </Button>
                </Col>
            </Row>
            {edit && <Row> 
                        <Col md={5}>
                        </Col>
                        <Col md={5}>
                            <Input bsSize="sm" disabled={submitting}  style={{marginTop:"5px", resize:"none", }} type="textarea" onChange={(e) => {setNewValue(e.target.value )}} value={newValue || ""} placeholder="New value text !"  />
                            {(edit && invalidMessage) && <p style={{color:"red", }}>{invalidMessage}</p>}
                            {(edit && successMessage) && <p style={{color:"green"}}>{successMessage}</p>} 
                        </Col>
                        <Col md={1} >
                        <Button disabled={submitting} size="small" style={{marginTop:"15px"}}  variant="contained" onClick={onClickSubmit}  color="primary" > {submitting ? <Spinner /> : "Submit" }</Button>
                        </Col>
                    </Row>}
        </Col>    
</Row>
<Divider variant="middle"  style={{width:"80%", textAlign:"center", margin:"10px"}} />  
</>
)}



function ListOfTerms(props){
    const terms = props?.terms

    const TermComp = (term) =>{
        let userTermHeight = () =>{return !term?.userTerm ? "calc(1.5em + 0.75rem + 2px)" :""  }
        let defaultTermHeight = () =>{return !term?.defaultTerm ? "calc(1.5em + 0.75rem + 2px)" :""  }
        return (
                <Row style={{marginTop:"5px"}} >
                    <Col md={5}>
                        <div className="termCard" style={{ height:defaultTermHeight()}}> {term?.defaultTerm}</div>  
                    </Col>
                    <Col md={5}>
                        <div className="termCard" style={{ height:userTermHeight()}}>{term?.userTerm} </div> 
                    </Col>
                </Row>
        )

    }

    const Terms = () => {return terms.map((term,i)=>{return <TermComp key={i} {...term} /> })}

    return <>
        {terms &&  <Terms/> }
     </> 
}