import moment from 'moment';
import targetFilter from './json/targetFilter.json';
import aggregationTarget from './json/aggregationTarget.json';

const indexedDB = window.indexedDB || window.mozIndexedDB || window.msIndexedDB;
const dbVersion = 1;
const createIDBDatabase = async (databaseName='masterDB', addTables=[]) => {
        await deleteDb(databaseName);
        const db = await getDb(databaseName, 'create', addTables.map(table => table.tableName));
        await addRows(databaseName, addTables);
        return ;
}
const addRows = async (databaseName, addData) => {
    const params = addData.shift();
    const {
        tableName,
        rows,
    } = params;

    const db = await getDb(databaseName);
    const transaction = db.transaction([tableName], "readwrite");
    transaction.oncomplete = function(event) {
        db.close();
        if(addData.length){
            addRows(databaseName, addData);
            return;
        }
        window.localStorage.setItem('hasMasterDB', true);
        window.localStorage.setItem('expirationTime', new Date(moment().format('YYYY-MM-DD 23:59:59')).getTime());
        console.log("End of all table inserts");
    };
    
    transaction.onerror = function(event) {
        db.close();
        console.log(`insert process failure → ${databaseName} ${tableName}`);
    };
    
    const objectStore = transaction.objectStore(tableName);
    for (let i in rows) {
        const request = objectStore.add(rows[i]);
        request.onsuccess = function(event) {
            // Note that if a large number of records exist, 
            // the browser will slow down when console log output, etc. is executed.
        };
    }



}
const deleteDb = async (databaseName) => {
    console.log('del');
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(databaseName);

        request.onsuccess = () => {
            console.log(`Database successfully deleted → ${databaseName}`)
            return resolve(request.result);
        }
        request.onblocked = () => {
            console.log(`Database deletion blocked → ${databaseName}
Transaction processing may not have been closed properly.
→ https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/close`);
            return resolve();
        }
        request.onerror = reject;
    });
}
const getDb = async (databaseName='masterDB', type='open', addTables=[]) => {
    return new Promise(function(resolve, reject) {
        const request = indexedDB.open(databaseName, dbVersion);
        request.onupgradeneeded = function (event) 
        {
            const db = event.target.result;
            db.onerror = () => {
                console.log('Error creating database');
            };
            if(type === 'open') return resolve(request.result);
            // Table creation is only possible when creating a database!
            //const keyParams = keyPath ? { keyPath: keyPath } : { autoIncrement : true };
            const initTables = [...addTables];
            initTables.forEach(tableName => {
                db.createObjectStore(tableName, { autoIncrement : true });
            });
            return resolve(request.result);
        }
        request.onblocked = () => {
            resolve(request);
        }
        request.onsuccess = function (event) { 
            return resolve(request.result);
        }
        request.onerror = function (event) {
            reject('error opening database ' + event.target.errorCode);
        }
    });
    /*
    return new Promise((resolve, reject) => {
        const request = type === 'open' ? await indexedDB.open(databaseName) : await indexedDB.open(databaseName, 1);
        request.onsuccess = () => {
            return resolve(request.result)
        };
        request.onblocked = () => {
            return resolve();
        }
        request.onupgradeneeded = (event) => {
            if(type === 'open') return resolve(request.result);
            const db = event.target.result;
            // Table creation is only possible when creating a database!
            //const keyParams = keyPath ? { keyPath: keyPath } : { autoIncrement : true };
            const initTables = [
                'store',
                'segment',
                'target',
                'categories',
                'competingStores',
            ]
            initTables.forEach(tableName => {
                db.createObjectStore(tableName, { autoIncrement : true });
            });

            return resolve(request.result)
        }
        request.onerror = reject;
    });
    */
}

const getAllRows = async (databaseName='masterDB', tableName='store') => {
    const db = await getDb(databaseName);
    if(!db.objectStoreNames || db.objectStoreNames.length === 0){
        console.log('database not found');
        db.close();
        return null;
    }
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([tableName]);
        const objItem = transaction.objectStore(tableName);
        const getRequest = objItem.getAll();
        
        getRequest.onsuccess = (event) => {
            const rows = event.target.result;
            db.close();
            return resolve(rows);
        }
        getRequest.onerror = reject;
    });
}
const getIDBMasterData = async () => {
    const databaseName = 'masterDB';
    const masterData = {
        store           : [],
        segment         : [],
        target          : [],
        categories      : [],
        competingStores : [],
    };
    const masterKeys = Object.keys(masterData);
    await Promise.all(masterKeys.map(async tableName => {
        masterData[tableName] = await getAllRows(databaseName, tableName);
        return masterData[tableName];
    }));
    const isAllNull = Boolean(Object.keys(masterData).filter(key => masterData[key] === null).length);
    masterData["target"] = targetFilter;
    return isAllNull ? null : masterData;

}
const getIDBFilterRows = async (tableName) => {
    const databaseName = 'masterDB';
    const rows = await getAllRows(databaseName, tableName);
    return rows;
}
export {
    createIDBDatabase,
    getIDBMasterData,
    getIDBFilterRows,
    deleteDb
}