import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({

});
const UI_Radiobox = React.forwardRef((props, ref) => {
    const classes = useStyles();
    React.useEffect(()=>{

    }, [])
    return (
        <Radio
            ref={ref}
            className={clsx(classes.Checkbox, props.className)}
            {...props}
        />
    )
});
UI_Radiobox.propTypes = {
    id    : PropTypes.string.isRequired,
    name  : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
}
UI_Radiobox.defaultProps = {

};

export default UI_Radiobox;