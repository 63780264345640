import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RakutenPointLogo from '../../common/Logo/RakutenPointLogo.jsx';
import Button from '../../common/Button/Button.jsx';

const useStyles = makeStyles((theme) => ({
    root : (props) =>({
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        width  : props.isFlex ? null : '100vw',
        height : props.isFlex ? null : '100vh',
        flex : 1,
    }),
    container : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        flexDirection : 'column',
    },
    message : {
        fontSize : 32,
        color : '#7f8c8d',
        padding : '12px 0 24px 0',
        '& .far' : {
            fontSize : '90%',
        }
    }
}));
export default function NotFound(props) {
    const {
        code=<span>4<i className="far fa-sad-tear"></i>4</span>,
        message='Not found...',
        isFlex=false,
    } = props;
    const classes = useStyles({
        isFlex,
    });
    const history = useHistory();
    const backHistory = (event) => {
        event.stopPropagation();
        event.preventDefault();
        history.goBack();
    }
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div><RakutenPointLogo /></div>
                <Typography className={classes.message}>
                    {code} <span style={{fontSize : '80%'}}>{message}</span>
                </Typography>
                <Button 
                    startIcon={<ArrowBackIcon />}
                    variant="contained"
                    onClick={backHistory}
                >Back</Button>
            </div>

        </div>
        
    )
}