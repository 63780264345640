import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Divider from '@material-ui/core/Divider';

function UserConsentDialog(props) {
    const { 
        close=null,
        aboutToDeleteOrUpdateFavouriteName=null, 
        open=null, 
        handleDeleteClick=null, 
        handleUpdateClick=null,
        isUpdateOldFavouriteName=false } = props;

    const handleDisagreeClick = () => {
        close();
    };

    const handleAgreeClick = () => {
        if(isUpdateOldFavouriteName){
        handleUpdateClick(aboutToDeleteOrUpdateFavouriteName)
        }else{
        handleDeleteClick(aboutToDeleteOrUpdateFavouriteName);
        }
    }
    const userConsentText = isUpdateOldFavouriteName ?
        "入力したタイトルが既に存在しています。上書き保存でよろしいでしょうか？" :
        `${aboutToDeleteOrUpdateFavouriteName} を削除しますか？`;
    const agreeText = isUpdateOldFavouriteName ?  "上書きする" : " 削除"
    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="alert-dialog-title">お気に入り条件</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {userConsentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagreeClick} color="primary">
                    キャンセル
                </Button>
                <Button onClick={handleAgreeClick} color="primary" autoFocus>
                {agreeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default React.memo(UserConsentDialog);
