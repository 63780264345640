import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({

});
const UI_Checkbox = React.forwardRef((props, ref) => {
    const classes = useStyles();
    React.useEffect(()=>{

    }, [])
    return (
        <Checkbox
            ref={ref}
            className={clsx(classes.Checkbox, props.className)}
            {...props}
        />
    )
});
UI_Checkbox.propTypes = {
    id    : PropTypes.string.isRequired,
    name  : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
}
UI_Checkbox.defaultProps = {

};

export default UI_Checkbox;