import React, { useState,Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import HeatMap from '../../HeatMap/HeatMap';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        
    },
    subInfoBox: {
        position: "relative",
        height:'280px',
        flex: 3,
        backgroundColor: 'rgba(0,0,0,0.05)',
        borderRadius:"5px"

    },
    segmentName:{
        flexGrow: 1,
        color:'#34495EBF' ,
        fontWeight:"bold!important"
    },
    segmentRow:{
        paddingTop: "10px"
    },
    chainName:{
        fontSize:"18px",
        color:'#34495EBF' ,
        fontWeight:"bold!important",
        textAlign: 'center',
        backgroundColor: "#f1f3f4"
    },
    title:{
        paddingTop: "5px",
        paddingBottom: "5px"
    }
}));


const OPTIONS = {
    root: null,
    rootMargin: "0px 0px 0px 0px",
    threshold: 0,
  };
  
const useIsVisible = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = React.useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            }),
        []
    );

    React.useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
};

function MarketAreaHeatmap(props) {
    let { heatMapData, storePosition, currentFormValues, filterOptions, selectedTargetInJapanese, ownStoreChainName=false } = props;
   
    const [dragedPosition, setDragedPosition ] = useState(storePosition[0]);

    const classes = useStyles();
    return (
       <div className={classes.root}>
            <Grid container className={classes.title}>
                    <Grid item   xs={6}>
                        <div className={classes.chainName}> {ownStoreChainName}</div>
                    </Grid>
                    <Grid item   xs={6}>
                        <div className={classes.chainName}>競合</div>
                    </Grid>
                </Grid>
            <Grid container style={{ overflow:"scroll", height: 'calc(100vh - (188px + 12px))'}} spacing={1}>
            <React.Fragment>
                {Object.keys(heatMapData).map((k) => {
                    return (
                        <SegmentWiseRow
                            key={k}
                            segmentName={k}
                            data={heatMapData[k]}
                            storePosition={storePosition}
                            currentFormValues={currentFormValues}
                            filterOptions={filterOptions}
                            dragedPosition={dragedPosition}
                            onMoveEnd={(e) => {
                                setDragedPosition(e);
                            }}
                            selectedTargetInJapanese={selectedTargetInJapanese}
                        />
                    );
                })}
                </React.Fragment>
            </Grid>
        </div>
    );
}

const SegmentWiseRow = React.memo((props) => {
    let { segmentName, data, storePosition, currentFormValues, filterOptions, onMoveEnd, dragedPosition, selectedTargetInJapanese } =
        props;
    const classes = useStyles();
    
    const elemRef = React.useRef();
    const isVisible = useIsVisible(elemRef);

    return (
        <Grid    ref={elemRef}  container  className={classes.segmentRow}>
            { segmentName !="allSegments" && <Grid item xs={12} > 
            <div className={classes.segmentName}>{segmentName !="allSegments" && segmentName   }</div>
            </Grid>}
            <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6}>
                        <div className={classes.subInfoBox}>
                            {data?.own  ? (
                                isVisible &&
                            
                                <HeatMap
                                    mapCells={data?.own?.mapCells}
                                    storePosition={storePosition}
                                    currentFormValues={currentFormValues}
                                    filterOptions={filterOptions}
                                    onMoveEnd={onMoveEnd}
                                    dragedPosition={dragedPosition}
                                    popupText = {selectedTargetInJapanese}
                                    valuePostfix = {"%"}
                                />
                            ) : (
                                <div>no data for own store</div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.subInfoBox}>
                            {data?.competitors ? (
                                isVisible &&
                                <HeatMap
                                    mapCells={data?.competitors?.mapCells}
                                    storePosition={storePosition}
                                    currentFormValues={currentFormValues}
                                    filterOptions={filterOptions}
                                    onMoveEnd={onMoveEnd}
                                    dragedPosition={dragedPosition}
                                    popupText = {selectedTargetInJapanese}
                                    valuePostfix = {"%"}
                                />
                            ) : (
                                <div>no data for competitors</div>
                            )}
                        </div>
                    </Grid>
            </Grid>
        </Grid>
      
    );
});

export default React.memo(MarketAreaHeatmap);





