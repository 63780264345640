import React, {useState, useRef, useContext} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '../../common/Link/Link.jsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation, useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Button from '../../common/Button/Button.jsx';
import TextField from '../../common/TextField/TextField.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import RakutenPointLogo from '../../common/Logo/RakutenPointLogo.jsx';
import Version from '../../components/Version/Version.jsx';
import { AuthContext } from '../../AuthenticationContext';
const useStyles = makeStyles((theme) => ({
    root : {
        width : '100vw',
        height : '100vh',
        backgroundColor : '#fff',
        display : 'flex',
        alignItems : 'center',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function SignIn(props) {
    const classes = useStyles();
    const {
        addAlert,
    } = props;
    const {
        setEmail,
        setPassword,
        errorMessage,
        isLoading,
        loginWithEmail, 
        logout,
    } = useContext(AuthContext);

    const [english, setEnglish] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const history  = useHistory();
    const location = useLocation();
    const sendLogin = async (e) => {
        addAlert([]);
        e.preventDefault();
        const result = await loginWithEmail();
        let searchQueries  = new URLSearchParams(location.search);
        const backURL      = searchQueries.get('back') || null;
        let afterLoginPath = backURL || '/sales';
        console.log('afterLoginPath', backURL);
        // result && history.replace(afterLoginPath);
        return false;
    }
    const changeShowPassword = () => {
        setShowPassword(!showPassword);
    }

    React.useEffect(()=>{
        //console.log('errorMessage', errorMessage);
        errorMessage && addAlert({
            id : `login-${new Date().getTime()}`,
            severity : 'warning',
            message : errorMessage,
            vertical : 'bottom',
        });
    }, [errorMessage]);
    // React.useEffect(()=>{
    //     (async () => {
    //         await logout();
    //     })();
    // }, [])
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper} style={{paddingBottom : 124,}}>
                <RakutenPointLogo />
                <form className={classes.form} noValidate onSubmit={sendLogin}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={english ? 'Email' : 'ユーザID'}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        type="email"
                        disabled={isLoading}
                        onChange={(event)=>setEmail(event.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={english ? 'Password' : 'パスワード'}
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        disabled={isLoading}
                        onChange={(event)=>setPassword(event.target.value)}
                        InputProps={{
                            endAdornment : (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={changeShowPassword}
                                        onMouseDown={changeShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size="large"
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <CircularProgress style={{
                                width : 16,
                                height : 16,
                                color : '#fff',
                                marginRight : 12,
                            }} />
                        )}
                        {english ?  'Login' : 'ログイン'}
                    </Button>
                
                </form>
            </div>
            </Container>
            <Version />
        </div>
    );
}