import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '../../common/Button/IconButton.jsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';

import RakutenPointLogo from '../../common/Logo/RakutenPointLogo.jsx';
import AppMenu from '../AppMenu/AppMenu.jsx';

const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        backgroundColor : '#f0f3f4',
        paddingLeft : 12,
        ...theme.mixins.toolbar,
    },
    drawerPaper: (props) => ({
        position: 'relative',
        whiteSpace: 'nowrap',
        width: props.open ? props.drawerWidth : null,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor : '#e8e9ea',
    }),
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
}));

export default function APP_Drawer(props) {
    const {
        open,
        handleDrawerClose,
        drawerWidth=280,
        appMenu,
    } = props;

    const classes = useStyles({
        drawerWidth,
        open,
    });

    React.useEffect(()=>{

    }, [])

    return (

        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                {open && (<RakutenPointLogo style={{
                height : 36,
                width : 'auto',
            }} />)}
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
                <AppMenu  appMenu={appMenu} open={open} />
        </Drawer>
    );
}