import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useLocation } from 'react-router-dom';
import Checkbox from '../../common/Checkbox/Checkbox.jsx';
import Radio from "../../common/Radiobox/Radiobox"
import NestedList from './NestedList.jsx';

import { commonStyles } from '../../css/css.jsx';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position : 'relative',
        zIndex : 1,
        contentVisibility : 'auto',
    },
    nowrap : {
        whiteSpace : 'nowrap',
        overflow : 'hidden',
        textOverflow : 'ellipsis',
    }
}));

export default function CheckboxList(props) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const {
        menus=[],
        onChange,
        changeGroupSelectMenus,
        onExpand,
        filterId,
        isGroupCheckEnabled,
        isRadio
    } = props;
    const location = useLocation();
    const handleToggle = (value) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        onChange && onChange(value);
    };
    // Exclude stores without mesh_cd flag
    const isMarketAreaInStore = Boolean(location.pathname.indexOf(/marketArea/) > -1 && filterId === 'store');
    return (
        <List className={classes.root}>
        {
            menus
                .filter(menu => menu.visible)
                .filter(menu => !isMarketAreaInStore || (isMarketAreaInStore && menu.value.mesh_cd))
                .map((options, i) => {
                const {
                    label,
                    subLabel:sub='',
                    checked,
                    groupName=null,
                    child=null,
                    key,
                    uniqueKey=null,
                    nowrap=false,
                } = options;
                const id = `${label}${sub}`;
                if(groupName && child){
                    return (
                        <NestedList 
                            key={`nestedList-${i}`}
                            group={options}
                            onChange={changeGroupSelectMenus}
                            onExpand={onExpand}
                            filterId={filterId}
                            isGroupCheckEnabled={isGroupCheckEnabled}
                            indent={1}
                        />
                    )
                }
                return (
                    <ListItem 
                        key={`list-item-${id}-${i}`} 
                        role="listitem" 
                        dense 
                        button 
                        onClick={handleToggle(label, uniqueKey)}
                    >
                        <ListItemIcon>
                            {
                                isRadio
                                ?
                                    <Radio
                                        id={id}
                                        name={filterId}
                                        value={label}
                                        edge="start"
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': id,
                                            'data-key' : key,
                                        }}
                                        color="primary"
                                    />
                                :
                                    <Checkbox
                                        id={id}
                                        name={filterId}
                                        value={label}
                                        edge="start"
                                        checked={checked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': id,
                                            'data-key' : key,
                                        }}
                                        color="primary"
                                    />
                            }
                        </ListItemIcon>
                        <ListItemText title={nowrap ? label : null} id={id} primary={
                            <div className={clsx(commonClasses.listLabel, 'checkListLabelBox')}>
                                <span className={nowrap ? classes.nowrap : commonClasses.main}>{label}</span>
                                {sub && <span className={commonClasses.sub}>{sub}</span>}
                            </div>
                        } />
                    </ListItem>
                );
            })
        }
        </List>
    );
}
