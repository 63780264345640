import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COMPARISON_MAP_COLORS } from '../../constants/mapBuilder';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  legend: {
    width: '62px',
  },
  legendBody: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  legendItem: {
    height: '20px',
    position: 'relative',
  },
  legendItemColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.5,
    zIndex: 1,
  },
  legendItemValue: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
    color: '#000',
    zIndex: 2,
  },
}));

const comparisonLegendItems = [
  ['等しい', COMPARISON_MAP_COLORS[0]],
  ['以上', COMPARISON_MAP_COLORS[1]],
  ['以下', COMPARISON_MAP_COLORS[2]],
  ['ディフォルト', COMPARISON_MAP_COLORS[3]]
]

function Legend(props) {
    const { colorIntervals, comparisonMap } = props;
    const classes = useStyles({});

    const legendItems = React.useMemo(() => {
        if (comparisonMap) {
            return comparisonLegendItems;
        }
        return colorIntervals.map(([value, color]) => [Math.round(value * 100) / 100, color]);
    }, [colorIntervals, comparisonMap]);

    return (
        <div className={classes.legend}>
            <div  className={classes.legendBody}>
                    <React.Fragment>
                        {legendItems.map(([value, color]) => (
                            <div className={classes.legendItem} key={value}>
                                <div
                                    className={classes.legendItemColor}
                                    style={{ backgroundColor: `${color}` }}
                                />
                                <div className={classes.legendItemValue}>{value.toLocaleString()}</div>
                            </div>
                        ))}
                    </React.Fragment>
            
            </div>
        </div>
    );
}

Legend.propTypes = {
  colorIntervals: PropTypes.array,
  comparisonMap: PropTypes.bool,
};

export default React.memo(Legend);
