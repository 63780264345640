import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TranslateIcon from '@material-ui/icons/Translate';

import AccountOptions from '../../components/Account/AccountOptions.jsx';
import RakutenPointLogo from '../../common/Logo/RakutenPointLogo.jsx';
import IconButton from '../../common/Button/IconButton.jsx';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor : '#fff',
       
    },
    appBarShift: (props) => ({
        marginLeft: props.drawerWidth,
        width: `calc(100% - ${props.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        color:'#34495EBF' ,
        fontWeight:"bold!important"
    },
}));


export default function Header(props) {
    const {
        open,
        handleDrawerOpen,
        drawerWidth,
        title="title",
        appMenu=[],
    } = props;

    const classes = useStyles({
        drawerWidth,
        open,
    });

    React.useEffect(()=>{

    }, []);

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
            <IconButton
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
                <MenuIcon />
            </IconButton>
            {!open && (<RakutenPointLogo style={{
                height : 36,
                width : 'auto',
                marginRight:20
            }} />)}
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}  >
                {title}
            </Typography>
            <IconButton color="primary" disabled={true}>
                <TranslateIcon />
            </IconButton>
            <IconButton color="primary" disabled={true}>
                <Badge 
                    badgeContent={0} 
                    overlap='rectangular'
                    color='secondary'
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <AccountOptions appMenu={appMenu} />
            </Toolbar>
        </AppBar>
    );
}