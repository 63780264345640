import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({

});

export default function UI_IconButton(props) {
    const classes = useStyles();
    const {
        children=null,
    } = props;
    React.useEffect(()=>{

    }, [])
    return (
        <IconButton
            {...props}
            className={clsx(classes.IconButton, props.className)}
        >
            {children}
        </IconButton>
    )
}
UI_IconButton.propTypes = {
    //text: PropTypes.string.isRequired,
}
UI_IconButton.defaultProps = {
    type      : 'button',
    //fullWidth : false,
    variant   : 'text',
    color     : 'primary',
    size      : null,
};