import React,{ useContext, useState } from "react";
import {Button } from "@material-ui/core";
import { Form, FormGroup, Label, Col, Input ,  FormText, Spinner, Dropdown,DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import { AuthContext } from "../../AuthenticationContext";
import {doc, updateDoc, getDocs,where,query, collection } from "firebase/firestore";

export default function EditUser(props){
    const [email, setEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const user_id = props?.user_id;
    const [invalidMessage, setInvalidMessage] = useState(null)
    const [isRoleDropdownOpen,setIsRoleDropdownOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [isActive, setIsActive] = useState(()=>{return props?.active && true})
    const [isActiveDropdownOpen, setIsActiveDropdownOpen] = useState(false)
    const [oldData, setOldData] = useState(()=>{return {...props,active:props?.active && true }})


    const {db} = useContext(AuthContext)
  
    const editMember = async() =>{
        const updatesToServer = {}
        invalidMessage && setInvalidMessage(null)
        successMessage && setSuccessMessage(null)
       
        if((isActive === oldData.active) && 
            (!roleId || (roleId && roleId === oldData?.role_id )) &&
            (!userName || (userName && userName === oldData?.user_name)) ){
            setInvalidMessage("No changes to save !")
            return false
        }
        if( email){
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                setInvalidMessage("Invalid Email Address !")
            return false
            }
            updatesToServer['email'] = email
        }
        if(userName){
            if(userName.length > 100) {
                setInvalidMessage(" Name length should not be more than 100 characters.");
                return
            }
            updatesToServer['user_name']= userName
        }
        if(roleId){
            if(!['admin','viewer'].includes(roleId) ){
                setInvalidMessage("Please choose a valid roleId.")
                return
            }
            updatesToServer['role_id']= roleId
        }
        updatesToServer['active'] = isActive

        const userRef = doc(db, "user_info", user_id);
        const userDocRef  = collection(db, "user_info");
        
        setSubmitting(true)

    if(email){
            const q = query(userDocRef, where("email", "==", email));
            await getDocs(q).then(
                async (snapShot)=>{
                    if(snapShot.empty){
                        await updateDoc(userRef, {...updatesToServer}).then((docSnap)=>{
                        setSuccessMessage("User information updated successfully.")
                            
                        }).catch((error)=>{
                            if(error.code === "permission-denied"){
                                setInvalidMessage("You don't have permission to modify.")
                            }else{
                                setInvalidMessage(JSON.stringify(error.code))
                            }      
                        })
                    }
                    else{
                        setInvalidMessage("This email already taken.")
                    }
                }
            ).catch((error)=>{
                setInvalidMessage(error.code)
            }    
            ).finally(
                ()=>{
                    setSubmitting(false)
                }
            ) 
        } else{
            await updateDoc(userRef, {...updatesToServer}).then(
                        (docSnap)=>{
                            setSuccessMessage("User information updated successfully.")
                            setOldData(()=>{
                                return {email:props?.email,
                                    user_name:  userName ? userName : props?.user_name,
                                    role_id: roleId ? roleId : props?.role_id,
                                    active : isActive,
                                    group_id : props?.group_id
                                }
                            })
                    }).catch((error)=>{
                        if(error.code === "permission-denied"){
                            setInvalidMessage("You don't have permission to modify.")
                        }else{
                            setInvalidMessage(JSON.stringify(error.code))
                        }      
                    }).finally(
                        ()=>{
                            setSubmitting(false)
                        }
                    ) 
        }
    }

    return(
    <>
     <h1>Member Edit</h1>
  <Form hidden={props?.hidden} >
    <FormGroup row>
        <Label for="exampleEmail" sm={2} > Email </Label>
    <Col sm={5}>
        <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da" }} >{oldData?.email}</div> 
    </Col>
    </FormGroup>
  
    <FormGroup row>
        <Label for="exampleEmail" sm={2} > Name </Label>
        <Col  sm={5}>
            <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da" }}  >{oldData?.user_name}</div> 
            <Input disabled={submitting} style={{marginTop:"5px"}} onChange={(e) => {setUserName(e.target.value )}} value={userName || ""} placeholder="Give a new name"  />
            <FormText>
                Name length should not be more than 100 characters.
            </FormText>
        </Col>
    </FormGroup>
  
    <FormGroup row>
        <Label for="exampleEmail" sm={2} > Role </Label>
    <Col sm={5}>
        <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da"}} >{oldData?.role_id}</div>
        <Dropdown disabled={submitting} style={{marginTop:"5px"}} direction="right" isOpen={isRoleDropdownOpen} toggle={()=>{setIsRoleDropdownOpen((prevState)=>{return !prevState})}}>
          <DropdownToggle caret>
            {roleId ? roleId[0].toUpperCase()+roleId.slice(1) : "Select Role" }
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>{setRoleId("admin")} } >Admin</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={()=>{setRoleId("viewer")} }>Viewer</DropdownItem>
          </DropdownMenu>
        </Dropdown>
    </Col>
    </FormGroup> 
    <FormGroup row>
          <Label  sm={2} > Active </Label>
      <Col sm={5}>
      <Dropdown disabled={submitting}  direction="right" isOpen={isActiveDropdownOpen} toggle={()=>{setIsActiveDropdownOpen((prevState)=>{return !prevState})}}>
          <DropdownToggle caret>
            {isActive ? "True" : "False"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>{setIsActive(true)} } >True</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={()=>{setIsActive(false)} } >False</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
      </FormGroup> 
  
    <FormGroup row>
        <Label  sm={2} > Group Id </Label>
    <Col sm={5}>
        <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da"}} >{props?.group_id}</div>
    </Col>
    </FormGroup> 
    { invalidMessage &&  
      <FormGroup row>
          <Label  sm={2} ></Label>
          <Col sm={5}>
          <div sm={5} className="form-control"  style={{border:"1px solid #dd3c10", backgroundColor:"#ffebe8"}}>{invalidMessage}</div>
          </Col>
      </FormGroup>}
      { successMessage &&  
      <FormGroup row>
          <Label for="exampleEmail" sm={2} ></Label>
          <Col sm={5}>
          <div sm={5} className="form-control"  style={{border:"1px solid #2fc431", backgroundColor:"#ddffdd"}}>{successMessage}</div>
          </Col>
      </FormGroup>}
    <FormGroup row >
        <Col sm={7} >     
         <Button disabled={submitting}   variant="contained"
                  color="primary"
                  onClick={editMember}
                  size="small" style={{float: "right"}}  >{submitting ? <Spinner color="light" size="">Loading...</Spinner> : "Submit" }</Button> 
            <Button    variant="contained"
            disabled={submitting}
                  size="small" style={{float: "right",marginRight:"10px"}} color="default" onClick={() => {props?.setEditUser(null)}} >Back</Button> 
        </Col>
    </FormGroup> 
  </Form>
  </> 
  )
  }
  
  

  