import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Link, useHistory } from 'react-router-dom';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Button from '../../common/Button/Button.jsx';
import { AuthContext } from '../../AuthenticationContext';

const useStyles = makeStyles((theme) => ({
    Divider : {
        margin : '8px 0',
    }
}))
export default function AccountOptions(props) {  
    const {logout, user, userProfile}  = useContext(AuthContext)
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
        appMenu=[],
    } = props;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (label) => () => {
        setAnchorEl(null);
    };

    const beforeLogout = async () => {
        await logout();
        history.push('/');
    }
    const onClickHistoryPush = (path) => () => {
        history.push(path)
    }
    const settingMenus = appMenu && appMenu?.find(menu => menu.label === '設定')?.child || [];
    const roleId       = userProfile?.role_id || null;
    return (
        <div style={{
            display : 'flex',
            alignItems : 'stretch',
            color : '#34495e',
            padding : '8px 24px',
        }}>
            <AccountCircleIcon style={{marginRight : 8,}} />
            <Typography>{user?.email || 'unknown'}</Typography>
        </div>
    );
}
