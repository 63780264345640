import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from './components/Header/Header.jsx';
import Drawer from './components/Drawer/Drawer.jsx';

import Sales from './pages/Sales/Sales.jsx';
import BoxPlot from './pages/Pricing/BoxPlot/BoxPlot.jsx';
import Filter from './pages/Filter/Filter.jsx';
import PeopleFlowAnalysis from './pages/PeopleFlowAnalysis/PeopleFlowAnalysis.jsx';
import HeatmapAnalysis from './pages/HeatmapAnalysis/HeatmapAnalysis.jsx';
import MarketAreaSummary from './pages/MarketAreaSummary/MarketAreaSummary.jsx';
import NotFound from './pages/NotFound/NotFound.jsx';
import FavoriteEdit from './pages/EditFavorite/EditFavorite.jsx';
import EditUserInformation from './pages/EditUserInformation/EditUserInformation.jsx';
import EditMember from './pages/EditMember/EditMember.jsx';
import { AuthContext } from './AuthenticationContext.js';
import EditScreenTerminology from './pages/EditScreenTerminology/EditScreenTerminology.jsx';
import DisabledPage from './pages/Disabled/DisabledPage.jsx';
import { triggerWindowResize } from './utility/triggerEvent.js';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width : '100vw',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        //paddingBottom: theme.spacing(4),
        maxWidth : '100%',
        height: 'calc(100vh - 64px)',
        display : 'flex',
        flexDirection : 'column',

        flex: 1,
        overflowY: 'auto',
    },
}));

export default function Main(props) {
    const {
        currentFormValues,
        filterMenuScrollTop,
        appMenu,
        addAlert,
        alert=[],
    } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const  [pageTitle, setPageTitle ] = useState(null)
    const handleDrawerOpen = () => {
        setOpen(true);
        triggerWindowResize();
    };
    const handleDrawerClose = () => {
        setOpen(false);
        triggerWindowResize();
    };

 
    const {userProfile} = useContext(AuthContext)
    const masterData = {}
    return (
        <div className={classes.root}>
        <CssBaseline />
        <Header 
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            drawerWidth={drawerWidth}
            appMenu={appMenu}
            title={pageTitle}
        />
        <Drawer 
            open={open}
            handleDrawerClose={handleDrawerClose}
            drawerWidth={drawerWidth}
            appMenu={appMenu}
        />
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container fixed className={classes.container}>
                <Switch>
                    <Route path="/screenTerminology" render={()=>{return <EditScreenTerminology/>} } />
                    <Route path='/favoriteEdit' render={() => {
                        return <FavoriteEdit/> }} />
                    <Route path='/editProfile' render={() =>{
                        return <EditUserInformation/> }} />
                    <Route path="/editMember" render={() => {
                        return userProfile?.role_id === "admin" ?  <EditMember/> : <NotFound isFlex={true} />}} />
                    <Route path='/peopleFlow' render={() => {
                        return <PeopleFlowAnalysis />
                    }} />
                    <Route path='/heatmap' render={() => {
                        return <HeatmapAnalysis />
                    }} />

                    <Route path={['/:major/:minor', '/:major']} render={({ match }) => {
                        let pageUrl = match?.url ;
                        setPageTitle(()=>{
                            if(pageUrl === "/sales" || pageUrl === "/sales/result" ) return "売り上げ";

                            if(pageUrl === "/assortment/summary" || pageUrl === "/assortment/summary/result") return "品揃え分析サマリ";
                            if(pageUrl === "/assortment/competitor" || pageUrl === "/assortment/competitor/result"  ) return "競合分析サマリ";
                            if(pageUrl === "/assortment/singleItem" || pageUrl === "/assortment/singleItem/result") return "単品比較";
                            if(pageUrl === "/assortment/basket" || pageUrl === "/assortment/basket/result") return "バスケット分析";

                            if(pageUrl === "/pricing/summary" || pageUrl === "/pricing/summary/result") return "プライシング分析サマリ";
                            if(pageUrl === "/pricing/competitor" || pageUrl === "/pricing/competitor/result") return "競合比較";
                            if(pageUrl === "/pricing/singleItem" || pageUrl === "/pricing/singleItem/result") return "単品比較";
                            if(pageUrl === "/pricing/boxPlot" || pageUrl === "/pricing/boxPlot/result") return "プライスバンド分析";

                    
                            if(pageUrl === "/marketArea/summary" || pageUrl === "/marketArea/summary/result") return "商圏分析サマリー";
                            if(pageUrl === "/marketArea/share" || pageUrl === "/marketArea/share/result") return "商圏シェア分析";
                            if(pageUrl === "/marketArea/profile" || pageUrl === "/marketArea/profile/result") return "プロファイル分析";
                            if(pageUrl === "/marketArea/heatMap" || pageUrl === "/marketArea/heatMap/result") return "エリアヒートマップ分析";
                            if(pageUrl === "/marketArea/peopleFlow" || pageUrl === "/marketArea/peopleFlow/result") return "ピープルフロー分析";

                            
                        })
                        return <Filter 
                            routerParams={match.params} 
                            currentFormValues={currentFormValues}
                            filterMenuScrollTop={filterMenuScrollTop}
                            addAlert={addAlert}
                            alert={alert}
                        />
                    }} />
                    
                    <Route render={() => {
                        return <NotFound isFlex={true} />
                    }} />

                </Switch>
            </Container>
        </main>
        </div>
    );
}