import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Tabs from '../Tabs/Tabs.jsx';
import { Paper, Typography } from '@material-ui/core';
import LineChart from '../Charts/LineChart.jsx';
const useStyles = makeStyles((theme) => ({
    tableBox : (props) => ({
        height : props.isShowFilter ? props.isTab ? `calc(100vh - (434px + 296px))` : `calc(100vh - 434px)` : props.isTab ? `calc(100vh - (228px + 296px))` : `calc(100vh - 228px)`,
        minHeight : 240,
        //transition : 'height .1s linear',
        '& .MuiDataGrid-row' : {
            backgroundColor : '#fff',
        }
    }),
    chartBox : {
        marginTop : 12,
        overflow : 'hidden',
    },
    splitCell : {
        borderLeft : '2px solid rgba(224, 224, 224, 1)',
    }
}));

export default function StoreByTable(props) {
    const {
        isTab=true,
        isShowFilter,
        options={},
        tabIndex,
        changeTabIndex,
    } = props;
    const numberFormatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
     });
    const getFormattedNumber = (num) => {
        return numberFormatter.format(num)
    }
    const customSorting = (v1, v2) => {
        if(!isNaN(v1) && !isNaN(v2)){
            return v1 - v2
        }
        else if(v1.includes('%') && v2.includes('%')){
            const v1WithoutPercentage = v1.slice(0, -1)
            const v2WithoutPercentage = v2.slice(0, -1)
            if(!isNaN(v1WithoutPercentage) && !isNaN(v2WithoutPercentage)){
                return Number(v1.slice(0, -1)) - Number(v2.slice(0, -1))
            }
        }
        return v1 - v2
    }
    const classes = useStyles({
        isShowFilter,
    });
    const [tabOptions, setTabOptions] = React.useState([]);
    React.useEffect(()=>{
        if(!options['tabName']) return;
        const newTabOptions = options['tabName'].map((name, i) => {
            const selected = i === tabIndex;
            return {
                name,
                selected, 
            }
        });
        setTabOptions(newTabOptions);
    }, [options, tabIndex]);
    
    let selectTable = options?.['single'] || {};
    
    if(isTab){
        let index = tabOptions.findIndex(t => t.selected);
        if(index === -1) index = 0;
        selectTable = options?.['tab']?.[index] || {};
    }
    //console.log({isTab}, {selectTable});
    const {
        rows = null,
        columns = null,
    } = selectTable;
    const tableRows = rows?.map((row, i) => {
        const params = {};
        row.forEach((value, i) => {
            params[columns[i]] = value;
        })
        return {
            id : i,
            ...params,
        }
    }) || null;
    const tableColumns = [];
    const isAssortmentSingleItemPage = columns?.find(c => c.endsWith('hidden__自店にない商品')) ? true : false;
    const isPricingSingleItemPage = columns?.find(c => c.endsWith('hidden__自店との比較')) ? true : false;
    let numCount = 0;
    columns && columns.forEach(value => {
        const isNum      = value.indexOf('#') > -1;
        const isCategory = value.indexOf('カテゴリ') > -1;
        const isItem     = value.indexOf('商品名') > -1;
        const isWallet   = value.indexOf('ウォレットシェア') > -1;
        const width      =  200 ;
        const type       = isCategory || isItem ? 'string' : 'number';
        const isHidden   = value.indexOf('hidden__') > -1;
        const isSplitColumn = isAssortmentSingleItemPage || isPricingSingleItemPage ? isCategory : isNum;
        tableColumns.push({
            width,
            type, 
            field: value,
            headerName: value,
            isSplitCell : isSplitColumn && numCount,
            cellClassName : (params) => {
                return params.colDef.isSplitCell ? classes.splitCell : '';
            },
            renderCell : isCategory ? (params)=>{
                 if(params.value === "temp_category_name"){
                    return  <span ></span>
                 }
            } : isWallet ? (params) => {
                const {
                    value=null,
                } = params;
                if(['-'].includes(value)){
                    return <span style={{opacity : 0.25}}>{value}</span>
                }
                return value !=='' && isFinite(value) ? `${value} %` : value;
            } : isNum ? (params) => {
                const {
                    value=null,
                    row,
                    field,
                } = params;
                const prefix = field.indexOf(' : ') > -1 ? `${field.split(' : ')[0]} : ` : '';
                const count = row[`${prefix}hidden__カウント`];
                const countColor = count === null ? '#bdc3c7' : count < 100 ? '#e74c3c' : count < 500 ? '#e67e22' : null;
                const noItemColor = row[`${prefix}hidden__自店にない商品`] ? '#e67e22' : null;
                const comparison = row[`${prefix}hidden__自店との比較`];
                const comparisonColor = comparison >= 5 ? '#e67e22' : null;
                const dotColor = countColor || noItemColor || comparisonColor;
                //console.log(`%c ${prefix}hidden__カウント : ${count}, value : ${value}`, `background:${countColor}`);
                //console.log(params, row);
                return <div style={{
                    display : 'flex',
                    justifyContent : 'space-between',
                    alignItems : 'center',
                    flex : 1,
                    paddingLeft : 8,
                }}>
                    <span style={{
                        display : 'block',
                        width : 8,
                        height : 8,
                        borderRadius : 4,
                        backgroundColor : dotColor,
                        opacity : value ? 1 : 0,
                    }}></span>
                    {value}
                </div>
            } : null,
            hide : isHidden,
            valueFormatter : (params) => {
                if(params.value == Infinity){
                    return '-'
                }
                if (params.value == null){
                    return ""
                }
                else{
                    if(!isNaN(params.value)){

                        if(params.value == "temp_category_name"){
                            return ""
                        }
                        return getFormattedNumber(params.value)
                    }
                    else{
                        return params.value
                    }
                }
            },
            sortComparator: customSorting
        });
        if(isSplitColumn){
            numCount++;
        }
    })
    //console.log({tableColumns})
    const isShowTable = tableRows && tableColumns.length;
    return (

        <>
            {
                isTab && (
                    <Tabs 
                        tabOptions={tabOptions} 
                        changeTabSelect={changeTabIndex}
                    />
                )
            }


        <div className={classes.tableBox}>
            {
                isShowTable ? (
                    <DataGrid
                        rows={tableRows}
                        columns={tableColumns}
                        pagination 
                        pageSize={100} 
                        rowsPerPageOptions={[100]}
                        disableColumnMenu={true}
                    />
                ) : (<Typography>データが存在しません</Typography>)
            }
        </div>
        </>
    );
}