import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import * as dfd from "danfojs";
import moment from 'moment';
import Button from '../../common/Button/Button.jsx';
import demographyOptions from '../Demography/demography.json'

const useStyles = makeStyles((theme) => ({

}));

export default function DownloadButton(props) {
    var rows= props?.rows || [] 
    const {
        useDanfojs=false,
        currentFormValues=null,
        isTab,
        tabName,
        columns=[],
        chartOptions=null,
        containerRef=null,
        isResultTypeOnlyChart=false,
        isprofile = false,
        demography = null,
        isTableLists=false,
        tabIndex=1
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [downloadHref, setDownloadHref] = React.useState(null);
    const [fileName, setFileName] = React.useState(null);


    const createCsvText = (dataFrame) => {
        const newLine = '\r\n';
        const delimiter = ',';
        const lines = [[...dataFrame.$columns]]
            .concat([...dataFrame.$data]).map(cells => {
                return cells.map(cell => {
                    return isFinite(cell) ? cell : `"${cell}"`;
                }).join(delimiter)
            });
        return lines.join(newLine);
    }
    const getAppPath = (menus) => {
        return menus.map(menu => {
            return menu.child ? getAppPath(menu.child) : menu.link ? menu : null
        }).flat().filter(menu => menu);
    }
    const dateFormat = (date, format='YYYY_MM_DD') => {
        return moment(date).format(format);
    }
    const getPeriod = () => {
        const {
            start_date = null,
            end_date   = null,
        } = currentFormValues?.current || {};
        if(!start_date || !end_date){
            return '';
        }
        return ` - ${dateFormat(start_date)}-${dateFormat(end_date)}`;
    }
    const getAppName = () => {
        const undefinedName = 'Marketing_View_Premium';
        const appMenuJson = JSON.parse(window.localStorage.getItem('appMenuData') || null);
        if(!appMenuJson) return undefinedName;
        const path    = location.pathname.replace(/\/result$/, '');
        const menus   = getAppPath(appMenuJson);
        const appName = menus?.find(menu => menu.link === path)?.label || undefinedName;
        return appName;
    }
    const getTabName = () => {
        return isTab && tabName ? ` - ${tabName}` : '';
    }
    const setUpCsv = (csvText='') => {
        const appName    = getAppName();
        const period     = getPeriod();
        const tabName    = getTabName();

        const bom  = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csvText], { type: 'text/csv' });
        const href = window.URL.createObjectURL(blob);
        const file = `${appName}${tabName}${period}.csv`;
        setDownloadHref(href);
        setFileName(file);
    }
    React.useEffect(()=>{
        if (isTableLists) {
            let newRows = rows.map((r) => {
                let row = { ...r };
                Object.keys(row).forEach(rowKey => {
                    let columnValueFormatter = columns.find(c => (c.headerName || c.field) === rowKey)?.valueFormatter;
                    columnValueFormatter && (row[rowKey] = columnValueFormatter({ value: row[rowKey] }));
                });
                return row;
            })
            rows = [...newRows]
        }

        if (rows.length && columns.length) {
            let rowColumnKeys  = columns.map(col =>col.field || col.headerName );
            const rowData = rows.map(row => {
                return  rowColumnKeys.map(key => row[key]) ;
            });
            let dataFrame = new dfd.DataFrame(rowData, { columns: rowColumnKeys });
            dataFrame = dataFrame.replace(Infinity, '-');
            dataFrame = dataFrame.replace("Infinity", '-');
            dataFrame = dataFrame.replace("temp_category_name", " ");

            // for market share % columns
            const markesharepercentcolumns = ['顧客数時間構成比','売上時間シェア', '売上時間構成比','顧客数時間シェア','顧客数(のべ)時間シェア','顧客数(のべ)時間構成比']
            const tableWithPercentageSign = ['来店率期間軸', '来店率ドーナッツ商圏軸', '来店率商圏サイズ軸' , '顧客カバレッジ'];

            // logic to add % sign in csv
            if ( markesharepercentcolumns.includes(tabName) || tableWithPercentageSign.includes(tabName)) {
                function percentaddition(x) {
                    if (typeof(x)=="number") {
                        return `${x}` + '%'
                    };
                    return x     
                };
                dataFrame=dataFrame.applyMap(percentaddition);
            };
            const csvText = useDanfojs ? dfd.toCSV(dataFrame) : createCsvText(dataFrame);
            setUpCsv(csvText);

        }
        if(chartOptions && isResultTypeOnlyChart){
            const series = [...chartOptions.series].filter(s => !s.type);

            const chart = containerRef?.current?.chart || null;
            const xAxis = chart?.xAxis?.[0] || null;
            const xAxisExtremes = xAxis?.getExtremes() || {min : 0, max : series[0]?.data?.length || 0};
            
            const {
                categories,
            } = chartOptions.xAxis;
            const csvHead = ['type, item, min, ptile_25, median, ptile_75, max'];
            const rows = series.map(s => {
                const type = s.name;
                return s.data.map((d, i) => {
                    return [
                        type,
                        categories[i],
                        
                    ].concat(d).join('\,');
                });
            }).flat();
            const csvText = csvHead.concat(rows).join('\n');
            setUpCsv(csvText);
        }
        if(isprofile && demography){
            let demography_csv = JSON.parse(JSON.stringify(demography))
            demography_csv = [demography_csv[tabIndex]]
            let fullCsvText = demography_csv.map(t=>{
                let csvText = t.map(r => {
                    const {
                        target,
                        val,
                        area_name,
                        pref_name,
                        city_name
                    } = r
                    let mainTable = `\n${pref_name}-${city_name}-${area_name}`
                    mainTable = mainTable + '\n'
                    mainTable = mainTable + `${demographyOptions[target]?.title?.ja ? demographyOptions[target]?.title?.ja : target}`
                    const subTable = Object.keys(val).forEach(lev1=>{
                        if(Array.isArray(val[lev1])){
                            let x = demographyOptions
                            let a = val[lev1] // level1 []
                            a.map(row => {
                                row['val'] = Number(row['scaled_value'])
                                delete row['scaled_value']
                            })
                            let columnsheaders = Object.keys(a[0]).map(header => demographyOptions[header]?.title?.ja ? demographyOptions[header]?.title?.ja : header);
                            let titleOfTable =  `${demographyOptions[target]?.title?.ja ? demographyOptions[target]?.title?.ja : target} (${demographyOptions[lev1]?.title?.ja ? demographyOptions[lev1]?.title?.ja : lev1})`
                            let rows = a.map(r => Object.values(r)).sort((a, b) => a[0] - b[0]).map(r=>r.join('\,')) 
                            let table = [titleOfTable,columnsheaders.join('\,'),...rows ].join('\n') ;
                            mainTable += "\n" + table
                        }else{
                            let lev2object = val[lev1]
                            let lev2Table = Object.keys(lev2object).forEach(lev2 =>{
                                let a = lev2object[lev2]
                                let titleOfTable =  `${demographyOptions[target]?.title?.ja ? demographyOptions[target]?.title?.ja : target} (${demographyOptions[lev1]?.title?.ja ? demographyOptions[lev1]?.title?.ja : lev1}) (${demographyOptions[lev2]?.title?.ja ? demographyOptions[lev2]?.title?.ja : lev2})`; 
                                if(Array.isArray(a)){
                                    a.map(row => {
                                        row['val'] = Number(row['scaled_value'])
                                        delete row['scaled_value']
                                    })
                                    let columnsheaders = Object.keys(a[0]).map(header => demographyOptions[header]?.title?.ja ? demographyOptions[header]?.title?.ja : header);
                                    let rows = a.map(r => Object.values(r)).sort((a, b) => a[0] - b[0]).map(r=>r.join('\,')) 
                                    let table = [titleOfTable,columnsheaders.join('\,'),...rows ].join('\n') ;
                                    mainTable += "\n" + table
                                }else{
                                    if(Object.keys(a).includes('yes')){
                                        a[demographyOptions[lev2]?.categories?.yes?.['ja'] ? demographyOptions[lev2]?.categories?.yes?.['ja'] : 'yes'] = Number(a['yes_scaled_value'])
                                        if(demographyOptions[lev2]?.categories?.yes?.['ja']){
                                            delete a['yes']
                                        }
                                        delete a['yes_scaled_value']
                                        a[demographyOptions[lev2]?.categories?.no?.['ja'] ? demographyOptions[lev2]?.categories?.no?.['ja'] : 'no'] = Number(a['no_scaled_value'])
                                        if(demographyOptions[lev2]?.categories?.no?.['ja']){
                                            delete a['no']
                                        }
                                        delete a['no_scaled_value']
                                    }
                                    else if(Object.keys(a).includes('m')){
                                        a['f'] = Number(a['f_scaled_value'])
                                        delete a['f_scaled_value']
                                        a['m'] =  Number(a['m_scaled_value'])
                                        delete a['m_scaled_value']
                                        a['o'] = Number(a['o_scaled_value'])
                                        delete a['o_scaled_value']
                                    }
                                    
                                    let columnHeaders = Object.keys(a).map(header => demographyOptions[header]?.title?.ja ? demographyOptions[header]?.title?.ja : header).join('\,')
                                    let rows = Object.values(a).join('\,');
                                    let table = [titleOfTable, columnHeaders, rows].join('\n');
                                    mainTable += "\n" + table
                                }
                            })
                        }
                    })
                    return mainTable
                })
                return csvText
            })
            setUpCsv(fullCsvText.join('\n\n'));
        }
    }, [rows, columns, isTab, tabName, chartOptions, demography, tabIndex]);


    return (
        <Button 
            color="primary"
            variant="contained"
            size="large"
            startIcon={<i className="fas fa-file-download"></i>}
            disabled={!Boolean(downloadHref)}
            href={downloadHref}
            download={fileName}
            component="a"
        >CSV ダウンロード</Button>
    );
}