import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    }));

export default function SimpleAlerts() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    return (
        <div className={classes.root}>
            <Grow in={open}>
                <Alert variant="filled" severity="error">
                    This is an error alert — check it out!
                </Alert>
            </Grow>
        </div>
    );
}
