import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({

});
const UI_TextField = React.forwardRef((props, ref) => {
    const classes = useStyles();

    React.useEffect(()=>{

    }, [])
    return (
        <TextField
            ref={ref}
            className={clsx(classes.Button, props.className)}
            {...props}
        />
    )
});
UI_TextField.propTypes = {
    id   : PropTypes.string.isRequired,
    name : PropTypes.string.isRequired,
}
UI_TextField.defaultProps = {

};

export default UI_TextField;