import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { PropTypes, defaultProps} from 'prop-types';
const useStyles = makeStyles({

});

export default function UI_Button(props) {
    const classes = useStyles();
    const {
        children=null,
    } = props;
    React.useEffect(()=>{

    }, [])
    return (
        <Button
            {...props}
            className={clsx(classes.Button, props.className)}
        >
            {children}
        </Button>
    )
}
UI_Button.propTypes = {
    //text: PropTypes.string.isRequired,
}
UI_Button.defaultProps = {
    type      : 'button',
    //fullWidth : false,
    variant   : 'text',
    color     : 'primary',
    size      : 'large',
};