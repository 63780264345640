export const cellValueDictionary = {
  '8a2f5bda2527fff': 2.08,
  '8a2f5bd848affff': 2,
  '8a2f5bda2467fff': 1.79,
  '8a2f5bdb1987fff': 5.83,
  '8a2f5bd8134ffff': 1.15,
  '8a2f5bd8ccb7fff': 1,
  '8a2f5bd8136ffff': 1,
  '8a2f5bd81357fff': 1.07,
  '8a2f5bd81347fff': 1,
  '8a2f5bd82517fff': 12.25,
  '8a2f5bd82437fff': 32.18,
  '8a2f5bd82427fff': 7.19,
  '8a2f5bdabcdffff': 1.64,
  '8a2f5bd8ca47fff': 2.7,
  '8a2f5bdabcd7fff': 2.2,
  '8a2f5bd9598ffff': 1.04,
  '8a2f5bd958affff': 1.13,
  '8a2f5bd865a7fff': 2,
  '8a2f5bd86597fff': 3.17,
  '8a2f5bd95b8ffff': 1.6,
  '8a2f5bd862c7fff': 1.31,
  '8a2f5bd82077fff': 6.11,
  '8a2f5bd8679ffff': 1.67,
  '8a2f5bd95987fff': 1.75,
  '8a2f5bd959b7fff': 2.63,
  '8a2f5bdb36effff': 2.29,
  '8a2f5bd959affff': 1.64,
  '8a2f5bd95807fff': 1.11,
  '8a2f5bd95837fff': 1.18,
  '8a2f5bdb3777fff': 2.61,
  '8a2f5bd9588ffff': 1,
  '8a2f5bdb3017fff': 1.43,
  '8a2f5bdb3747fff': 1.71,
  '8a2f5bd9591ffff': 1.29,
  '8a2f5bd8a837fff': 1.58,
  '8a2f5bdb3b0ffff': 1.96,
  '8a2f5bdb524ffff': 3.67,
  '8a2f5bda2517fff': 3.53,
  '8a2f5bda2cdffff': 3.5,
  '8a2f5bda2ccffff': 4.15,
  '8a2f5bda21b7fff': 4.25,
  '8a2f5bda21a7fff': 3.29,
  '8a2f5bdb3a37fff': 1.5,
  '8a2f5bdb3b8ffff': 3.57,
  '8a2f5bdb3aa7fff': 2.82,
  '8a2f5bdb3a87fff': 1.69,
  '8a2f5bd98a37fff': 11.1,
  '8a2f5bd846cffff': 2.53,
  '8a2f5bdaad27fff': 1.08,
  '8a2f5bd8e6a7fff': 3.81,
  '8a2f5bdb1c37fff': 1,
  '8a2f5bd913affff': 1.43,
  '8a2f5bd9121ffff': 1.11,
  '8a2f5bd9122ffff': 1.71,
  '8a2f5bd9130ffff': 1.11,
  '8a2f5bd91317fff': 1.2,
  '8a2f5bd9104ffff': 1,
  '8a2f5bd9135ffff': 1.46,
  '8a2f5bd91227fff': 1,
  '8a2f5bd91377fff': 1.21,
  '8a2f5bda2d87fff': 2.86,
  '8a2f5bd8c507fff': 1.08,
  '8a2f5bd8c417fff': 1.5,
  '8a2f5bd82057fff': 2.39,
  '8a2f5bd820e7fff': 2.41,
  '8a2f5bd820d7fff': 1.31,
  '8a2f5bd8208ffff': 2.42,
  '8a2f5bd82727fff': 2.44,
  '8a2f5bd82737fff': 2.1,
  '8a2f5bd8244ffff': 1.69,
  '8a2f5bd8245ffff': 1.41,
  '8a2f5bd824effff': 3.35,
  '8a2f5bd824cffff': 2,
  '8a2f5bd8c51ffff': 1,
  '8a2f5bd8c517fff': 1,
  '8a2f5bd8c52ffff': 1.9,
  '8a2f5bd81037fff': 1.46,
  '8a2f5bd812e7fff': 2.09,
  '8a2f5bd83b27fff': 1.81,
  '8a2f5bd8149ffff': 3.04,
  '8a2f5bd806affff': 2.55,
  '8a2f5bd82047fff': 3.36,
  '8a2f5bd91857fff': 3.71,
  '8a2f5bd90af7fff': 4.4,
  '8a2f5bd90ad7fff': 1.2,
  '8a2f5bd90a8ffff': 2.29,
  '8a2f5bd90327fff': 2.14,
  '8a2f5bdaa587fff': 2.04,
  '8a2f5bd91b37fff': 2.17,
  '8a2f5bdaa2effff': 1.41,
  '8a2f5bd8e22ffff': 1.28,
  '8a2f5bd8e227fff': 1,
  '8a2f5bd8e357fff': 1,
  '8a2f5bd8e377fff': 1,
  '8a2f5bd8eadffff': 1.67,
  '8a2f5bd8eac7fff': 2.46,
  '8a2f5bd940dffff': 1.56,
  '8a2f5bd9472ffff': 3.78,
  '8a2f5bd90a0ffff': 18.65,
  '8a2f5bd8c037fff': 3.48,
  '8a2f5bd8c11ffff': 2.31,
  '8a2f5bd8c147fff': 5.14,
  '8a2f5bd8c16ffff': 4.4,
  '8a2f5bd8cab7fff': 4.35,
  '8a2f5bd8caaffff': 3.25,
  '8a2f5bd8435ffff': 2.06,
  '8a2f5bdaa4f7fff': 1,
  '8a2f5bdaa2b7fff': 1.2,
  '8a2f5bd858b7fff': 1,
  '8a2f5bd8434ffff': 1,
  '8a2f5bd85c97fff': 1.17,
  '8a2f5bda2797fff': 2.62,
  '8a2f5bdb19affff': 9.5,
  '8a2f5bd988dffff': 1.88,
  '8a2f5bd8109ffff': 1.6,
  '8a2f5bd90a37fff': 4,
  '8a2f5bda2cf7fff': 5.18,
  '8a2f5bd90027fff': 1.53,
  '8a2f5bd90b1ffff': 6.65,
  '8a2f5bd94057fff': 1.27,
  '8a2f5bd90a77fff': 24.29,
  '8a2f5bd90baffff': 3.5,
  '8a2f5bd83a0ffff': 12.32,
  '8a2f5bd8ed17fff': 1,
  '8a2f5bdab69ffff': 6.38,
  '8a2f5bd91847fff': 1.78,
  '8a2f5bd8e127fff': 9.4,
  '8a2f5bd8c4c7fff': 11.9,
  '8a2f5bd90a07fff': 11.26,
  '8a2f5bd8465ffff': 1,
  '8a2f5bd84657fff': 1.07,
  '8a2f5bd84647fff': 1,
  '8a2f5bd83a2ffff': 3.29,
  '8a2f5bd80727fff': 2,
  '8a2f5bd84617fff': 2.08,
  '8a2f5bd808f7fff': 2.13,
  '8a2f5bd84357fff': 1.64,
  '8a2f5bd91ae7fff': 1.67,
  '8a2f5bd82a9ffff': 2.13,
  '8a2f5bd91a0ffff': 1,
  '8a2f5bdb38f7fff': 3,
  '8a2f5bdb38c7fff': 2.74,
  '8a2f5bdb38dffff': 3.36,
  '8a2f5bdb3167fff': 3.5,
  '8a2f5bdb3147fff': 3.17,
  '8a2f5bdb314ffff': 4.89,
  '8a2f5bdb3a97fff': 5.18,
  '8a2f5bdb3067fff': 8.62,
  '8a2f5bd8cc17fff': 1.5,
  '8a2f5bd8e8dffff': 8.5,
  '8a2f5bd83aa7fff': 3.08,
  '8a2f5bd8e687fff': 1.8,
  '8a2f5bdb1cc7fff': 2.58,
  '8a2f5bd84307fff': 1.65,
  '8a2f5bd88c2ffff': 1,
  '8a2f5bd8474ffff': 1.14,
  '8a2f5bd8001ffff': 2.82,
  '8a2f5bd95257fff': 6.15,
  '8a2f5bdb3367fff': 2.29,
  '8a2f5bda242ffff': 2,
  '8a2f5bd8c45ffff': 1.13,
  '8a2f5bda25affff': 1.2,
  '8a2f5bda2437fff': 1.25,
  '8a2f5bda2407fff': 1.4,
  '8a2f5bd95077fff': 2.32,
  '8a2f5bd95057fff': 1.74,
  '8a2f5bd9500ffff': 1.09,
  '8a2f5bd824c7fff': 3.29,
  '8a2f5bd825a7fff': 19.5,
  '8a2f5bd95857fff': 2,
  '8a2f5bd849b7fff': 1.4,
  '8a2f5bd94387fff': 1.25,
  '8a2f5bd9914ffff': 7.25,
  '8a2f5bd99a97fff': 4,
  '8a2f5bd99a87fff': 3,
  '8a2f5bdb305ffff': 2.41,
  '8a2f5bdb3047fff': 1.4,
  '8a2f5bd82217fff': 1.19,
  '8a2f5bd822affff': 1.58,
  '8a2f5bd8235ffff': 1.38,
  '8a2f5bd8222ffff': 1.5,
  '8a2f5bd82207fff': 1.21,
  '8a2f5bd9cceffff': 2.92,
  '8a2f5bd9ccdffff': 2.47,
  '8a2f5bd9c54ffff': 2.67,
  '8a2f5bd9cccffff': 2.63,
  '8a2f5bd825affff': 15.83,
  '8a2f5bd82587fff': 8.38,
  '8a2f5bd8269ffff': 3.63,
  '8a2f5bd82407fff': 6.37,
  '8a2f5bd8251ffff': 13.05,
  '8a2f5bd9ccc7fff': 4.4,
  '8a2f5bd9c1b7fff': 1.25,
  '8a2f5bd9c547fff': 1.83,
  '8a2f5bd9ccd7fff': 1.63,
  '8a2f5bd8258ffff': 78.38,
  '8a2f5bd825b7fff': 8,
  '8a2f5bd9c567fff': 2.58,
  '8a2f5bd86c8ffff': 1.38,
  '8a2f5bd9cc37fff': 1.71,
  '8a2f5bd95c37fff': 1.43,
  '8a2f5bd95c17fff': 2,
  '8a2f5bd9561ffff': 10.36,
  '8a2f5bd95547fff': 1.38,
  '8a2f5bd957a7fff': 1.43,
  '8a2f5bd9544ffff': 1.92,
  '8a2f5bd95467fff': 1.62,
  '8a2f5bd95607fff': 2.35,
  '8a2f5bd95617fff': 2.68,
  '8a2f5bd95577fff': 1,
  '8a2f5bd95caffff': 3,
  '8a2f5bd95cd7fff': 1.17,
  '8a2f5bd957affff': 2.5,
  '8a2f5bd95097fff': 1.57,
  '8a2f5bd95cdffff': 1.69,
  '8a2f5bd838a7fff': 1.76,
  '8a2f5bd838affff': 1.78,
  '8a2f5bd83817fff': 1.08,
  '8a2f5bdaa647fff': 1.11,
  '8a2f5bdaa0a7fff': 3.07,
  '8a2f5bdaac77fff': 1.9,
  '8a2f5bdaa19ffff': 1.43,
  '8a2f5bdaad4ffff': 2.93,
  '8a2f5bdaa087fff': 1.25,
  '8a2f5bd83ad7fff': 1,
  '8a2f5bd83a8ffff': 1.61,
  '8a2f5bd83327fff': 1.19,
  '8a2f5bdaa527fff': 2.5,
  '8a2f5bd81217fff': 1.8,
  '8a2f5bd83af7fff': 1.46,
  '8a2f5bd83a9ffff': 1,
  '8a2f5bd8216ffff': 1.05,
  '8a2f5bd8298ffff': 1.36,
  '8a2f5bd821affff': 2.24,
  '8a2f5bd8286ffff': 1.67,
  '8a2f5bd82ab7fff': 1.43,
  '8a2f5bd9cda7fff': 1,
  '8a2f5bd91a4ffff': 1,
  '8a2f5bd9596ffff': 12.59,
  '8a2f5bd864b7fff': 10.13,
  '8a2f5bd90b0ffff': 13.65,
  '8a2f5bd9594ffff': 4.9,
  '8a2f5bd9524ffff': 2.92,
  '8a2f5bd82b97fff': 1.09,
  '8a2f5bdb175ffff': 1.46,
  '8a2f5bdb16e7fff': 1.83,
  '8a2f5bdb16f7fff': 1.87,
  '8a2f5bdb16c7fff': 3.7,
  '8a2f5bd806d7fff': 3.58,
  '8a2f5bd820affff': 2.4,
  '8a2f5bd82067fff': 4.11,
  '8a2f5bd86487fff': 1.95,
  '8a2f5bd8202ffff': 3.69,
  '8a2f5bd828effff': 1.58,
  '8a2f5bd82987fff': 1.55,
  '8a2f5bd82837fff': 1.26,
  '8a2f5bd82b0ffff': 1,
  '8a2f5bd822f7fff': 1,
  '8a2f5bd8215ffff': 2.36,
  '8a2f5bd8214ffff': 2.18,
  '8a2f5bd8294ffff': 1.86,
  '8a2f5bd8218ffff': 23.45,
  '8a2f5bd86367fff': 2,
  '8a2f5bd82867fff': 1.29,
  '8a2f5bd8280ffff': 1.13,
  '8a2f5bd82177fff': 2.8,
  '8a2f5bd82b2ffff': 1.25,
  '8a2f5bd919a7fff': 1.43,
  '8a2f5bd82a17fff': 1.5,
  '8a2f5bd80707fff': 1,
  '8a2f5bd8000ffff': 2.75,
  '8a2f5bd8071ffff': 1.65,
  '8a2f5bd806a7fff': 2,
  '8a2f5bd82b47fff': 1.95,
  '8a2f5bd82157fff': 2.78,
  '8a2f5bd8161ffff': 1.73,
  '8a2f5bd8ac77fff': 1.3,
  '8a2f5bd8ac67fff': 1.22,
  '8a2f5bd8ac47fff': 1.23,
  '8a2f5bd8ed27fff': 2.56,
  '8a2f5bd8ac57fff': 1.95,
  '8a2f5bd9dd2ffff': 2.13,
  '8a2f5bd9dd27fff': 2.25,
  '8a2f5bd9dd37fff': 1.55,
  '8a2f5bd8369ffff': 1.11,
  '8a2f5bd9ca6ffff': 2.45,
  '8a2f5bd9ca67fff': 2.17,
  '8a2f5bd8a117fff': 1,
  '8a2f5bd8a137fff': 1,
  '8a2f5bd8c4effff': 7.11,
  '8a2f5bd81a9ffff': 2.63,
  '8a2f5bd83a77fff': 18.35,
  '8a2f5bd90a27fff': 11.25,
  '8a2f5bd90a2ffff': 17.54,
  '8a2f5bd9416ffff': 1,
  '8a2f5bd9566ffff': 5.76,
  '8a2f5bd91d6ffff': 2.3,
  '8a2f5bd9181ffff': 1.86,
  '8a2f5bd95b57fff': 1.33,
  '8a2f5bdb1597fff': 1.38,
  '8a2f5bd95ad7fff': 3.2,
  '8a2f5bd9532ffff': 1.4,
  '8a2f5bd942affff': 1.45,
  '8a2f5bd8c68ffff': 1,
  '8a2f5bd8c69ffff': 1,
  '8a2f5bd918cffff': 1.25,
  '8a2f5bd8dd37fff': 4.22,
  '8a2f5bdab55ffff': 2.91,
  '8a2f5bd9084ffff': 1.88,
  '8a2f5bdaa18ffff': 2.17,
  '8a2f5bd8499ffff': 1,
  '8a2f5bd8ed57fff': 1,
  '8a2f5bd8ed5ffff': 1.33,
  '8a2f5bdb33a7fff': 5.36,
  '8a2f5bd86c27fff': 1.06,
  '8a2f5bd86d1ffff': 1.31,
  '8a2f5bd9c7affff': 1.29,
  '8a2f5bd9c78ffff': 1,
  '8a2f5bdb30affff': 1.9,
  '8a2f5bdb3087fff': 1.21,
  '8a2f5bda25a7fff': 2.61,
  '8a2f5bdb525ffff': 4.05,
  '8a2f5bdb52effff': 3.43,
  '8a2f5bdb52c7fff': 3.83,
  '8a2f5bdb52dffff': 2.5,
  '8a2f5bdb192ffff': 3.78,
  '8a2f5bdb1907fff': 4,
  '8a2f5bd83b8ffff': 1.1,
  '8a2f5bd83a37fff': 1,
  '8a2f5bd86747fff': 1.07,
  '8a2f5bd8355ffff': 1,
  '8a2f5bd9c62ffff': 2.3,
  '8a2f5bd8191ffff': 2.45,
  '8a2f5bd918e7fff': 1.72,
  '8a2f5bda364ffff': 1.17,
  '8a2f5bd85917fff': 1.11,
  '8a2f5bd90c77fff': 1.27,
  '8a2f5bd90a67fff': 14.67,
  '8a2f5bd848dffff': 1.71,
  '8a2f5bd84ab7fff': 1.19,
  '8a2f5bd814c7fff': 2.25,
  '8a2f5bd952dffff': 5.24,
  '8a2f5bd952cffff': 4.19,
  '8a2f5bd82597fff': 4.95,
  '8a2f5bd8e8cffff': 3.82,
  '8a2f5bd8255ffff': 4.86,
  '8a2f5bd8242ffff': 4.09,
  '8a2f5bd90ae7fff': 8.26,
  '8a2f5bd8ac9ffff': 3.56,
  '8a2f5bd8ac97fff': 2.5,
  '8a2f5bd99177fff': 2.75,
  '8a2f5bd91527fff': 1.25,
  '8a2f5bd91c8ffff': 1.05,
  '8a2f5bd98b97fff': 5.19,
  '8a2f5bd98167fff': 3.89,
  '8a2f5bd90a9ffff': 2.53,
  '8a2f5bdb315ffff': 2.36,
  '8a2f5bdb3027fff': 1.61,
  '8a2f5bd82807fff': 1,
  '8a2f5bd82827fff': 1.13,
  '8a2f5bd99caffff': 1.67,
  '8a2f5bd98b07fff': 2.13,
  '8a2f5bd9c0dffff': 1.69,
  '8a2f5bd91a8ffff': 1,
  '8a2f5bd91ba7fff': 1.09,
  '8a2f5bd9539ffff': 1.42,
  '8a2f5bd950e7fff': 1.44,
  '8a2f5bd951affff': 2.32,
  '8a2f5bd951a7fff': 2.09,
  '8a2f5bd95897fff': 1.13,
  '8a2f5bd95997fff': 1.89,
  '8a2f5bdb3757fff': 1.46,
  '8a2f5bdb30d7fff': 1.58,
  '8a2f5bd988d7fff': 2.58,
  '8a2f5bdaa29ffff': 2.21,
  '8a2f5bdaa747fff': 1.25,
  '8a2f5bdaa297fff': 2.61,
  '8a2f5bd9d26ffff': 1.67,
  '8a2f5bdab52ffff': 2.33,
  '8a2f5bd8ac87fff': 1,
  '8a2f5bd84c9ffff': 1.5,
  '8a2f5bda229ffff': 1,
  '8a2f5bda2297fff': 1.2,
  '8a2f5bdb1367fff': 1,
  '8a2f5bd84d97fff': 1,
  '8a2f5bdb126ffff': 1.2,
  '8a2f5bd849affff': 1.46,
  '8a2f5bda266ffff': 1.21,
  '8a2f5bdaa007fff': 2.09,
  '8a2f5bdaaccffff': 2.26,
  '8a2f5bdaacdffff': 2,
  '8a2f5bdaa567fff': 3.64,
  '8a2f5bdb3b47fff': 2.64,
  '8a2f5bd9d317fff': 1,
  '8a2f5bd9d337fff': 1.36,
  '8a2f5bd9d307fff': 1.67,
  '8a2f5bd8e6b7fff': 2.29,
  '8a2f5bd9d8a7fff': 1.25,
  '8a2f5bd9d24ffff': 1.54,
  '8a2f5bd9c21ffff': 1.67,
  '8a2f5bd9c807fff': 1.53,
  '8a2f5bd9d06ffff': 1,
  '8a2f5bd9d9affff': 1.17,
  '8a2f5bd9d987fff': 1,
  '8a2f5bd9db8ffff': 3.11,
  '8a2f5bd8e157fff': 1.36,
  '8a2f5bd9db97fff': 1,
  '8a2f5bd9caf7fff': 2.17,
  '8a2f5bd9d04ffff': 1,
  '8a2f5bd82777fff': 1.29,
  '8a2f5bd9dd57fff': 1.55,
  '8a2f5bd9d357fff': 1.93,
  '8a2f5bd9d137fff': 1.94,
  '8a2f5bd9d127fff': 1.33,
  '8a2f5bd8e137fff': 2.76,
  '8a2f5bd9c917fff': 1.65,
  '8a2f5bd9c857fff': 2,
  '8a2f5bd9c2f7fff': 1.45,
  '8a2f5bda228ffff': 1.07,
  '8a2f5bda22f7fff': 1,
  '8a2f5bda22d7fff': 1,
  '8a2f5bd84907fff': 1,
  '8a2f5bd84927fff': 1,
  '8a2f5bd83b5ffff': 5.8,
  '8a2f5bdaa0dffff': 2.75,
  '8a2f5bdaa0cffff': 2.09,
  '8a2f5bd95b97fff': 2.05,
  '8a2f5bd95bb7fff': 1.27,
  '8a2f5bd958cffff': 1.38,
  '8a2f5bd959a7fff': 2,
  '8a2f5bd95167fff': 1.6,
  '8a2f5bd95177fff': 1.22,
  '8a2f5bd9585ffff': 1.82,
  '8a2f5bd839a7fff': 1.71,
  '8a2f5bd88d97fff': 1,
  '8a2f5bd88cb7fff': 1,
  '8a2f5bd88c97fff': 1,
  '8a2f5bd8ea97fff': 2.1,
  '8a2f5bd8e16ffff': 1.5,
  '8a2f5bd8e177fff': 5.4,
  '8a2f5bd8e12ffff': 8.2,
  '8a2f5bdaa10ffff': 2.17,
  '8a2f5bd8ccf7fff': 1.82,
  '8a2f5bd8194ffff': 2.24,
  '8a2f5bd81947fff': 5.53,
  '8a2f5bd8121ffff': 2.77,
  '8a2f5bd8195ffff': 1.47,
  '8a2f5bd85767fff': 1.87,
  '8a2f5bd850cffff': 1.32,
  '8a2f5bd850dffff': 2.26,
  '8a2f5bd85397fff': 1.73,
  '8a2f5bdaac9ffff': 5.6,
  '8a2f5bdaa1a7fff': 9.88,
  '8a2f5bd8196ffff': 3,
  '8a2f5bd81967fff': 5.37,
  '8a2f5bd8574ffff': 1,
  '8a2f5bd8576ffff': 1.41,
  '8a2f5bd852effff': 11.86,
  '8a2f5bdaa597fff': 5,
  '8a2f5bd852dffff': 3.63,
  '8a2f5bd852affff': 1,
  '8a2f5bdaaceffff': 10.5,
  '8a2f5bd85217fff': 1.6,
  '8a2f5bd8539ffff': 1,
  '8a2f5bd91947fff': 1.85,
  '8a2f5bd836effff': 1.3,
  '8a2f5bd9d9b7fff': 1.58,
  '8a2f5bd8c827fff': 1.13,
  '8a2f5bd8cc87fff': 2.1,
  '8a2f5bd86c1ffff': 1.64,
  '8a2f5bd90aaffff': 5.94,
  '8a2f5bd8ca6ffff': 8.76,
  '8a2f5bdab527fff': 1.67,
  '8a2f5bdab50ffff': 1.57,
  '8a2f5bd8c027fff': 8.35,
  '8a2f5bd950affff': 1,
  '8a2f5bd9501ffff': 1.7,
  '8a2f5bd950f7fff': 1,
  '8a2f5bd950d7fff': 1.59,
  '8a2f5bdb30f7fff': 2.3,
  '8a2f5bd95c6ffff': 1.22,
  '8a2f5bd95c4ffff': 2.19,
  '8a2f5bdb30a7fff': 1.33,
  '8a2f5bdb3187fff': 1.33,
  '8a2f5bda2507fff': 3.9,
  '8a2f5bd8a977fff': 1.67,
  '8a2f5bd8c4e7fff': 19.15,
  '8a2f5bd81227fff': 1.22,
  '8a2f5bd81277fff': 2.78,
  '8a2f5bd8c7b7fff': 2.07,
  '8a2f5bd8c787fff': 2.63,
  '8a2f5bd8c78ffff': 2.6,
  '8a2f5bd8c617fff': 2.62,
  '8a2f5bd8c61ffff': 2.38,
  '8a2f5bd8c6f7fff': 1.65,
  '8a2f5bd8c4d7fff': 4.75,
  '8a2f5bd8204ffff': 4.32,
  '8a2f5bd80057fff': 2.2,
  '8a2f5bd80a6ffff': 2.33,
  '8a2f5bdaa5b7fff': 6.16,
  '8a2f5bdaad5ffff': 1.43,
  '8a2f5bd8cc5ffff': 1.6,
  '8a2f5bd8cc1ffff': 1.83,
  '8a2f5bd86af7fff': 2,
  '8a2f5bd86ca7fff': 1.63,
  '8a2f5bd8a1b7fff': 1.07,
  '8a2f5bdb1c0ffff': 1.67,
  '8a2f5bd94b67fff': 1.57,
  '8a2f5bd95cf7fff': 1.4,
  '8a2f5bd83a1ffff': 3.47,
  '8a2f5bd952d7fff': 3.58,
  '8a2f5bd91927fff': 9.9,
  '8a2f5bd83517fff': 1.88,
  '8a2f5bd83d9ffff': 5.25,
  '8a2f5bd83c37fff': 5.43,
  '8a2f5bd9192ffff': 6.1,
  '8a2f5bd83d8ffff': 43.52,
  '8a2f5bd8351ffff': 1,
  '8a2f5bd83c17fff': 6.45,
  '8a2f5bd8e8d7fff': 13.95,
  '8a2f5bd83507fff': 1,
  '8a2f5bd86197fff': 1.5,
  '8a2f5bd86d9ffff': 2.85,
  '8a2f5bd86627fff': 1.47,
  '8a2f5bd9525ffff': 7.33,
  '8a2f5bd8385ffff': 1.45,
  '8a2f5bd83527fff': 1.5,
  '8a2f5bd83127fff': 4.17,
  '8a2f5bd83537fff': 1.18,
  '8a2f5bd82267fff': 4,
  '8a2f5bd83a67fff': 4.48,
  '8a2f5bd83107fff': 8.85,
  '8a2f5bd83c8ffff': 1.73,
  '8a2f5bd83117fff': 1.67,
  '8a2f5bd8e147fff': 1.76,
  '8a2f5bd8310ffff': 27.14,
  '8a2f5bd8312ffff': 4.3,
  '8a2f5bd83ca7fff': 1.25,
  '8a2f5bd83c87fff': 1.3,
  '8a2f5bd83d87fff': 3.38,
  '8a2f5bd8e167fff': 4.04,
  '8a2f5bd94777fff': 7.53,
  '8a2f5bd9099ffff': 1.57,
  '8a2f5bd90997fff': 1.63,
  '8a2f5bdb1997fff': 5.39,
  '8a2f5bd908f7fff': 1.47,
  '8a2f5bd9590ffff': 2.78,
  '8a2f5bd95957fff': 1.2,
  '8a2f5bd95977fff': 1.43,
  '8a2f5bd9c80ffff': 1.65,
  '8a2f5bdb319ffff': 1.17,
  '8a2f5bdb3467fff': 1,
  '8a2f5bdaac47fff': 2.27,
  '8a2f5bdaac5ffff': 5,
  '8a2f5bdaac4ffff': 6.25,
  '8a2f5bdaac6ffff': 3,
  '8a2f5bd838e7fff': 1.42,
  '8a2f5bd838effff': 1.56,
  '8a2f5bd838c7fff': 1.41,
  '8a2f5bd81897fff': 2.2,
  '8a2f5bd94317fff': 1.15,
  '8a2f5bd94307fff': 2.17,
  '8a2f5bd94747fff': 2.05,
  '8a2f5bd94b97fff': 1,
  '8a2f5bd8151ffff': 2.72,
  '8a2f5bdaacc7fff': 6.18,
  '8a2f5bd8eb2ffff': 5.16,
  '8a2f5bd8eb07fff': 3.5,
  '8a2f5bd8eb17fff': 3.41,
  '8a2f5bd8eb87fff': 3.42,
  '8a2f5bd8ed77fff': 2.53,
  '8a2f5bd8ed2ffff': 3.08,
  '8a2f5bd83a6ffff': 2.75,
  '8a2f5bd80627fff': 1.56,
  '8a2f5bd8eb97fff': 2.58,
  '8a2f5bd8060ffff': 1.78,
  '8a2f5bd806e7fff': 2.2,
  '8a2f5bd83a47fff': 2.77,
  '8a2f5bd8ed47fff': 2.94,
  '8a2f5bd80677fff': 1.8,
  '8a2f5bd839affff': 1.38,
  '8a2f5bd83837fff': 2.07,
  '8a2f5bd83a27fff': 1.3,
  '8a2f5bd8065ffff': 2.75,
  '8a2f5bd8064ffff': 1.43,
  '8a2f5bd83baffff': 1.2,
  '8a2f5bd83ba7fff': 1,
  '8a2f5bd83857fff': 2,
  '8a2f5bd80657fff': 2.38,
  '8a2f5bd8ed37fff': 3.23,
  '8a2f5bd83807fff': 2.31,
  '8a2f5bdb32c7fff': 4.06,
  '8a2f5bdb32e7fff': 2.29,
  '8a2f5bdb321ffff': 2.83,
  '8a2f5bdb3207fff': 2.79,
  '8a2f5bdb3237fff': 3.06,
  '8a2f5bdb331ffff': 2.61,
  '8a2f5bdb3317fff': 3.18,
  '8a2f5bdb3337fff': 3.68,
  '8a2f5bdb306ffff': 11.87,
  '8a2f5bd84d07fff': 1.38,
  '8a2f5bdb1917fff': 11.95,
  '8a2f5bd9474ffff': 1.74,
  '8a2f5bd94667fff': 1.62,
  '8a2f5bd90937fff': 2,
  '8a2f5bd83c77fff': 4.22,
  '8a2f5bd83c47fff': 3.29,
  '8a2f5bd83aaffff': 2.53,
  '8a2f5bd86417fff': 1,
  '8a2f5bd8658ffff': 1.13,
  '8a2f5bd82557fff': 2.79,
  '8a2f5bd820b7fff': 1.8,
  '8a2f5bd84267fff': 1.77,
  '8a2f5bdaa107fff': 2.43,
  '8a2f5bd8c0d7fff': 1,
  '8a2f5bd8c55ffff': 1,
  '8a2f5bd8c46ffff': 1.14,
  '8a2f5bd8c44ffff': 1.33,
  '8a2f5bd8c3b7fff': 1.13,
  '8a2f5bd8c557fff': 1.32,
  '8a2f5bd856c7fff': 2.11,
  '8a2f5bd865affff': 2.71,
  '8a2f5bd86437fff': 1.5,
  '8a2f5bd98af7fff': 3,
  '8a2f5bd98b8ffff': 7.87,
  '8a2f5bd998a7fff': 1,
  '8a2f5bd99d1ffff': 1.86,
  '8a2f5bd9989ffff': 1,
  '8a2f5bd998affff': 1,
  '8a2f5bd9988ffff': 1,
  '8a2f5bd99d5ffff': 3.28,
  '8a2f5bd99c2ffff': 1.17,
  '8a2f5bd99d57fff': 2.84,
  '8a2f5bd99c27fff': 1.75,
  '8a2f5bd99daffff': 1.27,
  '8a2f5bd808a7fff': 1.42,
  '8a2f5bd830f7fff': 3.1,
  '8a2f5bd8148ffff': 1.48,
  '8a2f5bd8a537fff': 1.89,
  '8a2f5bd86267fff': 2.32,
  '8a2f5bd86a1ffff': 2.56,
  '8a2f5bd86a07fff': 1.42,
  '8a2f5bd8c54ffff': 1.85,
  '8a2f5bd8ddb7fff': 1.56,
  '8a2f5bd8c177fff': 1.79,
  '8a2f5bd8c10ffff': 7.05,
  '8a2f5bd8061ffff': 1.22,
  '8a2f5bd80687fff': 2.5,
  '8a2f5bd9196ffff': 1.96,
  '8a2f5bd824b7fff': 2,
  '8a2f5bd82497fff': 1,
  '8a2f5bd8cc97fff': 1.25,
  '8a2f5bd95d57fff': 1,
  '8a2f5bd95c5ffff': 1.5,
  '8a2f5bd81287fff': 1.53,
  '8a2f5bd95cc7fff': 1,
  '8a2f5bd95ccffff': 1,
  '8a2f5bd951b7fff': 2.25,
  '8a2f5bd95197fff': 1.2,
  '8a2f5bd95a27fff': 3.14,
  '8a2f5bd95887fff': 1.29,
  '8a2f5bd82adffff': 3.55,
  '8a2f5bdb1b4ffff': 1,
  '8a2f5bd8e8f7fff': 2.06,
  '8a2f5bd90b57fff': 2.3,
  '8a2f5bd9c5affff': 2.3,
  '8a2f5bd82037fff': 3.75,
  '8a2f5bd85b27fff': 1.63,
  '8a2f5bd80537fff': 1.79,
  '8a2f5bd80507fff': 1,
  '8a2f5bd908effff': 1.08,
  '8a2f5bd9085ffff': 2.31,
  '8a2f5bd90bb7fff': 1,
  '8a2f5bd84027fff': 1.33,
  '8a2f5bd8402ffff': 1.21,
  '8a2f5bdaa58ffff': 2.22,
  '8a2f5bd852c7fff': 3.85,
  '8a2f5bd852d7fff': 1.6,
  '8a2f5bdaa5affff': 1.8,
  '8a2f5bd812cffff': 2,
  '8a2f5bdaa187fff': 2,
  '8a2f5bdaa037fff': 1.89,
  '8a2f5bdb3697fff': 1.2,
  '8a2f5bdb36b7fff': 1,
  '8a2f5bdb3687fff': 1.4,
  '8a2f5bdb1687fff': 1.27,
  '8a2f5bdb16a7fff': 1.55,
  '8a2f5bdb1527fff': 2.24,
  '8a2f5bda2cd7fff': 3.65,
  '8a2f5bdaacf7fff': 5.5,
  '8a2f5bd8675ffff': 1,
  '8a2f5bd866a7fff': 2.33,
  '8a2f5bd99d0ffff': 3.24,
  '8a2f5bd90837fff': 4.06,
  '8a2f5bd946cffff': 1.52,
  '8a2f5bd9c85ffff': 2.33,
  '8a2f5bd9cbb7fff': 1,
  '8a2f5bd958e7fff': 1,
  '8a2f5bd9581ffff': 1.4,
  '8a2f5bd943b7fff': 1.8,
  '8a2f5bd81d6ffff': 1.19,
  '8a2f5bd8498ffff': 1.62,
  '8a2f5bd80b4ffff': 1.5,
  '8a2f5bdb191ffff': 3.12,
  '8a2f5bd80b47fff': 2.1,
  '8a2f5bd80b6ffff': 2.58,
  '8a2f5bd81d67fff': 1.24,
  '8a2f5bd8199ffff': 1.08,
  '8a2f5bd81d47fff': 1,
  '8a2f5bd81997fff': 1,
  '8a2f5bd856b7fff': 2,
  '8a2f5bd85687fff': 1,
  '8a2f5bd856affff': 1.53,
  '8a2f5bda27affff': 1.06,
  '8a2f5bda271ffff': 1.53,
  '8a2f5bd98a07fff': 5.12,
  '8a2f5bd91537fff': 1.8,
  '8a2f5bd9c01ffff': 1.5,
  '8a2f5bd9c00ffff': 1.19,
  '8a2f5bd8e897fff': 3.4,
  '8a2f5bd9194ffff': 1.13,
  '8a2f5bd91867fff': 1.25,
  '8a2f5bd9195ffff': 1.36,
  '8a2f5bd8c18ffff': 2.57,
  '8a2f5bd8c0a7fff': 1.65,
  '8a2f5bd80d9ffff': 1.31,
  '8a2f5bd80ca7fff': 1.67,
  '8a2f5bd80cb7fff': 1.33,
  '8a2f5bdb362ffff': 1.2,
  '8a2f5bdb360ffff': 1.29,
  '8a2f5bdb3677fff': 1.29,
  '8a2f5bd836f7fff': 1.28,
  '8a2f5bdb1657fff': 1.6,
  '8a2f5bdb160ffff': 3.08,
  '8a2f5bdb1a57fff': 1.75,
  '8a2f5bd8e36ffff': 1.33,
  '8a2f5bdb1ce7fff': 2.42,
  '8a2f5bdb1ceffff': 1.32,
  '8a2f5bd9036ffff': 2.64,
  '8a2f5bd9034ffff': 3.7,
  '8a2f5bd91cb7fff': 1.64,
  '8a2f5bd90aeffff': 3.28,
  '8a2f5bd90adffff': 2.56,
  '8a2f5bd90347fff': 1.88,
  '8a2f5bd9c287fff': 1.75,
  '8a2f5bd91267fff': 2.2,
  '8a2f5bd9c36ffff': 1.38,
  '8a2f5bd9c207fff': 1,
  '8a2f5bd8412ffff': 1,
  '8a2f5bd84177fff': 1.42,
  '8a2f5bd95947fff': 1.78,
  '8a2f5bdb32cffff': 4.83,
  '8a2f5bd95967fff': 4.68,
  '8a2f5bd865b7fff': 2.9,
  '8a2f5bd8240ffff': 2.63,
  '8a2f5bd8241ffff': 2.38,
  '8a2f5bdb32dffff': 3.71,
  '8a2f5bd82767fff': 1,
  '8a2f5bd85957fff': 2,
  '8a2f5bd8590ffff': 1.87,
  '8a2f5bd84677fff': 1,
  '8a2f5bdaa117fff': 3.2,
  '8a2f5bda2d97fff': 1.6,
  '8a2f5bdb5367fff': 1.94,
  '8a2f5bdb530ffff': 1.67,
  '8a2f5bdb5227fff': 1.38,
  '8a2f5bd810d7fff': 1.29,
  '8a2f5bd82257fff': 1.69,
  '8a2f5bd8220ffff': 1.3,
  '8a2f5bd810c7fff': 1.2,
  '8a2f5bd810dffff': 1.14,
  '8a2f5bd81a97fff': 2.29,
  '8a2f5bd810affff': 1.22,
  '8a2f5bd8e10ffff': 2.83,
  '8a2f5bd8e107fff': 4.39,
  '8a2f5bd8e89ffff': 3.12,
  '8a2f5bd83c0ffff': 5.05,
  '8a2f5bd82277fff': 1.33,
  '8a2f5bd822e7fff': 1.25,
  '8a2f5bd8108ffff': 2.17,
  '8a2f5bd8236ffff': 2.13,
  '8a2f5bdaa1b7fff': 2,
  '8a2f5bdaa427fff': 2.43,
  '8a2f5bdaa50ffff': 2,
  '8a2f5bda22b7fff': 1.93,
  '8a2f5bda2387fff': 2.06,
  '8a2f5bd94247fff': 1,
  '8a2f5bd8c207fff': 2.44,
  '8a2f5bd88987fff': 1,
  '8a2f5bd85b2ffff': 1,
  '8a2f5bd85897fff': 1,
  '8a2f5bd85887fff': 1.04,
  '8a2f5bd831a7fff': 1.5,
  '8a2f5bd831b7fff': 2,
  '8a2f5bd96a67fff': 1.18,
  '8a2f5bd94687fff': 1.68,
  '8a2f5bd946a7fff': 1,
  '8a2f5bd946b7fff': 1,
  '8a2f5bd90b07fff': 4.55,
  '8a2f5bd8ca1ffff': 3.83,
  '8a2f5bda260ffff': 2.44,
  '8a2f5bd812affff': 2.17,
  '8a2f5bd8526ffff': 3.64,
  '8a2f5bd81497fff': 2.17,
  '8a2f5bdb1087fff': 2.94,
  '8a2f5bdb109ffff': 1.56,
  '8a2f5bdb108ffff': 1,
  '8a2f5bd86c2ffff': 1.42,
  '8a2f5bd86c77fff': 1.77,
  '8a2f5bd86c0ffff': 1.42,
  '8a2f5bdb1287fff': 1.18,
  '8a2f5bd9400ffff': 2.18,
  '8a2f5bd805a7fff': 1.84,
  '8a2f5bd91d97fff': 2,
  '8a2f5bd90a5ffff': 2.55,
  '8a2f5bd956affff': 7.75,
  '8a2f5bd8624ffff': 1.64,
  '8a2f5bd8611ffff': 1.3,
  '8a2f5bd954d7fff': 1.5,
  '8a2f5bd90867fff': 1.2,
  '8a2f5bd95597fff': 1,
  '8a2f5bd86c07fff': 1,
  '8a2f5bd86d0ffff': 2,
  '8a2f5bd840dffff': 1.25,
  '8a2f5bd840cffff': 1,
  '8a2f5bdb3157fff': 2,
  '8a2f5bd804affff': 1.14,
  '8a2f5bd804cffff': 1.32,
  '8a2f5bd804dffff': 3.71,
  '8a2f5bda2c8ffff': 4.07,
  '8a2f5bd86b5ffff': 2,
  '8a2f5bd86a2ffff': 2.24,
  '8a2f5bd86a77fff': 1.12,
  '8a2f5bd86d8ffff': 2.4,
  '8a2f5bd82aeffff': 1.77,
  '8a2f5bd86c5ffff': 2.79,
  '8a2f5bd86d77fff': 1.93,
  '8a2f5bd86d57fff': 1.5,
  '8a2f5bd86c47fff': 1.57,
  '8a2f5bd840c7fff': 1,
  '8a2f5bd840f7fff': 1,
  '8a2f5bd83db7fff': 2.24,
  '8a2f5bd9dd5ffff': 2.56,
  '8a2f5bd9dd4ffff': 1.82,
  '8a2f5bdb3a9ffff': 3.21,
  '8a2f5bd9014ffff': 1.14,
  '8a2f5bdb1617fff': 1.13,
  '8a2f5bd86997fff': 4.28,
  '8a2f5bd9d247fff': 2.18,
  '8a2f5bd9d8dffff': 2.33,
  '8a2f5bd958c7fff': 1.58,
  '8a2f5bd84767fff': 1,
  '8a2f5bdb316ffff': 1.17,
  '8a2f5bd90337fff': 2,
  '8a2f5bd952a7fff': 2.11,
  '8a2f5bdb3adffff': 2.75,
  '8a2f5bd9090ffff': 1.86,
  '8a2f5bd90367fff': 2.56,
  '8a2f5bd9514ffff': 1,
  '8a2f5bd90acffff': 2.74,
  '8a2f5bd8099ffff': 1.65,
  '8a2f5bd8c447fff': 3.24,
  '8a2f5bd90107fff': 2.16,
  '8a2f5bd95687fff': 5,
  '8a2f5bd956f7fff': 2.52,
  '8a2f5bd8c157fff': 4.89,
  '8a2f5bd82687fff': 13.32,
  '8a2f5bd826b7fff': 2.88,
  '8a2f5bd8279ffff': 1.72,
  '8a2f5bd94727fff': 1.25,
  '8a2f5bd8256ffff': 3.39,
  '8a2f5bd8ebaffff': 2.35,
  '8a2f5bd8eba7fff': 3.21,
  '8a2f5bd8e457fff': 1.53,
  '8a2f5bdb3717fff': 1.83,
  '8a2f5bd8564ffff': 1.08,
  '8a2f5bd81937fff': 1.29,
  '8a2f5bd8304ffff': 1.5,
  '8a2f5bd8ed4ffff': 3.14,
  '8a2f5bd8ed07fff': 2.91,
  '8a2f5bd8646ffff': 1.26,
  '8a2f5bd86707fff': 1.92,
  '8a2f5bd8678ffff': 2.48,
  '8a2f5bd82017fff': 3.52,
  '8a2f5bd8e11ffff': 2.69,
  '8a2f5bdaac57fff': 3.58,
  '8a2f5bdaac67fff': 1.5,
  '8a2f5bd8106ffff': 2.71,
  '8a2f5bdb1b6ffff': 1.59,
  '8a2f5bda279ffff': 1,
  '8a2f5bd90a1ffff': 4.95,
  '8a2f5bd81677fff': 1.94,
  '8a2f5bd8ca57fff': 2.12,
  '8a2f5bda27a7fff': 1.21,
  '8a2f5bda26e7fff': 1,
  '8a2f5bda2707fff': 2.64,
  '8a2f5bda2787fff': 2,
  '8a2f5bd84817fff': 2.33,
  '8a2f5bda2637fff': 1.25,
  '8a2f5bdb1837fff': 2.38,
  '8a2f5bda245ffff': 1,
  '8a2f5bda268ffff': 1,
  '8a2f5bd803affff': 1.13,
  '8a2f5bd80317fff': 1.5,
  '8a2f5bd86aaffff': 2.87,
  '8a2f5bdaa11ffff': 3,
  '8a2f5bd8031ffff': 1.76,
  '8a2f5bd8022ffff': 1.79,
  '8a2f5bd80007fff': 2.14,
  '8a2f5bd803a7fff': 1.05,
  '8a2f5bd81d57fff': 1.5,
  '8a2f5bd85ccffff': 1.09,
  '8a2f5bd85197fff': 1.33,
  '8a2f5bd851b7fff': 1,
  '8a2f5bd854affff': 3,
  '8a2f5bd8424ffff': 1.73,
  '8a2f5bdb3327fff': 1.38,
  '8a2f5bd86027fff': 1.11,
  '8a2f5bd86497fff': 6.54,
  '8a2f5bd82d97fff': 3.33,
  '8a2f5bd82cb7fff': 1.62,
  '8a2f5bd82c97fff': 3.06,
  '8a2f5bd9526ffff': 3.74,
  '8a2f5bd82537fff': 4.5,
  '8a2f5bd8219ffff': 6.63,
  '8a2f5bd828dffff': 1.25,
  '8a2f5bd82027fff': 2.2,
  '8a2f5bdaaad7fff': 1.6,
  '8a2f5bd8525ffff': 3,
  '8a2f5bdaa027fff': 1.73,
  '8a2f5bdaaaf7fff': 1,
  '8a2f5bd81d0ffff': 1.21,
  '8a2f5bd81c27fff': 3,
  '8a2f5bd91967fff': 2.47,
  '8a2f5bd864cffff': 1.67,
  '8a2f5bd864c7fff': 2.17,
  '8a2f5bd95b2ffff': 1.41,
  '8a2f5bdb304ffff': 2.33,
  '8a2f5bdabcf7fff': 1.33,
  '8a2f5bd98b2ffff': 1,
  '8a2f5bd8620ffff': 1.95,
  '8a2f5bd86207fff': 1.25,
  '8a2f5bd908cffff': 2.33,
  '8a2f5bd91937fff': 6.95,
  '8a2f5bd83157fff': 4.33,
  '8a2f5bd83c4ffff': 2.56,
  '8a2f5bd83d97fff': 5.08,
  '8a2f5bd8206ffff': 3.41,
  '8a2f5bd82007fff': 4.36,
  '8a2f5bdb388ffff': 1.21,
  '8a2f5bd85b97fff': 1,
  '8a2f5bd8ed6ffff': 1.13,
  '8a2f5bdab587fff': 1.29,
  '8a2f5bd95277fff': 4.17,
  '8a2f5bd953affff': 1,
  '8a2f5bd82367fff': 3.18,
  '8a2f5bd86657fff': 2.09,
  '8a2f5bd9520ffff': 2.24,
  '8a2f5bd91827fff': 1,
  '8a2f5bd956effff': 2.19,
  '8a2f5bd956c7fff': 1,
  '8a2f5bd91957fff': 2.17,
  '8a2f5bd90917fff': 1.88,
  '8a2f5bd9080ffff': 2,
  '8a2f5bd90857fff': 2.3,
  '8a2f5bd9565ffff': 1.85,
  '8a2f5bd9190ffff': 1.75,
  '8a2f5bd86ac7fff': 2.45,
  '8a2f5bd832e7fff': 2.07,
  '8a2f5bd988affff': 3.52,
  '8a2f5bd9888ffff': 2.11,
  '8a2f5bd95b07fff': 2.48,
  '8a2f5bd988a7fff': 2.13,
  '8a2f5bd9c657fff': 4.33,
  '8a2f5bd9c677fff': 4.08,
  '8a2f5bd9c70ffff': 1.22,
  '8a2f5bd9c717fff': 1.5,
  '8a2f5bd9c737fff': 2.17,
  '8a2f5bd988cffff': 1.4,
  '8a2f5bd98b87fff': 4.4,
  '8a2f5bd98baffff': 2.24,
  '8a2f5bd98a2ffff': 3.67,
  '8a2f5bd98a77fff': 3.5,
  '8a2f5bd98a47fff': 2.9,
  '8a2f5bd98a6ffff': 3.06,
  '8a2f5bd99d07fff': 3.18,
  '8a2f5bd99147fff': 3.41,
  '8a2f5bd9912ffff': 2.41,
  '8a2f5bd99107fff': 2.38,
  '8a2f5bd99c6ffff': 2.33,
  '8a2f5bd99c67fff': 2.95,
  '8a2f5bd99d37fff': 3.95,
  '8a2f5bd95daffff': 1.33,
  '8a2f5bd95c1ffff': 1.2,
  '8a2f5bd8eb5ffff': 1,
  '8a2f5bd8ea77fff': 1.88,
  '8a2f5bd8ea2ffff': 1.43,
  '8a2f5bd8eb57fff': 1.5,
  '8a2f5bd88c37fff': 1,
  '8a2f5bd88c27fff': 1,
  '8a2f5bd88c07fff': 1.57,
  '8a2f5bd952f7fff': 2.5,
  '8a2f5bd95287fff': 2.28,
  '8a2f5bd94b9ffff': 1.84,
  '8a2f5bd91a1ffff': 1.19,
  '8a2f5bd95c27fff': 2,
  '8a2f5bd9005ffff': 1.71,
  '8a2f5bd8545ffff': 1.18,
  '8a2f5bd8e017fff': 1.9,
  '8a2f5bd8e14ffff': 1.89,
  '8a2f5bd8120ffff': 1.83,
  '8a2f5bd81977fff': 3.65,
  '8a2f5bd8544ffff': 1,
  '8a2f5bda252ffff': 3.14,
  '8a2f5bd8ca4ffff': 2.85,
  '8a2f5bd8cc4ffff': 1.95,
  '8a2f5bd8c467fff': 3.71,
  '8a2f5bd8c457fff': 5.24,
  '8a2f5bd9006ffff': 2.73,
  '8a2f5bd8ad5ffff': 1.61,
  '8a2f5bd9da57fff': 2.75,
  '8a2f5bd8a0a7fff': 1,
  '8a2f5bd9da5ffff': 1.67,
  '8a2f5bd8a89ffff': 1.63,
  '8a2f5bd8a807fff': 1,
  '8a2f5bd9dac7fff': 2.67,
  '8a2f5bd9d377fff': 1.58,
  '8a2f5bd9dadffff': 1.94,
  '8a2f5bd8ac07fff': 1.04,
  '8a2f5bd8a8b7fff': 2.8,
  '8a2f5bd8e4effff': 1.69,
  '8a2f5bd83317fff': 2.61,
  '8a2f5bd8e51ffff': 1.67,
  '8a2f5bd830affff': 1.33,
  '8a2f5bd830effff': 1.3,
  '8a2f5bd8e787fff': 2,
  '8a2f5bd9578ffff': 1.5,
  '8a2f5bd95717fff': 1.29,
  '8a2f5bd95447fff': 1.42,
  '8a2f5bd9518ffff': 1.71,
  '8a2f5bd9546ffff': 1.24,
  '8a2f5bd9555ffff': 1.53,
  '8a2f5bd90ac7fff': 4.16,
  '8a2f5bd95637fff': 1.85,
  '8a2f5bd91db7fff': 1.69,
  '8a2f5bd90a47fff': 1.82,
  '8a2f5bd95787fff': 1,
  '8a2f5bd9560ffff': 4.86,
  '8a2f5bd95657fff': 3.75,
  '8a2f5bd9545ffff': 1,
  '8a2f5bd950a7fff': 1.82,
  '8a2f5bd824affff': 1.6,
  '8a2f5bd95737fff': 1.29,
  '8a2f5bd95697fff': 5.05,
  '8a2f5bd9575ffff': 1.69,
  '8a2f5bd91af7fff': 1.95,
  '8a2f5bd9185ffff': 1.5,
  '8a2f5bd952b7fff': 1.59,
  '8a2f5bd85697fff': 2.17,
  '8a2f5bd80b67fff': 3.87,
  '8a2f5bd91aaffff': 2.41,
  '8a2f5bd91ac7fff': 1,
  '8a2f5bd815affff': 1,
  '8a2f5bd8c477fff': 1.93,
  '8a2f5bd806b7fff': 2.35,
  '8a2f5bd82b6ffff': 1.76,
  '8a2f5bd82b67fff': 1.71,
  '8a2f5bd80497fff': 1.73,
  '8a2f5bd83137fff': 4.24,
  '8a2f5bd8389ffff': 3.65,
  '8a2f5bd83d4ffff': 2.32,
  '8a2f5bd8068ffff': 2.29,
  '8a2f5bd804d7fff': 1.75,
  '8a2f5bdab68ffff': 2.36,
  '8a2f5bdb130ffff': 1.33,
  '8a2f5bdb131ffff': 1.79,
  '8a2f5bd817a7fff': 1.47,
  '8a2f5bd817b7fff': 1,
  '8a2f5bd9c5a7fff': 2.53,
  '8a2f5bd9124ffff': 2.3,
  '8a2f5bd9125ffff': 1.17,
  '8a2f5bd8665ffff': 1.33,
  '8a2f5bd828affff': 1.17,
  '8a2f5bd820a7fff': 3.12,
  '8a2f5bd8211ffff': 1.27,
  '8a2f5bd82117fff': 1.2,
  '8a2f5bd82127fff': 1.13,
  '8a2f5bd82817fff': 1,
  '8a2f5bd829a7fff': 1,
  '8a2f5bd8288ffff': 1.78,
  '8a2f5bd8210ffff': 2.11,
  '8a2f5bd86607fff': 1.36,
  '8a2f5bd91c9ffff': 2.36,
  '8a2f5bdb1d77fff': 4.3,
  '8a2f5bdb1d67fff': 4.58,
  '8a2f5bd82457fff': 1.48,
  '8a2f5bd81047fff': 1.71,
  '8a2f5bd8105ffff': 1.18,
  '8a2f5bd83b57fff': 3.05,
  '8a2f5bd83daffff': 2.05,
  '8a2f5bd83b47fff': 1.56,
  '8a2f5bd98937fff': 1.45,
  '8a2f5bd9c65ffff': 3,
  '8a2f5bd8364ffff': 1.05,
  '8a2f5bd8449ffff': 1,
  '8a2f5bdaa36ffff': 1.15,
  '8a2f5bdabd9ffff': 1.85,
  '8a2f5bdabcb7fff': 1.38,
  '8a2f5bd8caa7fff': 3.36,
  '8a2f5bd8c0b7fff': 4.06,
  '8a2f5bd8c097fff': 3.08,
  '8a2f5bdab557fff': 1.7,
  '8a2f5bdab577fff': 1.29,
  '8a2f5bdaa5a7fff': 2.33,
  '8a2f5bdaa517fff': 2.78,
  '8a2f5bdaa507fff': 2.5,
  '8a2f5bdaa52ffff': 2.46,
  '8a2f5bdaacd7fff': 2.06,
  '8a2f5bd8209ffff': 1.6,
  '8a2f5bdaa537fff': 2.42,
  '8a2f5bd9ca37fff': 3,
  '8a2f5bd90db7fff': 1.17,
  '8a2f5bd81c9ffff': 1.82,
  '8a2f5bd81537fff': 1.1,
  '8a2f5bd81517fff': 1.4,
  '8a2f5bd80267fff': 1.1,
  '8a2f5bd80277fff': 1.75,
  '8a2f5bdb14b7fff': 2.06,
  '8a2f5bdb396ffff': 1,
  '8a2f5bd990e7fff': 1,
  '8a2f5bd9901ffff': 1.25,
  '8a2f5bd990f7fff': 1,
  '8a2f5bd990c7fff': 1,
  '8a2f5bd9d49ffff': 2.19,
  '8a2f5bd9c28ffff': 1.58,
  '8a2f5bd9c767fff': 1.43,
  '8a2f5bd94b17fff': 1.15,
  '8a2f5bd94267fff': 1.08,
  '8a2f5bdb198ffff': 2.75,
  '8a2f5bd84847fff': 1.8,
  '8a2f5bd8041ffff': 1.63,
  '8a2f5bd830e7fff': 1.64,
  '8a2f5bd81237fff': 1.38,
  '8a2f5bd81c47fff': 2.41,
  '8a2f5bd95d6ffff': 1,
  '8a2f5bd95d4ffff': 1,
  '8a2f5bd8e937fff': 1,
  '8a2f5bd9c02ffff': 1,
  '8a2f5bd9c15ffff': 1,
  '8a2f5bd81ac7fff': 1.11,
  '8a2f5bd81acffff': 1.46,
  '8a2f5bd8e8c7fff': 3.25,
  '8a2f5bd81adffff': 1.33,
  '8a2f5bd81ab7fff': 2.41,
  '8a2f5bd81aeffff': 1.5,
  '8a2f5bd81a5ffff': 1,
  '8a2f5bd8e337fff': 1.58,
  '8a2f5bd8e327fff': 1.42,
  '8a2f5bd8ea9ffff': 1.29,
  '8a2f5bd8ea8ffff': 1.25,
  '8a2f5bd8eaeffff': 1.31,
  '8a2f5bd8e15ffff': 1.17,
  '8a2f5bd8e04ffff': 1.25,
  '8a2f5bd8e06ffff': 1,
  '8a2f5bd8e32ffff': 1,
  '8a2f5bd8ec4ffff': 1.63,
  '8a2f5bd8e3a7fff': 1.67,
  '8a2f5bd8e1affff': 2.21,
  '8a2f5bd9dd67fff': 1,
  '8a2f5bd8ea87fff': 1.11,
  '8a2f5bd9424ffff': 1.13,
  '8a2f5bd9425ffff': 1.23,
  '8a2f5bd9558ffff': 1,
  '8a2f5bd95417fff': 1.25,
  '8a2f5bd954affff': 1.33,
  '8a2f5bd954f7fff': 1,
  '8a2f5bd95487fff': 1,
  '8a2f5bd954a7fff': 1,
  '8a2f5bd955a7fff': 1,
  '8a2f5bd8eb27fff': 3.45,
  '8a2f5bd8ebb7fff': 2.84,
  '8a2f5bd8cae7fff': 1.81,
  '8a2f5bd8254ffff': 3.8,
  '8a2f5bd8ca67fff': 1.64,
  '8a2f5bdb1887fff': 2.2,
  '8a2f5bd9cd4ffff': 1,
  '8a2f5bdb13affff': 1.96,
  '8a2f5bd99157fff': 1.69,
  '8a2f5bd9180ffff': 1.77,
  '8a2f5bd82cd7fff': 1.65,
  '8a2f5bd9d927fff': 1.43,
  '8a2f5bd9d907fff': 1.22,
  '8a2f5bd9549ffff': 1.92,
  '8a2f5bd90b27fff': 2.08,
  '8a2f5bd9466ffff': 1.58,
  '8a2f5bd9091ffff': 1.79,
  '8a2f5bd816c7fff': 1.47,
  '8a2f5bd8145ffff': 1,
  '8a2f5bd81427fff': 1.55,
  '8a2f5bd8146ffff': 1.6,
  '8a2f5bd8005ffff': 2.08,
  '8a2f5bd8e88ffff': 2.36,
  '8a2f5bd83a17fff': 1.2,
  '8a2f5bd852cffff': 8.75,
  '8a2f5bdb152ffff': 2.13,
  '8a2f5bdb1547fff': 2.09,
  '8a2f5bd9c577fff': 1.1,
  '8a2f5bd9c197fff': 1.3,
  '8a2f5bd9c52ffff': 1.18,
  '8a2f5bd9c50ffff': 1.42,
  '8a2f5bd9c2dffff': 1.9,
  '8a2f5bd9dbb7fff': 2.67,
  '8a2f5bd9c507fff': 1.64,
  '8a2f5bdb32d7fff': 1,
  '8a2f5bd8ec5ffff': 1,
  '8a2f5bd8cc8ffff': 1,
  '8a2f5bd8cd8ffff': 1.33,
  '8a2f5bd8c527fff': 1.18,
  '8a2f5bd83c2ffff': 1.44,
  '8a2f5bd81307fff': 1,
  '8a2f5bd81d27fff': 1.5,
  '8a2f5bd8568ffff': 1.5,
  '8a2f5bd856d7fff': 1.76,
  '8a2f5bd856effff': 1.22,
  '8a2f5bdb1147fff': 1.2,
  '8a2f5bd8e79ffff': 2,
  '8a2f5bdb1cf7fff': 3.5,
  '8a2f5bd9d92ffff': 2.45,
  '8a2f5bd832dffff': 1.17,
  '8a2f5bd9c46ffff': 2.16,
  '8a2f5bd9c71ffff': 2.1,
  '8a2f5bd989a7fff': 2.12,
  '8a2f5bd98987fff': 3.08,
  '8a2f5bd9898ffff': 2.58,
  '8a2f5bd8e887fff': 1.05,
  '8a2f5bd8e8affff': 1.27,
  '8a2f5bd8e8a7fff': 1,
  '8a2f5bd8e2cffff': 1.86,
  '8a2f5bd8e8effff': 2.7,
  '8a2f5bd9cb9ffff': 1,
  '8a2f5bd808c7fff': 1.4,
  '8a2f5bd8192ffff': 3.42,
  '8a2f5bd80adffff': 1,
  '8a2f5bd80367fff': 1,
  '8a2f5bd8036ffff': 1.17,
  '8a2f5bdaace7fff': 3.17,
  '8a2f5bd99da7fff': 1.82,
  '8a2f5bd98bb7fff': 1.52,
  '8a2f5bd98ba7fff': 1.78,
  '8a2f5bd9c64ffff': 2.11,
  '8a2f5bd98b1ffff': 3.42,
  '8a2f5bd98a27fff': 1.91,
  '8a2f5bd9881ffff': 1.29,
  '8a2f5bd9885ffff': 1.13,
  '8a2f5bd81447fff': 1.23,
  '8a2f5bd8144ffff': 1,
  '8a2f5bd8088ffff': 1.29,
  '8a2f5bd8c4b7fff': 1,
  '8a2f5bd8e96ffff': 1,
  '8a2f5bd8a927fff': 2,
  '8a2f5bd8e2effff': 1.2,
  '8a2f5bd8e027fff': 1.2,
  '8a2f5bd8e0b7fff': 1.13,
  '8a2f5bd8eab7fff': 1,
  '8a2f5bd8e297fff': 1,
  '8a2f5bd8a95ffff': 1,
  '8a2f5bd8c19ffff': 1.94,
  '8a2f5bd88d87fff': 2.09,
  '8a2f5bd8e2d7fff': 1,
  '8a2f5bd8e00ffff': 1,
  '8a2f5bd8e087fff': 1,
  '8a2f5bdaa51ffff': 4.64,
  '8a2f5bd8c88ffff': 3.07,
  '8a2f5bdaa6f7fff': 1,
  '8a2f5bd8e0c7fff': 1,
  '8a2f5bd8ec77fff': 1,
  '8a2f5bd8ec67fff': 1.21,
  '8a2f5bd8e3b7fff': 1,
  '8a2f5bd8c727fff': 1,
  '8a2f5bd8c707fff': 1.3,
  '8a2f5bd8c6c7fff': 1.6,
  '8a2f5bd8eacffff': 1.68,
  '8a2f5bd99b27fff': 1,
  '8a2f5bd9dad7fff': 2,
  '8a2f5bd8a577fff': 1,
  '8a2f5bd8a567fff': 1.07,
  '8a2f5bd9d897fff': 1.2,
  '8a2f5bd9dc6ffff': 1.89,
  '8a2f5bd9d8cffff': 2.06,
  '8a2f5bd9db87fff': 2,
  '8a2f5bd9da37fff': 2.42,
  '8a2f5bd9da07fff': 1.86,
  '8a2f5bd9da0ffff': 1.69,
  '8a2f5bd8acdffff': 1.07,
  '8a2f5bd95a2ffff': 3.9,
  '8a2f5bd95a47fff': 1.14,
  '8a2f5bd9d327fff': 1.17,
  '8a2f5bd9d32ffff': 1,
  '8a2f5bd9d30ffff': 1,
  '8a2f5bd942a7fff': 1.33,
  '8a2f5bd8349ffff': 1.57,
  '8a2f5bd848d7fff': 1.53,
  '8a2f5bd81857fff': 1.74,
  '8a2f5bd8a467fff': 1.38,
  '8a2f5bd8c807fff': 2,
  '8a2f5bd91147fff': 1,
  '8a2f5bd91157fff': 1.9,
  '8a2f5bd9c0cffff': 1.29,
  '8a2f5bd988f7fff': 2.12,
  '8a2f5bd98b27fff': 2.8,
  '8a2f5bd9c2d7fff': 1.95,
  '8a2f5bdb16cffff': 2,
  '8a2f5bd82417fff': 6,
  '8a2f5bd95b0ffff': 2.7,
  '8a2f5bd86d4ffff': 1.4,
  '8a2f5bd9ccf7fff': 2.53,
  '8a2f5bd8699ffff': 1.73,
  '8a2f5bdb1707fff': 1.94,
  '8a2f5bd9cb8ffff': 1.14,
  '8a2f5bdb1637fff': 2,
  '8a2f5bd91977fff': 1.8,
  '8a2f5bd9d2dffff': 1.31,
  '8a2f5bd9ca77fff': 1.92,
  '8a2f5bdb1ccffff': 1.8,
  '8a2f5bd82ad7fff': 2.79,
  '8a2f5bd9c66ffff': 1.4,
  '8a2f5bd86987fff': 2.7,
  '8a2f5bdb1587fff': 2.87,
  '8a2f5bdb3ad7fff': 2.24,
  '8a2f5bd94367fff': 1.08,
  '8a2f5bd94377fff': 1,
  '8a2f5bd94adffff': 1.17,
  '8a2f5bd954c7fff': 1,
  '8a2f5bdb3637fff': 1.44,
  '8a2f5bd9515ffff': 1,
  '8a2f5bd9476ffff': 1,
  '8a2f5bd94af7fff': 1.3,
  '8a2f5bd9430ffff': 1,
  '8a2f5bd94767fff': 1.63,
  '8a2f5bd9096ffff': 1,
  '8a2f5bd90a57fff': 5.24,
  '8a2f5bd9434ffff': 1,
  '8a2f5bd94227fff': 2,
  '8a2f5bdb3107fff': 1,
  '8a2f5bd94a57fff': 1,
  '8a2f5bd864effff': 1.6,
  '8a2f5bdb3647fff': 1,
  '8a2f5bd836cffff': 2.11,
  '8a2f5bd9ca27fff': 2.21,
  '8a2f5bd98817fff': 2.13,
  '8a2f5bd98837fff': 2.16,
  '8a2f5bd989affff': 1,
  '8a2f5bd98917fff': 1,
  '8a2f5bd9c29ffff': 1.14,
  '8a2f5bd9ca17fff': 2,
  '8a2f5bd9ca1ffff': 1.38,
  '8a2f5bd9c367fff': 1.33,
  '8a2f5bd9dd97fff': 1,
  '8a2f5bd9cacffff': 1.33,
  '8a2f5bd9cac7fff': 1.22,
  '8a2f5bd8325ffff': 2.35,
  '8a2f5bd8e5a7fff': 2.38,
  '8a2f5bd9c35ffff': 1.33,
  '8a2f5bd9c34ffff': 1.2,
  '8a2f5bd833affff': 3.25,
  '8a2f5bd9d917fff': 1.6,
  '8a2f5bd9d9a7fff': 1.3,
  '8a2f5bd86aa7fff': 2.08,
  '8a2f5bd86ab7fff': 1.09,
  '8a2f5bd9c22ffff': 1,
  '8a2f5bd9c20ffff': 1,
  '8a2f5bd9899ffff': 1.44,
  '8a2f5bdb325ffff': 1.96,
  '8a2f5bdaa627fff': 1,
  '8a2f5bdaa197fff': 1.38,
  '8a2f5bdaa2f7fff': 1.44,
  '8a2f5bdabc87fff': 1,
  '8a2f5bdaa437fff': 3.15,
  '8a2f5bdaa74ffff': 1.62,
  '8a2f5bd854d7fff': 3.36,
  '8a2f5bd854dffff': 3,
  '8a2f5bdaa0e7fff': 1.32,
  '8a2f5bdaa71ffff': 1,
  '8a2f5bdaa72ffff': 1,
  '8a2f5bdaa62ffff': 1,
  '8a2f5bd8e9affff': 1.58,
  '8a2f5bd8e987fff': 1.6,
  '8a2f5bd9911ffff': 1.2,
  '8a2f5bd99027fff': 1.2,
  '8a2f5bd99867fff': 1,
  '8a2f5bd9995ffff': 1.09,
  '8a2f5bd8a4dffff': 1.13,
  '8a2f5bd99ba7fff': 2.54,
  '8a2f5bd99b2ffff': 1.57,
  '8a2f5bd99b0ffff': 1.27,
  '8a2f5bd8e55ffff': 1.18,
  '8a2f5bd9ddaffff': 1.14,
  '8a2f5bd9da77fff': 1.88,
  '8a2f5bd8c4f7fff': 4.06,
  '8a2f5bdb381ffff': 1.22,
  '8a2f5bdb1097fff': 1,
  '8a2f5bdb1467fff': 1,
  '8a2f5bdb1477fff': 1,
  '8a2f5bd9191ffff': 2.11,
  '8a2f5bd91807fff': 1.5,
  '8a2f5bd91837fff': 1.96,
  '8a2f5bd90c0ffff': 1.56,
  '8a2f5bda3517fff': 1,
  '8a2f5bd94ab7fff': 1.08,
  '8a2f5bd94aa7fff': 1.75,
  '8a2f5bd94a87fff': 1,
  '8a2f5bd81407fff': 2.17,
  '8a2f5bd814e7fff': 2.05,
  '8a2f5bd81437fff': 1.2,
  '8a2f5bd8259ffff': 2.56,
  '8a2f5bd8140ffff': 2,
  '8a2f5bd83b2ffff': 1.33,
  '8a2f5bd90807fff': 3.35,
  '8a2f5bd8345ffff': 1,
  '8a2f5bd8334ffff': 1.17,
  '8a2f5bd814dffff': 1.69,
  '8a2f5bd83b77fff': 1.85,
  '8a2f5bd83b0ffff': 1.27,
  '8a2f5bd83caffff': 1.33,
  '8a2f5bd83ab7fff': 2.87,
  '8a2f5bd8141ffff': 2,
  '8a2f5bd8142ffff': 1,
  '8a2f5bd9da6ffff': 1.67,
  '8a2f5bd8e78ffff': 1.06,
  '8a2f5bd86d17fff': 1.45,
  '8a2f5bd846a7fff': 2.23,
  '8a2f5bd84607fff': 1.25,
  '8a2f5bd8462ffff': 1.4,
  '8a2f5bd8475ffff': 1.21,
  '8a2f5bd842b7fff': 1.22,
  '8a2f5bdaad47fff': 1.42,
  '8a2f5bdaa897fff': 2.21,
  '8a2f5bdaa89ffff': 1.58,
  '8a2f5bd8e2f7fff': 1,
  '8a2f5bd8e28ffff': 1,
  '8a2f5bd83c07fff': 5.86,
  '8a2f5bd8ecd7fff': 1.84,
  '8a2f5bd8e067fff': 1.24,
  '8a2f5bd8249ffff': 1.17,
  '8a2f5bd918b7fff': 1.5,
  '8a2f5bd952effff': 2.29,
  '8a2f5bd8381ffff': 1.5,
  '8a2f5bd82917fff': 1,
  '8a2f5bd816a7fff': 2.11,
  '8a2f5bd81317fff': 1,
  '8a2f5bd81297fff': 1.42,
  '8a2f5bd824f7fff': 1.67,
  '8a2f5bd82797fff': 3.19,
  '8a2f5bdab7affff': 1,
  '8a2f5bdab787fff': 1.13,
  '8a2f5bdab797fff': 1.17,
  '8a2f5bd8cb77fff': 1.8,
  '8a2f5bd8cb0ffff': 1.55,
  '8a2f5bd8cb1ffff': 1.5,
  '8a2f5bd8cbaffff': 2.41,
  '8a2f5bd88517fff': 1,
  '8a2f5bd98a17fff': 1.5,
  '8a2f5bd8078ffff': 1.82,
  '8a2f5bd80617fff': 1.27,
  '8a2f5bda2227fff': 1,
  '8a2f5bd85227fff': 1.86,
  '8a2f5bd85207fff': 1.44,
  '8a2f5bd8521ffff': 1,
  '8a2f5bd83967fff': 1,
  '8a2f5bd81597fff': 1,
  '8a2f5bd8300ffff': 1,
  '8a2f5bd838dffff': 1.73,
  '8a2f5bd9031ffff': 1.14,
  '8a2f5bdab727fff': 1.06,
  '8a2f5bdb180ffff': 1.59,
  '8a2f5bdb1857fff': 1.76,
  '8a2f5bdb18e7fff': 1,
  '8a2f5bd947affff': 1.45,
  '8a2f5bd94637fff': 1.76,
  '8a2f5bd9471ffff': 1,
  '8a2f5bda2c17fff': 1,
  '8a2f5bdb52d7fff': 1.8,
  '8a2f5bd90817fff': 1.95,
  '8a2f5bd909a7fff': 1,
  '8a2f5bd9098ffff': 1.62,
  '8a2f5bd81807fff': 1.3,
  '8a2f5bdaa577fff': 1.7,
  '8a2f5bd85d37fff': 1,
  '8a2f5bd85d07fff': 1.29,
  '8a2f5bd83d27fff': 1,
  '8a2f5bda2537fff': 3.4,
  '8a2f5bda35a7fff': 1.22,
  '8a2f5bd812a7fff': 1.3,
  '8a2f5bdb5237fff': 1.84,
  '8a2f5bd83147fff': 3,
  '8a2f5bd846e7fff': 1.6,
  '8a2f5bd8ecb7fff': 1.5,
  '8a2f5bd846c7fff': 1.33,
  '8a2f5bd8c4dffff': 1.84,
  '8a2f5bd8c7a7fff': 1.33,
  '8a2f5bd8c70ffff': 1.14,
  '8a2f5bd8c40ffff': 2.36,
  '8a2f5bd8c627fff': 1,
  '8a2f5bd88d47fff': 1.17,
  '8a2f5bd8c48ffff': 1.57,
  '8a2f5bd8e847fff': 1,
  '8a2f5bd8c717fff': 1,
  '8a2f5bd8c60ffff': 1,
  '8a2f5bd8c657fff': 1,
  '8a2f5bd86cf7fff': 1.23,
  '8a2f5bd8c197fff': 1,
  '8a2f5bda2c07fff': 1.12,
  '8a2f5bda2ce7fff': 1.92,
  '8a2f5bda2c1ffff': 1.61,
  '8a2f5bd91a97fff': 1.24,
  '8a2f5bd85777fff': 3.05,
  '8a2f5bdb189ffff': 1,
  '8a2f5bd956e7fff': 3.45,
  '8a2f5bd95647fff': 5.17,
  '8a2f5bdab637fff': 1.7,
  '8a2f5bd9c4f7fff': 1.17,
  '8a2f5bd91d4ffff': 1.17,
  '8a2f5bdb10b7fff': 2.53,
  '8a2f5bdb1127fff': 1.18,
  '8a2f5bdb1107fff': 1.7,
  '8a2f5bd82ae7fff': 1.85,
  '8a2f5bd9522ffff': 2.45,
  '8a2f5bd82ac7fff': 1.18,
  '8a2f5bd82af7fff': 1,
  '8a2f5bdb5377fff': 1.76,
  '8a2f5bdb5357fff': 1.44,
  '8a2f5bda2daffff': 1.17,
  '8a2f5bdb531ffff': 1.6,
  '8a2f5bd8eb37fff': 1.38,
  '8a2f5bd9156ffff': 1.06,
  '8a2f5bdb3127fff': 1.77,
  '8a2f5bdb389ffff': 1.29,
  '8a2f5bd9d7affff': 1,
  '8a2f5bd9dc4ffff': 1,
  '8a2f5bd82acffff': 1.5,
  '8a2f5bd8c847fff': 1.27,
  '8a2f5bd90b37fff': 2,
  '8a2f5bdb14a7fff': 1.64,
  '8a2f5bd82d07fff': 1.79,
  '8a2f5bd82d27fff': 1.71,
  '8a2f5bd83d17fff': 1.61,
  '8a2f5bd83d07fff': 1.55,
  '8a2f5bd83d37fff': 1.45,
  '8a2f5bd8c8affff': 1.93,
  '8a2f5bd8c90ffff': 1,
  '8a2f5bdaab9ffff': 1,
  '8a2f5bdab737fff': 1.33,
  '8a2f5bdaa16ffff': 1.25,
  '8a2f5bdab6affff': 1.65,
  '8a2f5bdaaab7fff': 1.11,
  '8a2f5bdabcaffff': 1.45,
  '8a2f5bdab687fff': 1.22,
  '8a2f5bd8585ffff': 1,
  '8a2f5bd9c607fff': 1.38,
  '8a2f5bd94757fff': 1.87,
  '8a2f5bd95877fff': 1.1,
  '8a2f5bd95d17fff': 1.46,
  '8a2f5bdb110ffff': 2.57,
  '8a2f5bdb1727fff': 1.9,
  '8a2f5bd815a7fff': 1.63,
  '8a2f5bd95677fff': 2.75,
  '8a2f5bd81587fff': 1,
  '8a2f5bd815b7fff': 1.5,
  '8a2f5bd91107fff': 2,
  '8a2f5bd91217fff': 1.33,
  '8a2f5bd98d4ffff': 1.21,
  '8a2f5bd91d77fff': 1.45,
  '8a2f5bd9102ffff': 1.25,
  '8a2f5bd9c68ffff': 1.5,
  '8a2f5bd91d47fff': 1.94,
  '8a2f5bd913a7fff': 1,
  '8a2f5bdaa14ffff': 1,
  '8a2f5bdaad1ffff': 1,
  '8a2f5bd85ad7fff': 1,
  '8a2f5bd85c37fff': 2.45,
  '8a2f5bdaa56ffff': 1,
  '8a2f5bdaa127fff': 2.1,
  '8a2f5bd8530ffff': 1.17,
  '8a2f5bd85307fff': 1,
  '8a2f5bd9092ffff': 1.69,
  '8a2f5bd908d7fff': 1.54,
  '8a2f5bd90127fff': 1,
  '8a2f5bd99b1ffff': 1.2,
  '8a2f5bd9595ffff': 1.59,
  '8a2f5bd9582ffff': 2.91,
  '8a2f5bd82847fff': 1,
  '8a2f5bd82857fff': 1.2,
  '8a2f5bd8285ffff': 1,
  '8a2f5bdb1a6ffff': 2.5,
  '8a2f5bdb1a67fff': 1,
  '8a2f5bda26b7fff': 1,
  '8a2f5bda2697fff': 1,
  '8a2f5bda241ffff': 1,
  '8a2f5bd824e7fff': 2.33,
  '8a2f5bd95367fff': 1.36,
  '8a2f5bd95a0ffff': 2.11,
  '8a2f5bda346ffff': 1,
  '8a2f5bd9c41ffff': 1.14,
  '8a2f5bd9c4affff': 1.17,
  '8a2f5bd9c707fff': 1.92,
  '8a2f5bd82ce7fff': 1,
  '8a2f5bd82ceffff': 1.19,
  '8a2f5bd9534ffff': 1.62,
  '8a2f5bd9536ffff': 2.25,
  '8a2f5bd82c0ffff': 1,
  '8a2f5bd8c5b7fff': 1.53,
  '8a2f5bd85287fff': 1.29,
  '8a2f5bd82137fff': 1.41,
  '8a2f5bd90377fff': 1.33,
  '8a2f5bd8649ffff': 2.74,
  '8a2f5bd86157fff': 1.27,
  '8a2f5bdb112ffff': 1.38,
  '8a2f5bdb18dffff': 1.47,
  '8a2f5bd84517fff': 1,
  '8a2f5bdb125ffff': 1,
  '8a2f5bdb1017fff': 1.13,
  '8a2f5bdb10affff': 1.23,
  '8a2f5bdb3217fff': 1.27,
  '8a2f5bd8481ffff': 4.35,
  '8a2f5bda2667fff': 1,
  '8a2f5bdb384ffff': 1.63,
  '8a2f5bd9586ffff': 2.14,
  '8a2f5bdb3a47fff': 1.24,
  '8a2f5bdb3b5ffff': 1.33,
  '8a2f5bdb1737fff': 1.55,
  '8a2f5bdb1157fff': 2.29,
  '8a2f5bdb155ffff': 1.7,
  '8a2f5bdb142ffff': 1.68,
  '8a2f5bd86c57fff': 1.5,
  '8a2f5bd99c77fff': 1,
  '8a2f5bdab46ffff': 2.67,
  '8a2f5bd8049ffff': 1.67,
  '8a2f5bd9c6effff': 2.43,
  '8a2f5bd99d8ffff': 1,
  '8a2f5bd99c37fff': 1,
  '8a2f5bd98ac7fff': 2.55,
  '8a2f5bd98aa7fff': 1.8,
  '8a2f5bd9c2b7fff': 2.2,
  '8a2f5bd98a87fff': 1.56,
  '8a2f5bd8308ffff': 2.18,
  '8a2f5bd83087fff': 2.06,
  '8a2f5bd9120ffff': 1.13,
  '8a2f5bd824dffff': 1.4,
  '8a2f5bd91b6ffff': 2.11,
  '8a2f5bd91207fff': 1.27,
  '8a2f5bd9111ffff': 1.25,
  '8a2f5bd9105ffff': 1,
  '8a2f5bd954b7fff': 1.46,
  '8a2f5bd90b77fff': 1,
  '8a2f5bd90b17fff': 2.19,
  '8a2f5bd9138ffff': 1,
  '8a2f5bd83207fff': 2.38,
  '8a2f5bd91237fff': 1.3,
  '8a2f5bd956dffff': 1.27,
  '8a2f5bdb34dffff': 1,
  '8a2f5bd91277fff': 1.3,
  '8a2f5bd91077fff': 1.14,
  '8a2f5bd9c517fff': 1.35,
  '8a2f5bd91357fff': 1.25,
  '8a2f5bd91047fff': 1,
  '8a2f5bd802a7fff': 1.13,
  '8a2f5bd91877fff': 1.57,
  '8a2f5bd9004ffff': 1.44,
  '8a2f5bd91027fff': 1.63,
  '8a2f5bd91d67fff': 1.42,
  '8a2f5bdb3ba7fff': 1.73,
  '8a2f5bdb1cdffff': 1.35,
  '8a2f5bdb15affff': 2.59,
  '8a2f5bdb1117fff': 1,
  '8a2f5bdb1517fff': 1.19,
  '8a2f5bdb1c9ffff': 3.24,
  '8a2f5bdb1507fff': 1.08,
  '8a2f5bdb1197fff': 1.2,
  '8a2f5bdb332ffff': 1.83,
  '8a2f5bdb171ffff': 2.43,
  '8a2f5bdb156ffff': 2.37,
  '8a2f5bdb15b7fff': 1.45,
  '8a2f5bdb14affff': 2.46,
  '8a2f5bdb1407fff': 1,
  '8a2f5bdb1557fff': 1.95,
  '8a2f5bdb1cd7fff': 1.38,
  '8a2f5bd86687fff': 1.26,
  '8a2f5bd8669ffff': 2.11,
  '8a2f5bd82d17fff': 2.33,
  '8a2f5bd82c87fff': 1,
  '8a2f5bd91acffff': 2.48,
  '8a2f5bd91b8ffff': 1,
  '8a2f5bd9cc1ffff': 2,
  '8a2f5bd9cc17fff': 1.36,
  '8a2f5bd9cd1ffff': 2.11,
  '8a2f5bd9cd07fff': 2.25,
  '8a2f5bd82697fff': 2,
  '8a2f5bdb300ffff': 1.59,
  '8a2f5bdb3057fff': 1.33,
  '8a2f5bdb30e7fff': 1.27,
  '8a2f5bd82147fff': 1.13,
  '8a2f5bd82b37fff': 1.2,
  '8a2f5bd90277fff': 1.27,
  '8a2f5bd9032ffff': 1.83,
  '8a2f5bd82b87fff': 1,
  '8a2f5bd85c07fff': 2.54,
  '8a2f5bd8591ffff': 1.88,
  '8a2f5bd8518ffff': 1,
  '8a2f5bd85ca7fff': 1,
  '8a2f5bd8430ffff': 1.9,
  '8a2f5bd85c0ffff': 1,
  '8a2f5bd85d9ffff': 1.62,
  '8a2f5bd85537fff': 1.13,
  '8a2f5bd80717fff': 1,
  '8a2f5bd80737fff': 1.62,
  '8a2f5bd9464ffff': 1.25,
  '8a2f5bd849a7fff': 1.29,
  '8a2f5bd812c7fff': 1.33,
  '8a2f5bd8175ffff': 2.58,
  '8a2f5bd9151ffff': 1.8,
  '8a2f5bd8a527fff': 1.3,
  '8a2f5bd85c5ffff': 1,
  '8a2f5bd90c4ffff': 1.67,
  '8a2f5bd90ceffff': 1,
  '8a2f5bd90c5ffff': 1,
  '8a2f5bd90a6ffff': 1,
  '8a2f5bd90ce7fff': 1,
  '8a2f5bd90137fff': 1,
  '8a2f5bd82317fff': 3.33,
  '8a2f5bd90037fff': 2.78,
  '8a2f5bd90047fff': 2.47,
  '8a2f5bd806dffff': 3.05,
  '8a2f5bd83d2ffff': 2.44,
  '8a2f5bd8cca7fff': 1,
  '8a2f5bd8ccaffff': 1.62,
  '8a2f5bdb1d6ffff': 2.54,
  '8a2f5bdb1d4ffff': 1.09,
  '8a2f5bdb1d47fff': 1.38,
  '8a2f5bd9d22ffff': 1.83,
  '8a2f5bd9dae7fff': 1.5,
  '8a2f5bd9cab7fff': 1.22,
  '8a2f5bdb181ffff': 1.6,
  '8a2f5bdb56dffff': 1.48,
  '8a2f5bd811affff': 1.92,
  '8a2f5bd81117fff': 2.5,
  '8a2f5bd81c4ffff': 1.31,
  '8a2f5bd8411ffff': 1.25,
  '8a2f5bdb18effff': 1.56,
  '8a2f5bdb1bb7fff': 1.33,
  '8a2f5bdb185ffff': 1.74,
  '8a2f5bd82757fff': 2.79,
  '8a2f5bd9dc67fff': 1.67,
  '8a2f5bd9c837fff': 2.11,
  '8a2f5bd9cbaffff': 2.31,
  '8a2f5bd85c27fff': 2.11,
  '8a2f5bd85c2ffff': 1.62,
  '8a2f5bd946affff': 1,
  '8a2f5bd9086ffff': 1.2,
  '8a2f5bd9468ffff': 1,
  '8a2f5bd80b0ffff': 1.4,
  '8a2f5bd80a27fff': 1.11,
  '8a2f5bd82527fff': 2.3,
  '8a2f5bd91a17fff': 1.25,
  '8a2f5bd90a97fff': 1.83,
  '8a2f5bd90307fff': 1.24,
  '8a2f5bd90317fff': 1,
  '8a2f5bda3477fff': 1.75,
  '8a2f5bd84bb7fff': 1,
  '8a2f5bd90a17fff': 1.78,
  '8a2f5bda34e7fff': 1.25,
  '8a2f5bda340ffff': 1.14,
  '8a2f5bd942c7fff': 1,
  '8a2f5bd9198ffff': 1.33,
  '8a2f5bd919affff': 1.67,
  '8a2f5bd91907fff': 1.44,
  '8a2f5bd9529ffff': 1.5,
  '8a2f5bd9114ffff': 1,
  '8a2f5bd9115ffff': 1.27,
  '8a2f5bd91067fff': 1,
  '8a2f5bd91b87fff': 1.59,
  '8a2f5bd86587fff': 3.83,
  '8a2f5bd98887fff': 1.56,
  '8a2f5bd8e6c7fff': 2,
  '8a2f5bd8cb6ffff': 1,
  '8a2f5bdab487fff': 1,
  '8a2f5bdab7b7fff': 2,
  '8a2f5bd8250ffff': 2.67,
  '8a2f5bd918a7fff': 1.25,
  '8a2f5bd91987fff': 2.1,
  '8a2f5bd9199ffff': 1.17,
  '8a2f5bd91997fff': 4,
  '8a2f5bd864d7fff': 2.75,
  '8a2f5bd8640ffff': 1.14,
  '8a2f5bdb16dffff': 2.14,
  '8a2f5bd822effff': 2.1,
  '8a2f5bd81747fff': 1,
  '8a2f5bdb312ffff': 1,
  '8a2f5bdb310ffff': 1.67,
  '8a2f5bdb3117fff': 1.2,
  '8a2f5bd86b4ffff': 1.22,
  '8a2f5bd98967fff': 1.31,
  '8a2f5bd9896ffff': 2.53,
  '8a2f5bd95b87fff': 1.11,
  '8a2f5bda2577fff': 1.33,
  '8a2f5bd95247fff': 2.11,
  '8a2f5bd86957fff': 1,
  '8a2f5bd8695ffff': 1.3,
  '8a2f5bd8622ffff': 1.86,
  '8a2f5bdb14dffff': 2.32,
  '8a2f5bd86d67fff': 2.13,
  '8a2f5bdb38affff': 2.43,
  '8a2f5bdb38e7fff': 1,
  '8a2f5bda239ffff': 1.33,
  '8a2f5bd9c017fff': 2,
  '8a2f5bd950cffff': 2.25,
  '8a2f5bd824a7fff': 3.75,
  '8a2f5bd82447fff': 1.43,
  '8a2f5bd82a87fff': 1.91,
  '8a2f5bd95917fff': 1,
  '8a2f5bdb30cffff': 1.35,
  '8a2f5bdb33b7fff': 1.23,
  '8a2f5bdb1487fff': 1.65,
  '8a2f5bdb5aeffff': 1.81,
  '8a2f5bd95ce7fff': 1.17,
  '8a2f5bdb3b2ffff': 1.11,
  '8a2f5bdb14d7fff': 4.26,
  '8a2f5bd95c57fff': 1,
  '8a2f5bd86c97fff': 1,
  '8a2f5bd9592ffff': 1.75,
  '8a2f5bd95c0ffff': 1.33,
  '8a2f5bdb3267fff': 1,
  '8a2f5bdb14f7fff': 4.96,
  '8a2f5bda27b7fff': 2.18,
  '8a2f5bdb320ffff': 1.36,
  '8a2f5bdb335ffff': 1,
  '8a2f5bdb141ffff': 1.38,
  '8a2f5bd95c47fff': 1.25,
  '8a2f5bd9599ffff': 1.33,
  '8a2f5bd95c77fff': 1,
  '8a2f5bdb32effff': 1.52,
  '8a2f5bdb158ffff': 2.07,
  '8a2f5bdb1807fff': 1.08,
  '8a2f5bdb1c67fff': 1,
  '8a2f5bdb1867fff': 1,
  '8a2f5bd86daffff': 2,
  '8a2f5bdb3257fff': 2.41,
  '8a2f5bdb1ae7fff': 1.76,
  '8a2f5bda2557fff': 1.29,
  '8a2f5bda2547fff': 1.24,
  '8a2f5bdb18a7fff': 1.4,
  '8a2f5bdb1a37fff': 1.35,
  '8a2f5bdb1a1ffff': 1,
  '8a2f5bdb1ac7fff': 1.09,
  '8a2f5bdb1aeffff': 1.88,
  '8a2f5bdb1ad7fff': 1.4,
  '8a2f5bdb1a5ffff': 1.5,
  '8a2f5bdb1baffff': 1.5,
  '8a2f5bdb132ffff': 1.25,
  '8a2f5bd9cce7fff': 1.11,
  '8a2f5bd868effff': 1,
  '8a2f5bd86c67fff': 1.4,
  '8a2f5bd8182ffff': 2.13,
  '8a2f5bda36e7fff': 1,
  '8a2f5bd851affff': 1.38,
  '8a2f5bd8516ffff': 1.45,
  '8a2f5bd85147fff': 1.67,
  '8a2f5bd80a37fff': 1.43,
  '8a2f5bd94337fff': 1.11,
  '8a2f5bd9dd9ffff': 1.12,
  '8a2f5bd9ca47fff': 1.29,
  '8a2f5bd9dccffff': 1.55,
  '8a2f5bd9dd8ffff': 1.08,
  '8a2f5bd91aa7fff': 1,
  '8a2f5bd9c627fff': 1.63,
  '8a2f5bd9c467fff': 2.43,
  '8a2f5bd9895ffff': 1.33,
  '8a2f5bd98867fff': 1.15,
  '8a2f5bda205ffff': 1,
  '8a2f5bda23a7fff': 1.55,
  '8a2f5bda204ffff': 1.33,
  '8a2f5bd86917fff': 1.57,
  '8a2f5bd8486ffff': 1,
  '8a2f5bd95087fff': 1.14,
  '8a2f5bd94677fff': 2.04,
  '8a2f5bd9475ffff': 1.47,
  '8a2f5bd901affff': 1.47,
  '8a2f5bd9cc87fff': 2.88,
  '8a2f5bd9cc97fff': 1.79,
  '8a2f5bd8c6cffff': 1.27,
  '8a2f5bd8cce7fff': 2.69,
  '8a2f5bd8ad8ffff': 1.4,
  '8a2f5bd98aeffff': 1.7,
  '8a2f5bdaa21ffff': 1.31,
  '8a2f5bd91b07fff': 1.45,
  '8a2f5bd80cdffff': 1.08,
  '8a2f5bd80cd7fff': 1,
  '8a2f5bd80cc7fff': 1.22,
  '8a2f5bd84397fff': 1.38,
  '8a2f5bd99127fff': 1.83,
  '8a2f5bd99137fff': 2.81,
  '8a2f5bd99d17fff': 1.27,
  '8a2f5bd98a4ffff': 2.22,
  '8a2f5bd80667fff': 1.3,
  '8a2f5bd8075ffff': 1,
  '8a2f5bd9c1a7fff': 1,
  '8a2f5bdb3857fff': 1.75,
  '8a2f5bdb385ffff': 1.45,
  '8a2f5bdaa497fff': 3.08,
  '8a2f5bdaa327fff': 1.29,
  '8a2f5bd86717fff': 1.46,
  '8a2f5bd86737fff': 1.69,
  '8a2f5bd95b67fff': 2,
  '8a2f5bd84717fff': 1.57,
  '8a2f5bd95227fff': 1.29,
  '8a2f5bd8644ffff': 1.11,
  '8a2f5bd8c947fff': 1,
  '8a2f5bd94aaffff': 1.33,
  '8a2f5bd910d7fff': 1,
  '8a2f5bd8052ffff': 1.47,
  '8a2f5bd8050ffff': 1,
  '8a2f5bda36c7fff': 2.17,
  '8a2f5bdb318ffff': 1.35,
  '8a2f5bd859b7fff': 1.36,
  '8a2f5bd95ac7fff': 2.63,
  '8a2f5bd95acffff': 3.33,
  '8a2f5bd95adffff': 3.62,
  '8a2f5bd9438ffff': 1.3,
  '8a2f5bd940cffff': 1.75,
  '8a2f5bd90827fff': 1.73,
  '8a2f5bd95a57fff': 2.17,
  '8a2f5bd95aeffff': 3,
  '8a2f5bd98127fff': 1.25,
  '8a2f5bd95baffff': 1.74,
  '8a2f5bd9584ffff': 1.76,
  '8a2f5bd82b07fff': 1.2,
  '8a2f5bd8c62ffff': 1,
  '8a2f5bd8c75ffff': 1,
  '8a2f5bd84c27fff': 1.47,
  '8a2f5bd9c727fff': 1.17,
  '8a2f5bd9511ffff': 1.67,
  '8a2f5bdabce7fff': 1,
  '8a2f5bdabcc7fff': 1,
  '8a2f5bdab567fff': 1,
  '8a2f5bdaa26ffff': 1,
  '8a2f5bdaa34ffff': 1.18,
  '8a2f5bdaa8d7fff': 1,
  '8a2f5bd90d57fff': 1,
  '8a2f5bd8306ffff': 1.19,
  '8a2f5bd940effff': 1.65,
  '8a2f5bd9405ffff': 1.6,
  '8a2f5bd942effff': 1,
  '8a2f5bdb366ffff': 1.87,
  '8a2f5bd83957fff': 3.9,
  '8a2f5bd8395ffff': 2.18,
  '8a2f5bd814d7fff': 1.38,
  '8a2f5bd81797fff': 2.78,
  '8a2f5bd8179ffff': 1.3,
  '8a2f5bd90ba7fff': 1.89,
  '8a2f5bd8314ffff': 3.31,
  '8a2f5bd91507fff': 1.46,
  '8a2f5bd81c67fff': 2.13,
  '8a2f5bd84d2ffff': 1,
  '8a2f5bdb3aeffff': 1.05,
  '8a2f5bdb3a57fff': 1,
  '8a2f5bd95c8ffff': 1,
  '8a2f5bd90a87fff': 3.93,
  '8a2f5bd82547fff': 3.29,
  '8a2f5bd8cb2ffff': 1,
  '8a2f5bd8cb67fff': 1.7,
  '8a2f5bdab71ffff': 1.56,
  '8a2f5bd82357fff': 1.2,
  '8a2f5bd8331ffff': 2.42,
  '8a2f5bdb31a7fff': 1.33,
  '8a2f5bd8cac7fff': 1.19,
  '8a2f5bd8c65ffff': 1.64,
  '8a2f5bd8138ffff': 1.4,
  '8a2f5bd8c127fff': 2.31,
  '8a2f5bd810a7fff': 1,
  '8a2f5bd88c0ffff': 1,
  '8a2f5bd8c237fff': 1.33,
  '8a2f5bd908a7fff': 1.27,
  '8a2f5bd86247fff': 2.86,
  '8a2f5bd98997fff': 1.37,
  '8a2f5bd8ac4ffff': 1,
  '8a2f5bd8ac5ffff': 1.14,
  '8a2f5bd8359ffff': 1.92,
  '8a2f5bd9c977fff': 1,
  '8a2f5bd9166ffff': 1,
  '8a2f5bd91667fff': 1.33,
  '8a2f5bda3657fff': 1.75,
  '8a2f5bda36effff': 1.78,
  '8a2f5bd80d4ffff': 1.29,
  '8a2f5bd80d6ffff': 1,
  '8a2f5bd9ca2ffff': 1.61,
  '8a2f5bd9dd77fff': 1.69,
  '8a2f5bd800b7fff': 1.68,
  '8a2f5bd8019ffff': 1.29,
  '8a2f5bd8018ffff': 1,
  '8a2f5bd80037fff': 1.93,
  '8a2f5bd8011ffff': 1,
  '8a2f5bd8010ffff': 1.36,
  '8a2f5bd8012ffff': 1.58,
  '8a2f5bd808affff': 2.48,
  '8a2f5bd9886ffff': 1.18,
  '8a2f5bd9d497fff': 2.13,
  '8a2f5bd91c97fff': 2.82,
  '8a2f5bd98d67fff': 1,
  '8a2f5bd8689ffff': 1,
  '8a2f5bd94607fff': 1.14,
  '8a2f5bd94627fff': 1.13,
  '8a2f5bd90c07fff': 1.14,
  '8a2f5bd82c4ffff': 1,
  '8a2f5bd82087fff': 1.33,
  '8a2f5bd82097fff': 1.25,
  '8a2f5bd82c5ffff': 1,
  '8a2f5bd91727fff': 1,
  '8a2f5bd91707fff': 1.25,
  '8a2f5bd8c437fff': 1.48,
  '8a2f5bd9d36ffff': 2,
  '8a2f5bd9dc77fff': 1.77,
  '8a2f5bd859affff': 1.33,
  '8a2f5bd942d7fff': 1.54,
  '8a2f5bd85977fff': 1.25,
  '8a2f5bdb12c7fff': 1.1,
  '8a2f5bdb144ffff': 1.43,
  '8a2f5bd86087fff': 1.45,
  '8a2f5bdb166ffff': 1.17,
  '8a2f5bdb162ffff': 1.75,
  '8a2f5bdb12e7fff': 2.05,
  '8a2f5bdb129ffff': 1.47,
  '8a2f5bd86927fff': 1.24,
  '8a2f5bdb1257fff': 1.48,
  '8a2f5bd84db7fff': 1.32,
  '8a2f5bdb136ffff': 1.42,
  '8a2f5bdb12effff': 1.5,
  '8a2f5bdb1577fff': 1.33,
  '8a2f5bdb17a7fff': 1.5,
  '8a2f5bd84427fff': 1,
  '8a2f5bd84737fff': 1.45,
  '8a2f5bd8619ffff': 1.29,
  '8a2f5bd860b7fff': 1.79,
  '8a2f5bd84887fff': 1.45,
  '8a2f5bdb12f7fff': 1,
  '8a2f5bdb104ffff': 1,
  '8a2f5bdb121ffff': 1,
  '8a2f5bd9891ffff': 1,
  '8a2f5bdb145ffff': 1.36,
  '8a2f5bd91427fff': 1.17,
  '8a2f5bd91d9ffff': 1.71,
  '8a2f5bd91d87fff': 2,
  '8a2f5bd8c98ffff': 1.17,
  '8a2f5bd8ccdffff': 1.53,
  '8a2f5bd8c537fff': 1,
  '8a2f5bd812effff': 2.13,
  '8a2f5bd812d7fff': 1.29,
  '8a2f5bd8e92ffff': 1.13,
  '8a2f5bd8e907fff': 1.63,
  '8a2f5bd8e917fff': 1.7,
  '8a2f5bd83247fff': 1.14,
  '8a2f5bd83257fff': 1.36,
  '8a2f5bd832f7fff': 1.31,
  '8a2f5bd8572ffff': 1.37,
  '8a2f5bd84d8ffff': 1.89,
  '8a2f5bd84c37fff': 1.75,
  '8a2f5bdb188ffff': 2.56,
  '8a2f5bdb18affff': 1.27,
  '8a2f5bdb18f7fff': 2.71,
  '8a2f5bdb18c7fff': 1.69,
  '8a2f5bd84daffff': 1,
  '8a2f5bd86007fff': 1.41,
  '8a2f5bd8601ffff': 1,
  '8a2f5bd82ca7fff': 1.5,
  '8a2f5bd86377fff': 1.5,
  '8a2f5bd8671ffff': 1.85,
  '8a2f5bd8614ffff': 1.13,
  '8a2f5bd86117fff': 1.14,
  '8a2f5bdaac0ffff': 2.29,
  '8a2f5bd86ce7fff': 1.82,
  '8a2f5bd9daf7fff': 1,
  '8a2f5bdb3b57fff': 2.09,
  '8a2f5bd95927fff': 1.08,
  '8a2f5bdb365ffff': 1.29,
  '8a2f5bdb329ffff': 1.42,
  '8a2f5bdb328ffff': 1,
  '8a2f5bd9568ffff': 1.33,
  '8a2f5bd8659ffff': 3.48,
  '8a2f5bdb32f7fff': 1.39,
  '8a2f5bd956d7fff': 1.42,
  '8a2f5bd91cd7fff': 1,
  '8a2f5bdb3227fff': 1,
  '8a2f5bd95d2ffff': 1.1,
  '8a2f5bd95c2ffff': 1,
  '8a2f5bdb36cffff': 1.64,
  '8a2f5bd8388ffff': 3.13,
  '8a2f5bd838d7fff': 3.27,
  '8a2f5bd86357fff': 1.23,
  '8a2f5bdaa2dffff': 1.71,
  '8a2f5bd84987fff': 1.17,
  '8a2f5bdb1397fff': 1.14,
  '8a2f5bdb1caffff': 2,
  '8a2f5bd8662ffff': 2.16,
  '8a2f5bd8a827fff': 1.1,
  '8a2f5bd9184ffff': 1.83,
  '8a2f5bdaa59ffff': 2.5,
  '8a2f5bd81887fff': 1.4,
  '8a2f5bd8ea0ffff': 2.9,
  '8a2f5bd8e617fff': 1.23,
  '8a2f5bd9991ffff': 1.22,
  '8a2f5bd814affff': 1.06,
  '8a2f5bd8c107fff': 2.94,
  '8a2f5bd8164ffff': 1.25,
  '8a2f5bd8c49ffff': 1.31,
  '8a2f5bd853b7fff': 1.18,
  '8a2f5bd8505ffff': 1,
  '8a2f5bd86797fff': 1.35,
  '8a2f5bd9c84ffff': 1.45,
  '8a2f5bd830cffff': 1.56,
  '8a2f5bd833b7fff': 1.5,
  '8a2f5bd9c847fff': 1,
  '8a2f5bd9011ffff': 1.67,
  '8a2f5bdb30dffff': 2.25,
  '8a2f5bdb30c7fff': 1.5,
  '8a2f5bdb30effff': 1.56,
  '8a2f5bd8a1affff': 1,
  '8a2f5bd8e697fff': 1.4,
  '8a2f5bd80d37fff': 2.33,
  '8a2f5bd801a7fff': 1,
  '8a2f5bd846f7fff': 1.31,
  '8a2f5bd86a0ffff': 1.6,
  '8a2f5bd8461ffff': 1.42,
  '8a2f5bd86d97fff': 1.85,
  '8a2f5bd90c1ffff': 1,
  '8a2f5bd946d7fff': 1.22,
  '8a2f5bdaa75ffff': 1,
  '8a2f5bd85a77fff': 1.65,
  '8a2f5bdaa0c7fff': 1.29,
  '8a2f5bdaa08ffff': 1.5,
  '8a2f5bd81c8ffff': 1.05,
  '8a2f5bd81cf7fff': 1.04,
  '8a2f5bd81c1ffff': 1.4,
  '8a2f5bd81cd7fff': 1,
  '8a2f5bd81ce7fff': 1.05,
  '8a2f5bd81caffff': 1.45,
  '8a2f5bd8181ffff': 1,
  '8a2f5bd81107fff': 1.06,
  '8a2f5bd811a7fff': 1,
  '8a2f5bd85167fff': 1,
  '8a2f5bd81617fff': 2.2,
  '8a2f5bd8154ffff': 1.25,
  '8a2f5bd81cdffff': 1.83,
  '8a2f5bd810b7fff': 1,
  '8a2f5bd8114ffff': 1.22,
  '8a2f5bd81d07fff': 1.29,
  '8a2f5bd8112ffff': 1.13,
  '8a2f5bd8030ffff': 1,
  '8a2f5bd80227fff': 1.29,
  '8a2f5bd81cb7fff': 1,
  '8a2f5bd81ca7fff': 1,
  '8a2f5bd819b7fff': 1,
  '8a2f5bd81987fff': 1,
  '8a2f5bd81637fff': 1.13,
  '8a2f5bd81d97fff': 1,
  '8a2f5bd81d37fff': 2.15,
  '8a2f5bd91257fff': 1,
  '8a2f5bd846b7fff': 1.55,
  '8a2f5bd854f7fff': 1.33,
  '8a2f5bd82a57fff': 1.3,
  '8a2f5bd84367fff': 1.75,
  '8a2f5bda2457fff': 1,
  '8a2f5bd99117fff': 1.44,
  '8a2f5bd991affff': 1.27,
  '8a2f5bda2caffff': 1.33,
  '8a2f5bdab4c7fff': 1.13,
  '8a2f5bdab4f7fff': 1.21,
  '8a2f5bd9c0d7fff': 1.29,
  '8a2f5bd9c6d7fff': 1.38,
  '8a2f5bd8125ffff': 1.4,
  '8a2f5bdaa2d7fff': 2,
  '8a2f5bd95667fff': 2.58,
  '8a2f5bdb36c7fff': 1.25,
  '8a2f5bdb36e7fff': 1.5,
  '8a2f5bdb308ffff': 1.96,
  '8a2f5bd9574ffff': 1,
  '8a2f5bd950c7fff': 1.2,
  '8a2f5bd95207fff': 1.86,
  '8a2f5bd95237fff': 1.5,
  '8a2f5bd95297fff': 1.33,
  '8a2f5bd95397fff': 2.12,
  '8a2f5bd950effff': 2.33,
  '8a2f5bd950b7fff': 1.06,
  '8a2f5bd95117fff': 1.29,
  '8a2f5bd95d77fff': 1.36,
  '8a2f5bd9519ffff': 1,
  '8a2f5bd81c2ffff': 2.14,
  '8a2f5bdb5257fff': 2.33,
  '8a2f5bd8e407fff': 1.65,
  '8a2f5bd8e42ffff': 1.88,
  '8a2f5bd8e40ffff': 1.56,
  '8a2f5bd82327fff': 2.46,
  '8a2f5bd827b7fff': 1.75,
  '8a2f5bd9cc07fff': 1.29,
  '8a2f5bd812b7fff': 1.25,
  '8a2f5bd952c7fff': 2.19,
  '8a2f5bd91ce7fff': 1,
  '8a2f5bd91cf7fff': 1,
  '8a2f5bd90a4ffff': 1,
  '8a2f5bd9026ffff': 1.91,
  '8a2f5bd830d7fff': 1.83,
  '8a2f5bd8a897fff': 1.71,
  '8a2f5bd9db37fff': 1.35,
  '8a2f5bd9dba7fff': 1,
  '8a2f5bdb16effff': 1,
  '8a2f5bdb1c97fff': 1.33,
  '8a2f5bd808d7fff': 2.5,
  '8a2f5bd918affff': 1.11,
  '8a2f5bd9081ffff': 2.57,
  '8a2f5bd857b7fff': 2.22,
  '8a2f5bd857a7fff': 1.5,
  '8a2f5bd8c0affff': 1.76,
  '8a2f5bd82a77fff': 1.83,
  '8a2f5bd82a2ffff': 1.18,
  '8a2f5bdb3307fff': 1.31,
  '8a2f5bdab18ffff': 1,
  '8a2f5bdab187fff': 1,
  '8a2f5bd95387fff': 1.1,
  '8a2f5bd8e8e7fff': 1.47,
  '8a2f5bd90b4ffff': 1,
  '8a2f5bd86c17fff': 1.33,
  '8a2f5bd9c6e7fff': 2.29,
  '8a2f5bdb3b07fff': 2.35,
  '8a2f5bdb3b27fff': 1.72,
  '8a2f5bdb3b77fff': 3.96,
  '8a2f5bdb3b87fff': 1.4,
  '8a2f5bdb3a67fff': 1.95,
  '8a2f5bd862dffff': 1.75,
  '8a2f5bd86a87fff': 2.07,
  '8a2f5bd8c857fff': 3.63,
  '8a2f5bd8dda7fff': 1.59,
  '8a2f5bd8c01ffff': 1.38,
  '8a2f5bd8c0f7fff': 1.54,
  '8a2f5bd8c21ffff': 1.59,
  '8a2f5bd8cb17fff': 1.54,
  '8a2f5bd8c81ffff': 2.05,
  '8a2f5bd8c8e7fff': 2,
  '8a2f5bdab5affff': 1,
  '8a2f5bdab597fff': 1.33,
  '8a2f5bdaa28ffff': 1.13,
  '8a2f5bdab42ffff': 1,
  '8a2f5bdab417fff': 1,
  '8a2f5bd8cba7fff': 1.27,
  '8a2f5bd8c85ffff': 1.25,
  '8a2f5bd8ca2ffff': 1.3,
  '8a2f5bdab49ffff': 1,
  '8a2f5bdab59ffff': 1,
  '8a2f5bdab697fff': 1.38,
  '8a2f5bdab457fff': 1,
  '8a2f5bdab4b7fff': 1,
  '8a2f5bdab5b7fff': 1.2,
  '8a2f5bdab5a7fff': 1,
  '8a2f5bd8cb9ffff': 2.3,
  '8a2f5bd8ca17fff': 2.2,
  '8a2f5bd8c077fff': 1.3,
  '8a2f5bd8cb4ffff': 1.25,
  '8a2f5bdab467fff': 1.36,
  '8a2f5bdab4effff': 1,
  '8a2f5bd8c837fff': 1,
  '8a2f5bd8ca37fff': 1,
  '8a2f5bdaa667fff': 2.05,
  '8a2f5bd8c8d7fff': 1,
  '8a2f5bd8ca77fff': 1.44,
  '8a2f5bdab4e7fff': 1.44,
  '8a2f5bdab58ffff': 1,
  '8a2f5bdab497fff': 1,
  '8a2f5bd8cb47fff': 1.43,
  '8a2f5bd8cb27fff': 1,
  '8a2f5bd8cb07fff': 1,
  '8a2f5bd8c84ffff': 1,
  '8a2f5bd8c8dffff': 1,
  '8a2f5bd8c8cffff': 1.58,
  '8a2f5bdab4d7fff': 1,
  '8a2f5bdab427fff': 2.33,
  '8a2f5bd99a17fff': 1.47,
  '8a2f5bd9985ffff': 1.17,
  '8a2f5bd99c4ffff': 1,
  '8a2f5bd99db7fff': 2.33,
  '8a2f5bd99d87fff': 1.36,
  '8a2f5bd99c47fff': 1.14,
  '8a2f5bd8a4c7fff': 1.25,
  '8a2f5bd9984ffff': 1.11,
  '8a2f5bd9915ffff': 1,
  '8a2f5bd99927fff': 1.37,
  '8a2f5bd82b57fff': 1.5,
  '8a2f5bd82b5ffff': 3.18,
  '8a2f5bd8e007fff': 1.07,
  '8a2f5bd8e02ffff': 2.36,
  '8a2f5bd8e0f7fff': 1,
  '8a2f5bd8e1a7fff': 2.16,
  '8a2f5bd86cc7fff': 2.58,
  '8a2f5bd86cd7fff': 1.83,
  '8a2f5bd94ad7fff': 1.21,
  '8a2f5bd908e7fff': 1.42,
  '8a2f5bd98ae7fff': 2.06,
  '8a2f5bd98a0ffff': 1.17,
  '8a2f5bdb322ffff': 1.88,
  '8a2f5bd9d4b7fff': 2.1,
  '8a2f5bd912e7fff': 1.25,
  '8a2f5bd912effff': 1,
  '8a2f5bd9c5b7fff': 1.86,
  '8a2f5bd9c597fff': 1.33,
  '8a2f5bd9c587fff': 1.38,
  '8a2f5bd9c59ffff': 1.17,
  '8a2f5bd9c4a7fff': 1,
  '8a2f5bd9c797fff': 1,
  '8a2f5bd9c79ffff': 1.25,
  '8a2f5bd9c6a7fff': 1.67,
  '8a2f5bd9c687fff': 1,
  '8a2f5bdb146ffff': 1,
  '8a2f5bd90357fff': 1.57,
  '8a2f5bd90b47fff': 1,
  '8a2f5bd903affff': 1,
  '8a2f5bd84cb7fff': 1.67,
  '8a2f5bd84167fff': 1.73,
  '8a2f5bd80b77fff': 2.18,
  '8a2f5bdb1817fff': 1.3,
  '8a2f5bd86d07fff': 1.2,
  '8a2f5bd9c99ffff': 1,
  '8a2f5bdb15a7fff': 1.7,
  '8a2f5bd901a7fff': 2,
  '8a2f5bd9010ffff': 1.5,
  '8a2f5bd9012ffff': 1.58,
  '8a2f5bd908affff': 1.35,
  '8a2f5bd80b2ffff': 1,
  '8a2f5bd81b9ffff': 1.46,
  '8a2f5bd84247fff': 1.33,
  '8a2f5bd8e747fff': 1.7,
  '8a2f5bd8e74ffff': 1.3,
  '8a2f5bd8e527fff': 1.65,
  '8a2f5bd8e52ffff': 1.55,
  '8a2f5bd83027fff': 1.91,
  '8a2f5bd8ad47fff': 1.17,
  '8a2f5bd82877fff': 1.53,
  '8a2f5bd82977fff': 1.58,
  '8a2f5bd84317fff': 1.42,
  '8a2f5bdb024ffff': 1.38,
  '8a2f5bdb025ffff': 1,
  '8a2f5bdaa4b7fff': 2.63,
  '8a2f5bd88d67fff': 1.5,
  '8a2f5bd810f7fff': 2.67,
  '8a2f5bd88c8ffff': 1.59,
  '8a2f5bda2ceffff': 3.75,
  '8a2f5bd998cffff': 1.2,
  '8a2f5bd99bb7fff': 1.14,
  '8a2f5bd99b97fff': 1,
  '8a2f5bd9910ffff': 1.44,
  '8a2f5bd998dffff': 1,
  '8a2f5bd98aaffff': 1.76,
  '8a2f5bd83c57fff': 2.04,
  '8a2f5bd83c5ffff': 1.55,
  '8a2f5bd83ce7fff': 1.27,
  '8a2f5bd83c1ffff': 1.64,
  '8a2f5bd8318ffff': 1,
  '8a2f5bd830b7fff': 1.6,
  '8a2f5bd8ec17fff': 1,
  '8a2f5bd83547fff': 1,
  '8a2f5bd83ceffff': 1.38,
  '8a2f5bd83c67fff': 1.9,
  '8a2f5bd8224ffff': 1,
  '8a2f5bd8226ffff': 1.36,
  '8a2f5bd9cd27fff': 2.13,
  '8a2f5bd836d7fff': 1.35,
  '8a2f5bd8358ffff': 3,
  '8a2f5bd800e7fff': 1.17,
  '8a2f5bd837affff': 1.13,
  '8a2f5bd83787fff': 1,
  '8a2f5bd8ecaffff': 1.53,
  '8a2f5bd8ec8ffff': 1.67,
  '8a2f5bd90227fff': 1.27,
  '8a2f5bd91097fff': 1,
  '8a2f5bd9154ffff': 1,
  '8a2f5bd91467fff': 1.61,
  '8a2f5bd81867fff': 1.75,
  '8a2f5bd81847fff': 1.45,
  '8a2f5bd81877fff': 1,
  '8a2f5bd8185ffff': 1.1,
  '8a2f5bd818e7fff': 1.5,
  '8a2f5bd81b87fff': 1.42,
  '8a2f5bd81a87fff': 1.75,
  '8a2f5bd8122ffff': 1.58,
  '8a2f5bd8184ffff': 1.27,
  '8a2f5bd81837fff': 1.15,
  '8a2f5bd8190ffff': 2.33,
  '8a2f5bd81b27fff': 1.14,
  '8a2f5bdaa48ffff': 1.62,
  '8a2f5bd8c29ffff': 1.42,
  '8a2f5bd868cffff': 2.45,
  '8a2f5bd84287fff': 1,
  '8a2f5bd842affff': 1.1,
  '8a2f5bd842e7fff': 1.25,
  '8a2f5bd855affff': 1.5,
  '8a2f5bd8178ffff': 1.1,
  '8a2f5bd85b9ffff': 1,
  '8a2f5bd85b8ffff': 1.06,
  '8a2f5bd85a37fff': 1,
  '8a2f5bd85a27fff': 1.2,
  '8a2f5bd85a2ffff': 1.29,
  '8a2f5bd85b57fff': 2.5,
  '8a2f5bdb3867fff': 1.67,
  '8a2f5bdb394ffff': 1.33,
  '8a2f5bd8e7affff': 1.84,
  '8a2f5bd8330ffff': 1.38,
  '8a2f5bd83237fff': 2.88,
  '8a2f5bd8320ffff': 1.64,
  '8a2f5bd8e437fff': 1.53,
  '8a2f5bd8e7a7fff': 1.92,
  '8a2f5bd8e0a7fff': 1,
  '8a2f5bd84c87fff': 1,
  '8a2f5bd82247fff': 1.39,
  '8a2f5bd80247fff': 1.65,
  '8a2f5bd8609ffff': 1.3,
  '8a2f5bd86d37fff': 1.21,
  '8a2f5bd95a5ffff': 1.36,
  '8a2f5bd82db7fff': 1.69,
  '8a2f5bd864a7fff': 1.33,
  '8a2f5bd95a77fff': 1.73,
  '8a2f5bd86d2ffff': 1.43,
  '8a2f5bd86d27fff': 1.61,
  '8a2f5bdb169ffff': 1.91,
  '8a2f5bda2427fff': 1.5,
  '8a2f5bd9556ffff': 1.95,
  '8a2f5bd95567fff': 1.5,
  '8a2f5bd8ace7fff': 1,
  '8a2f5bd82637fff': 1.73,
  '8a2f5bd82787fff': 2.25,
  '8a2f5bd8a11ffff': 1,
  '8a2f5bd82677fff': 1.79,
  '8a2f5bd8278ffff': 1.25,
  '8a2f5bd8c167fff': 1.5,
  '8a2f5bd8524ffff': 1.31,
  '8a2f5bd946c7fff': 1.67,
  '8a2f5bd80ccffff': 1.37,
  '8a2f5bd80ceffff': 1.14,
  '8a2f5bd91367fff': 1.5,
  '8a2f5bd91adffff': 1,
  '8a2f5bdb3247fff': 1.42,
  '8a2f5bd95267fff': 1.94,
  '8a2f5bdb378ffff': 1.13,
  '8a2f5bdb37affff': 1.71,
  '8a2f5bdab4a7fff': 1,
  '8a2f5bd80797fff': 1.14,
  '8a2f5bd804e7fff': 1.69,
  '8a2f5bd9d59ffff': 2.43,
  '8a2f5bd9d4a7fff': 1,
  '8a2f5bd98a1ffff': 2.9,
  '8a2f5bd8a1a7fff': 1.13,
  '8a2f5bd9530ffff': 1.29,
  '8a2f5bda274ffff': 1.71,
  '8a2f5bda2747fff': 3.58,
  '8a2f5bda275ffff': 1.11,
  '8a2f5bda2607fff': 1.92,
  '8a2f5bd9428ffff': 1,
  '8a2f5bd8e507fff': 1,
  '8a2f5bd8e50ffff': 1.07,
  '8a2f5bdb02cffff': 1,
  '8a2f5bdaa2c7fff': 1.93,
  '8a2f5bdaa2cffff': 1.28,
  '8a2f5bd919b7fff': 1.32,
  '8a2f5bd86a6ffff': 1.42,
  '8a2f5bd8469ffff': 1,
  '8a2f5bd8460ffff': 1.2,
  '8a2f5bd84667fff': 1,
  '8a2f5bd84377fff': 1.6,
  '8a2f5bd85daffff': 1.33,
  '8a2f5bda36d7fff': 1.33,
  '8a2f5bda36f7fff': 1,
  '8a2f5bda365ffff': 1,
  '8a2f5bd859a7fff': 1.62,
  '8a2f5bd9d297fff': 1,
  '8a2f5bd8569ffff': 2.28,
  '8a2f5bda2397fff': 1.17,
  '8a2f5bd85bb7fff': 1.2,
  '8a2f5bd85867fff': 1.33,
  '8a2f5bd84867fff': 1.57,
  '8a2f5bda276ffff': 1.33,
  '8a2f5bd84327fff': 1.4,
  '8a2f5bd9c05ffff': 1.62,
  '8a2f5bda20d7fff': 1.11,
  '8a2f5bda2727fff': 1.64,
  '8a2f5bda20dffff': 1.57,
  '8a2f5bda20c7fff': 1.67,
  '8a2f5bda272ffff': 1.14,
  '8a2f5bda208ffff': 1.74,
  '8a2f5bda20cffff': 1.95,
  '8a2f5bd84d87fff': 1,
  '8a2f5bd9d187fff': 1.56,
  '8a2f5bd9dd47fff': 1.85,
  '8a2f5bd8365ffff': 1.08,
  '8a2f5bdb1b5ffff': 1,
  '8a2f5bdb1847fff': 1.79,
  '8a2f5bd9150ffff': 1.11,
  '8a2f5bd830a7fff': 1.2,
  '8a2f5bd83c6ffff': 1.25,
  '8a2f5bd830c7fff': 2.83,
  '8a2f5bd83567fff': 1.5,
  '8a2f5bd8162ffff': 3,
  '8a2f5bd95b1ffff': 1.5,
  '8a2f5bd84c2ffff': 1.14,
  '8a2f5bd864f7fff': 1.07,
  '8a2f5bd83b4ffff': 1.9,
  '8a2f5bd802c7fff': 1.71,
  '8a2f5bd83827fff': 2.75,
  '8a2f5bd8382ffff': 1,
  '8a2f5bd8396ffff': 2.64,
  '8a2f5bd81b57fff': 1.19,
  '8a2f5bdaa487fff': 1.33,
  '8a2f5bdaa4d7fff': 1,
  '8a2f5bd81b5ffff': 1.4,
  '8a2f5bd81b2ffff': 1,
  '8a2f5bdaa4a7fff': 1.06,
  '8a2f5bd81a8ffff': 1.08,
  '8a2f5bdaa4dffff': 1,
  '8a2f5bd81b77fff': 1.25,
  '8a2f5bd81b47fff': 1,
  '8a2f5bd81367fff': 1,
  '8a2f5bd81b6ffff': 1.17,
  '8a2f5bd81a47fff': 1,
  '8a2f5bd8cdaffff': 1.29,
  '8a2f5bd81a37fff': 1,
  '8a2f5bd8426ffff': 1.54,
  '8a2f5bd85c9ffff': 1,
  '8a2f5bd85cd7fff': 1,
  '8a2f5bd85cc7fff': 1,
  '8a2f5bd95a37fff': 1.21,
  '8a2f5bd9088ffff': 1.23,
  '8a2f5bd90007fff': 1.79,
  '8a2f5bd83657fff': 1.17,
  '8a2f5bd83677fff': 1,
  '8a2f5bd8326ffff': 1.43,
  '8a2f5bd8e1b7fff': 1.65,
  '8a2f5bd9c55ffff': 1,
  '8a2f5bd8361ffff': 1.2,
  '8a2f5bd8ec9ffff': 1.25,
  '8a2f5bd8e187fff': 1.3,
  '8a2f5bd8e037fff': 1.54,
  '8a2f5bd8e7b7fff': 2.2,
  '8a2f5bd94a6ffff': 1.82,
  '8a2f5bd95d37fff': 2.14,
  '8a2f5bdb369ffff': 2.22,
  '8a2f5bd9528ffff': 2.89,
  '8a2f5bd94b6ffff': 1,
  '8a2f5bdb37b7fff': 1,
  '8a2f5bdb3657fff': 1.43,
  '8a2f5bd9c08ffff': 1.78,
  '8a2f5bd81387fff': 1.57,
  '8a2f5bd8cc57fff': 1.89,
  '8a2f5bd8615ffff': 1.47,
  '8a2f5bd828f7fff': 1,
  '8a2f5bd9c72ffff': 1.14,
  '8a2f5bd90147fff': 1.53,
  '8a2f5bd9015ffff': 1,
  '8a2f5bd90177fff': 1,
  '8a2f5bd90057fff': 1.33,
  '8a2f5bd90167fff': 1.09,
  '8a2f5bd83357fff': 2.43,
  '8a2f5bd83377fff': 1.1,
  '8a2f5bd83927fff': 1,
  '8a2f5bd80287fff': 1,
  '8a2f5bd8028ffff': 1,
  '8a2f5bd802affff': 1.08,
  '8a2f5bd8390ffff': 1.55,
  '8a2f5bd80297fff': 1.33,
  '8a2f5bd9531ffff': 2.23,
  '8a2f5bd81927fff': 3,
  '8a2f5bd81907fff': 3.24,
  '8a2f5bdaa207fff': 1.38,
  '8a2f5bdaa1affff': 3.08,
  '8a2f5bdaad2ffff': 1.46,
  '8a2f5bd85b07fff': 1,
  '8a2f5bdaacaffff': 1.57,
  '8a2f5bdb1697fff': 1.55,
  '8a2f5bd82a1ffff': 1.25,
  '8a2f5bd83897fff': 1.93,
  '8a2f5bd83d1ffff': 1.15,
  '8a2f5bd82a4ffff': 1,
  '8a2f5bd82a5ffff': 1,
  '8a2f5bd8276ffff': 1,
  '8a2f5bd80607fff': 1.2,
  '8a2f5bd8070ffff': 1.44,
  '8a2f5bd800effff': 1,
  '8a2f5bd800d7fff': 1.43,
  '8a2f5bd8004ffff': 1.17,
  '8a2f5bd80027fff': 1.44,
  '8a2f5bd9cadffff': 1.25,
  '8a2f5bd8069ffff': 1.15,
  '8a2f5bd8059ffff': 1,
  '8a2f5bd82a07fff': 1.5,
  '8a2f5bd82aaffff': 1.25,
  '8a2f5bd83cf7fff': 1.3,
  '8a2f5bd83da7fff': 2.79,
  '8a2f5bd82a8ffff': 1.2,
  '8a2f5bd83d67fff': 1.14,
  '8a2f5bd83867fff': 1.36,
  '8a2f5bd82a0ffff': 1.2,
  '8a2f5bd82a47fff': 1,
  '8a2f5bd806cffff': 1,
  '8a2f5bd83d6ffff': 1,
  '8a2f5bd83d77fff': 1.88,
  '8a2f5bd83947fff': 1.55,
  '8a2f5bd83917fff': 1.58,
  '8a2f5bd82b1ffff': 1.13,
  '8a2f5bd829affff': 1.16,
  '8a2f5bd83557fff': 1,
  '8a2f5bd8344ffff': 1,
  '8a2f5bd8225ffff': 1,
  '8a2f5bd83717fff': 1.11,
  '8a2f5bd82a27fff': 1,
  '8a2f5bd9435ffff': 1.57,
  '8a2f5bd94357fff': 1,
  '8a2f5bd94347fff': 1,
  '8a2f5bda254ffff': 2.55,
  '8a2f5bda256ffff': 1.3,
  '8a2f5bda20b7fff': 1.5,
  '8a2f5bd8488ffff': 1.5,
  '8a2f5bd820f7fff': 2.05,
  '8a2f5bdaaadffff': 1.17,
  '8a2f5bdaa377fff': 1,
  '8a2f5bd81567fff': 1,
  '8a2f5bd83847fff': 1.21,
  '8a2f5bdaa2a7fff': 1,
  '8a2f5bd81547fff': 1.29,
  '8a2f5bd8025ffff': 1.78,
  '8a2f5bd81017fff': 2.45,
  '8a2f5bd85b1ffff': 1,
  '8a2f5bd84037fff': 1,
  '8a2f5bdb1317fff': 1.63,
  '8a2f5bd9c957fff': 1.2,
  '8a2f5bd82d37fff': 1.92,
  '8a2f5bd8152ffff': 1.33,
  '8a2f5bd99ab7fff': 1.76,
  '8a2f5bd8648ffff': 1.47,
  '8a2f5bd95747fff': 1,
  '8a2f5bd9cc27fff': 1.43,
  '8a2f5bd862f7fff': 1.43,
  '8a2f5bd80c6ffff': 1.18,
  '8a2f5bd80c67fff': 1,
  '8a2f5bd8478ffff': 1,
  '8a2f5bd8479ffff': 1,
  '8a2f5bd84797fff': 1.17,
  '8a2f5bd84787fff': 1,
  '8a2f5bd842a7fff': 1.4,
  '8a2f5bd8421ffff': 1.14,
  '8a2f5bd84257fff': 1,
  '8a2f5bd8380ffff': 2.43,
  '8a2f5bd8e5b7fff': 1.91,
  '8a2f5bda2d8ffff': 1.18,
  '8a2f5bda2c37fff': 1,
  '8a2f5bdb1c1ffff': 1,
  '8a2f5bd8e6effff': 1.29,
  '8a2f5bd86167fff': 1.1,
  '8a2f5bd86b97fff': 1.44,
  '8a2f5bd81c57fff': 1.54,
  '8a2f5bd8e767fff': 1.14,
  '8a2f5bd8e0affff': 1,
  '8a2f5bd8e75ffff': 1,
  '8a2f5bd8e76ffff': 1.82,
  '8a2f5bd90067fff': 1,
  '8a2f5bd90117fff': 1.06,
  '8a2f5bd908b7fff': 1,
  '8a2f5bd8c117fff': 1.67,
  '8a2f5bdb1057fff': 1.78,
  '8a2f5bd91327fff': 1,
  '8a2f5bdb3ab7fff': 1.33,
  '8a2f5bd85b5ffff': 3.04,
  '8a2f5bd85a67fff': 1.48,
  '8a2f5bdb3b4ffff': 1.79,
  '8a2f5bdb3a0ffff': 1.37,
  '8a2f5bda2c87fff': 1.69,
  '8a2f5bdb159ffff': 2.83,
  '8a2f5bd86147fff': 1.1,
  '8a2f5bd8616ffff': 1.31,
  '8a2f5bda2097fff': 2,
  '8a2f5bda2197fff': 1.33,
  '8a2f5bd81a17fff': 1.2,
  '8a2f5bd95a97fff': 1.17,
  '8a2f5bd95a9ffff': 1,
  '8a2f5bdb3707fff': 1.4,
  '8a2f5bdb371ffff': 1.14,
  '8a2f5bd8e2b7fff': 1,
  '8a2f5bd8a907fff': 1.33,
  '8a2f5bd8264ffff': 2.13,
  '8a2f5bd9c9a7fff': 1.61,
  '8a2f5bd820c7fff': 1.08,
  '8a2f5bdb1437fff': 1.06,
  '8a2f5bd8eae7fff': 1.3,
  '8a2f5bd8a957fff': 1.71,
  '8a2f5bd81a6ffff': 1,
  '8a2f5bda37affff': 1,
  '8a2f5bda3637fff': 1.5,
  '8a2f5bd85d0ffff': 1.4,
  '8a2f5bd98a67fff': 2.81,
  '8a2f5bd82c6ffff': 1,
  '8a2f5bd8289ffff': 1,
  '8a2f5bd848f7fff': 2.23,
  '8a2f5bd81af7fff': 1.36,
  '8a2f5bd864affff': 1,
  '8a2f5bdb3397fff': 1,
  '8a2f5bdb3767fff': 1.46,
  '8a2f5bdb3287fff': 1.5,
  '8a2f5bd848e7fff': 2.57,
  '8a2f5bd80577fff': 1.17,
  '8a2f5bdb3177fff': 2.17,
  '8a2f5bd82d0ffff': 1.1,
  '8a2f5bd82c77fff': 1,
  '8a2f5bd867affff': 1.64,
  '8a2f5bd82c27fff': 1,
  '8a2f5bd95b5ffff': 1.25,
  '8a2f5bd95b27fff': 1.5,
  '8a2f5bd95a6ffff': 1,
  '8a2f5bd82c9ffff': 1.84,
  '8a2f5bd82d1ffff': 1.29,
  '8a2f5bd81377fff': 1,
  '8a2f5bd8cd17fff': 1.17,
  '8a2f5bd81327fff': 1.17,
  '8a2f5bd8cd97fff': 1,
  '8a2f5bd8cd07fff': 1,
  '8a2f5bdaac8ffff': 1.23,
  '8a2f5bd8cd27fff': 1,
  '8a2f5bd83aeffff': 1.43,
  '8a2f5bd83a57fff': 3.06,
  '8a2f5bd8e4dffff': 1,
  '8a2f5bd8200ffff': 1.33,
  '8a2f5bdaaa8ffff': 1.33,
  '8a2f5bdaac07fff': 1.13,
  '8a2f5bdaac27fff': 1.5,
  '8a2f5bd856f7fff': 1.75,
  '8a2f5bd8416ffff': 1.69,
  '8a2f5bd83b67fff': 1.25,
  '8a2f5bd8391ffff': 1.95,
  '8a2f5bda342ffff': 2.08,
  '8a2f5bd9189ffff': 1,
  '8a2f5bd9c6dffff': 1.8,
  '8a2f5bd98897fff': 1,
  '8a2f5bd99d6ffff': 1.56,
  '8a2f5bd9c2e7fff': 1,
  '8a2f5bd91127fff': 1,
  '8a2f5bd91897fff': 1.33,
  '8a2f5bd91137fff': 1.17,
  '8a2f5bd82b4ffff': 1.67,
  '8a2f5bd864dffff': 1.65,
  '8a2f5bd9c40ffff': 1,
  '8a2f5bd9c2effff': 1.53,
  '8a2f5bd9d597fff': 1.5,
  '8a2f5bdb3297fff': 1.63,
  '8a2f5bd9db07fff': 1,
  '8a2f5bd9db17fff': 1,
  '8a2f5bd9da1ffff': 1.59,
  '8a2f5bd9d12ffff': 1.14,
  '8a2f5bd8366ffff': 1.33,
  '8a2f5bd9daaffff': 1.43,
  '8a2f5bd9db27fff': 1.19,
  '8a2f5bd9d84ffff': 1,
  '8a2f5bd9da17fff': 1.37,
  '8a2f5bd9db0ffff': 1,
  '8a2f5bd83687fff': 1.23,
  '8a2f5bd9c0b7fff': 1.43,
  '8a2f5bd9dbaffff': 1,
  '8a2f5bd9d8c7fff': 1,
  '8a2f5bd9d6d7fff': 1.36,
  '8a2f5bdb5acffff': 2.18,
  '8a2f5bda2cb7fff': 1.25,
  '8a2f5bd8c647fff': 1.36,
  '8a2f5bd8c677fff': 1,
  '8a2f5bd9c14ffff': 1.17,
  '8a2f5bd9caa7fff': 1.14,
  '8a2f5bd9cb5ffff': 1,
  '8a2f5bd9cb4ffff': 1.2,
  '8a2f5bd836b7fff': 1,
  '8a2f5bd836affff': 1.4,
  '8a2f5bd8360ffff': 1.5,
  '8a2f5bd83647fff': 1.44,
  '8a2f5bd98b0ffff': 1.19,
  '8a2f5bd9dc17fff': 1.06,
  '8a2f5bd95b47fff': 1.07,
  '8a2f5bd838cffff': 2.33,
  '8a2f5bd83a87fff': 1.17,
  '8a2f5bd83177fff': 1.4,
  '8a2f5bd88c17fff': 1,
  '8a2f5bd88c77fff': 1,
  '8a2f5bd88d5ffff': 1,
  '8a2f5bd9022ffff': 2.04,
  '8a2f5bd91ccffff': 1,
  '8a2f5bd9020ffff': 1,
  '8a2f5bd9035ffff': 1.33,
  '8a2f5bd90267fff': 1,
  '8a2f5bd95497fff': 1.13,
  '8a2f5bd91177fff': 1,
  '8a2f5bd9030ffff': 1.2,
  '8a2f5bd90257fff': 1,
  '8a2f5bd9d717fff': 1.75,
  '8a2f5bd953b7fff': 1,
  '8a2f5bdb1037fff': 1.29,
  '8a2f5bdb11affff': 1.1,
  '8a2f5bd90b2ffff': 1.33,
  '8a2f5bd8021ffff': 1,
  '8a2f5bd8392ffff': 1,
  '8a2f5bd802effff': 1,
  '8a2f5bd802e7fff': 1.08,
  '8a2f5bd9d277fff': 1.6,
  '8a2f5bd9d6affff': 1,
  '8a2f5bd8a127fff': 1.71,
  '8a2f5bd90d4ffff': 1.06,
  '8a2f5bd98acffff': 1.67,
  '8a2f5bdb532ffff': 1.15,
  '8a2f5bdb12a7fff': 1.79,
  '8a2f5bdb1217fff': 1.87,
  '8a2f5bdb1827fff': 1,
  '8a2f5bdb138ffff': 1.33,
  '8a2f5bdb1937fff': 1,
  '8a2f5bdb1047fff': 1.43,
  '8a2f5bd8594ffff': 1,
  '8a2f5bd838b7fff': 1.28,
  '8a2f5bd83887fff': 1.59,
  '8a2f5bd9cd0ffff': 1.14,
  '8a2f5bd8335ffff': 1.33,
  '8a2f5bdb3ae7fff': 1,
  '8a2f5bdb139ffff': 1.3,
  '8a2f5bd9535ffff': 3.55,
  '8a2f5bd869affff': 1.33,
  '8a2f5bd82507fff': 1.58,
  '8a2f5bdb1387fff': 1.61,
  '8a2f5bdb13a7fff': 1.39,
  '8a2f5bdb115ffff': 1.33,
  '8a2f5bd8692ffff': 1.2,
  '8a2f5bd86907fff': 1,
  '8a2f5bd9c60ffff': 1.6,
  '8a2f5bdb12dffff': 1,
  '8a2f5bdb1237fff': 1.2,
  '8a2f5bd82d8ffff': 1.33,
  '8a2f5bd95357fff': 1.82,
  '8a2f5bd82daffff': 2.16,
  '8a2f5bd861b7fff': 1.69,
  '8a2f5bdb176ffff': 1.29,
  '8a2f5bdb120ffff': 1.11,
  '8a2f5bd91b2ffff': 1,
  '8a2f5bda2417fff': 1.17,
  '8a2f5bd91da7fff': 1.5,
  '8a2f5bd9415ffff': 1,
  '8a2f5bdb101ffff': 1.27,
  '8a2f5bdb10a7fff': 1.47,
  '8a2f5bdb1c2ffff': 1,
  '8a2f5bdb1247fff': 1,
  '8a2f5bd95067fff': 1.15,
  '8a2f5bd9506ffff': 1.63,
  '8a2f5bd80987fff': 1.2,
  '8a2f5bd808dffff': 1.38,
  '8a2f5bd9016ffff': 1.29,
  '8a2f5bd83667fff': 1.71,
  '8a2f5bd83297fff': 1.75,
  '8a2f5bd83477fff': 1.11,
  '8a2f5bd82667fff': 1,
  '8a2f5bd88d1ffff': 2.11,
  '8a2f5bd9505ffff': 1.92,
  '8a2f5bd950dffff': 1.15,
  '8a2f5bdaa017fff': 1.9,
  '8a2f5bda224ffff': 1,
  '8a2f5bdaadb7fff': 1,
  '8a2f5bd81c97fff': 1.44,
  '8a2f5bd8e21ffff': 1.55,
  '8a2f5bd8e217fff': 1.74,
  '8a2f5bd8091ffff': 1,
  '8a2f5bd8464ffff': 1,
  '8a2f5bd809b7fff': 1.67,
  '8a2f5bd80817fff': 2,
  '8a2f5bd8098ffff': 2,
  '8a2f5bd808cffff': 1,
  '8a2f5bd80b9ffff': 1,
  '8a2f5bd80977fff': 1,
  '8a2f5bd80947fff': 1.33,
  '8a2f5bd80a47fff': 2.38,
  '8a2f5bd8565ffff': 1,
  '8a2f5bd80917fff': 1,
  '8a2f5bd80a57fff': 1.46,
  '8a2f5bd80a17fff': 1.5,
  '8a2f5bdb18cffff': 1,
  '8a2f5bdb026ffff': 1,
  '8a2f5bd9c45ffff': 1.64,
  '8a2f5bd989b7fff': 1.25,
  '8a2f5bd9ccb7fff': 1.17,
  '8a2f5bd99a9ffff': 1.25,
  '8a2f5bd8ca0ffff': 1.69,
  '8a2f5bd8468ffff': 1.25,
  '8a2f5bd8439ffff': 1.33,
  '8a2f5bd8438ffff': 1.42,
  '8a2f5bd9089ffff': 1.47,
  '8a2f5bd90897fff': 1,
  '8a2f5bd90c67fff': 1,
  '8a2f5bd90d8ffff': 1.5,
  '8a2f5bd91cdffff': 1.25,
  '8a2f5bd91577fff': 1.09,
  '8a2f5bd9152ffff': 1.2,
  '8a2f5bd95777fff': 1,
  '8a2f5bd9502ffff': 1.13,
  '8a2f5bd9186ffff': 1,
  '8a2f5bd9504ffff': 1.54,
  '8a2f5bd95047fff': 1.25,
  '8a2f5bd82487fff': 1,
  '8a2f5bd95147fff': 1,
  '8a2f5bd9516ffff': 1.18,
  '8a2f5bd95ab7fff': 1.38,
  '8a2f5bd952affff': 1.5,
  '8a2f5bd9538ffff': 1,
  '8a2f5bd85837fff': 1,
  '8a2f5bd9da67fff': 1.53,
  '8a2f5bdb3457fff': 1,
  '8a2f5bd94b8ffff': 1.4,
  '8a2f5bd96b4ffff': 1.25,
  '8a2f5bda249ffff': 1,
  '8a2f5bd9432ffff': 1.21,
  '8a2f5bdaa66ffff': 1.29,
  '8a2f5bd8c14ffff': 2.11,
  '8a2f5bd9d807fff': 1,
  '8a2f5bdb1b0ffff': 1,
  '8a2f5bdb1b2ffff': 1,
  '8a2f5bd82467fff': 1.38,
  '8a2f5bd82477fff': 1.55,
  '8a2f5bd9cd37fff': 1.28,
  '8a2f5bd9cd17fff': 1,
  '8a2f5bd8581ffff': 1,
  '8a2f5bd9caeffff': 1.18,
  '8a2f5bdb380ffff': 1,
  '8a2f5bd90c17fff': 1,
  '8a2f5bd86097fff': 2.24,
  '8a2f5bd9142ffff': 1.67,
  '8a2f5bd9da2ffff': 2.5,
  '8a2f5bd8ea57fff': 1.57,
  '8a2f5bdaa227fff': 2,
  '8a2f5bdaa357fff': 1,
  '8a2f5bdaa237fff': 1,
  '8a2f5bd84017fff': 1,
  '8a2f5bd8418ffff': 1,
  '8a2f5bda2cc7fff': 1.25,
  '8a2f5bd85787fff': 2.8,
  '8a2f5bd85797fff': 1.81,
  '8a2f5bd8445ffff': 1.41,
  '8a2f5bd868dffff': 2.75,
  '8a2f5bd86a97fff': 1.25,
  '8a2f5bd8ea67fff': 1.33,
  '8a2f5bd88d07fff': 1.37,
  '8a2f5bd9c947fff': 1.5,
  '8a2f5bd9c827fff': 1.15,
  '8a2f5bda246ffff': 1.6,
  '8a2f5bda2737fff': 1.42,
  '8a2f5bda2657fff': 1.17,
  '8a2f5bda26effff': 1,
  '8a2f5bda265ffff': 1.22,
  '8a2f5bd8548ffff': 1.7,
  '8a2f5bd85d67fff': 1.25,
  '8a2f5bd8eb77fff': 1.33,
  '8a2f5bd8c227fff': 1.2,
  '8a2f5bd810effff': 1.14,
  '8a2f5bd80077fff': 2.67,
  '8a2f5bd8015ffff': 1.24,
  '8a2f5bd8634ffff': 1.36,
  '8a2f5bd80067fff': 1.09,
  '8a2f5bd9025ffff': 1,
  '8a2f5bd9108ffff': 1,
  '8a2f5bd91087fff': 1.19,
  '8a2f5bd90247fff': 1.13,
  '8a2f5bd9024ffff': 1,
  '8a2f5bda2c0ffff': 1,
  '8a2f5bd81c6ffff': 2.59,
  '8a2f5bd90b5ffff': 1.46,
  '8a2f5bd8c3a7fff': 1.11,
  '8a2f5bd88d6ffff': 1.4,
  '8a2f5bdb1567fff': 1.22,
  '8a2f5bd81b0ffff': 2.38,
  '8a2f5bd8111ffff': 1.6,
  '8a2f5bd8116ffff': 1,
  '8a2f5bd95b6ffff': 1.5,
  '8a2f5bd8124ffff': 1.22,
  '8a2f5bd8ccd7fff': 1.2,
  '8a2f5bd8cccffff': 1.17,
  '8a2f5bd8cb97fff': 1.14,
  '8a2f5bdab627fff': 1.27,
  '8a2f5bd82a97fff': 1.8,
  '8a2f5bd867b7fff': 1,
  '8a2f5bd8654ffff': 2.17,
  '8a2f5bdb3387fff': 1,
  '8a2f5bdb33affff': 1,
  '8a2f5bd84a07fff': 1.43,
  '8a2f5bd9002ffff': 1.13,
  '8a2f5bd8c02ffff': 1.44,
  '8a2f5bd81257fff': 1,
  '8a2f5bd9c397fff': 1.88,
  '8a2f5bd9c76ffff': 2,
  '8a2f5bd86457fff': 1.43,
  '8a2f5bd81097fff': 1.86,
  '8a2f5bd827a7fff': 1,
  '8a2f5bdaa25ffff': 1.09,
  '8a2f5bdab19ffff': 1,
  '8a2f5bdaa307fff': 1.43,
  '8a2f5bd8c8f7fff': 1.38,
  '8a2f5bdab707fff': 1.14,
  '8a2f5bdab0a7fff': 1,
  '8a2f5bdaa367fff': 1.41,
  '8a2f5bd8ad97fff': 1.23,
  '8a2f5bd8adb7fff': 1,
  '8a2f5bdab757fff': 1.11,
  '8a2f5bd8cb87fff': 1.2,
  '8a2f5bd95317fff': 1,
  '8a2f5bd84b97fff': 1.14,
  '8a2f5bd848cffff': 2.64,
  '8a2f5bd83c97fff': 1.5,
  '8a2f5bdb1187fff': 1.12,
  '8a2f5bdaa407fff': 1,
  '8a2f5bd99ca7fff': 1.27,
  '8a2f5bd95ceffff': 1,
  '8a2f5bd99a37fff': 1.44,
  '8a2f5bd8e557fff': 1,
  '8a2f5bd832effff': 1.67,
  '8a2f5bd8e517fff': 1.86,
  '8a2f5bd83047fff': 1.77,
  '8a2f5bd8234ffff': 1.36,
  '8a2f5bd84a97fff': 1.79,
  '8a2f5bd9d4d7fff': 1.14,
  '8a2f5bd82377fff': 1.58,
  '8a2f5bd9c437fff': 1.71,
  '8a2f5bd858a7fff': 1,
  '8a2f5bd85d5ffff': 1.07,
  '8a2f5bd85827fff': 1.07,
  '8a2f5bd85487fff': 1.14,
  '8a2f5bd8081ffff': 1.25,
  '8a2f5bd80b57fff': 1.13,
  '8a2f5bd80a1ffff': 1,
  '8a2f5bd8094ffff': 1,
  '8a2f5bd846effff': 1.13,
  '8a2f5bd80997fff': 1.38,
  '8a2f5bd80b1ffff': 1.2,
  '8a2f5bd81bb7fff': 1,
  '8a2f5bd83397fff': 1.5,
  '8a2f5bd806effff': 1.4,
  '8a2f5bd802d7fff': 1,
  '8a2f5bd802dffff': 1,
  '8a2f5bd8024ffff': 1.77,
  '8a2f5bd869b7fff': 1.14,
  '8a2f5bdb1677fff': 1.27,
  '8a2f5bdb165ffff': 1.33,
  '8a2f5bd86ae7fff': 1.13,
  '8a2f5bd86ad7fff': 1.4,
  '8a2f5bdb1607fff': 1.4,
  '8a2f5bdb172ffff': 1.05,
  '8a2f5bdb16affff': 1.11,
  '8a2f5bdb17b7fff': 1,
  '8a2f5bdb14c7fff': 1.53,
  '8a2f5bdb3a6ffff': 1,
  '8a2f5bdb3ac7fff': 1.15,
  '8a2f5bdb1797fff': 1.33,
  '8a2f5bdb14effff': 1.09,
  '8a2f5bd82947fff': 1.6,
  '8a2f5bdb370ffff': 1.16,
  '8a2f5bd82397fff': 1,
  '8a2f5bd866d7fff': 1,
  '8a2f5bd866c7fff': 1.22,
  '8a2f5bd866f7fff': 1.1,
  '8a2f5bd95757fff': 1.33,
  '8a2f5bd95627fff': 1.2,
  '8a2f5bd868f7fff': 1.21,
  '8a2f5bd8681ffff': 1.53,
  '8a2f5bd868affff': 1,
  '8a2f5bd86817fff': 1,
  '8a2f5bd868a7fff': 1.4,
  '8a2f5bd86d6ffff': 1.38,
  '8a2f5bda3557fff': 1,
  '8a2f5bda37a7fff': 1,
  '8a2f5bd9c91ffff': 1.33,
  '8a2f5bdab0d7fff': 1.27,
  '8a2f5bdab0f7fff': 1.69,
  '8a2f5bd91b77fff': 1,
  '8a2f5bd8160ffff': 1,
  '8a2f5bd81667fff': 1.33,
  '8a2f5bd83267fff': 1.27,
  '8a2f5bd83a07fff': 1,
  '8a2f5bd94a37fff': 1,
  '8a2f5bd988b7fff': 1.76,
  '8a2f5bd86c4ffff': 1.17,
  '8a2f5bda2627fff': 1.71,
  '8a2f5bd95847fff': 1.71,
  '8a2f5bd94287fff': 1,
  '8a2f5bd98ad7fff': 1.5,
  '8a2f5bd98947fff': 1.29,
  '8a2f5bd9c447fff': 1.22,
  '8a2f5bd90d77fff': 1,
  '8a2f5bd81697fff': 1.31,
  '8a2f5bdaa287fff': 1,
  '8a2f5bd8e69ffff': 1.09,
  '8a2f5bd9db4ffff': 1.14,
  '8a2f5bd99937fff': 1.25,
  '8a2f5bd99907fff': 1.14,
  '8a2f5bd9dc1ffff': 2.36,
  '8a2f5bd86caffff': 1.17,
  '8a2f5bdb161ffff': 1.6,
  '8a2f5bd83d47fff': 2.33,
  '8a2f5bd81d1ffff': 1.22,
  '8a2f5bdb5ae7fff': 1,
  '8a2f5bd86adffff': 1.44,
  '8a2f5bd80d17fff': 1.12,
  '8a2f5bd86a4ffff': 1.76,
  '8a2f5bd80da7fff': 1.04,
  '8a2f5bd8e717fff': 1.18,
  '8a2f5bd8e737fff': 1,
  '8a2f5bd8e09ffff': 1,
  '8a2f5bd8e44ffff': 1.79,
  '8a2f5bd8e45ffff': 1.33,
  '8a2f5bda2237fff': 1,
  '8a2f5bda23affff': 1.37,
  '8a2f5bdb116ffff': 1.31,
  '8a2f5bdb114ffff': 1.08,
  '8a2f5bd8dd17fff': 1.15,
  '8a2f5bd8c22ffff': 1.29,
  '8a2f5bd8cbb7fff': 1.5,
  '8a2f5bd8c8effff': 1.36,
  '8a2f5bd83347fff': 1.5,
  '8a2f5bd83acffff': 1,
  '8a2f5bd83367fff': 1.65,
  '8a2f5bd86527fff': 1.68,
  '8a2f5bd9d45ffff': 1.29,
  '8a2f5bd8cc2ffff': 1,
  '8a2f5bd8cc0ffff': 2.05,
  '8a2f5bd8cc47fff': 1,
  '8a2f5bda2477fff': 1.43,
  '8a2f5bda240ffff': 1,
  '8a2f5bdb5247fff': 1.79,
  '8a2f5bdb5277fff': 1.8,
  '8a2f5bdb564ffff': 1,
  '8a2f5bd8284ffff': 1,
  '8a2f5bd82957fff': 1,
  '8a2f5bdb1167fff': 1,
  '8a2f5bdb1b97fff': 1.14,
  '8a2f5bdb1007fff': 1.25,
  '8a2f5bd8432ffff': 1.57,
  '8a2f5bdb1b87fff': 1,
  '8a2f5bd82cc7fff': 1.19,
  '8a2f5bd82ccffff': 1.27,
  '8a2f5bd8e197fff': 1.04,
  '8a2f5bd8e81ffff': 1.56,
  '8a2f5bd8e117fff': 1.5,
  '8a2f5bd8eccffff': 1.29,
  '8a2f5bd8e19ffff': 1,
  '8a2f5bd95b9ffff': 1.2,
  '8a2f5bd8ec6ffff': 1.42,
  '8a2f5bd866b7fff': 1.47,
  '8a2f5bd86637fff': 1.42,
  '8a2f5bdaa24ffff': 1.11,
  '8a2f5bdab537fff': 1,
  '8a2f5bd8272ffff': 1.18,
  '8a2f5bd820dffff': 1.5,
  '8a2f5bd84b9ffff': 1.06,
  '8a2f5bd85337fff': 1.42,
  '8a2f5bdaac1ffff': 1.09,
  '8a2f5bd836c7fff': 1.2,
  '8a2f5bd8262ffff': 1.43,
  '8a2f5bd9c8e7fff': 1.71,
  '8a2f5bd9d937fff': 1,
  '8a2f5bd9cba7fff': 1.67,
  '8a2f5bd83077fff': 1.57,
  '8a2f5bd8436ffff': 1.55,
  '8a2f5bd862d7fff': 1,
  '8a2f5bd8621ffff': 1.33,
  '8a2f5bda262ffff': 1,
  '8a2f5bd94ac7fff': 1.36,
  '8a2f5bd81a4ffff': 1,
  '8a2f5bd8cda7fff': 1,
  '8a2f5bd8ed0ffff': 1.25,
  '8a2f5bd80caffff': 2.54,
  '8a2f5bd8c1affff': 1.4,
  '8a2f5bd80c87fff': 1,
  '8a2f5bd8009ffff': 2.13,
  '8a2f5bd80567fff': 1.77,
  '8a2f5bdb3077fff': 1.2,
  '8a2f5bd83cd7fff': 1.88,
  '8a2f5bda2217fff': 1,
  '8a2f5bda225ffff': 1,
  '8a2f5bda2257fff': 1.2,
  '8a2f5bda220ffff': 1,
  '8a2f5bd9126ffff': 1.2,
  '8a2f5bd95827fff': 1.83,
  '8a2f5bd95107fff': 1.14,
  '8a2f5bdb1acffff': 1,
  '8a2f5bd99197fff': 1.17,
  '8a2f5bd99d47fff': 1,
  '8a2f5bd9999ffff': 1.29,
  '8a2f5bd9d2c7fff': 1,
  '8a2f5bd9d2e7fff': 1,
  '8a2f5bdaa6e7fff': 1,
  '8a2f5bdb36affff': 1,
  '8a2f5bdb361ffff': 1.43,
  '8a2f5bd866e7fff': 1.6,
  '8a2f5bd8c087fff': 1.2,
  '8a2f5bda25b7fff': 1.33,
  '8a2f5bd9981ffff': 1,
  '8a2f5bd998f7fff': 1,
  '8a2f5bd868c7fff': 1.13,
  '8a2f5bd91ad7fff': 1.33,
  '8a2f5bd91347fff': 1.14,
  '8a2f5bd918effff': 2.67,
  '8a2f5bdb148ffff': 1.1,
  '8a2f5bdb3b67fff': 1.36,
  '8a2f5bdb16d7fff': 1.2,
  '8a2f5bd867a7fff': 1.33,
  '8a2f5bd86477fff': 1.26,
  '8a2f5bd864e7fff': 1.11,
  '8a2f5bd86387fff': 1,
  '8a2f5bd80d1ffff': 1,
  '8a2f5bd80c27fff': 1,
  '8a2f5bd80c37fff': 1,
  '8a2f5bd8636ffff': 1.79,
  '8a2f5bd8638ffff': 1,
  '8a2f5bd860dffff': 1,
  '8a2f5bd8642ffff': 1,
  '8a2f5bd95b37fff': 2.17,
  '8a2f5bd86447fff': 1,
  '8a2f5bd8c687fff': 1,
  '8a2f5bd889b7fff': 1,
  '8a2f5bd8c6e7fff': 1,
  '8a2f5bd8c0e7fff': 1,
  '8a2f5bd8c0c7fff': 1,
  '8a2f5bd9131ffff': 1,
  '8a2f5bd9ccaffff': 1.25,
  '8a2f5bd9136ffff': 1.2,
  '8a2f5bd85517fff': 1.1,
  '8a2f5bd855a7fff': 1.96,
  '8a2f5bd8558ffff': 1,
  '8a2f5bda219ffff': 1.11,
  '8a2f5bda20affff': 1,
  '8a2f5bd9dcb7fff': 1,
  '8a2f5bd90d0ffff': 1.71,
  '8a2f5bd90d07fff': 2.95,
  '8a2f5bd8519ffff': 1.14,
  '8a2f5bd850b7fff': 1.83,
  '8a2f5bd85527fff': 1.25,
  '8a2f5bd8c817fff': 1.27,
  '8a2f5bd98a57fff': 2.07,
  '8a2f5bd98a5ffff': 1.67,
  '8a2f5bda24effff': 1.73,
  '8a2f5bd83277fff': 1,
  '8a2f5bd9ca0ffff': 1.71,
  '8a2f5bd9cae7fff': 1.2,
  '8a2f5bd9ca57fff': 1.5,
  '8a2f5bd9dd87fff': 1,
  '8a2f5bd9cca7fff': 1.13,
  '8a2f5bd9cc9ffff': 1.64,
  '8a2f5bd9c527fff': 1.14,
  '8a2f5bd86a5ffff': 1.75,
  '8a2f5bd86a8ffff': 1.23,
  '8a2f5bd86327fff': 1,
  '8a2f5bd85d6ffff': 1,
  '8a2f5bd85d57fff': 1.8,
  '8a2f5bdaa637fff': 1,
  '8a2f5bdaa78ffff': 1.25,
  '8a2f5bdaa617fff': 1,
  '8a2f5bd9dc47fff': 1,
  '8a2f5bd8eaa7fff': 1,
  '8a2f5bd85b17fff': 1,
  '8a2f5bdab61ffff': 1.3,
  '8a2f5bd8cc37fff': 1.86,
  '8a2f5bda20a7fff': 1,
  '8a2f5bdb14cffff': 1.17,
  '8a2f5bd8c00ffff': 1,
  '8a2f5bd88927fff': 1,
  '8a2f5bd8c407fff': 1,
  '8a2f5bd81c77fff': 1.75,
  '8a2f5bd8c66ffff': 1.75,
  '8a2f5bda36cffff': 1.25,
  '8a2f5bd8476ffff': 1.54,
  '8a2f5bd95307fff': 1,
  '8a2f5bd8a457fff': 1.35,
  '8a2f5bd8a4e7fff': 1.5,
  '8a2f5bd8a4effff': 1.05,
  '8a2f5bd8a4cffff': 1,
  '8a2f5bd80257fff': 1.05,
  '8a2f5bd8020ffff': 1.36,
  '8a2f5bd81d8ffff': 1,
  '8a2f5bd809a7fff': 1,
  '8a2f5bd83767fff': 1.38,
  '8a2f5bdab45ffff': 1,
  '8a2f5bd8169ffff': 2.09,
  '8a2f5bd9dc27fff': 2.75,
  '8a2f5bd83057fff': 1.09,
  '8a2f5bd83a5ffff': 1.89,
  '8a2f5bd86257fff': 1,
  '8a2f5bd8305ffff': 1,
  '8a2f5bd8338ffff': 1,
  '8a2f5bd83387fff': 1.38,
  '8a2f5bda20f7fff': 1,
  '8a2f5bd8176ffff': 1,
  '8a2f5bd8ad6ffff': 1.09,
  '8a2f5bdb3a07fff': 1.43,
  '8a2f5bdb3a8ffff': 1.94,
  '8a2f5bdb38effff': 1,
  '8a2f5bdb3a1ffff': 1.25,
  '8a2f5bd9d96ffff': 1,
  '8a2f5bdaa41ffff': 1.25,
  '8a2f5bd832affff': 1.14,
  '8a2f5bd816dffff': 1,
  '8a2f5bd8ed67fff': 1,
  '8a2f5bd8e48ffff': 1.2,
  '8a2f5bdb311ffff': 1.65,
  '8a2f5bdb3007fff': 1,
  '8a2f5bdb3037fff': 1.33,
  '8a2f5bdb301ffff': 1.6,
  '8a2f5bdb375ffff': 1.45,
  '8a2f5bdb376ffff': 1,
  '8a2f5bdb32b7fff': 1.33,
  '8a2f5bdaa137fff': 3.31,
  '8a2f5bd9576ffff': 1,
  '8a2f5bd804effff': 2.63,
  '8a2f5bd86347fff': 2.36,
  '8a2f5bd918dffff': 1,
  '8a2f5bd80637fff': 1,
  '8a2f5bd8315ffff': 2.5,
  '8a2f5bd908c7fff': 1.13,
  '8a2f5bd908dffff': 1.17,
  '8a2f5bdaa38ffff': 2.45,
  '8a2f5bdaa39ffff': 1,
  '8a2f5bdb168ffff': 1.64,
  '8a2f5bdb3bb7fff': 1,
  '8a2f5bdb38d7fff': 1.29,
  '8a2f5bdb3baffff': 1.5,
  '8a2f5bdb17affff': 1.15,
  '8a2f5bd846affff': 1.14,
  '8a2f5bdb16b7fff': 1.1,
  '8a2f5bdb3a2ffff': 1.83,
  '8a2f5bdb178ffff': 1.25,
  '8a2f5bd8c577fff': 1,
  '8a2f5bd858cffff': 1,
  '8a2f5bd8edb7fff': 1.4,
  '8a2f5bd83d0ffff': 2,
  '8a2f5bd81aa7fff': 1.14,
  '8a2f5bd94697fff': 1.5,
  '8a2f5bd82d57fff': 1.75,
  '8a2f5bd82d47fff': 1.25,
  '8a2f5bd80d97fff': 1.13,
  '8a2f5bd85377fff': 1.09,
  '8a2f5bd8532ffff': 1.22,
  '8a2f5bd85717fff': 1.14,
  '8a2f5bd85737fff': 1.95,
  '8a2f5bd9cb6ffff': 1.6,
  '8a2f5bdb3727fff': 1.82,
  '8a2f5bdb372ffff': 1,
  '8a2f5bd8246ffff': 1.36,
  '8a2f5bd8625ffff': 1.61,
  '8a2f5bd8531ffff': 2.39,
  '8a2f5bd85317fff': 1.13,
  '8a2f5bd83637fff': 1,
  '8a2f5bd9d167fff': 1,
  '8a2f5bd83167fff': 1.14,
  '8a2f5bd8371ffff': 1,
  '8a2f5bd8480ffff': 1.1,
  '8a2f5bdb1777fff': 1,
  '8a2f5bdb170ffff': 1.07,
  '8a2f5bdb12b7fff': 1.9,
  '8a2f5bdb174ffff': 1.77,
  '8a2f5bdb1297fff': 1,
  '8a2f5bd86a9ffff': 1,
  '8a2f5bd86337fff': 1,
  '8a2f5bd8c387fff': 1,
  '8a2f5bd848c7fff': 1.96,
  '8a2f5bdb379ffff': 1,
  '8a2f5bd86177fff': 1.62,
  '8a2f5bd8612ffff': 1,
  '8a2f5bd8489ffff': 1.5,
  '8a2f5bd99967fff': 1,
  '8a2f5bd834affff': 1.31,
  '8a2f5bd8055ffff': 1.29,
  '8a2f5bd86057fff': 1,
  '8a2f5bd82b77fff': 1.29,
  '8a2f5bd80097fff': 1,
  '8a2f5bd80c8ffff': 1.06,
  '8a2f5bd80547fff': 1.33,
  '8a2f5bdaa0b7fff': 1.2,
  '8a2f5bd8054ffff': 1,
  '8a2f5bd8056ffff': 1.94,
  '8a2f5bd80647fff': 1.63,
  '8a2f5bdb324ffff': 1,
  '8a2f5bd85087fff': 1,
  '8a2f5bd814cffff': 1.07,
  '8a2f5bdb3137fff': 1,
  '8a2f5bd9d1a7fff': 1,
  '8a2f5bd9d56ffff': 1,
  '8a2f5bd8044ffff': 1,
  '8a2f5bd80447fff': 1.13,
  '8a2f5bd8494ffff': 1.75,
  '8a2f5bd8495ffff': 1,
  '8a2f5bd84947fff': 1.33,
  '8a2f5bd84b8ffff': 1,
  '8a2f5bd81127fff': 1.7,
  '8a2f5bd818a7fff': 1.25,
  '8a2f5bd81c87fff': 1.67,
  '8a2f5bd8026ffff': 1.25,
  '8a2f5bd9d487fff': 1.5,
  '8a2f5bd9c0a7fff': 1.5,
  '8a2f5bd9c0affff': 1.5,
  '8a2f5bd90c47fff': 1.07,
  '8a2f5bd90c57fff': 1,
  '8a2f5bd9d977fff': 2.88,
  '8a2f5bd80b27fff': 1,
  '8a2f5bd85647fff': 1,
  '8a2f5bd8386ffff': 1.6,
  '8a2f5bd83b37fff': 2.16,
  '8a2f5bd85237fff': 1,
  '8a2f5bd853affff': 1,
  '8a2f5bd850e7fff': 1,
  '8a2f5bd85cdffff': 1.17,
  '8a2f5bd8552ffff': 1,
  '8a2f5bd852f7fff': 1.19,
  '8a2f5bd852e7fff': 1,
  '8a2f5bd85a07fff': 1,
  '8a2f5bd850affff': 1,
  '8a2f5bd85297fff': 1.31,
  '8a2f5bd8529ffff': 1.73,
  '8a2f5bd84a9ffff': 1.2,
  '8a2f5bd850f7fff': 1,
  '8a2f5bd850effff': 1,
  '8a2f5bd853a7fff': 1,
  '8a2f5bdaad77fff': 1.65,
  '8a2f5bd85a6ffff': 1.76,
  '8a2f5bd84337fff': 1.11,
  '8a2f5bd85347fff': 1.25,
  '8a2f5bd82d87fff': 1.14,
  '8a2f5bd82caffff': 1.25,
  '8a2f5bd90c27fff': 1,
  '8a2f5bd82197fff': 1.77,
  '8a2f5bd82187fff': 1.31,
  '8a2f5bd821b7fff': 1,
  '8a2f5bd834b7fff': 1.63,
  '8a2f5bd9c96ffff': 1.2,
  '8a2f5bdaa8b7fff': 1.81,
  '8a2f5bdaad6ffff': 1.08,
  '8a2f5bd8c1a7fff': 1,
  '8a2f5bd8e84ffff': 1.23,
  '8a2f5bda3447fff': 1,
  '8a2f5bda3457fff': 1,
  '8a2f5bda2db7fff': 1,
  '8a2f5bd91a87fff': 1,
  '8a2f5bdb526ffff': 1.2,
  '8a2f5bda2d9ffff': 1.11,
  '8a2f5bda2ca7fff': 3,
  '8a2f5bd8e257fff': 1,
  '8a2f5bd9c58ffff': 1.12,
  '8a2f5bd9c4cffff': 1,
  '8a2f5bdb5adffff': 1.08,
  '8a2f5bda248ffff': 1,
  '8a2f5bda24d7fff': 1.13,
  '8a2f5bda24dffff': 1,
  '8a2f5bda2617fff': 1,
  '8a2f5bda278ffff': 1,
  '8a2f5bd823a7fff': 2.14,
  '8a2f5bd8c5affff': 1.24,
  '8a2f5bd8c58ffff': 1,
  '8a2f5bd8072ffff': 1.08,
  '8a2f5bd90d67fff': 1.56,
  '8a2f5bd90847fff': 1,
  '8a2f5bd946dffff': 1.22,
  '8a2f5bd9d967fff': 1,
  '8a2f5bd81787fff': 1.5,
  '8a2f5bd9082ffff': 1,
  '8a2f5bd8604ffff': 1,
  '8a2f5bd83307fff': 1.29,
  '8a2f5bd9469ffff': 1,
  '8a2f5bd96a6ffff': 1,
  '8a2f5bd94a97fff': 1,
  '8a2f5bda21affff': 1,
  '8a2f5bd99cc7fff': 1.13,
  '8a2f5bd8270ffff': 2.17,
  '8a2f5bda26affff': 1,
  '8a2f5bda2687fff': 1,
  '8a2f5bda3417fff': 1.22,
  '8a2f5bd8101ffff': 1.6,
  '8a2f5bdb151ffff': 1.11,
  '8a2f5bd82337fff': 2.25,
  '8a2f5bd82307fff': 1.36,
  '8a2f5bd8159ffff': 1,
  '8a2f5bd82927fff': 1,
  '8a2f5bd8628ffff': 1,
  '8a2f5bdb3a27fff': 1.76,
  '8a2f5bd99c17fff': 1,
  '8a2f5bd862e7fff': 1.29,
  '8a2f5bdb3847fff': 1.29,
  '8a2f5bd8e0cffff': 1.2,
  '8a2f5bd8ca07fff': 1.46,
  '8a2f5bd9d4effff': 1,
  '8a2f5bd85b47fff': 1.84,
  '8a2f5bd83b1ffff': 1.43,
  '8a2f5bd83b07fff': 1.22,
  '8a2f5bd854b7fff': 1,
  '8a2f5bd9c667fff': 2.05,
  '8a2f5bd8415ffff': 1.16,
  '8a2f5bd8414ffff': 1.23,
  '8a2f5bd85567fff': 1,
  '8a2f5bd84067fff': 2,
  '8a2f5bd9d997fff': 1,
  '8a2f5bd83407fff': 1.23,
  '8a2f5bd9d99ffff': 1,
  '8a2f5bd83067fff': 1.61,
  '8a2f5bd82627fff': 1.41,
  '8a2f5bd8698ffff': 1.61,
  '8a2f5bd82c17fff': 1,
  '8a2f5bd826cffff': 1,
  '8a2f5bd8268ffff': 1,
  '8a2f5bd9c44ffff': 1.67,
  '8a2f5bd8265ffff': 1,
  '8a2f5bd82747fff': 1.06,
  '8a2f5bd82707fff': 1.09,
  '8a2f5bd827affff': 1.6,
  '8a2f5bd8275ffff': 1.55,
  '8a2f5bd95337fff': 1,
  '8a2f5bd8668ffff': 1.2,
  '8a2f5bd82c37fff': 1.75,
  '8a2f5bd82c8ffff': 1.5,
  '8a2f5bd953a7fff': 1,
  '8a2f5bd95a1ffff': 1.42,
  '8a2f5bd8645ffff': 1.31,
  '8a2f5bd8c7affff': 1.3,
  '8a2f5bd8c187fff': 1.25,
  '8a2f5bd9155ffff': 1,
  '8a2f5bd91737fff': 2.74,
  '8a2f5bdb1a9ffff': 1.29,
  '8a2f5bdb1347fff': 1.25,
  '8a2f5bdb1377fff': 1.5,
  '8a2f5bdb1a8ffff': 1.44,
  '8a2f5bdb1a97fff': 1.25,
  '8a2f5bd98d77fff': 1.25,
  '8a2f5bd8ec2ffff': 1,
  '8a2f5bd95137fff': 1,
  '8a2f5bd95127fff': 1,
  '8a2f5bd9c94ffff': 1.06,
  '8a2f5bd82617fff': 1.41,
  '8a2f5bd8c6d7fff': 1,
  '8a2f5bd8a82ffff': 1.14,
  '8a2f5bdb309ffff': 1.2,
  '8a2f5bdb3097fff': 1.12,
  '8a2f5bd80417fff': 1.13,
  '8a2f5bd8c89ffff': 1,
  '8a2f5bdb1137fff': 1,
  '8a2f5bd8302ffff': 1.41,
  '8a2f5bdb10d7fff': 1.52,
  '8a2f5bd8676ffff': 1,
  '8a2f5bda24affff': 1,
  '8a2f5bdb3b9ffff': 1,
  '8a2f5bd91167fff': 1,
  '8a2f5bd804b7fff': 1,
  '8a2f5bd91917fff': 1.17,
  '8a2f5bd8c79ffff': 1.36,
  '8a2f5bd8c797fff': 1,
  '8a2f5bd8a99ffff': 1,
  '8a2f5bd9da4ffff': 1,
  '8a2f5bd8ad67fff': 1.5,
  '8a2f5bd8ad07fff': 1,
  '8a2f5bd8e6cffff': 1,
  '8a2f5bd8a997fff': 1,
  '8a2f5bd8ea47fff': 1.33,
  '8a2f5bd83d5ffff': 1.9,
  '8a2f5bd82347fff': 1.88,
  '8a2f5bd8e20ffff': 1.15,
  '8a2f5bd8e2e7fff': 1.5,
  '8a2f5bd81957fff': 1.83,
  '8a2f5bd81827fff': 1.13,
  '8a2f5bda2767fff': 1.21,
  '8a2f5bd8399ffff': 2.54,
  '8a2f5bd84a87fff': 2.57,
  '8a2f5bd84aa7fff': 1.13,
  '8a2f5bd85587fff': 1.21,
  '8a2f5bd8c6a7fff': 1,
  '8a2f5bda351ffff': 1.07,
  '8a2f5bd8641ffff': 1.16,
  '8a2f5bd83ccffff': 1.5,
  '8a2f5bd8c877fff': 1.58,
  '8a2f5bdaa30ffff': 1.14,
  '8a2f5bd8c80ffff': 1.15,
  '8a2f5bdaa02ffff': 1,
  '8a2f5bd83c9ffff': 1,
  '8a2f5bd83cdffff': 1.63,
  '8a2f5bd83cb7fff': 1.27,
  '8a2f5bd83227fff': 1.57,
  '8a2f5bd805b7fff': 1,
  '8a2f5bd8040ffff': 1,
  '8a2f5bd81577fff': 1.11,
  '8a2f5bd85b77fff': 1.29,
  '8a2f5bd85a1ffff': 1,
  '8a2f5bd955affff': 1,
  '8a2f5bd91517fff': 1,
  '8a2f5bd94baffff': 1.36,
  '8a2f5bd94b87fff': 1,
  '8a2f5bd9c82ffff': 1.17,
  '8a2f5bd81c37fff': 1.33,
  '8a2f5bd81daffff': 1,
  '8a2f5bd85157fff': 1.07,
  '8a2f5bd8510ffff': 1,
  '8a2f5bda34a7fff': 1,
  '8a2f5bd9548ffff': 1,
  '8a2f5bd991b7fff': 1.2,
  '8a2f5bd99c5ffff': 1,
  '8a2f5bd8a517fff': 1,
  '8a2f5bd9d0affff': 1,
  '8a2f5bd9d01ffff': 1,
  '8a2f5bd99187fff': 1,
  '8a2f5bd991a7fff': 1,
  '8a2f5bd99ccffff': 1,
  '8a2f5bd8c897fff': 1.82,
  '8a2f5bd8cc6ffff': 1,
  '8a2f5bd8c637fff': 1.09,
  '8a2f5bd8c607fff': 1,
  '8a2f5bd90ab7fff': 1,
  '8a2f5bd8c6affff': 1,
  '8a2f5bd85187fff': 1,
  '8a2f5bd85577fff': 1,
  '8a2f5bd95a07fff': 1.09,
  '8a2f5bd8a54ffff': 1,
  '8a2f5bd8a097fff': 1.27,
  '8a2f5bd8e997fff': 1.28,
  '8a2f5bd816cffff': 1,
  '8a2f5bd94297fff': 1,
  '8a2f5bd81247fff': 1.29,
  '8a2f5bd95727fff': 2.05,
  '8a2f5bd9509ffff': 1.4,
  '8a2f5bd83cc7fff': 1,
  '8a2f5bd84baffff': 1.27,
  '8a2f5bd9c95ffff': 1,
  '8a2f5bdb3a17fff': 1.2,
  '8a2f5bdb3af7fff': 2.13,
  '8a2f5bd88cd7fff': 1.06,
  '8a2f5bd842d7fff': 1,
  '8a2f5bd80b8ffff': 1,
  '8a2f5bd842dffff': 1,
  '8a2f5bd80baffff': 1,
  '8a2f5bd8c497fff': 1,
  '8a2f5bdaad37fff': 1.25,
  '8a2f5bd88d9ffff': 1,
  '8a2f5bdaa70ffff': 1.76,
  '8a2f5bd85747fff': 1.25,
  '8a2f5bd85757fff': 1,
  '8a2f5bd8eb9ffff': 1,
  '8a2f5bd9095ffff': 1,
  '8a2f5bd86127fff': 1,
  '8a2f5bdabc8ffff': 1,
  '8a2f5bd83587fff': 1.21,
  '8a2f5bdb302ffff': 1,
  '8a2f5bd8e99ffff': 1,
  '8a2f5bd8e98ffff': 1,
  '8a2f5bd8e90ffff': 1.33,
  '8a2f5bd852b7fff': 1,
  '8a2f5bd8281ffff': 1,
  '8a2f5bd8660ffff': 1.28,
  '8a2f5bd828a7fff': 1,
  '8a2f5bd824d7fff': 1,
  '8a2f5bdb52a7fff': 1,
  '8a2f5bda2087fff': 2.79,
  '8a2f5bda238ffff': 1.25,
  '8a2f5bd847b7fff': 1.17,
  '8a2f5bd84477fff': 1.78,
  '8a2f5bd8440ffff': 1.27,
  '8a2f5bd844effff': 1,
  '8a2f5bd9c4effff': 1.17,
  '8a2f5bd9992ffff': 1.24,
  '8a2f5bd82cdffff': 1.42,
  '8a2f5bd9c09ffff': 1,
  '8a2f5bd8336ffff': 1,
  '8a2f5bd8ed97fff': 1.2,
  '8a2f5bd8ea27fff': 1.29,
  '8a2f5bd8ea37fff': 1,
  '8a2f5bd81207fff': 1.17,
  '8a2f5bd84457fff': 1,
  '8a2f5bd9c61ffff': 1.77,
  '8a2f5bd84447fff': 1.07,
  '8a2f5bd99d4ffff': 1.27,
  '8a2f5bd9c327fff': 1,
  '8a2f5bd9cad7fff': 1,
  '8a2f5bd82647fff': 1.3,
  '8a2f5bd84697fff': 1.27,
  '8a2f5bd9882ffff': 1,
  '8a2f5bd999b7fff': 1.09,
  '8a2f5bd99997fff': 1.5,
  '8a2f5bd9d69ffff': 1.4,
  '8a2f5bd99d27fff': 1.1,
  '8a2f5bd95b4ffff': 1,
  '8a2f5bd86467fff': 1.38,
  '8a2f5bd8656ffff': 1.13,
  '8a2f5bd86567fff': 1,
  '8a2f5bd98b57fff': 1.47,
  '8a2f5bd940d7fff': 1,
  '8a2f5bd942f7fff': 1.27,
  '8a2f5bd813b7fff': 1,
  '8a2f5bd80047fff': 1.06,
  '8a2f5bd81ad7fff': 1.33,
  '8a2f5bd80177fff': 1,
  '8a2f5bd80857fff': 1.22,
  '8a2f5bd80b97fff': 1.33,
  '8a2f5bd9c147fff': 1,
  '8a2f5bd9c8effff': 1.75,
  '8a2f5bd80847fff': 1,
  '8a2f5bd8231ffff': 1.33,
  '8a2f5bd95347fff': 1.54,
  '8a2f5bd94a1ffff': 1,
  '8a2f5bd94a07fff': 1,
  '8a2f5bd8356ffff': 1.53,
  '8a2f5bd8354ffff': 1.84,
  '8a2f5bd83097fff': 1,
  '8a2f5bdb13b7fff': 1,
  '8a2f5bdb1227fff': 1,
  '8a2f5bdb1357fff': 1.28,
  '8a2f5bdb10f7fff': 1.43,
  '8a2f5bd84537fff': 1,
  '8a2f5bdb135ffff': 1,
  '8a2f5bdb134ffff': 1.29,
  '8a2f5bd84d9ffff': 1,
  '8a2f5bdb122ffff': 1.17,
  '8a2f5bdb106ffff': 1,
  '8a2f5bda24f7fff': 1,
  '8a2f5bd84c97fff': 2,
  '8a2f5bdb1267fff': 1.5,
  '8a2f5bd84c17fff': 1.14,
  '8a2f5bda24b7fff': 1,
  '8a2f5bdb186ffff': 1.18,
  '8a2f5bdb1b1ffff': 1,
  '8a2f5bdb1a87fff': 1,
  '8a2f5bd9d20ffff': 1.15,
  '8a2f5bd999affff': 1.33,
  '8a2f5bd9998ffff': 1.33,
  '8a2f5bd8a55ffff': 1.29,
  '8a2f5bd8a557fff': 1,
  '8a2f5bd8a50ffff': 1,
  '8a2f5bd8a51ffff': 1.13,
  '8a2f5bd8a5affff': 1.33,
  '8a2f5bd9d207fff': 1.17,
  '8a2f5bd8a947fff': 1,
  '8a2f5bd8341ffff': 1.17,
  '8a2f5bd82cf7fff': 1.18,
  '8a2f5bd9c027fff': 1,
  '8a2f5bd9c157fff': 1,
  '8a2f5bd9c007fff': 1.2,
  '8a2f5bd9c037fff': 1.07,
  '8a2f5bdb535ffff': 1,
  '8a2f5bdb5267fff': 1.5,
  '8a2f5bdab617fff': 1.11,
  '8a2f5bd8a987fff': 1.67,
  '8a2f5bdabc9ffff': 1,
  '8a2f5bd823affff': 1,
  '8a2f5bd82227fff': 1,
  '8a2f5bd818dffff': 1,
  '8a2f5bd8c977fff': 1,
  '8a2f5bd8c957fff': 1,
  '8a2f5bd95017fff': 1.88,
  '8a2f5bd99cf7fff': 1,
  '8a2f5bd8324ffff': 1.14,
  '8a2f5bd8a867fff': 1,
  '8a2f5bd9d82ffff': 1,
  '8a2f5bd8e307fff': 1.67,
  '8a2f5bd8274ffff': 1.2,
  '8a2f5bdb345ffff': 1.25,
  '8a2f5bd9d35ffff': 1,
  '8a2f5bd9e947fff': 1,
  '8a2f5bd9e977fff': 1,
  '8a2f5bd9e90ffff': 1,
  '8a2f5bdb3197fff': 1,
  '8a2f5bd9d877fff': 1.25,
  '8a2f5bd9daa7fff': 1,
  '8a2f5bd95ba7fff': 1,
  '8a2f5bd86897fff': 1.07,
  '8a2f5bd80807fff': 1.44,
  '8a2f5bd80ba7fff': 1.06,
  '8a2f5bd85cb7fff': 1,
  '8a2f5bd800cffff': 1,
  '8a2f5bd9c39ffff': 2.71,
  '8a2f5bd9cc57fff': 1,
  '8a2f5bd9ddb7fff': 1,
  '8a2f5bd9c2a7fff': 1.19,
  '8a2f5bd9c11ffff': 2,
  '8a2f5bd9dca7fff': 1,
  '8a2f5bd9c077fff': 2,
  '8a2f5bd9c417fff': 1.5,
  '8a2f5bd9c217fff': 1.2,
  '8a2f5bd8c4affff': 1.55,
  '8a2f5bdaa31ffff': 1,
  '8a2f5bd83437fff': 2.25,
  '8a2f5bd86277fff': 1,
  '8a2f5bd913b7fff': 1,
  '8a2f5bd8329ffff': 1.4,
  '8a2f5bd83a97fff': 2,
  '8a2f5bd845a7fff': 1.1,
  '8a2f5bd942dffff': 1,
  '8a2f5bd828c7fff': 1.55,
  '8a2f5bdb3617fff': 1,
  '8a2f5bdb344ffff': 1.27,
  '8a2f5bdb346ffff': 1,
  '8a2f5bd82d2ffff': 1,
  '8a2f5bd85c47fff': 1,
  '8a2f5bd9884ffff': 1.58,
  '8a2f5bd98b17fff': 1.57,
  '8a2f5bd9d7b7fff': 1.25,
  '8a2f5bd988effff': 1.16,
  '8a2f5bd818effff': 1,
  '8a2f5bdb374ffff': 2.76,
  '8a2f5bd8c2f7fff': 1,
  '8a2f5bd8c357fff': 1,
  '8a2f5bd8c347fff': 1,
  '8a2f5bd8c367fff': 1.25,
  '8a2f5bd8cacffff': 1,
  '8a2f5bd8ca5ffff': 1.21,
  '8a2f5bdab01ffff': 1.18,
  '8a2f5bdab72ffff': 1.43,
  '8a2f5bdab70ffff': 1,
  '8a2f5bd8e4d7fff': 1,
  '8a2f5bd8e4f7fff': 1,
  '8a2f5bd8caf7fff': 1,
  '8a2f5bd8c377fff': 1,
  '8a2f5bd8c30ffff': 1.43,
  '8a2f5bd8c32ffff': 1,
  '8a2f5bd8cad7fff': 1,
  '8a2f5bd8cadffff': 1,
  '8a2f5bd8c327fff': 1,
  '8a2f5bd8c31ffff': 1,
  '8a2f5bdb52cffff': 1.26,
  '8a2f5bd84077fff': 1.67,
  '8a2f5bdb1ba7fff': 1,
  '8a2f5bd82c2ffff': 1,
  '8a2f5bd82d5ffff': 2,
  '8a2f5bd9d48ffff': 1.25,
  '8a2f5bd81aaffff': 1.08,
  '8a2f5bd8e047fff': 1.29,
  '8a2f5bdb3377fff': 2.42,
  '8a2f5bdb1307fff': 1.07,
  '8a2f5bdb1207fff': 1.12,
  '8a2f5bd9c987fff': 1.06,
  '8a2f5bd9c9affff': 2.5,
  '8a2f5bd82607fff': 1.38,
  '8a2f5bd9c9b7fff': 1,
  '8a2f5bd90b8ffff': 1.71,
  '8a2f5bd8e817fff': 1,
  '8a2f5bd8e807fff': 1,
  '8a2f5bd8e82ffff': 1.25,
  '8a2f5bdb1c17fff': 1.9,
  '8a2f5bd8e537fff': 1.25,
  '8a2f5bd8e567fff': 1.5,
  '8a2f5bd8ec0ffff': 1,
  '8a2f5bd8ec1ffff': 1.33,
  '8a2f5bd832c7fff': 1.33,
  '8a2f5bd832d7fff': 1.82,
  '8a2f5bd8328ffff': 1.41,
  '8a2f5bd868b7fff': 1,
  '8a2f5bd9439ffff': 1,
  '8a2f5bd9c647fff': 1.86,
  '8a2f5bd8661ffff': 1,
  '8a2f5bd866affff': 1.14,
  '8a2f5bda264ffff': 1.45,
  '8a2f5bd84917fff': 1,
  '8a2f5bda2287fff': 1.5,
  '8a2f5bd8595ffff': 1.15,
  '8a2f5bd958f7fff': 1.08,
  '8a2f5bd9db57fff': 1.09,
  '8a2f5bd91567fff': 1,
  '8a2f5bd91547fff': 1,
  '8a2f5bd9c377fff': 1.73,
  '8a2f5bd9c32ffff': 1.11,
  '8a2f5bd9cd5ffff': 2,
  '8a2f5bd826affff': 1,
  '8a2f5bd9c107fff': 1,
  '8a2f5bd9c8affff': 1,
  '8a2f5bd95aa7fff': 1.71,
  '8a2f5bd95a17fff': 1,
  '8a2f5bd8cb57fff': 1.1,
  '8a2f5bd8cb5ffff': 1,
  '8a2f5bdaa447fff': 1,
  '8a2f5bd822a7fff': 1.14,
  '8a2f5bd9470ffff': 1.75,
  '8a2f5bd8496ffff': 1,
  '8a2f5bd8118ffff': 1,
  '8a2f5bdaa387fff': 1,
  '8a2f5bd8c94ffff': 1,
  '8a2f5bd80437fff': 1,
  '8a2f5bd95a4ffff': 1.33,
  '8a2f5bd8e94ffff': 1,
  '8a2f5bd8e827fff': 1,
  '8a2f5bd836e7fff': 1,
  '8a2f5bd8eb0ffff': 1.59,
  '8a2f5bd9ca07fff': 1.28,
  '8a2f5bd8062ffff': 2.56,
  '8a2f5bd80c17fff': 1,
  '8a2f5bd80c1ffff': 1,
  '8a2f5bdb1417fff': 2,
  '8a2f5bd9cd2ffff': 1.44,
  '8a2f5bdb182ffff': 1.67,
  '8a2f5bdb1877fff': 1.14,
  '8a2f5bd861a7fff': 1.13,
  '8a2f5bdb1c57fff': 1.39,
  '8a2f5bd816affff': 1.47,
  '8a2f5bdb3a77fff': 1.3,
  '8a2f5bda2447fff': 1.06,
  '8a2f5bd86b9ffff': 1,
  '8a2f5bd8400ffff': 1,
  '8a2f5bdb3277fff': 1.2,
  '8a2f5bd816d7fff': 1.13,
  '8a2f5bd91b17fff': 1.11,
  '8a2f5bd80557fff': 2,
  '8a2f5bd80ce7fff': 1,
  '8a2f5bd94a17fff': 1.17,
  '8a2f5bd94327fff': 1,
  '8a2f5bd94a9ffff': 1.2,
  '8a2f5bd862affff': 1,
  '8a2f5bd86d47fff': 1,
  '8a2f5bd854a7fff': 1.2,
  '8a2f5bd82107fff': 1.4,
  '8a2f5bda2c57fff': 1,
  '8a2f5bd83757fff': 1.72,
  '8a2f5bd8375ffff': 1.88,
  '8a2f5bd837b7fff': 1,
  '8a2f5bd83707fff': 1,
  '8a2f5bd8370ffff': 1,
  '8a2f5bd830dffff': 1,
  '8a2f5bd8362ffff': 1.38,
  '8a2f5bd832b7fff': 2.52,
  '8a2f5bd832a7fff': 1,
  '8a2f5bd83287fff': 1.18,
  '8a2f5bd83747fff': 1.39,
  '8a2f5bd834c7fff': 1.2,
  '8a2f5bdb105ffff': 1.31,
  '8a2f5bd860a7fff': 1,
  '8a2f5bd98977fff': 1,
  '8a2f5bd9da27fff': 1,
  '8a2f5bd9db5ffff': 1.14,
  '8a2f5bd9d8e7fff': 1.12,
  '8a2f5bd9d837fff': 1,
  '8a2f5bd8e4b7fff': 1,
  '8a2f5bd9db67fff': 1,
  '8a2f5bd9dd07fff': 1.67,
  '8a2f5bd9d957fff': 1,
  '8a2f5bdaaac7fff': 1.13,
  '8a2f5bda3647fff': 1,
  '8a2f5bd86a57fff': 1,
  '8a2f5bd85d87fff': 1,
  '8a2f5bd86777fff': 1,
  '8a2f5bdb1c07fff': 1.06,
  '8a2f5bd9406ffff': 1.15,
  '8a2f5bda2047fff': 1,
  '8a2f5bda24c7fff': 1.25,
  '8a2f5bd9c88ffff': 1,
  '8a2f5bdaa32ffff': 1.2,
  '8a2f5bd823b7fff': 1.32,
  '8a2f5bd8c6effff': 1,
  '8a2f5bd8409ffff': 1,
  '8a2f5bd8446ffff': 1,
  '8a2f5bd80db7fff': 1.57,
  '8a2f5bd86aeffff': 1,
  '8a2f5bdb1757fff': 1,
  '8a2f5bd84c1ffff': 1,
  '8a2f5bd9c3a7fff': 1,
  '8a2f5bd9c047fff': 1,
  '8a2f5bd9c04ffff': 1.13,
  '8a2f5bd835b7fff': 2,
  '8a2f5bd83597fff': 1.5,
  '8a2f5bd9c087fff': 2,
  '8a2f5bd843affff': 1.13,
  '8a2f5bd8431ffff': 1.17,
  '8a2f5bd84637fff': 1,
  '8a2f5bd8291ffff': 1,
  '8a2f5bd83457fff': 1.16,
  '8a2f5bd9c777fff': 1,
  '8a2f5bdb1067fff': 1,
  '8a2f5bd80b17fff': 1,
  '8a2f5bd8561ffff': 1.48,
  '8a2f5bd80c47fff': 1.13,
  '8a2f5bd80c77fff': 1.08,
  '8a2f5bd808b7fff': 1.08,
  '8a2f5bd80c07fff': 1,
  '8a2f5bd8419ffff': 1,
  '8a2f5bd841affff': 1,
  '8a2f5bdb336ffff': 1,
  '8a2f5bd8002ffff': 1,
  '8a2f5bd85c87fff': 1,
  '8a2f5bd80887fff': 1,
  '8a2f5bd80d5ffff': 1.59,
  '8a2f5bd8491ffff': 1,
  '8a2f5bd82577fff': 1.29,
  '8a2f5bdb18d7fff': 1.04,
  '8a2f5bda2c5ffff': 1,
  '8a2f5bd85a47fff': 1,
  '8a2f5bdae697fff': 1,
  '8a2f5bdaa397fff': 1,
  '8a2f5bd81b8ffff': 1.13,
  '8a2f5bd8549ffff': 1,
  '8a2f5bd85caffff': 1,
  '8a2f5bd956cffff': 1.3,
  '8a2f5bd8205ffff': 2,
  '8a2f5bd820effff': 1,
  '8a2f5bd9e94ffff': 1,
  '8a2f5bd91747fff': 1,
  '8a2f5bdb19a7fff': 2.13,
  '8a2f5bd81267fff': 1,
  '8a2f5bd8c76ffff': 1,
  '8a2f5bdaa0d7fff': 1,
  '8a2f5bd86237fff': 1.83,
  '8a2f5bd86217fff': 1.21,
  '8a2f5bd828cffff': 1.4,
  '8a2f5bd9404ffff': 1,
  '8a2f5bd9182ffff': 1,
  '8a2f5bd99167fff': 1.1,
  '8a2f5bd9c6cffff': 1.67,
  '8a2f5bd8239ffff': 1,
  '8a2f5bd958d7fff': 1,
  '8a2f5bd8188ffff': 1.08,
  '8a2f5bd81147fff': 1.09,
  '8a2f5bd81d4ffff': 1.05,
  '8a2f5bd856dffff': 1.7,
  '8a2f5bd856a7fff': 1,
  '8a2f5bd940e7fff': 1,
  '8a2f5bdaa7a7fff': 1,
  '8a2f5bdaa42ffff': 1.77,
  '8a2f5bd8535ffff': 1,
  '8a2f5bd85357fff': 1,
  '8a2f5bdaa797fff': 1,
  '8a2f5bd8515ffff': 1.38,
  '8a2f5bdaa557fff': 1.2,
  '8a2f5bdaadaffff': 1,
  '8a2f5bdaa6d7fff': 1,
  '8a2f5bd8e347fff': 1,
  '8a2f5bd81687fff': 1,
  '8a2f5bd8eca7fff': 1.75,
  '8a2f5bd828e7fff': 1.14,
  '8a2f5bd83037fff': 1.5,
  '8a2f5bd8168ffff': 1.26,
  '8a2f5bd8a58ffff': 1,
  '8a2f5bd857affff': 1.35,
  '8a2f5bd8578ffff': 1,
  '8a2f5bd85637fff': 1,
  '8a2f5bd9c427fff': 1.25,
  '8a2f5bd9c42ffff': 1.44,
  '8a2f5bd8e597fff': 1.77,
  '8a2f5bd8ec97fff': 1,
  '8a2f5bdb3347fff': 1.75,
  '8a2f5bd8c737fff': 1.25,
  '8a2f5bd8635ffff': 1,
  '8a2f5bd9c3b7fff': 1,
  '8a2f5bd8046ffff': 1.29,
  '8a2f5bd8e4cffff': 1,
  '8a2f5bd810e7fff': 1.29,
  '8a2f5bd86937fff': 3.58,
  '8a2f5bdb3b37fff': 1,
  '8a2f5bdb3b17fff': 1.57,
  '8a2f5bd98b9ffff': 1.06,
  '8a2f5bd9816ffff': 1,
  '8a2f5bd8131ffff': 1.2,
  '8a2f5bd98957fff': 1.25,
  '8a2f5bda2677fff': 1.15,
  '8a2f5bd8292ffff': 1.1,
  '8a2f5bda26c7fff': 1,
  '8a2f5bd84d67fff': 1,
  '8a2f5bd84707fff': 1,
  '8a2f5bda2717fff': 1,
  '8a2f5bd84d47fff': 1,
  '8a2f5bd9d087fff': 1.29,
  '8a2f5bd9d017fff': 1,
  '8a2f5bdb150ffff': 1.27,
  '8a2f5bd86507fff': 1.36,
  '8a2f5bd8652ffff': 1.2,
  '8a2f5bd86517fff': 1.33,
  '8a2f5bd8398ffff': 1,
  '8a2f5bd8165ffff': 1,
  '8a2f5bd8e077fff': 1.36,
  '8a2f5bd8c747fff': 1.25,
  '8a2f5bd804a7fff': 1,
  '8a2f5bd86cdffff': 1.35,
  '8a2f5bd86577fff': 1.44,
  '8a2f5bd8650ffff': 1.57,
  '8a2f5bd8651ffff': 1.6,
  '8a2f5bdb1d57fff': 1,
  '8a2f5bd8542ffff': 1,
  '8a2f5bd9510ffff': 1.76,
  '8a2f5bd860cffff': 1.17,
  '8a2f5bd813affff': 1,
  '8a2f5bd8e9b7fff': 1,
  '8a2f5bd81197fff': 1,
  '8a2f5bd81ccffff': 1,
  '8a2f5bd81cc7fff': 1,
  '8a2f5bd810cffff': 1,
  '8a2f5bd8584ffff': 1,
  '8a2f5bda2317fff': 1,
  '8a2f5bda23b7fff': 1,
  '8a2f5bdaa047fff': 1,
  '8a2f5bdaa057fff': 1,
  '8a2f5bd99c1ffff': 1.09,
  '8a2f5bd8a587fff': 1,
  '8a2f5bd94707fff': 1.08,
  '8a2f5bd91307fff': 1.25,
  '8a2f5bd81457fff': 1.35,
  '8a2f5bd813a7fff': 1,
  '8a2f5bd81057fff': 1,
  '8a2f5bd83b9ffff': 1,
  '8a2f5bd8316ffff': 1.2,
  '8a2f5bd81727fff': 1,
  '8a2f5bd81737fff': 1,
  '8a2f5bd83197fff': 1,
  '8a2f5bd831affff': 1,
  '8a2f5bd8221ffff': 1,
  '8a2f5bd86a17fff': 1.29,
  '8a2f5bd8e5affff': 1.14,
  '8a2f5bd806f7fff': 1.59,
  '8a2f5bdb1c8ffff': 1.17,
  '8a2f5bd818b7fff': 2,
  '8a2f5bdaa147fff': 1.22,
  '8a2f5bd8128ffff': 1,
  '8a2f5bd812f7fff': 1,
  '8a2f5bdab78ffff': 1,
  '8a2f5bdb3b6ffff': 1.57,
  '8a2f5bd85aeffff': 1,
  '8a2f5bd85ac7fff': 1.06,
  '8a2f5bd85acffff': 1.67,
  '8a2f5bdaad57fff': 1,
  '8a2f5bdaad0ffff': 1,
  '8a2f5bd8c007fff': 1.65,
  '8a2f5bd90077fff': 1,
  '8a2f5bd90b9ffff': 1,
  '8a2f5bd90aa7fff': 1,
  '8a2f5bd90b97fff': 1,
  '8a2f5bd8129ffff': 1,
  '8a2f5bd8ca27fff': 1,
  '8a2f5bd9589ffff': 1.1,
  '8a2f5bd95217fff': 1,
  '8a2f5bd94b47fff': 1,
  '8a2f5bd958b7fff': 1,
  '8a2f5bd8271ffff': 1.4,
  '8a2f5bdb1977fff': 1,
  '8a2f5bdb190ffff': 1,
  '8a2f5bd8c82ffff': 1.39,
  '8a2f5bd842cffff': 1.39,
  '8a2f5bd855b7fff': 1,
  '8a2f5bd8368ffff': 1.05,
  '8a2f5bda209ffff': 1.1,
  '8a2f5bd9c057fff': 1.17,
  '8a2f5bd9cb1ffff': 1.11,
  '8a2f5bd9cb0ffff': 1.33,
  '8a2f5bd8554ffff': 1.39,
  '8a2f5bd85ab7fff': 1,
  '8a2f5bd958a7fff': 1.25,
  '8a2f5bdaac87fff': 1.33,
  '8a2f5bdab777fff': 1.2,
  '8a2f5bdab607fff': 1.18,
  '8a2f5bd83b97fff': 1.17,
  '8a2f5bd80a07fff': 1.2,
  '8a2f5bd8035ffff': 1.3,
  '8a2f5bd8a9b7fff': 1,
  '8a2f5bd8c397fff': 1,
  '8a2f5bd98827fff': 1,
  '8a2f5bd85557fff': 1,
  '8a2f5bd8c017fff': 1.31,
  '8a2f5bd83007fff': 1.17,
  '8a2f5bd86697fff': 1,
  '8a2f5bd95d1ffff': 1.32,
  '8a2f5bd9cb17fff': 1.44,
  '8a2f5bd9d81ffff': 1,
  '8a2f5bd9d8d7fff': 1.76,
  '8a2f5bd9d8affff': 1,
  '8a2f5bd9d8b7fff': 1,
  '8a2f5bdaa22ffff': 1,
  '8a2f5bdb1447fff': 1.33,
  '8a2f5bda2187fff': 1.06,
  '8a2f5bd83ae7fff': 1.4,
  '8a2f5bd83337fff': 1,
  '8a2f5bd91a07fff': 1,
  '8a2f5bd91a27fff': 1,
  '8a2f5bd8a857fff': 1,
  '8a2f5bd86037fff': 1,
  '8a2f5bd9d0b7fff': 1,
  '8a2f5bd9d097fff': 1.55,
  '8a2f5bd9d54ffff': 1.4,
  '8a2f5bd8eb4ffff': 1,
  '8a2f5bd8509ffff': 1.46,
  '8a2f5bd85097fff': 1.26,
  '8a2f5bd81c0ffff': 1,
  '8a2f5bdb3aaffff': 1.27,
  '8a2f5bd80587fff': 1,
  '8a2f5bd81c17fff': 1,
  '8a2f5bd8dd97fff': 1.17,
  '8a2f5bd82b27fff': 1,
  '8a2f5bd82967fff': 1.33,
  '8a2f5bd8296ffff': 1,
  '8a2f5bd9d6dffff': 1.13,
  '8a2f5bd9d6f7fff': 1,
  '8a2f5bd9d6c7fff': 1,
  '8a2f5bd8674ffff': 1.11,
  '8a2f5bd862b7fff': 1.14,
  '8a2f5bd86297fff': 1.83,
  '8a2f5bd86397fff': 1,
  '8a2f5bd863b7fff': 1,
  '8a2f5bd80d8ffff': 1.11,
  '8a2f5bd856e7fff': 1.21,
  '8a2f5bd8e85ffff': 2,
  '8a2f5bd9422ffff': 1,
  '8a2f5bd819affff': 1.08,
  '8a2f5bd8229ffff': 1,
  '8a2f5bd8266ffff': 1.2,
  '8a2f5bd9c937fff': 1,
  '8a2f5bd8c9affff': 1,
  '8a2f5bd8c99ffff': 1.08,
  '8a2f5bd8c8b7fff': 1,
  '8a2f5bd9d567fff': 1.55,
  '8a2f5bd86ceffff': 1,
  '8a2f5bd860effff': 1.06,
  '8a2f5bd8e4a7fff': 1,
  '8a2f5bd8e4affff': 1,
  '8a2f5bd86727fff': 1,
  '8a2f5bd845affff': 1,
  '8a2f5bd801b7fff': 1,
  '8a2f5bd816f7fff': 1,
  '8a2f5bd95817fff': 1.06,
  '8a2f5bd95a67fff': 1,
  '8a2f5bd822c7fff': 1.13,
  '8a2f5bd822dffff': 1,
  '8a2f5bd834dffff': 1,
  '8a2f5bd834cffff': 1,
  '8a2f5bdb10e7fff': 1.29,
  '8a2f5bd95767fff': 1,
  '8a2f5bd8c8a7fff': 1,
  '8a2f5bd83b17fff': 2.28,
  '8a2f5bd8384ffff': 1.4,
  '8a2f5bd9dd0ffff': 1.33,
  '8a2f5bd8a877fff': 1,
  '8a2f5bd81657fff': 1,
  '8a2f5bdb528ffff': 1,
  '8a2f5bdb529ffff': 1,
  '8a2f5bd88d37fff': 1,
  '8a2f5bd8180ffff': 1,
  '8a2f5bd83997fff': 1,
  '8a2f5bd9c75ffff': 2.96,
  '8a2f5bd84c67fff': 1.25,
  '8a2f5bd84c47fff': 2,
  '8a2f5bd84c77fff': 1.25,
  '8a2f5bd9cb47fff': 1.08,
  '8a2f5bd8c4cffff': 1,
  '8a2f5bd8c39ffff': 1,
  '8a2f5bd8eb47fff': 1,
  '8a2f5bd86acffff': 1.74,
  '8a2f5bd88d0ffff': 2.57,
  '8a2f5bd88d77fff': 1,
  '8a2f5bd90887fff': 1,
  '8a2f5bdb1b67fff': 1.43,
  '8a2f5bd95af7fff': 1.89,
  '8a2f5bd84857fff': 1,
  '8a2f5bd8485ffff': 1,
  '8a2f5bd91d27fff': 1,
  '8a2f5bd99b07fff': 1.16,
  '8a2f5bd99b37fff': 1.13,
  '8a2f5bd9916ffff': 1.13,
  '8a2f5bd8ea6ffff': 1,
  '8a2f5bd82c1ffff': 1,
  '8a2f5bd8c56ffff': 1,
  '8a2f5bdb199ffff': 1.15,
  '8a2f5bdaa3affff': 1.26,
  '8a2f5bdb36dffff': 3,
  '8a2f5bd804f7fff': 1,
  '8a2f5bd804c7fff': 1.17,
  '8a2f5bd8252ffff': 1.33,
  '8a2f5bd850a7fff': 1,
  '8a2f5bd8c2affff': 2,
  '8a2f5bd9cc8ffff': 1.06,
  '8a2f5bd91baffff': 1,
  '8a2f5bd9521ffff': 1.2,
  '8a2f5bd9134ffff': 1.21,
  '8a2f5bd952e7fff': 1.33,
  '8a2f5bd9cb07fff': 1.3,
  '8a2f5bd9cb37fff': 1.13,
  '8a2f5bd8e9a7fff': 2,
  '8a2f5bd84ba7fff': 1,
  '8a2f5bdaa3a7fff': 1,
  '8a2f5bdaa0f7fff': 1,
  '8a2f5bd81817fff': 1,
  '8a2f5bd8c92ffff': 1,
  '8a2f5bdaa217fff': 1.33,
  '8a2f5bd88c9ffff': 1.06,
  '8a2f5bd88c87fff': 1,
  '8a2f5bd84687fff': 1.25,
  '8a2f5bd8a0b7fff': 1,
  '8a2f5bd9da87fff': 1.35,
  '8a2f5bdae6d7fff': 1,
  '8a2f5bd83217fff': 1.13,
  '8a2f5bd9db6ffff': 1,
  '8a2f5bd958dffff': 1.67,
  '8a2f5bd9dd17fff': 1,
  '8a2f5bd9dd1ffff': 1.27,
  '8a2f5bd9d447fff': 1,
  '8a2f5bd9d467fff': 1,
  '8a2f5bd8230ffff': 1.56,
  '8a2f5bd9d267fff': 1.11,
  '8a2f5bd85727fff': 1,
  '8a2f5bd85707fff': 1,
  '8a2f5bd85627fff': 1,
  '8a2f5bd84097fff': 1,
  '8a2f5bd84547fff': 1,
  '8a2f5bd84577fff': 1,
  '8a2f5bd84c8ffff': 1,
  '8a2f5bd84cf7fff': 1,
  '8a2f5bd84467fff': 1.33,
  '8a2f5bd844a7fff': 1,
  '8a2f5bd844b7fff': 1,
  '8a2f5bd8694ffff': 1,
  '8a2f5bd8685ffff': 1,
  '8a2f5bd86bb7fff': 1.5,
  '8a2f5bd8442ffff': 2,
  '8a2f5bd84417fff': 1,
  '8a2f5bd86847fff': 1,
  '8a2f5bd86877fff': 1.33,
  '8a2f5bdb1ab7fff': 1.44,
  '8a2f5bd84407fff': 1,
  '8a2f5bd82c57fff': 1,
  '8a2f5bd82c47fff': 1.5,
  '8a2f5bd91717fff': 1.6,
  '8a2f5bdb3b97fff': 1.44,
  '8a2f5bd95b17fff': 1,
  '8a2f5bdb1497fff': 1.3,
  '8a2f5bd83ac7fff': 1,
  '8a2f5bdb1077fff': 1.72,
  '8a2f5bdb154ffff': 1.75,
  '8a2f5bd80157fff': 1,
  '8a2f5bd814effff': 1.71,
  '8a2f5bd82a67fff': 1.67,
  '8a2f5bdb3477fff': 1,
  '8a2f5bd838f7fff': 1.17,
  '8a2f5bd86da7fff': 1.42,
  '8a2f5bdb3a4ffff': 1.69,
  '8a2f5bd85457fff': 1,
  '8a2f5bdab08ffff': 1,
  '8a2f5bd84237fff': 1.25,
  '8a2f5bd84297fff': 1,
  '8a2f5bdaa657fff': 1,
  '8a2f5bd84207fff': 1,
  '8a2f5bd8420ffff': 1.33,
  '8a2f5bdab087fff': 1,
  '8a2f5bd98a8ffff': 1.29,
  '8a2f5bd95ae7fff': 1,
  '8a2f5bd9461ffff': 1,
  '8a2f5bd946e7fff': 1,
  '8a2f5bd94617fff': 1,
  '8a2f5bd8006ffff': 1.13,
  '8a2f5bd9d58ffff': 1,
  '8a2f5bd95d0ffff': 1.79,
  '8a2f5bd94b2ffff': 1,
  '8a2f5bdb334ffff': 1,
  '8a2f5bd94b77fff': 1,
  '8a2f5bd94a4ffff': 1,
  '8a2f5bd95d07fff': 1,
  '8a2f5bd94a67fff': 1,
  '8a2f5bdb34f7fff': 1,
  '8a2f5bd95da7fff': 1,
  '8a2f5bd81a77fff': 1.75,
  '8a2f5bd81a67fff': 1,
  '8a2f5bd8135ffff': 1,
  '8a2f5bda2207fff': 1.43,
  '8a2f5bd826effff': 1,
  '8a2f5bd8048ffff': 1.1,
  '8a2f5bd8158ffff': 1,
  '8a2f5bd84aaffff': 1,
  '8a2f5bd84a8ffff': 1,
  '8a2f5bd84117fff': 1,
  '8a2f5bd840e7fff': 1,
  '8a2f5bd95a8ffff': 1.25,
  '8a2f5bdab717fff': 1.33,
  '8a2f5bd8ea17fff': 1,
  '8a2f5bd8ea07fff': 1,
  '8a2f5bd85a4ffff': 1.25,
  '8a2f5bd909affff': 1.08,
  '8a2f5bdb128ffff': 1.06,
  '8a2f5bdb12d7fff': 1,
  '8a2f5bd80daffff': 1.29,
  '8a2f5bdb1a17fff': 1.13,
  '8a2f5bdaa2affff': 1,
  '8a2f5bdab4dffff': 1,
  '8a2f5bd8c42ffff': 1.11,
  '8a2f5bd8c41ffff': 1.43,
  '8a2f5bd9902ffff': 1.13,
  '8a2f5bd9894ffff': 2.25,
  '8a2f5bd9dc2ffff': 1.25,
  '8a2f5bd81b37fff': 1.09,
  '8a2f5bdaa417fff': 1.25,
  '8a2f5bd84387fff': 1,
  '8a2f5bdaa4affff': 1.25,
  '8a2f5bdaa49ffff': 1.1,
  '8a2f5bd8444ffff': 1.2,
  '8a2f5bd84727fff': 1,
  '8a2f5bd81b07fff': 1,
  '8a2f5bd9904ffff': 1,
  '8a2f5bdb3b1ffff': 1.25,
  '8a2f5bd8c5a7fff': 1.2,
  '8a2f5bd8e80ffff': 1.17,
  '8a2f5bd83627fff': 1,
  '8a2f5bd8339ffff': 1,
  '8a2f5bd8374ffff': 1.23,
  '8a2f5bd95007fff': 1,
  '8a2f5bdb10dffff': 1,
  '8a2f5bdb1457fff': 1.35,
  '8a2f5bdb5347fff': 2.14,
  '8a2f5bdb534ffff': 1.44,
  '8a2f5bdb536ffff': 1.09,
  '8a2f5bd8c09ffff': 1,
  '8a2f5bd9c757fff': 1,
  '8a2f5bdab40ffff': 1,
  '8a2f5bd85947fff': 1,
  '8a2f5bdaad07fff': 1,
  '8a2f5bd8534ffff': 1,
  '8a2f5bd85327fff': 1,
  '8a2f5bd85aa7fff': 1,
  '8a2f5bd85a87fff': 1,
  '8a2f5bd81067fff': 1.42,
  '8a2f5bd81b97fff': 1.21,
  '8a2f5bd98d6ffff': 1.95,
  '8a2f5bd9d1b7fff': 1,
  '8a2f5bd8404ffff': 1.09,
  '8a2f5bd8406ffff': 1,
  '8a2f5bd84347fff': 1,
  '8a2f5bdb1427fff': 1.09,
  '8a2f5bd9d2d7fff': 1.14,
  '8a2f5bd96aeffff': 1.25,
  '8a2f5bd96ae7fff': 1,
  '8a2f5bd96ac7fff': 1,
  '8a2f5bd88db7fff': 2,
  '8a2f5bdab767fff': 1,
  '8a2f5bd80477fff': 1.39,
  '8a2f5bda22c7fff': 1.11,
  '8a2f5bd84967fff': 1,
  '8a2f5bd8301ffff': 1,
  '8a2f5bd83017fff': 1,
  '8a2f5bd83187fff': 1,
  '8a2f5bd912affff': 1,
  '8a2f5bd91247fff': 1,
  '8a2f5bd912f7fff': 1,
  '8a2f5bd84827fff': 1,
  '8a2f5bd85ba7fff': 1,
  '8a2f5bdae6dffff': 1,
  '8a2f5bd814a7fff': 1,
  '8a2f5bdb1d0ffff': 1,
  '8a2f5bd85ceffff': 1,
  '8a2f5bd85c57fff': 1,
  '8a2f5bd82d9ffff': 1,
  '8a2f5bd84627fff': 1,
  '8a2f5bd81d5ffff': 1,
  '8a2f5bd8cdb7fff': 1,
  '8a2f5bd9d4c7fff': 1.16,
  '8a2f5bd86557fff': 2.2,
  '8a2f5bd99d9ffff': 1.29,
  '8a2f5bdb1787fff': 1,
  '8a2f5bd80897fff': 1.06,
  '8a2f5bd8a88ffff': 1,
  '8a2f5bd8c757fff': 1,
  '8a2f5bd9106ffff': 1.17,
  '8a2f5bd9e96ffff': 1,
  '8a2f5bd8c597fff': 1,
  '8a2f5bd86a47fff': 1.17,
  '8a2f5bd8458ffff': 1,
  '8a2f5bd8248ffff': 1,
  '8a2f5bd91b27fff': 1.25,
  '8a2f5bda221ffff': 1.25,
  '8a2f5bda222ffff': 1,
  '8a2f5bda22a7fff': 1.33,
  '8a2f5bd80d77fff': 1,
  '8a2f5bd9c537fff': 1.4,
  '8a2f5bd8ac17fff': 1,
  '8a2f5bd8ad87fff': 1,
  '8a2f5bd83497fff': 1,
  '8a2f5bd807b7fff': 1.33,
  '8a2f5bd80787fff': 1,
  '8a2f5bd84c0ffff': 2,
  '8a2f5bd86857fff': 1.2,
  '8a2f5bd84c6ffff': 1.64,
  '8a2f5bd868e7fff': 1,
  '8a2f5bd869a7fff': 1,
  '8a2f5bd8e2dffff': 1.2,
  '8a2f5bd9554ffff': 1.23,
  '8a2f5bd82887fff': 1,
  '8a2f5bd83bb7fff': 1.08,
  '8a2f5bd9110ffff': 1.33,
  '8a2f5bd8a9affff': 1.71,
  '8a2f5bd8a98ffff': 1.07,
  '8a2f5bda2c97fff': 1.25,
  '8a2f5bd91b97fff': 1,
  '8a2f5bd9d90ffff': 1,
  '8a2f5bd9d947fff': 1,
  '8a2f5bd9d757fff': 1,
  '8a2f5bd8c0cffff': 1,
  '8a2f5bd80d2ffff': 1,
  '8a2f5bd80d0ffff': 1.13,
  '8a2f5bd99917fff': 1.13,
  '8a2f5bd8422ffff': 1,
  '8a2f5bd84277fff': 1,
  '8a2f5bd8559ffff': 1,
  '8a2f5bd8670ffff': 1,
  '8a2f5bd9c297fff': 1.17,
  '8a2f5bd91b5ffff': 1.12,
  '8a2f5bd91b4ffff': 1.23,
  '8a2f5bd8c2b7fff': 1.31,
  '8a2f5bd8c2a7fff': 1,
  '8a2f5bd8c287fff': 1,
  '8a2f5bd9562ffff': 1.47,
  '8a2f5bd9571ffff': 1,
  '8a2f5bd956a7fff': 1,
  '8a2f5bd9572ffff': 1.61,
  '8a2f5bdb3c4ffff': 1.15,
  '8a2f5bdb5207fff': 1.25,
  '8a2f5bd8e39ffff': 1.6,
  '8a2f5bd8e387fff': 1.17,
  '8a2f5bd8a90ffff': 1,
  '8a2f5bd8e29ffff': 1,
  '8a2f5bd95d67fff': 1.09,
  '8a2f5bdb1027fff': 1,
  '8a2f5bdb111ffff': 1.25,
  '8a2f5bd8110ffff': 1,
  '8a2f5bd8c71ffff': 1.22,
  '8a2f5bd958effff': 1.67,
  '8a2f5bd8639ffff': 1.5,
  '8a2f5bdb1c87fff': 1.68,
  '8a2f5bda2c9ffff': 1,
  '8a2f5bdb3a5ffff': 1.3,
  '8a2f5bd86cb7fff': 1,
  '8a2f5bd8332ffff': 1,
  '8a2f5bd8ecf7fff': 1.09,
  '8a2f5bd80347fff': 1.25,
  '8a2f5bd8ec47fff': 1.2,
  '8a2f5bd81ceffff': 1.41,
  '8a2f5bd80377fff': 1,
  '8a2f5bd9dcaffff': 1.38,
  '8a2f5bd81477fff': 1,
  '8a2f5bd8038ffff': 1.33,
  '8a2f5bd834a7fff': 1.17,
  '8a2f5bd9d86ffff': 1.09,
  '8a2f5bd8c777fff': 1,
  '8a2f5bda22cffff': 1.31,
  '8a2f5bd8546ffff': 1,
  '8a2f5bd84d37fff': 1.17,
  '8a2f5bdb1a4ffff': 1.22,
  '8a2f5bd836a7fff': 1,
  '8a2f5bd83617fff': 1.31,
  '8a2f5bd83c27fff': 2,
  '8a2f5bd9c617fff': 1,
  '8a2f5bd9188ffff': 1,
  '8a2f5bd8321ffff': 1.8,
  '8a2f5bd83607fff': 1,
  '8a2f5bd8e6d7fff': 1,
  '8a2f5bdb30b7fff': 1,
  '8a2f5bdaa4c7fff': 1,
  '8a2f5bd81337fff': 1,
  '8a2f5bd8ac8ffff': 1,
  '8a2f5bd8c1b7fff': 1,
  '8a2f5bd86ccffff': 1.85,
  '8a2f5bd95187fff': 1.1,
  '8a2f5bdb1aaffff': 1.42,
  '8a2f5bd9db77fff': 1,
  '8a2f5bd8e61ffff': 1,
  '8a2f5bda269ffff': 1,
  '8a2f5bd81ba7fff': 1.06,
  '8a2f5bd8e927fff': 1,
  '8a2f5bdb124ffff': 1,
  '8a2f5bdaa0affff': 1,
  '8a2f5bdaa09ffff': 1,
  '8a2f5bdaac97fff': 1,
  '8a2f5bd98807fff': 1.11,
  '8a2f5bd9d5b7fff': 1,
  '8a2f5bd95b77fff': 1,
  '8a2f5bd84cdffff': 1,
  '8a2f5bd8454ffff': 1,
  '8a2f5bd8a8a7fff': 1,
  '8a2f5bd8e59ffff': 1.44,
  '8a2f5bd8e487fff': 1,
  '8a2f5bd86d5ffff': 1.13,
  '8a2f5bd91a9ffff': 1.09,
  '8a2f5bd9d707fff': 1,
  '8a2f5bdaa4cffff': 2,
  '8a2f5bd85adffff': 1.25,
  '8a2f5bd95907fff': 1.07,
  '8a2f5bdb382ffff': 1,
  '8a2f5bdb31affff': 1,
  '8a2f5bd8a18ffff': 1,
  '8a2f5bd8a017fff': 1,
  '8a2f5bd8a197fff': 1,
  '8a2f5bd9cb57fff': 1.33,
  '8a2f5bd8a477fff': 1,
  '8a2f5bd9c18ffff': 1,
  '8a2f5bda366ffff': 1.5,
  '8a2f5bd8086ffff': 1,
  '8a2f5bd80b37fff': 1,
  '8a2f5bd8084ffff': 1,
  '8a2f5bd90d1ffff': 1,
  '8a2f5bdabca7fff': 1.07,
  '8a2f5bd86b47fff': 1,
  '8a2f5bd847a7fff': 1,
  '8a2f5bd8079ffff': 1.8,
  '8a2f5bdb1a0ffff': 1,
  '8a2f5bd98ab7fff': 1,
  '8a2f5bd8201ffff': 1.59,
  '8a2f5bdb102ffff': 1.14,
  '8a2f5bdb100ffff': 1.2,
  '8a2f5bd9c0effff': 1,
  '8a2f5bd8ec07fff': 1.4,
  '8a2f5bdb368ffff': 1,
  '8a2f5bd8c887fff': 1,
  '8a2f5bd8655ffff': 1.25,
  '8a2f5bd80217fff': 1,
  '8a2f5bd802f7fff': 1,
  '8a2f5bd856cffff': 1,
  '8a2f5bd9579ffff': 1,
  '8a2f5bd91c87fff': 1,
  '8a2f5bd9570ffff': 1.18,
  '8a2f5bd81087fff': 1,
  '8a2f5bd85247fff': 1.44,
  '8a2f5bdaa097fff': 1,
  '8a2f5bd95c07fff': 1.24,
  '8a2f5bda341ffff': 1.17,
  '8a2f5bd8ecdffff': 1,
  '8a2f5bdb354ffff': 1,
  '8a2f5bd91887fff': 1.4,
  '8a2f5bd837a7fff': 1,
  '8a2f5bd96a77fff': 1,
  '8a2f5bd96a47fff': 1.1,
  '8a2f5bd96a57fff': 1,
  '8a2f5bd90d17fff': 1,
  '8a2f5bd9d88ffff': 1,
  '8a2f5bd9d887fff': 1,
  '8a2f5bd86c6ffff': 1,
  '8a2f5bd9018ffff': 1,
  '8a2f5bd86427fff': 1.18,
  '8a2f5bd863a7fff': 1,
  '8a2f5bd8c36ffff': 1,
  '8a2f5bd81b1ffff': 1,
  '8a2f5bd9ca4ffff': 1.08,
  '8a2f5bd9dda7fff': 1.56,
  '8a2f5bd9ca5ffff': 1.48,
  '8a2f5bdb364ffff': 1,
  '8a2f5bdb3667fff': 1.2,
  '8a2f5bd86767fff': 1,
  '8a2f5bd866effff': 1,
  '8a2f5bdb3acffff': 1.68,
  '8a2f5bd81627fff': 1.2,
  '8a2f5bd81607fff': 1,
  '8a2f5bd83977fff': 1,
  '8a2f5bdaa01ffff': 1,
  '8a2f5bdaa00ffff': 1.6,
  '8a2f5bd8e49ffff': 1,
  '8a2f5bd8e497fff': 1,
  '8a2f5bd86067fff': 1,
  '8a2f5bd85027fff': 1.17,
  '8a2f5bd84217fff': 1.24,
  '8a2f5bd84747fff': 1,
  '8a2f5bd84937fff': 1,
  '8a2f5bd8a8d7fff': 1.1,
  '8a2f5bdaa767fff': 1.18,
  '8a2f5bd8ecc7fff': 1,
  '8a2f5bd8394ffff': 1.23,
  '8a2f5bd9e967fff': 1,
  '8a2f5bd821a7fff': 1,
  '8a2f5bd940f7fff': 1,
  '8a2f5bd822cffff': 1.33,
  '8a2f5bd826a7fff': 1,
  '8a2f5bd81b67fff': 1,
  '8a2f5bd9d0a7fff': 1.25,
  '8a2f5bd8e65ffff': 1,
  '8a2f5bd946f7fff': 1.5,
  '8a2f5bd80acffff': 1,
  '8a2f5bd8579ffff': 1,
  '8a2f5bd80aa7fff': 1,
  '8a2f5bd80a2ffff': 1,
  '8a2f5bd854cffff': 1,
  '8a2f5bd854e7fff': 1,
  '8a2f5bd81d17fff': 1,
  '8a2f5bd80ac7fff': 1,
  '8a2f5bd80b5ffff': 1,
  '8a2f5bd80ae7fff': 1.07,
  '8a2f5bd80a67fff': 1,
  '8a2f5bd80aeffff': 1,
  '8a2f5bd80a4ffff': 1,
  '8a2f5bd81d2ffff': 1,
  '8a2f5bd94047fff': 1.14,
  '8a2f5bd83987fff': 1.95,
  '8a2f5bd84837fff': 1,
  '8a2f5bdb1667fff': 1.4,
  '8a2f5bd918f7fff': 1,
  '8a2f5bd854c7fff': 1,
  '8a2f5bd8541ffff': 1,
  '8a2f5bd91337fff': 1,
  '8a2f5bd9c2affff': 1.05,
  '8a2f5bd9cb97fff': 1,
  '8a2f5bd9c137fff': 1,
  '8a2f5bd828b7fff': 1,
  '8a2f5bd82997fff': 1,
  '8a2f5bd82d77fff': 1,
  '8a2f5bd8cd87fff': 1.06,
  '8a2f5bd8cd9ffff': 1,
  '8a2f5bd9d89ffff': 1.18,
  '8a2f5bd85d8ffff': 1,
  '8a2f5bd85257fff': 1,
  '8a2f5bdaa177fff': 1,
  '8a2f5bdb3787fff': 2.04,
  '8a2f5bd8c917fff': 1,
  '8a2f5bd8c91ffff': 1,
  '8a2f5bd9c387fff': 1,
  '8a2f5bdb1647fff': 1,
  '8a2f5bd8582ffff': 1.52,
  '8a2f5bd8e54ffff': 1.3,
  '8a2f5bd8e56ffff': 1.61,
  '8a2f5bd8e18ffff': 1,
  '8a2f5bd8e46ffff': 1.13,
  '8a2f5bd8e097fff': 1.3,
  '8a2f5bd8e947fff': 1,
  '8a2f5bd8e977fff': 1.4,
  '8a2f5bd8e957fff': 1.38,
  '8a2f5bd99baffff': 1,
  '8a2f5bd839b7fff': 1,
  '8a2f5bd806c7fff': 1,
  '8a2f5bd8c587fff': 1.22,
  '8a2f5bd91057fff': 1,
  '8a2f5bd91817fff': 1,
  '8a2f5bd8626ffff': 1.25,
  '8a2f5bdb36f7fff': 1.11,
  '8a2f5bd9c74ffff': 1,
  '8a2f5bdb520ffff': 1.14,
  '8a2f5bdb522ffff': 1.25,
  '8a2f5bd81647fff': 1,
  '8a2f5bd9dd6ffff': 1,
  '8a2f5bd9905ffff': 1,
  '8a2f5bdab0affff': 1.21,
  '8a2f5bdab0c7fff': 1,
  '8a2f5bdb3357fff': 1,
  '8a2f5bdb330ffff': 1.2,
  '8a2f5bd9d31ffff': 1,
  '8a2f5bd8e237fff': 1.11,
  '8a2f5bd8372ffff': 1,
  '8a2f5bd83d57fff': 1.2,
  '8a2f5bd9986ffff': 1.07,
  '8a2f5bd8a497fff': 1,
  '8a2f5bda2597fff': 1,
  '8a2f5bd95587fff': 1.2,
  '8a2f5bd82297fff': 1,
  '8a2f5bd822b7fff': 1.13,
  '8a2f5bd8cd37fff': 1,
  '8a2f5bd8ad1ffff': 1,
  '8a2f5bd9c557fff': 1,
  '8a2f5bdb1897fff': 1,
  '8a2f5bdb566ffff': 1,
  '8a2f5bdb1537fff': 1,
  '8a2f5bd8cc07fff': 1,
  '8a2f5bd8ccc7fff': 1.33,
  '8a2f5bd8e587fff': 1.2,
  '8a2f5bdb12cffff': 1.15,
  '8a2f5bd86967fff': 1,
  '8a2f5bd862effff': 1,
  '8a2f5bd85c1ffff': 1.14,
  '8a2f5bd85d97fff': 1,
  '8a2f5bd85c17fff': 1,
  '8a2f5bd85c4ffff': 1,
  '8a2f5bd851a7fff': 1,
  '8a2f5bdb184ffff': 1.06,
  '8a2f5bdb1b17fff': 1,
  '8a2f5bd99877fff': 1.09,
  '8a2f5bd82c07fff': 1,
  '8a2f5bd82897fff': 1,
  '8a2f5bd9cb27fff': 1.4,
  '8a2f5bd9c4e7fff': 1,
  '8a2f5bd9c4c7fff': 1,
  '8a2f5bd9eb67fff': 1,
  '8a2f5bd9eb6ffff': 1,
  '8a2f5bd9c6b7fff': 1,
  '8a2f5bd98d2ffff': 1,
  '8a2f5bd82287fff': 1,
  '8a2f5bd9d667fff': 1,
  '8a2f5bd94217fff': 1.33,
  '8a2f5bd85677fff': 1.13,
  '8a2f5bd8562ffff': 1.14,
  '8a2f5bd8575ffff': 1.44,
  '8a2f5bd8560ffff': 1,
  '8a2f5bd80c9ffff': 1,
  '8a2f5bd844cffff': 1,
  '8a2f5bd844c7fff': 1,
  '8a2f5bd8c95ffff': 1.59,
  '8a2f5bd9d34ffff': 1.17,
  '8a2f5bd833a7fff': 1,
  '8a2f5bd807affff': 1.2,
  '8a2f5bd8a507fff': 1,
  '8a2f5bd8a52ffff': 1,
  '8a2f5bd90d47fff': 1.25,
  '8a2f5bd8ec87fff': 1,
  '8a2f5bd81917fff': 1,
  '8a2f5bd9d547fff': 1,
  '8a2f5bd8e6affff': 1,
  '8a2f5bd9d08ffff': 1,
  '8a2f5bd99b9ffff': 1,
  '8a2f5bd99b87fff': 1,
  '8a2f5bd8684ffff': 1,
  '8a2f5bd8c427fff': 1,
  '8a2f5bd8a917fff': 1,
  '8a2f5bd988c7fff': 1.67,
  '8a2f5bd80517fff': 1.1,
  '8a2f5bd82a37fff': 1,
  '8a2f5bd9d68ffff': 1,
  '8a2f5bd8599ffff': 1,
  '8a2f5bdb1627fff': 1,
  '8a2f5bd80c57fff': 1,
  '8a2f5bda24cffff': 1,
  '8a2f5bdb326ffff': 1.15,
  '8a2f5bd86537fff': 1,
  '8a2f5bd86017fff': 1,
  '8a2f5bd84497fff': 1,
  '8a2f5bd86867fff': 1,
  '8a2f5bdb38cffff': 1.06,
  '8a2f5bdb386ffff': 1.25,
  '8a2f5bd80af7fff': 1.64,
  '8a2f5bd8e0effff': 1,
  '8a2f5bd9d4dffff': 1,
  '8a2f5bd9d4cffff': 1,
  '8a2f5bdaa167fff': 1,
  '8a2f5bd9c457fff': 1,
  '8a2f5bd9d847fff': 1,
  '8a2f5bda2757fff': 1,
  '8a2f5bd94397fff': 1.05,
  '8a2f5bd946effff': 1.16,
  '8a2f5bd90d2ffff': 1.2,
  '8a2f5bd98847fff': 1,
  '8a2f5bd84d5ffff': 1,
  '8a2f5bd80137fff': 1,
  '8a2f5bd80117fff': 1,
  '8a2f5bd80427fff': 1,
  '8a2f5bd80407fff': 1,
  '8a2f5bd80197fff': 1,
  '8a2f5bd805affff': 1,
  '8a2f5bd8051ffff': 1,
  '8a2f5bd8e68ffff': 1,
  '8a2f5bd9c56ffff': 1,
  '8a2f5bd8588ffff': 1.13,
  '8a2f5bd85a17fff': 1,
  '8a2f5bdb3607fff': 1,
  '8a2f5bdb3627fff': 1.6,
  '8a2f5bd81da7fff': 1.04,
  '8a2f5bdaaaaffff': 1.33,
  '8a2f5bd80a5ffff': 1.28,
  '8a2f5bd852a7fff': 1.09,
  '8a2f5bd91477fff': 1.5,
  '8a2f5bd91447fff': 1,
  '8a2f5bdb5657fff': 1,
  '8a2f5bdb5647fff': 1.5,
  '8a2f5bd8a8f7fff': 1.07,
  '8a2f5bd8cc67fff': 1,
  '8a2f5bd8cc77fff': 1.06,
  '8a2f5bd854effff': 1.33,
  '8a2f5bdaa7b7fff': 1,
  '8a2f5bd99d97fff': 1,
  '8a2f5bd9426ffff': 1,
  '8a2f5bd8322ffff': 1,
  '8a2f5bd8666ffff': 1.08,
  '8a2f5bd88937fff': 1,
  '8a2f5bd91a2ffff': 1,
  '8a2f5bd8c74ffff': 1,
  '8a2f5bd80597fff': 1,
  '8a2f5bd80487fff': 1,
  '8a2f5bd862cffff': 1,
  '8a2f5bd9c49ffff': 1,
  '8a2f5bd8456ffff': 1,
  '8a2f5bd8a56ffff': 1,
  '8a2f5bd8a547fff': 1,
  '8a2f5bd8ca8ffff': 1,
  '8a2f5bd8ada7fff': 1,
  '8a2f5bd8adaffff': 1,
  '8a2f5bd8ac1ffff': 1.08,
  '8a2f5bd8aceffff': 1,
  '8a2f5bd8ac6ffff': 1,
  '8a2f5bd8a107fff': 1,
  '8a2f5bd86827fff': 1.62,
  '8a2f5bd8eaf7fff': 1,
  '8a2f5bd8ead7fff': 1,
  '8a2f5bd91b1ffff': 1,
  '8a2f5bd8410ffff': 2.13,
  '8a2f5bd84107fff': 2.22,
  '8a2f5bd95707fff': 1.29,
  '8a2f5bd85987fff': 1,
  '8a2f5bd84227fff': 1,
  '8a2f5bd80d67fff': 1,
  '8a2f5bd81527fff': 1,
  '8a2f5bd8511ffff': 1,
  '8a2f5bd85997fff': 1,
  '8a2f5bd8425ffff': 1,
  '8a2f5bd86b6ffff': 1,
  '8a2f5bd8e447fff': 1,
  '8a2f5bd9d64ffff': 2,
  '8a2f5bd85417fff': 1,
  '8a2f5bd8379ffff': 1.14,
  '8a2f5bd84a27fff': 1,
  '8a2f5bd80d07fff': 1.11,
  '8a2f5bd80d27fff': 1,
  '8a2f5bda22effff': 1.67,
  '8a2f5bd83877fff': 1,
  '8a2f5bdb1ca7fff': 1,
  '8a2f5bd84127fff': 1.21,
  '8a2f5bd9d8effff': 1.5,
  '8a2f5bd9d197fff': 1.53,
  '8a2f5bd8a12ffff': 1.44,
  '8a2f5bd8ec57fff': 1.05,
  '8a2f5bd9c19ffff': 1,
  '8a2f5bd9889ffff': 1,
  '8a2f5bdb5ac7fff': 1,
  '8a2f5bd9d18ffff': 1,
  '8a2f5bd9d19ffff': 1.44,
  '8a2f5bd80907fff': 1,
  '8a2f5bd80927fff': 1,
  '8a2f5bd8090ffff': 1,
  '8a2f5bd834f7fff': 1,
  '8a2f5bdaa8dffff': 1,
  '8a2f5bd88527fff': 1.39,
  '8a2f5bd88507fff': 1,
  '8a2f5bd88537fff': 1.48,
  '8a2f5bd8e207fff': 1.7,
  '8a2f5bd86617fff': 1,
  '8a2f5bd90d5ffff': 1,
  '8a2f5bd90c2ffff': 1,
  '8a2f5bdb1af7fff': 1,
  '8a2f5bd81d87fff': 1.31,
  '8a2f5bd8e547fff': 1,
  '8a2f5bd85807fff': 1,
  '8a2f5bdb18b7fff': 1,
  '8a2f5bd918c7fff': 1.18,
  '8a2f5bd80747fff': 1.5,
  '8a2f5bd9462ffff': 1,
  '8a2f5bd9880ffff': 1.18,
  '8a2f5bd9c747fff': 1,
  '8a2f5bd8506ffff': 1.17,
  '8a2f5bd85367fff': 1,
  '8a2f5bd850d7fff': 1,
  '8a2f5bd8e30ffff': 1,
  '8a2f5bd91b47fff': 1.33,
  '8a2f5bdb339ffff': 1,
  '8a2f5bd81467fff': 1,
  '8a2f5bd8155ffff': 1.33,
  '8a2f5bd8501ffff': 1,
  '8a2f5bd8500ffff': 1,
  '8a2f5bd8504ffff': 1,
  '8a2f5bd85017fff': 1,
  '8a2f5bd85007fff': 1,
  '8a2f5bd85057fff': 1,
  '8a2f5bd850c7fff': 1,
  '8a2f5bd86317fff': 1,
  '8a2f5bd85a8ffff': 1,
  '8a2f5bd9d697fff': 1.33,
  '8a2f5bd95537fff': 1,
  '8a2f5bd95527fff': 1,
  '8a2f5bd85c8ffff': 1,
  '8a2f5bd85b4ffff': 1.11,
  '8a2f5bd9cb77fff': 1.06,
  '8a2f5bd9cb67fff': 1,
  '8a2f5bd9cb2ffff': 1,
  '8a2f5bd9c407fff': 1,
  '8a2f5bd9dcdffff': 1,
  '8a2f5bda270ffff': 1.42,
  '8a2f5bda2777fff': 1,
  '8a2f5bd91757fff': 2,
  '8a2f5bd85037fff': 1,
  '8a2f5bd85067fff': 1,
  '8a2f5bd8e6dffff': 1.25,
  '8a2f5bd80757fff': 1,
  '8a2f5bd82387fff': 1.2,
  '8a2f5bd94a0ffff': 1,
  '8a2f5bd8ec37fff': 1,
  '8a2f5bd8edaffff': 1,
  '8a2f5bd8348ffff': 1,
  '8a2f5bd83487fff': 1.11,
  '8a2f5bd8ece7fff': 1,
  '8a2f5bd91a6ffff': 1,
  '8a2f5bdaaaa7fff': 1,
  '8a2f5bdab547fff': 1,
  '8a2f5bdab197fff': 1,
  '8a2f5bd8e857fff': 1,
  '8a2f5bd842c7fff': 1,
  '8a2f5bd842effff': 1,
  '8a2f5bdb1927fff': 1.33,
  '8a2f5bd8e34ffff': 1,
  '8a2f5bdb395ffff': 1,
  '8a2f5bda36dffff': 1.5,
  '8a2f5bdb3957fff': 1,
  '8a2f5bdb1aa7fff': 1,
  '8a2f5bdb140ffff': 1.11,
  '8a2f5bd9dcf7fff': 1,
  '8a2f5bd9dc07fff': 1,
  '8a2f5bd8ac0ffff': 1.05,
  '8a2f5bd8cc27fff': 1,
  '8a2f5bd8cd0ffff': 1,
  '8a2f5bd84977fff': 1,
  '8a2f5bd8492ffff': 1,
  '8a2f5bd85d1ffff': 1,
  '8a2f5bd94a5ffff': 1,
  '8a2f5bd95d8ffff': 1,
  '8a2f5bd95d87fff': 1,
  '8a2f5bd95ca7fff': 1,
  '8a2f5bd8522ffff': 1,
  '8a2f5bd8340ffff': 1.33,
  '8a2f5bd8350ffff': 1,
  '8a2f5bd98d47fff': 1.5,
  '8a2f5bdaa157fff': 1,
  '8a2f5bd86787fff': 1,
  '8a2f5bd99047fff': 1,
  '8a2f5bd99057fff': 1,
  '8a2f5bdaa40ffff': 1,
  '8a2f5bd9982ffff': 1,
  '8a2f5bd9c477fff': 1.07,
  '8a2f5bd86287fff': 1.07,
  '8a2f5bd8e01ffff': 1,
  '8a2f5bd82237fff': 1,
  '8a2f5bdaad97fff': 1,
  '8a2f5bdaad9ffff': 1,
  '8a2f5bdaa4e7fff': 1.5,
  '8a2f5bdaa6c7fff': 1,
  '8a2f5bdaac37fff': 1,
  '8a2f5bd84a2ffff': 1,
  '8a2f5bd8c297fff': 1,
  '8a2f5bd858d7fff': 1.27,
  '8a2f5bd8ac27fff': 1,
  '8a2f5bd8ad4ffff': 1.42,
  '8a2f5bd8dd27fff': 1,
  '8a2f5bd84ca7fff': 1,
  '8a2f5bd91d0ffff': 1.2,
  '8a2f5bd8a967fff': 1.13,
  '8a2f5bdaa887fff': 1,
  '8a2f5bd8295ffff': 1,
  '8a2f5bd80697fff': 1,
  '8a2f5bd84af7fff': 1,
  '8a2f5bd84ae7fff': 1,
  '8a2f5bdaa687fff': 1,
  '8a2f5bd8378ffff': 1,
  '8a2f5bd8a91ffff': 1,
  '8a2f5bda26dffff': 1,
  '8a2f5bd9112ffff': 1,
  '8a2f5bd8232ffff': 1.11,
  '8a2f5bd85d77fff': 1,
  '8a2f5bd9552ffff': 1,
  '8a2f5bd84d57fff': 1,
  '8a2f5bd91ab7fff': 1,
  '8a2f5bd8e38ffff': 1,
  '8a2f5bd8e60ffff': 1,
  '8a2f5bd8e31ffff': 1,
  '8a2f5bd8e2a7fff': 1,
  '8a2f5bd95027fff': 1,
  '8a2f5bd800a7fff': 1,
  '8a2f5bd8c72ffff': 1,
  '8a2f5bd99b77fff': 1,
  '8a2f5bdb149ffff': 1,
  '8a2f5bd858dffff': 1,
  '8a2f5bd85177fff': 1,
  '8a2f5bd9da8ffff': 1,
  '8a2f5bd99957fff': 1,
  '8a2f5bd8acd7fff': 1,
  '8a2f5bd8acf7fff': 1,
  '8a2f5bdb37a7fff': 1,
  '8a2f5bd8c067fff': 1.27,
  '8a2f5bd8ca97fff': 1,
  '8a2f5bd9132ffff': 1.18,
  '8a2f5bdb32affff': 1,
  '8a2f5bd80187fff': 1,
  '8a2f5bd8a92ffff': 1,
  '8a2f5bd8e0dffff': 1,
  '8a2f5bd99037fff': 1,
  '8a2f5bdaa35ffff': 1,
  '8a2f5bd85657fff': 1,
  '8a2f5bdaa347fff': 1,
  '8a2f5bd9420ffff': 1,
  '8a2f5bd9421ffff': 1.14,
  '8a2f5bd94207fff': 1,
  '8a2f5bd861affff': 1,
  '8a2f5bd80527fff': 1,
  '8a2f5bd82aa7fff': 1.17,
  '8a2f5bd98367fff': 1,
  '8a2f5bd86107fff': 1.14,
  '8a2f5bd858affff': 1.11,
  '8a2f5bdaa0effff': 1,
  '8a2f5bdaaacffff': 1,
  '8a2f5bd88d2ffff': 1,
  '8a2f5bd8520ffff': 1.25,
  '8a2f5bd80957fff': 1,
  '8a2f5bdae69ffff': 1,
  '8a2f5bdab477fff': 1,
  '8a2f5bdab54ffff': 1,
  '8a2f5bd85497fff': 1,
  '8a2f5bd8104ffff': 1,
  '8a2f5bd8c4a7fff': 1,
  '8a2f5bd8580ffff': 1,
  '8a2f5bd91557fff': 1,
  '8a2f5bd81507fff': 1,
  '8a2f5bd84b17fff': 1,
  '8a2f5bda3487fff': 2,
  '8a2f5bdb3877fff': 1,
  '8a2f5bdb14e7fff': 1.09,
  '8a2f5bd817affff': 1,
  '8a2f5bd98b37fff': 1,
  '8a2f5bd8a887fff': 1,
  '8a2f5bd84007fff': 1,
  '8a2f5bda358ffff': 1,
  '8a2f5bdb1767fff': 1,
  '8a2f5bd8156ffff': 1,
  '8a2f5bd86407fff': 1.33,
  '8a2f5bd86c37fff': 1,
  '8a2f5bd9c967fff': 1,
  '8a2f5bd858f7fff': 1,
  '8a2f5bd95937fff': 1.14,
  '8a2f5bdb36d7fff': 1,
  '8a2f5bd94a47fff': 1,
  '8a2f5bd8cb8ffff': 1,
  '8a2f5bdab4cffff': 1,
  '8a2f5bdab79ffff': 1,
  '8a2f5bd8c307fff': 1,
  '8a2f5bd9c257fff': 1,
  '8a2f5bd90907fff': 1,
  '8a2f5bd91d17fff': 1,
  '8a2f5bd8e607fff': 1,
  '8a2f5bd90d6ffff': 1,
  '8a2f5bdaa6dffff': 1,
  '8a2f5bd9cd97fff': 1,
  '8a2f5bdaa477fff': 1,
  '8a2f5bdaa457fff': 1,
  '8a2f5bdaa7affff': 1,
  '8a2f5bd9d367fff': 1.47,
  '8a2f5bd9d347fff': 1,
  '8a2f5bd86b0ffff': 1,
  '8a2f5bd86a27fff': 1,
  '8a2f5bd8672ffff': 1,
  '8a2f5bd863affff': 1,
  '8a2f5bd94b4ffff': 1,
  '8a2f5bd9c7a7fff': 1,
  '8a2f5bd8acb7fff': 1,
  '8a2f5bd88da7fff': 1,
  '8a2f5bd86137fff': 1,
  '8a2f5bd95327fff': 1.1,
  '8a2f5bd9cd77fff': 1,
  '8a2f5bd95aaffff': 1,
  '8a2f5bd91c6ffff': 1,
  '8a2f5bd988e7fff': 1.07,
  '8a2f5bd98857fff': 1,
  '8a2f5bd9da47fff': 1,
  '8a2f5bd8a42ffff': 1,
  '8a2f5bd8a407fff': 1,
  '8a2f5bd8a427fff': 1,
  '8a2f5bd818cffff': 1,
  '8a2f5bd81167fff': 1,
  '8a2f5bd91b67fff': 1,
  '8a2f5bd9c307fff': 1,
  '8a2f5bd95867fff': 1.65,
  '8a2f5bd8c28ffff': 1,
  '8a2f5bd99007fff': 1,
  '8a2f5bd9900ffff': 1,
  '8a2f5bd85127fff': 1,
  '8a2f5bd84a1ffff': 1,
  '8a2f5bd86baffff': 1,
  '8a2f5bdb195ffff': 1.1,
  '8a2f5bda2497fff': 1.06,
  '8a2f5bd99897fff': 1,
  '8a2f5bd86677fff': 1,
  '8a2f5bd81137fff': 1,
  '8a2f5bd95d27fff': 1,
  '8a2f5bd84a37fff': 1.1,
  '8a2f5bdaa757fff': 1.14,
  '8a2f5bd84c4ffff': 1,
  '8a2f5bd9000ffff': 1,
  '8a2f5bdab48ffff': 1,
  '8a2f5bd8c96ffff': 1,
  '8a2f5bd8c967fff': 1,
  '8a2f5bdab7a7fff': 1,
  '8a2f5bdab44ffff': 1,
  '8a2f5bdaa277fff': 1,
  '8a2f5bd83417fff': 1.1,
  '8a2f5bdaa55ffff': 1,
  '8a2f5bd9892ffff': 1.11,
  '8a2f5bd9c0e7fff': 1,
  '8a2f5bd9c237fff': 1,
  '8a2f5bd9dc57fff': 1.33,
  '8a2f5bd84877fff': 1,
  '8a2f5bd8186ffff': 1,
  '8a2f5bd9c2cffff': 1,
  '8a2f5bd99947fff': 1,
  '8a2f5bd84ac7fff': 1,
  '8a2f5bd99c07fff': 1,
  '8a2f5bdaad87fff': 1,
  '8a2f5bd82b8ffff': 1,
  '8a2f5bd84157fff': 1,
  '8a2f5bdaa677fff': 1,
  '8a2f5bdaa777fff': 1,
  '8a2f5bd835a7fff': 1,
  '8a2f5bda34b7fff': 1,
  '8a2f5bd84437fff': 1,
  '8a2f5bd840d7fff': 1.5,
  '8a2f5bd99827fff': 1,
  '8a2f5bd8428ffff': 1,
  '8a2f5bd84da7fff': 1.13,
  '8a2f5bd8408ffff': 1,
  '8a2f5bd84087fff': 1,
  '8a2f5bd840a7fff': 1,
  '8a2f5bd84ceffff': 1,
  '8a2f5bd84ce7fff': 1,
  '8a2f5bd84197fff': 1,
  '8a2f5bd841b7fff': 1,
  '8a2f5bd843b7fff': 1,
  '8a2f5bd8c8c7fff': 1,
  '8a2f5bd84a17fff': 1,
  '8a2f5bd8ea1ffff': 1,
  '8a2f5bd81d77fff': 1,
  '8a2f5bdaa697fff': 1,
  '8a2f5bdaa6b7fff': 1,
  '8a2f5bd84caffff': 1,
  '8a2f5bd9564ffff': 1,
  '8a2f5bd82d6ffff': 1,
  '8a2f5bd8c217fff': 1,
  '8a2f5bd81ae7fff': 1,
  '8a2f5bda3677fff': 1.33,
  '8a2f5bd8319ffff': 1,
  '8a2f5bd8212ffff': 1,
  '8a2f5bda255ffff': 1,
  '8a2f5bda2037fff': 1,
  '8a2f5bda24e7fff': 1,
  '8a2f5bd889affff': 1,
  '8a2f5bd88917fff': 1,
  '8a2f5bdb338ffff': 1,
  '8a2f5bdb32a7fff': 1,
  '8a2f5bd9580ffff': 1.05,
  '8a2f5bd99977fff': 1,
  '8a2f5bd9d817fff': 1,
  '8a2f5bdab41ffff': 1.08,
  '8a2f5bd84d17fff': 1,
  '8a2f5bd8571ffff': 1,
  '8a2f5bd83a4ffff': 1,
  '8a2f5bd9c267fff': 1,
  '8a2f5bd9dc97fff': 1,
  '8a2f5bd99887fff': 1,
  '8a2f5bd9d71ffff': 1,
  '8a2f5bda2247fff': 1,
  '8a2f5bda35affff': 1,
  '8a2f5bd8c59ffff': 1,
  '8a2f5bd94657fff': 1.56,
  '8a2f5bdb1277fff': 1,
  '8a2f5bd9431ffff': 1,
  '8a2f5bd84897fff': 1,
  '8a2f5bd90daffff': 1,
  '8a2f5bd84c07fff': 1,
  '8a2f5bd8e2affff': 1.61,
  '8a2f5bd808effff': 1,
  '8a2f5bd80167fff': 1,
  '8a2f5bd812dffff': 1,
  '8a2f5bd86837fff': 1,
  '8a2f5bd835affff': 1.29,
  '8a2f5bdab407fff': 1,
  '8a2f5bdb164ffff': 1.37,
  '8a2f5bd9c637fff': 1.31,
  '8a2f5bd8c15ffff': 1.44,
  '8a2f5bd80337fff': 1,
  '8a2f5bd80327fff': 1,
  '8a2f5bd80a8ffff': 1,
  '8a2f5bd80a9ffff': 1,
  '8a2f5bd80307fff': 1,
  '8a2f5bdaa257fff': 1,
  '8a2f5bdaa20ffff': 1,
  '8a2f5bda2647fff': 1,
  '8a2f5bd9c097fff': 1,
  '8a2f5bda218ffff': 1,
  '8a2f5bd9dceffff': 1,
  '8a2f5bd84d27fff': 1,
  '8a2f5bd9170ffff': 1,
  '8a2f5bd9478ffff': 1,
  '8a2f5bd99ce7fff': 1,
  '8a2f5bd9d857fff': 1,
  '8a2f5bd9d85ffff': 1,
  '8a2f5bd9d94ffff': 1,
  '8a2f5bd9c067fff': 1,
  '8a2f5bda35b7fff': 1.5,
  '8a2f5bd820cffff': 1,
  '8a2f5bd8690ffff': 2,
  '8a2f5bd83447fff': 1.5,
  '8a2f5bdb11b7fff': 1,
  '8a2f5bd9c7b7fff': 1,
  '8a2f5bd9c51ffff': 1,
  '8a2f5bd9c30ffff': 1,
  '8a2f5bd9d107fff': 1,
  '8a2f5bd8e637fff': 1,
  '8a2f5bd81757fff': 1,
  '8a2f5bd8171ffff': 1.2,
  '8a2f5bdb19b7fff': 1,
  '8a2f5bd998d7fff': 1,
  '8a2f5bd9c8dffff': 1,
  '8a2f5bd9c167fff': 1,
  '8a2f5bd9c177fff': 1,
  '8a2f5bd9c8c7fff': 1,
  '8a2f5bd8039ffff': 1,
  '8a2f5bd8e467fff': 1.07,
  '8a2f5bd8029ffff': 1,
  '8a2f5bd8311ffff': 1,
  '8a2f5bda244ffff': 1,
  '8a2f5bd8c38ffff': 1,
  '8a2f5bd90157fff': 1,
  '8a2f5bd94277fff': 1,
  '8a2f5bd88d57fff': 1,
  '8a2f5bd9174ffff': 1,
  '8a2f5bd88d27fff': 1,
  '8a2f5bd8e427fff': 1,
  '8a2f5bd94077fff': 1.07,
  '8a2f5bdab747fff': 1,
  '8a2f5bdb565ffff': 1,
  '8a2f5bd94ae7fff': 1,
  '8a2f5bda36b7fff': 1,
  '8a2f5bda369ffff': 1,
  '8a2f5bd84a0ffff': 1,
  '8a2f5bd98927fff': 1,
  '8a2f5bdab0b7fff': 1,
  '8a2f5bdab097fff': 1,
  '8a2f5bd840b7fff': 1,
  '8a2f5bd8a41ffff': 1,
  '8a2f5bd8a40ffff': 1,
  '8a2f5bd9d80ffff': 1,
  '8a2f5bd86a67fff': 1,
  '8a2f5bd80127fff': 1,
  '8a2f5bd80d57fff': 1,
  '8a2f5bd86c9ffff': 1,
  '8a2f5bd9512ffff': 1,
  '8a2f5bd9146ffff': 1,
  '8a2f5bdb1b9ffff': 1,
  '8a2f5bdaa707fff': 1.29,
  '8a2f5bd95a87fff': 1,
  '8a2f5bdb118ffff': 1,
  '8a2f5bd808e7fff': 1.24,
  '8a2f5bd8080ffff': 1,
  '8a2f5bd8e6e7fff': 2,
  '8a2f5bdae49ffff': 1,
  '8a2f5bd8c12ffff': 1.5,
  '8a2f5bd8508ffff': 1,
  '8a2f5bd85267fff': 2,
  '8a2f5bd99a27fff': 1,
  '8a2f5bd80357fff': 1,
  '8a2f5bd95037fff': 1,
  '8a2f5bd9c16ffff': 1,
  '8a2f5bd8e477fff': 1,
  '8a2f5bd83697fff': 1,
  '8a2f5bd9c877fff': 1,
  '8a2f5bd834d7fff': 1,
  '8a2f5bd834e7fff': 1,
  '8a2f5bd834effff': 1,
  '8a2f5bd8342ffff': 1,
  '8a2f5bd9dc8ffff': 1,
  '8a2f5bd9ca97fff': 1.07,
  '8a2f5bd9ca87fff': 1,
  '8a2f5bd9c86ffff': 1,
  '8a2f5bd9c347fff': 1,
  '8a2f5bd83797fff': 1,
  '8a2f5bd8034ffff': 1.05,
  '8a2f5bd8490ffff': 1,
  '8a2f5bda345ffff': 1,
  '8a2f5bda3717fff': 1,
  '8a2f5bda3627fff': 1,
  '8a2f5bda3607fff': 1,
  '8a2f5bda362ffff': 1,
  '8a2f5bda3407fff': 1,
  '8a2f5bda360ffff': 1,
  '8a2f5bd91aeffff': 1,
  '8a2f5bd91d1ffff': 2,
  '8a2f5bd91d07fff': 1,
  '8a2f5bd95377fff': 1,
  '8a2f5bd9dcc7fff': 1,
  '8a2f5bd80767fff': 1,
  '8a2f5bd800dffff': 1,
  '8a2f5bd90987fff': 1,
  '8a2f5bda2017fff': 1,
  '8a2f5bd9dc87fff': 1,
  '8a2f5bdb119ffff': 1,
  '8a2f5bd943a7fff': 1,
  '8a2f5bd94237fff': 1,
  '8a2f5bd943affff': 1,
  '8a2f5bd94b1ffff': 1,
  '8a2f5bd80017fff': 1,
  '8a2f5bdaaa97fff': 1,
  '8a2f5bd83adffff': 1,
  '8a2f5bd8ed9ffff': 1,
  '8a2f5bd9144ffff': 1,
  '8a2f5bd868d7fff': 1,
  '8a2f5bd9c2c7fff': 1,
  '8a2f5bdb12affff': 1,
  '8a2f5bd8c0effff': 1,
  '8a2f5bd828d7fff': 1,
  '8a2f5bd8e317fff': 1,
  '8a2f5bd8ad77fff': 1,
  '8a2f5bd8e25ffff': 1,
  '8a2f5bd816b7fff': 1,
  '8a2f5bd9e937fff': 1,
  '8a2f5bd9569ffff': 1,
  '8a2f5bd8a45ffff': 1,
  '8a2f5bd84d1ffff': 1,
  '8a2f5bd8caeffff': 1,
  '8a2f5bd86db7fff': 1,
  '8a2f5bd84807fff': 1,
  '8a2f5bd8c337fff': 1,
  '8a2f5bd8c06ffff': 1,
  '8a2f5bdb1717fff': 1,
  '8a2f5bd80937fff': 1,
  '8a2f5bd95437fff': 1,
  '8a2f5bd80457fff': 1.08,
  '8a2f5bd99987fff': 1,
  '8a2f5bda3667fff': 1,
  '8a2f5bda34effff': 1,
  '8a2f5bd91777fff': 1,
  '8a2f5bd8ec27fff': 1,
  '8a2f5bd8ed1ffff': 1,
  '8a2f5bd8a81ffff': 1,
  '8a2f5bd8cc9ffff': 1,
  '8a2f5bd816effff': 1,
  '8a2f5bd88ce7fff': 1,
  '8a2f5bd8e08ffff': 1,
  '8a2f5bd8238ffff': 1,
  '8a2f5bd8ad9ffff': 1.22,
  '8a2f5bd8a9a7fff': 1,
  '8a2f5bd9890ffff': 1,
  '8a2f5bd98907fff': 1,
  '8a2f5bd9db9ffff': 1,
  '8a2f5bdb1b47fff': 1,
  '8a2f5bd8452ffff': 1,
  '8a2f5bd84187fff': 1,
  '8a2f5bd8556ffff': 1,
  '8a2f5bda355ffff': 1,
  '8a2f5bd942b7fff': 1,
  '8a2f5bd84997fff': 1,
  '8a2f5bd9d91ffff': 1,
  '8a2f5bd99077fff': 1,
  '8a2f5bd8cd1ffff': 1,
  '8a2f5bd8085ffff': 1,
  '8a2f5bdaacb7fff': 1,
  '8a2f5bd8514ffff': 1,
  '8a2f5bd80a97fff': 1,
  '8a2f5bd81557fff': 1,
  '8a2f5bd9109ffff': 1,
  '8a2f5bd90b87fff': 1,
  '8a2f5bd8170ffff': 1,
  '8a2f5bd81717fff': 1,
  '8a2f5bd94ba7fff': 1,
  '8a2f5bd9c0c7fff': 1,
  '8a2f5bd8aca7fff': 1,
  '8a2f5bd9d227fff': 1,
  '8a2f5bd9d237fff': 1,
  '8a2f5bd8a8e7fff': 1,
  '8a2f5bd8a80ffff': 1,
  '8a2f5bd8150ffff': 1,
  '8a2f5bd8c567fff': 1,
  '8a2f5bd8c50ffff': 1,
  '8a2f5bd82567fff': 1,
  '8a2f5bdb3977fff': 1,
  '8a2f5bdb3947fff': 1,
  '8a2f5bd81a27fff': 1,
  '8a2f5bd85597fff': 1,
  '8a2f5bd91cc7fff': 1,
  '8a2f5bd86757fff': 1,
  '8a2f5bd9c06ffff': 1,
  '8a2f5bd9cc5ffff': 1,
  '8a2f5bd88daffff': 1,
  '8a2f5bd88d17fff': 1,
  '8a2f5bd8688ffff': 1,
  '8a2f5bd86887fff': 1,
  '8a2f5bd8e4c7fff': 1,
  '8a2f5bd83777fff': 1,
  '8a2f5bd816e7fff': 1,
  '8a2f5bd9171ffff': 1,
  '8a2f5bd9c997fff': 1,
  '8a2f5bd86b8ffff': 1,
  '8a2f5bd9d577fff': 1,
  '8a2f5bd848effff': 1,
  '8a2f5bd86b57fff': 1,
  '8a2f5bd8691ffff': 1,
  '8a2f5bd8629ffff': 1,
  '8a2f5bd81487fff': 1,
  '8a2f5bd84047fff': 1,
  '8a2f5bd841a7fff': 1,
  '8a2f5bda3497fff': 1,
  '8a2f5bd84057fff': 1,
  '8a2f5bd81a2ffff': 1,
  '8a2f5bd81a57fff': 1,
  '8a2f5bdb1957fff': 1,
  '8a2f5bd98adffff': 1,
  '8a2f5bd91a5ffff': 1,
  '8a2f5bda2007fff': 1,
  '8a2f5bdab6a7fff': 1,
  '8a2f5bd99d2ffff': 1,
  '8a2f5bd99d77fff': 1,
  '8a2f5bdb1b77fff': 1,
  '-1': 8.75,
};
