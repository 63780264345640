import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/ja'; // 日本語ローカライズ

import Toolbar from '../../components/Toolbar/Toolbar.jsx';
import FilterList from '../../components/FilterList/FilterList.jsx';
import ToolFooter from '../../components/ToolFooter/ToolFooter.jsx';
import Result from '../../components/Result/Result.jsx';
import {config} from '../../config.jsx';

import StackedColumnChart from  '../../components/Charts/StackedColumnChart' ;
import ColumnChart from '../../components/Charts/ColumnChart';
import LineGraph from '../../components/LineGraph/LineGraph.jsx';

const useStyles = makeStyles((theme) => ({

}));
export default function Sales(props) {
    const {
        range={},
        masterData=null,
    } = props;
    
    const location = useLocation();

    const [storeJson, setStoreJson] = React.useState([]);
    const [segmentJson, setSegmentJson] = React.useState([]);
    const [targetJson, setTargetJson] = React.useState([]);
    const [competingStoresJSON, setCompetingStoresJSON] = React.useState([]);
    const [startDate, setStartDate]       = React.useState(range.start || moment('2021-01-01'));
    const [endDate, setEndDate]           = React.useState(range.end || moment('2022-01-01'));
    const [isShowResult, setIsShowResult] = React.useState(false);
    const [isShowFilter, setIsShowFilter] = React.useState(true);
    const [isTab, setIsTab] = React.useState(true);
    const [isIndicator, setIsIndicator] = React.useState(true);
    const [filterOptions, setFilterOptions] = React.useState([]);

   
    const options = {
        title: "Sample Graph Title",
        xAxisTitle: "X axis title should be given",
        yAxisTitle:" Y Axis title should be given" ,
        yAxisLable:"%",
        categories:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug","Sep","Oct","Nov","Dec"],
        data: [
            {
                name: 'population',
                lineColor: '#4073FF',
                data: [7.0, 6.0, 9.0, 14.0, 18.0, 21.0, 25.0, 26.0, 23.0, 18.0, 13.0, 9.0]
                }, {
                name: 'Competitor Customer Amount',
                lineColor: '#DB4035',
                data: [3.0, 4.0, 7.0, 4.0, 15.0, 22.0, 23.0, 26.0, 12.0, 20.0, 16.0, 14.0]
                } 
        ]
    }


    const classes = useStyles({
        isShowResult,
    });
    const toggleShowFilter = (flag) => {
        setIsShowFilter(flag);
    }
    const toggleIsTab = () => {
        setIsTab(!isTab);
    }
    const toggleIsIndicator = () => {
        setIsIndicator(!isIndicator);
    }

    React.useEffect(()=>{
        if(!masterData) return;
        const {
            competingStores,
            segment,
            store,
            target,
        } = masterData;
        setStoreJson(store);
        setSegmentJson(segment);
        setTargetJson(target);
        setCompetingStoresJSON(competingStores);

    }, [masterData]);
    React.useEffect(()=>{
        setFilterOptions([{
            json  : storeJson,
            title : '対象店舗',
            searchInput : {
                placeholder : '店舗名検索',
            },
            isStoreFilter : true,
        },{
            json  : segmentJson,
            title : 'セグメント',
        },{
            json  : targetJson,
            title : '対象データ',
        },{
            type  : 'group',
            json  : competingStoresJSON,
            title : '競合店',
        }]);
    }, [storeJson, segmentJson, targetJson, competingStoresJSON])
    React.useEffect(()=>{
        const {
            pathname,
        } = location;
        const _isShowResult = Boolean(pathname.search(/result/) > -1);
        setIsShowResult(_isShowResult);
        !_isShowResult && toggleShowFilter(true);
    }, [location]);



    const StackColumnChartOptions = {
        title: "Stackde charts title",
        categories: ['Below 20', '20s', '30s', '40s', '50s', 'Over 60', 'NA'],
        xAxisTitle:"X axis title",
        xAxissLabel: "",
        yAxisLabels:"%",
        yAxisTitle : "Y axis title",
        data: [
          {
          name: 'NA',
          color:"#a3a4a5",
          data: [0, 0, 0, 0, 2, 9, 0]
          },
          {
            name: 'Female',
            color:"#f9d89c",
            data: [5, 3, 7, 2, 4, 4, 7]
            },
          {
            name: 'Male',
            color:"#e79b37",
            data: [5, 3, 4, 7, 2, 4, 20]},
        ]
      };


      const GroupedColumnChartOptions = {
        title: "Grouped Column chart",
        xAxisLabel: "",
        xAxisTitle: "X Axis Title",
        yAxisLabel: "%",
        yAxisTitle: "Y Axis Title",
        data: [{
            name: '商圏住民',
            color:"#ae2218",
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
        
        }, 
        // Note :- if you want to display single column in the chart, just add one object to the data.
        {
            name: '顧客',
            color:"#2f6eba",
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]
        
        },]
    }

    
    return (
        <>
            {/* {
                isShowFilter && (
                    <>
                        <Toolbar 
                            range={range}
                            isShowFilter={isShowFilter}
                            isTab={isTab}
                            isIndicator={isIndicator}
                            startDate={startDate}
                            endDate={endDate}
                            isShowResult={isShowResult}
                            toggleIsTab={toggleIsTab}
                            toggleIsIndicator={toggleIsIndicator}
                            toggleShowFilter={toggleShowFilter}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            setIsShowResult={setIsShowResult}

                            showDateRange={true}
                            showTabSwitch={true}
                            showIndicatorSwitch={true}
                        />
                        <FilterList 
                            filterOptions={filterOptions}
                            isShowResult={isShowResult}
                        />
                    </>
                )
            }
            
            {
                !isShowResult && (
                    <ToolFooter 
                        to={'/sales/result'}
                        text='データ作成'
                    />
                )
            } */}

            <div>
                <StackedColumnChart titleIcon="children" {...StackColumnChartOptions} />
            </div>
            <div>
                <ColumnChart  {...GroupedColumnChartOptions}/>
            </div>
            
                <LineGraph  {...options} />   
            <Route path='/sales/result' render={() => {
                return (
                    <Result 
                        isShowFilter={isShowFilter} 
                        toggleShowFilter={toggleShowFilter}
                        isTab={isTab}
                        toggleIsTab={toggleIsTab}
                        isIndicator={isIndicator}
                        toggleIsIndicator={toggleIsIndicator}
                    />
                )
            }} />
        </>
    );
}