import React, {useContext} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, useGridControlState } from '@material-ui/data-grid';
import { Divider, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import moment from 'moment';
import * as dfd from "danfojs";

import Button from '../../common/Button/Button.jsx';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.jsx';
import Tabs from '../Tabs/Tabs.jsx';
import DownloadButton from '../DownloadButton/DownloadButton.jsx';
import DownloadImageButton from '../DownloadButton/DownloadImageButton.jsx';

import { AuthContext } from '../../AuthenticationContext.js';
import BoxPlotChart from '../Charts/BoxPlotChart.jsx';
import demographyOptions from "../Demography/demography.json";
import Demography from '../Demography/Demography.jsx';
import HeatMap from '../HeatMap/HeatMap.jsx';
import LineChart from '../Charts/LineChart.jsx';
import TabByTable from '../TabByTable/TabByTable.jsx';
import StoreByTable from '../StoreByTable/StoreByTable.jsx';
import MarketAreaHeatmap from './ResultComponents/MarketAreaHeatmap.jsx';
import { 
    API_URL, 
    validateResponse, 
    // getKeyInCookie,
    getNdJsonFetch,
} from '../../utility/fetchUtility.js';

import {
    getSalesResults,

    getAssortmentSummary,
    getAssortmentCompetitor,
    getAssortmentSingleItemComparison,
    getAssortmentBasket,

    getPricingSummary,
    getPricingSingleItemComparison,
    getPricingBoxPlot,
    getPricingCompetitor,
    
    getMarketAreaSummary,
    getMarketAreaShare,
    getMarketAreaProfile,
    getMarketAreaHeatmap,
    getMarketAreaPeopleFlow,

} from '../../utility/getResult.js'
import LoadingCircle from '../Loading/LoadingCircle.jsx';
import {config} from '../../config.jsx';
// dummy data
import dataJSON from '../../json/MVP_API/sales_analysis_summary_Jan-Mar_monthly.json';



const useStyles = makeStyles((theme) => ({

    
    root : (props) => ({
        borderTop : props.isShowFilter ? 'solid 1px #ddd' : null,
    }),
    tableBox : (props) => ({
        height : props.isShowFilter ? 'calc(100vh - 434px)' : 'calc(100vh - 180px)',
        //transition : 'height .1s linear',
        '& .MuiDataGrid-row' : {
            backgroundColor : '#fff',
        }
    }),
    withTabTableBox : (props) => ({
        height : props.isShowFilter ? 'calc(100vh - (434px + 48px))' : 'calc(100vh - (180px + 48px))',
        overflow : 'hidden',
    }),
    toolbar : {
        padding : '12px 0 12px 0',
        display : 'flex',
        justifyContent : 'space-between',
    },
    subInfoBox : {
        position: "relative",
        height : 'calc(100vh - (188px + 274px + 12px))',
        '& .highcharts-container' : {
            height : 'calc(100vh - (188px + 274px + 12px)) !important',
        }
    },
    splitCell : {
        borderLeft : '2px solid rgba(224, 224, 224, 1)',
    }
}));

export default function Result(props) {
    const {
        isShowFilter,
        toggleShowFilter,
        currentFormValues,
        major,
        minor,
        getFilterOptions,
        isTab,
        isIndicator,
        isPostBodyType,
        toggleIsTab,
        toggleIsIndicator,
        toggleIsPostBodyType,
        addAlert,
        alert,
        pageTitle,
        isReload,
        tabCondition,
        isShowChart,
        setIsShowChart,
        mapCells,
        setMapCells,
        showChainAndSegmentSwitch=false,
    } = props;
    const {token} = useContext(AuthContext);

    const classes = useStyles({isShowFilter});
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState(true);
    const [showError, setShowError] = React.useState(false);
    const [tableRows, setTableRows] = React.useState([]);
    const [originalData, setOriginalData] = React.useState(null);
    const [recordsCount, setRecordsCount] = React.useState(null);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [isDanfo, setIsDanfo] = React.useState(false);
    
    const [tableTabOptions, setTableTabOptions] = React.useState(null);
    const [tabColumnKey, setTabColumnKey] = React.useState(null);
    const [resultType, setResultType] = React.useState(null);
    const [chartOptions, setChartOptions] = React.useState(null);
    const [demography, setDemography] = React.useState(null);
    const [downloadDemography, setDownloadDemography] = React.useState(null);
    const [tabByTableIndex, setTabByTableIndex] = React.useState(0);
    const [tabByTableOptions, setTabByTableOptions] = React.useState(null);
    const [tabByTableOptionsData, setTabByTableOptionsData] = React.useState(null);
    const [storeByTableOptions, setStoreByTableOptions] = React.useState(null);
    const [storeByTableTabIndex, setStoreByTableTabIndex] = React.useState(0);
    const [tableListsOptions, setTableListsOptions] = React.useState([]);
    const [heatMapData, setHeatMapData] = React.useState(null)
    const [heatMapDownloadData,setHeatMapDownloadData] = React.useState(null);
    const delayTimer = React.useRef(null);
    const highchartsRef = React.useRef(null);
    const hasShowChart = ['/marketArea/share/result'].includes(location.pathname);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [tabIndex, setTabIndex] = React.useState(0);
    
    const numberFormatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
     });
    const getFormattedNumber = (num) => {
        return numberFormatter.format(num)
    }
    const customSorting = (v1, v2) => {
        if(!isNaN(v1) && !isNaN(v2)){
            return v1 - v2
        }
        else if(v1.includes('%') && v2.includes('%')){
            const v1WithoutPercentage = v1.slice(0, -1)
            const v2WithoutPercentage = v2.slice(0, -1)
            if(!isNaN(v1WithoutPercentage) && !isNaN(v2WithoutPercentage)){
                return Number(v1.slice(0, -1)) - Number(v2.slice(0, -1))
            }
        }
        return v1 - v2
    }
    const getData = async () => {
        const columnKeys = ['指標', 'セグメント'].concat(dataJSON[0].headers.map((h, i) => {
                          // index , segment
            if(i === 0) return null;
            
            const otherKeys = h.headers.map(date => {
                return `${h.name}:${date}`;
            });
            return otherKeys;

        })
        .filter(d => d !== null))
        .flat();

        const columnData = dataJSON.map(d => {
            return d.data.map(c => {
                
                return [d.selection].concat(c.map(g => {
                    if(String(g).search(/%$/) > -1){
                        return Number(g.replace(/%$/, ''));
                    }
                    return g;
                }));
            })
            
        }).flat();

        const tabKeys = ['指標', 'セグメント'];
        const tabOptions = {};
        tabKeys.forEach(key => {
            const index   = columnKeys.indexOf(key);
            const options = [...new Set(columnData.map(col => col[index]))];
            tabOptions[key] = options.map((name, i) => {
                return {
                    name,
                    selected : i === 0,
                }
            })
        });
        setTableTabOptions(tabOptions);

        const sampleDataFrame = new dfd.DataFrame(columnData, {columns: columnKeys});
        
        // Since loading is instantaneous, use setTimeout to delay it
        // Not required if data is obtained from API
        delayTimer.current = setTimeout(() => {
            setOriginalData(sampleDataFrame);
        }, 1000);
    }
    const getGroupByKey = (newTabKey) => {
        const groupBy = isIndicator ?  ['指標', 'セグメント'] : ['セグメント', '指標'] ;
        return groupBy;
    }
    const createTableData = (newTabKey) => {
        
        if(!originalData || !tableTabOptions){
            //setIsLoading(false);
            return;
        }
        const groupByKeys = getGroupByKey();
        if(!isIndicator && !isTab && groupByKeys.length < 2) return;
        let _originalData = originalData.copy();   
      
        if(isTab && newTabKey){
            const tabKey = newTabKey;
            const tabOptions = tableTabOptions[tabKey];
            
            let selectTabName = tabOptions.find(t => t.selected).name;

            _originalData = _originalData.query(_originalData[`${newTabKey}`].eq(`${selectTabName}`));

            if(!_originalData.$data.length){
                //setIsLoading(false);
                return;
            }

            _originalData.drop({ columns: [tabKey], inplace: true });
            const delIndex = groupByKeys.findIndex(key => key === tabKey);
            groupByKeys.splice(delIndex, 1);
        }
        const groupByDataFrame = _originalData.groupby(groupByKeys);
        const sumTable = groupByDataFrame.sum();
        
        isDanfo && sumTable.plot('result-table').table();
        
        const columns = sumTable.$columns.map(name => name.replace(/_sum/, ''));
        const percentageIndicatorIndex = columns.includes('showPercentage') ? columns.indexOf('showPercentage') : -1;
        if(percentageIndicatorIndex > 0){
            columns.splice(percentageIndicatorIndex, 1);
        }
        const plusSignIndicatorIndex = columns.includes('showPlusSign') ? columns.indexOf('showPlusSign') : -1;
        if(plusSignIndicatorIndex>0){
            columns.splice(plusSignIndicatorIndex, 1);
        }
        const rows    = sumTable.$data.map((col, i) => {
            let showPercentage = false
            let showPlusSign = false
            if(percentageIndicatorIndex >= 0){
                showPercentage = col[percentageIndicatorIndex]
                col.splice(percentageIndicatorIndex, 1);
            }
            if(plusSignIndicatorIndex >= 0){
                showPlusSign = col[plusSignIndicatorIndex]
                col.splice(plusSignIndicatorIndex, 1);
            }
            const params = {
                id : i,
            };
            columns.map((name, j) => {
                if(showPlusSign && !showPercentage && !isNaN(col[j]) && col[j] != Infinity){
                    params[name] = `${getFormattedNumber(col[j])<=0? "":"+"}` + getFormattedNumber(col[j]) 
                }           
                else if(showPercentage && !isNaN(col[j]) && col[j] != Infinity){
                    params[name] = `${getFormattedNumber(col[j])>0 && showPlusSign ? "+":""}` + getFormattedNumber(col[j]) + '%' 
                } 
              
                else{
                    params[name] = col[j];
                }
            })
            return params;
        });

        setTableColumns(columns.map((name, j) => {
            return {
                field      : name,
                headerName : name,
                width      : name === 'セグメント' ? 400 : 200,
                type       : ['セグメント', '指標'].includes(name) ? "string" : "number",
                valueFormatter : (params) => {
                    if(params.value == Infinity){
                        return '-'
                    }
                    else{
                        if(!isNaN(params.value)){
                            return `${typeof params.value == 'string' && params?.value[0] == "+" ? "+" : ""}${getFormattedNumber(params.value)}`
                        }
                        else{
                            return params.value
                        }
                    }
                },
                sortComparator: customSorting
            }
        }))
        setTableRows(rows);
    }
    const closeResult = () => {
        toggleShowFilter(true);
        const backURL = location.pathname.replace(/\/result$/, '');
        history.push(backURL);
    }
    const toggleIsDanfo = () => {
        setIsDanfo(!isDanfo);
    }
    const changeTabSelect = (index) => {
        const newTabOptions = {...tableTabOptions};
        newTabOptions[tabColumnKey] = newTabOptions[tabColumnKey].map((option, i) => {
            return {
                ...option,
                selected : i === index,
            }
        });
        
        setTableTabOptions(newTabOptions);
    }
    const changeTabByTableIndex = (index=0) => {
        setTabByTableIndex(index);
    }
    const toggleIsShowChart = () => {
        setIsShowChart(!isShowChart);
    }
  

    const convertId2String = (rows) => {
        return rows.map(row => {
            const newRow = {...row};
            const {
                segment_id=undefined,
            } = newRow;
            if(segment_id !== undefined){
                const masterData = currentFormValues?.current?.segment || [];
                const name = masterData.find(d => d.value === segment_id)?.label || segment_id;
                newRow.segment_id = name;
            }
            return newRow;
        })
    }
    
    const margeRows = (target, source) => {
        return target.map(t => {
            const {
                agg_target_day, 
                segment_id,
            } = t;
            const targetMMDD = moment(agg_target_day).format('MM-DD');

            const s = source.find(s => 
                moment(s.agg_target_day).format('MM-DD') === targetMMDD
                && s.segment_id === segment_id
            ) || {};
            return {
                ...t,
                ...s,
            }
        })
    }
    
    const getDataFunctions = {
        'sales/result' : {
            api  : getSalesResults,
            type : 'table', 
        },
        'assortment/summary' : {
            api  : getAssortmentSummary,
            type : 'table', 
        },
        'assortment/competitor' : {
            api  : getAssortmentCompetitor,
            type : 'tableOnly', 
        },
        'pricing/competitor' : {
            api : getPricingCompetitor,
            type : 'tableLists'
        },
        'assortment/singleItem' : {
            api : getAssortmentSingleItemComparison,
            type : 'storeByTable',
        },
        'assortment/basket' : {
            api : getAssortmentBasket,
            type : 'storeByTable',
        },
        'pricing/summary' : {
            api  : getPricingSummary,
            type : 'tableOnly', 
        },
        'pricing/singleItem' : {
            api : getPricingSingleItemComparison,
            type : 'storeByTable',
        },
        'pricing/boxPlot' : {
            api  : getPricingBoxPlot,
            type : 'chart', 
        },
        'marketArea/summary' : {
            api : getMarketAreaSummary,
            type : 'tabByTable',
        },
        'marketArea/profile' : {
            api : getMarketAreaProfile,
            type : 'profile',
        },
        'marketArea/peopleFlow' : {
            api : getMarketAreaPeopleFlow,
            type : 'tableWithChart',
        },
        'marketArea/share' : {
            api : getMarketAreaShare,
            type : 'tabByTable',
        },
        'marketArea/heatMap':{
            api:getMarketAreaHeatmap,
            type: 'heatMap'
        }
    }
    const ResultComponent = (props) => {
        if(isLoading){
            return (
                <LoadingCircle style={{
                    height : 'calc(100vh - 180px)',
                }}/>
            )
        }
        if(showError){
            return (
                <div style={{
                    height : 'calc(100vh - 186px)',
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center',
                    opacity : 0.5,
                    fontSize: '55px'
                }}>
                    <Typography>{errorMessage}</Typography>
                </div>
            )
        }
        if(resultType === 'chart' && chartOptions){
            return (
                <div style={{
                    padding : 12,
                    height : 'calc(100vh - 180px)',
                }}>
                    <BoxPlotChart options={chartOptions} highchartsRef={highchartsRef} />
                </div>
            )
        }
        if(resultType === 'profile' && demography){
            return (
                <Demography options={demography} currentFormValues={currentFormValues} tabIndex={tabIndex} setTabIndex={setTabIndex}/>
            )
        }
        if(resultType === 'tabByTable' && tabByTableOptions){
            return (
                <TabByTable 
                    isShowFilter={isShowFilter} 
                    tabIndex={tabByTableIndex} 
                    changeTabIndex={changeTabByTableIndex}
                    options={tabByTableOptions} 
                    isChart={true}
                    chartSeriesIgnoreKeys={['id', 'chainName',"segmentName","segmentId"]}
                    hasShowChart={hasShowChart}
                    isShowChart={isShowChart}
                    isIndicator={isIndicator}
                    showChainAndSegmentSwitch={showChainAndSegmentSwitch}
                />
            )

        }
        if(resultType === 'storeByTable' && storeByTableOptions){
            return (
                <StoreByTable 
                    isTab={isTab}
                    isShowFilter={isShowFilter} 
                    options={storeByTableOptions}
                    tabIndex={storeByTableTabIndex}
                    changeTabIndex={setStoreByTableTabIndex}
                />
            )
        }
        if(resultType === 'tableLists' && tableListsOptions){
            return tableListsOptions.map(options => {
                const {
                    title,
                    tableColumns,
                    tableRows,
                } = options;
                return (
                    <div key={`tableLists-${title}`} style={{
                        paddingBottom : 24,
                    }}>
                        <div style={{
                            display : 'flex',
                            justifyContent : 'space-between',
                            alignItems : 'center',
                            paddingBottom : 6,
                        }}>
                            <Typography style={{
                                color : 'rgba(52, 73, 94,1.0)',
                                marginBottom : 4,
                                paddingLeft : 2,
                            }}>{title}</Typography>
                            <DownloadButton 
                                currentFormValues={currentFormValues} 
                                isTab={true}
                                tabName={title}
                                //tableTabOptions={tableTabOptions?.[tabColumnKey] || null}
                                columns={tableColumns}
                                rows={tableRows}
                                isTableLists = {true}
                            />
                        </div>
                        <div className={classes.tableBox} style={{
                            height : 'calc(100vh - 234px)',
                        }}>
                            <DataGrid
                                loading={isLoading}
                                rows={tableRows}
                                columns={tableColumns.map(col => {
                                    return {
                                        ...col,
                                        cellClassName : (params) => {
                                            return params.colDef.isSplitCell ? classes.splitCell : '';
                                        },
                                    }
                                })}
                                pagination 
                                pageSize={100} 
                                rowsPerPageOptions={[100]}
                                disableColumnMenu={true}
                            />
                        </div>
                    </div>
                )

            })
        }
        if(resultType === 'heatMap' && heatMapData  && Object.keys(heatMapData?.originalDataFrame || {}).length >0) {
            let storeSelectMap = currentFormValues.current?.store?.filter(d => {
               return d.checked;
                });
           
            let storePosition = storeSelectMap.map((s)=>{
                let {
                    latitude=null,
                    longitude=null,
                } = s?.value;
                return {
                    latitude : Number(latitude),
                    longitude : Number(longitude),
                    zoom : 14
                }
            }) ;
         return <MarketAreaHeatmap ownStoreChainName={heatMapData?.ownStoreChainName} heatMapData={heatMapData?.originalDataFrame} selectedTargetInJapanese={heatMapData?.selectedTargetInJapanese} storePosition={storePosition}  filterOptions={filterOptions}  currentFormValues={currentFormValues}/>
        }
        if(tableTabOptions){
            return (
                <>
                    {
                        isTab && (
                            <Tabs 
                                tabOptions={tableTabOptions[tabColumnKey]} 
                                changeTabSelect={changeTabSelect}
                            />
                        )
                    }

                    <div className={clsx(classes.tableBox, isTab && classes.withTabTableBox)}>
                        <DataGrid
                            loading={isLoading}
                            rows={tableRows}
                            columns={tableColumns}
                            pagination 
                            pageSize={100} 
                            rowsPerPageOptions={[100]}
                            disableColumnMenu={true}
                        />
                    </div>
                </>
            )
        }
        if(resultType === 'tableWithChart' && tableRows && mapCells) {
            const storeSelectMap = currentFormValues.current.store.find(d => d.selected)?.value || null;
            const {
                latitude=null,
                longitude=null,
            } = storeSelectMap;
            const storePosition = {
                latitude : Number(latitude),
                longitude : Number(longitude),
                zoom : 14
            };
            return (
                <div style={{
                    display : 'flex',
                    flexDirection : 'column',
                    height : 'calc(100vh - 188px)',
                    gap : 12,
                }}>
                    <div className={clsx(classes.tableBox, isTab && classes.withTabTableBox)} style={{
                        height : 274,
                    }}>
                        <DataGrid
                            loading={isLoading}
                            rows={tableRows}
                            columns={tableColumns}
                            pagination 
                            pageSize={100} 
                            rowsPerPageOptions={[100]}
                            disableColumnMenu={true}
                        />
                    </div>
                    <Paper style={{
                        flex : 1, 
                        display : 'grid',
                        gridTemplateColumns : '2fr 3fr',
                        borderRadius : 4, 
                        overflow : 'hidden',
                    }}>
                        <div style={{
                            flex : 1,
                        }} className={classes.subInfoBox}>
                            <LineChart options={chartOptions} />
                        </div>
                        <div style={{
                            flex : 3,
                            backgroundColor:'rgba(0,0,0,0.05)',
                        }} className={classes.subInfoBox}>
                            <HeatMap 
                                mapCells={mapCells}
                                storePosition={[storePosition]}
                                currentFormValues={currentFormValues}
                                filterOptions={filterOptions}
                            />
                        </div>
                    </Paper>
                </div>
            )
        }
        if(resultType === 'tableOnly' && tableRows) {
            return (
                <div style={{
                    display : 'flex',
                    flexDirection : 'column',
                    height : 'calc(100vh - 188px)',
                    gap : 12,
                }}>
                    <div className={clsx(classes.tableBox, isTab && classes.withTabTableBox)} style={{
                        /*height : 274,*/
                    }}>
                        <DataGrid
                            loading={isLoading}
                            rows={tableRows}
                            columns={tableColumns}
                            pagination 
                            pageSize={100} 
                            rowsPerPageOptions={[100]}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div style={{
                height : 'calc(100vh - 186px)',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                opacity : 0.5,
            }}>
                <Typography>データが存在しません</Typography>
            </div>
        )
    }

    React.useEffect(()=>{
        toggleShowFilter(false);
        
        if(alert?.id) {
            history.replace(`/${major}${minor ? `/${minor}` : ''}`);
            return;
        }
        const filterOptions = getFilterOptions(major, minor);
        const filterEnabled = Boolean(filterOptions
            .map(key => currentFormValues.current[key] ? true : false)
            .filter(flag => flag === false).length === 0);
        if(!filterEnabled){
            history.replace(`/${major}${minor ? `/${minor}` : ''}`);
            return;
        }

        if(currentFormValues.current.isPostBodyType){
        //if(true){
            getData();
        }else{
            const filterOptions = getFilterOptions(major, minor);
            (async ()=>{
                setIsLoading(true);
                setTableColumns([]);
                setTableRows([]);
                setTabColumnKey(null);
                setChartOptions(null);
                setDemography(null);
                setDownloadDemography(null);
                setTabByTableOptions(null);
                setTabByTableOptionsData(null);
                setTabByTableIndex(0);
                setStoreByTableOptions([]);
                setTableListsOptions([]);
                const selectedTargets = currentFormValues.current?.target
                ?.filter(t => t.visible && t.checked)
                ?.map(t => t.value) || [];

                const apiKey = `${major}/${minor}`;
                const apiParams = getDataFunctions[apiKey];
                if(!apiParams){
                    addAlert({
                        id : `error_${new Date().getTime()}`,
                        severity : 'info',
                        message : 'API is not implemented',
                    });
                    return;
                }
                const getDataFunction = apiParams.api || null;
                setResultType(apiParams.type);
                if(!getDataFunction){
                    setIsLoading(false);
                    return;
                }
                const {
                    mapData=null,
                    tabOptions,
                    originalDataFrame,
                    recordsCount = null,
                    chartSplitOptions=null,
                    error=null,
                    message=null,
                    downloadOriginalDataFrame=null,
                    downloadData=null,
                    selectedTargetInJapanese=false,
                    ownStoreChainName=false
                } = await getDataFunction({
                    token, 
                    history, 
                    currentFormValues,
                    filterOptions,
                    selectedTargets,
                });
                if(error){
                    setIsLoading(false);
                    setShowError(true)
                    setErrorMessage(message)
                    return;
                }
                else{
                    setShowError(false)
                }
                setRecordsCount(recordsCount);
                if(apiParams.type === 'chart'){
                    setChartOptions(originalDataFrame);
                    setIsLoading(false);
                    return;
                }
                if(apiParams.type === 'profile'){
                    if(originalDataFrame.length){
                        setDemography([...(originalDataFrame || [])].sort((a,b)=> demographyOptions?.[a?.target]?.order_id - demographyOptions?.[b?.target]?.order_id));
                        setDownloadDemography([...(downloadOriginalDataFrame || [])].sort((a,b)=> demographyOptions?.[a?.[0]?.target]?.order_id - demographyOptions?.[b?.[0]?.target]?.order_id))
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                }
                if(apiParams.type === 'tabByTable'){
                    setTabByTableOptionsData(originalDataFrame);
                    setIsLoading(false);
                    return;
                }
                if(apiParams.type === 'storeByTable'){
                    setStoreByTableOptions(originalDataFrame);
                    setIsLoading(false);
                    return;
                }
                if(apiParams.type === 'tableLists'){
                    setTableListsOptions(originalDataFrame);
                    setIsLoading(false);
                    return;
                }
                if(apiParams.type === 'heatMap'){
                    setHeatMapData({originalDataFrame, downloadData, selectedTargetInJapanese, ownStoreChainName});
                    setIsLoading(false);
                    return;
                }
                // table
                const hasData = Boolean(originalDataFrame.$data.length);
                if (apiParams.type === 'tableWithChart') {
                    setMapCells(mapData);
                    const columns = originalDataFrame.$columns.map(name => name);
                    const rows    = originalDataFrame.$data.map((col, i) => {
                        const params = {
                            id : i,
                        };
                        columns.map((name, j) => {
                            params[name] = col[j];
                        })
                        return params;
                    });
                    setTableColumns(columns.map((name, j) => {
                        const isString = ['種別'].includes(name);
                        return {
                            field      : name,
                            headerName : name,
                            width      : isString ? 200 : 180,
                            type       : isString ? "string" : "number",
                            valueFormatter : (params) => {
                                if(params.value == Infinity){
                                    return '-'
                                }
                                else{
                                    if(!isNaN(params.value)){
                                        return getFormattedNumber(params.value)
                                    }
                                    else{
                                        return params.value
                                    }
                                }
                            },
                            sortComparator: customSorting
                        }
                    }));
                    let categories = [];
                    const chartData = {};

                    chartSplitOptions.forEach(prefix => {
                        if(chartData[prefix] === undefined){
                            chartData[prefix] = [];
                        }
                        rows.forEach(row => {
                            const keys     = Object.keys(row);
                            const name     = row[keys[1]];
                            const dataKeys = keys.slice(2);
                            const data     = [name];
                            dataKeys.filter(key => key.indexOf(prefix) > -1).forEach(key => {
                                const categoryName = key.replace(prefix, '');
                                if(!categories.includes(categoryName)) categories.push(categoryName);
                                data.push(row[key]);
                            });
                            chartData[prefix].push(data);
                        });
                    });
                    const series = [];
                    Object.keys(chartData).forEach(prefix => {
                        chartData[prefix].forEach(row => {
                            const name       = row.shift();
                            const yAxisIndex = name === '人流数' ? 1 : 0;
                            series.push({
                                name  : prefix + name,
                                data  : [...row],
                                yAxis : yAxisIndex,
                            });
                        })
                    })
                    setChartOptions({
                        chart : {
                            type : 'line',
                            //height : '100%',
                        },
                        xAxis : {
                            categories,
                        },
                        yAxis : [{
                            title : {
                                text : '',
                            }
                        },{
                            title : {
                                text : '',
                            },
                            opposite : true,
                        }],
                        series,
                    });
                    setTableRows(rows);
                }else if(apiParams.type === 'tableOnly'){
                    const columns = originalDataFrame.$columns.map(name => name);
                    const rows    = originalDataFrame.$data.map((col, i) => {
                        const params = {
                            id : i,
                        };
                        columns.map((name, j) => {
                            params[name] = col[j];
                        })
                        return params;
                    });
                    setTableColumns(columns.map((name, j) => {
                        const isString = ['ストアチェーン名'].includes(name);
                        return {
                            field      : name,
                            headerName : name,
                            minWidth   : 200,
                            flex       : 1,
                            type       : isString ? "string" : "number",
                            valueFormatter : (params) => {
                                if(params.value == Infinity){
                                    return '-'
                                }
                                else{
                                    if(!isNaN(params.value)){
                                        return getFormattedNumber(params.value)
                                    }
                                    else{
                                        return params.value
                                    }
                                }
                            },
                            sortComparator: customSorting
                        }
                    }));
                    setTableRows(rows);
                }else{
                    setTableTabOptions(hasData ? tabOptions : null);
                    setOriginalData(hasData ? originalDataFrame : null);
                }
                setIsLoading(false);

            })();
        }
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
        }
    }, [isReload]);

    React.useEffect(()=>{
        const newTabColumnKey = isIndicator ? '指標' : 'セグメント';
        !Boolean(tabByTableOptionsData) && setTabColumnKey(newTabColumnKey);
        !Boolean(tabByTableOptionsData) &&  createTableData(newTabColumnKey);
        if(Boolean(tabByTableOptionsData)){
            
            if(isIndicator || `${major}/${minor}` != 'marketArea/share'){
                setTabByTableOptions(tabByTableOptionsData);
                return null;
            }
            if(!isIndicator && `${major}/${minor}` == 'marketArea/share'){
                let newTabByTableOptionsData =  tabByTableOptionsData.slice().map((tab)=>{
                    let rows = [...tab?.rows];
                    let columns = [...tab?.columns];
                    let groupingColumns =  [{
                        "field": "chainName",
                        "headerName": "企業名",
                        "width": 200,
                        "type": "string"
                    },{
                        "field": "segmentName",
                        "headerName": "セグメント",
                        "minWidth": 350,
                        "type": "string"
                    }]
                    let groupings =  [groupingColumns[1], groupingColumns[0]];
                    let newRows = [...rows].sort((a,b)=> a.segmentId > b.segmentId ? 1 : -1) ;
                    return  {...tab,rows:[...newRows].map((e,i)=>{
                        return {...e,id:i}
                    }), columns: [...groupings,...columns.slice(2)]}
                })
                setTabByTableOptions(newTabByTableOptionsData);
            }
        }
    }, [isTab, isIndicator, originalData, isDanfo, tableTabOptions, tabByTableOptionsData]);
    const filterOptions = getFilterOptions(major, minor);

    const getDownloadParams = () => {
        const tabOptions = tableTabOptions?.[tabColumnKey] || null;
        let downloadRows = tableRows;
        let downloadColumns = tableColumns;
        let downloadTabName = tabOptions?.find(tab => tab.selected)?.name || null;
        if(resultType === 'tabByTable' && tabByTableOptions){
            const {
                columns=null,
                rows=null,
                tabName=null,
            } = tabByTableOptions[tabByTableIndex];
            downloadRows    = [...rows].map((c)=>{
                let d = {...c}
                delete d.segmentId;
                return d
            });
            downloadColumns = columns;
            downloadTabName = tabName;
        }
        if(resultType === 'storeByTable' && storeByTableOptions && storeByTableOptions['tab']){
            const index   = storeByTableTabIndex;
            const options = isTab ? storeByTableOptions['tab'][index] : storeByTableOptions['single'];

            const {
                columns=null,
                rows=null,
            } = options;
            const tabName   = isTab ? storeByTableOptions['tabName'][index] : null;
            downloadColumns = [...columns].map(name => {
                const field = name.replace('hidden__', '');
                return {
                    field,
                }
            });

            downloadRows    = [...rows].map((row, i) => {
                return row.reduce((o,n,i)=>{
                    return {...o, [downloadColumns?.[i]?.field ?? ""]:n}
                },{})
            });
          
            downloadTabName = tabName;
        }
        const isShowMainDownloadButton = Boolean(location.pathname.search('pricing/competitor/result') === -1)
        return {
            downloadRows,
            downloadColumns,
            downloadTabName,
            isShowMainDownloadButton,
        }
    }
    const {
        downloadRows,
        downloadColumns,
        downloadTabName,
        isShowMainDownloadButton,
    } = getDownloadParams();

    return (
        <div className={classes.root}>
            <div className='page-title'>{pageTitle}</div>
            <div className={classes.toolbar}>
                <div style={{
                    display : 'flex',
                    gap : 12,
                    gridGap : 12,
                }}>
                    {
                        isShowMainDownloadButton && (
                            <DownloadButton 
                                currentFormValues={currentFormValues} 
                                isTab={isTab}
                                tabName={downloadTabName}
                                //tableTabOptions={tableTabOptions?.[tabColumnKey] || null}
                                columns={ heatMapData?.downloadData?.downloadColumns || downloadColumns}
                                rows={heatMapData?.downloadData?.downloadRows || downloadRows}
                                chartOptions={chartOptions}
                                containerRef={highchartsRef}
                                isResultTypeOnlyChart = {resultType === "chart"  ? true : false}
                                tabByTableIndex={tabByTableIndex}
                                tabByTableOptions={tabByTableOptions}
                                isprofile = {Boolean(resultType === 'profile')} 
                                demography = {downloadDemography}
                                tabIndex = {tabIndex}
                            />
                        )
                    }

                    {
                        resultType === 'chart' && (
                            <DownloadImageButton 
                                currentFormValues={currentFormValues} 
                                containerRef={highchartsRef}
                                chartOptions={chartOptions}
                            />
                        )
                    }
                    {
                        (!isShowFilter && resultType === 'tabByTable') && hasShowChart && (
                            <ToggleSwitch 
                            labels={['', 'チャート表示']} 
                            checked={isShowChart} 
                            onChange={toggleIsShowChart}
                            isDarkTheme={false}
                            //disabled={}
                        />
                        )
                    }
                
                    {
                        !isShowFilter && (
                            <>
                                {
                                    tabCondition.useTab && (
                                        <ToggleSwitch 
                                            labels={['シングル', 'タブ']} 
                                            checked={isTab} 
                                            onChange={toggleIsTab}
                                            isDarkTheme={false}
                                            //disabled={}
                                        />
                                    )
                                }
                                {
                                    (tabCondition.useIndicator ||showChainAndSegmentSwitch) && (
                                        <ToggleSwitch 
                                            labels={['セグメント',showChainAndSegmentSwitch ? '企業名' : '指標']} 
                                            checked={isIndicator} 
                                            onChange={toggleIsIndicator}
                                            isDarkTheme={false}
                                            //disabled={}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    {/*
                    <Divider  orientation="vertical" flexItem/>
                    <ToggleSwitch 
                        labels={['DataGrid (MUI)', 'Table (Danfo.js)']} 
                        checked={isDanfo} 
                        onChange={toggleIsDanfo}
                        isDarkTheme={false}
                        color='secondary'
                        //disabled={}
                    />
                    */}
                </div>
                <div style={{
                    display : 'flex',
                    gap : 12,
                    gridGap : 12,
                }}>
                    <Button 
                        color={isShowFilter ? 'secondary' : 'primary'}
                        variant="text"
                        size="large"
                        startIcon={isShowFilter ? <PublishIcon /> : <GetAppIcon />}
                        onClick={()=>toggleShowFilter(!isShowFilter)}
                    >{
                        isShowFilter ? 'フィルター非表示' : 'フィルター表示'
                    }</Button>
                    <Button 
                        color="secondary"
                        variant="text"
                        size="large"
                        startIcon={<HighlightOffIcon/>}
                        onClick={closeResult}
                    >結果を閉じる</Button>
                </div>

            </div>
            { !isLoading && recordsCount && < RecordsCount record_count={recordsCount} /> }
            <ResultComponent />
            
        </div>
    );
}

const RecordsCount = (props) =>{

    let over_below = '以上'
    let record_count = parseInt(props?.record_count)

    if (record_count < 1000){
        if( record_count > 500){
            record_count = 1000
        }
        else if (record_count > 300)
            {record_count = 500}
        else if( record_count > 100){
            record_count = 300
        }
        else{
            record_count = 100
        }
        over_below = '未満'
    } else {
        let digit_count = record_count.toString().length
        let first_digit = Math.floor(record_count / Math.pow(10, digit_count - 1))

        if (first_digit >= 5) {
            first_digit = 5
        } else if (first_digit >= 3) {
            first_digit = 3
        } else {
            first_digit = 1
        }
        record_count = first_digit * Math.pow(10, digit_count - 1)
    }
    const numeral = require('numeral');
    const myNumeral = numeral(record_count);
    const value = myNumeral.format('0,0');
    return <div style={{textAlign:"end", fontSize: "1rem",color: "rgba(0, 0, 0, 0.7)" , paddingRight:"12px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: "bold"}}> 件数: {value}件 {over_below}</div>
}