import React, { useState, useContext, useEffect } from 'react';
import { doc, collection, getDocs, where,query, addDoc,Timestamp, deleteDoc, setDoc } from "@firebase/firestore";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import moment from 'moment';

import { AuthContext } from "../../AuthenticationContext";

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import Divider from '@material-ui/core/Divider';

import UserConsentDialog from './UserConsentDialog';

import AddNewFavourite from "./AddNewFavourite";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  favList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 250
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  MenuItemRoot: { height: "50px" },
  MenuItemSelected: {
    backgroundColor: '#3f51b5 !important',
    color: 'white',
  },
  deleteIcon: { fontSize: "20" },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  loadingContainer : {
    position : 'absolute',
    top : 0,
    bottom : 0,
    right : 0,
    left : 0,
    backgroundColor : 'rgba(0, 0, 0, 0.5)',
    color : '#ecf0f1',
    display : 'flex',
    justifyContent : 'center',
    alignItems : 'center',
    zIndex : 9999,
}
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FavouriteEdit(props) {
  const {
    currentFormValues=null,  
    minDate,
    maxDate
  } = props
  
  const classes = useStyles();
  const [selectedFavourite, setSelectedFavourite] = useState( currentFormValues?.current?.selectedFavourite || null);
  const [listOfFavouritesNames, setListOfFavouritesNames] = useState( currentFormValues.current.listOfFavouritesNames || []);
  const [aboutToDeleteFavourite, setAboutToDeleteFavourite] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openUserConsentDialog, setOpenUserConsentDialog] = useState(false);

  const [isInProgress, setIsInProgress] = useState(false);
  const {db,user,updateSelections,setUpdateSelections,} = useContext(AuthContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const [favoriteHistory, setFavoriteHistory] = useState(  currentFormValues.current.historyData || null);
  const [favoriteHistoryFetchError,setFavoriteHistoryFetchError] = useState(null);
  const [favoriteInDetail,setFavoriteInDetail] = useState(null);
  const [favName, setFavName] = useState(null);
  const [favFlag, setFavFlag] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);
  const [updating, setUpdating] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListItemClick = (name) => {
    setSelectedFavourite(name);
    let selectedData = favoriteHistory?.find(e=>e.favourite_name==name);
    if(selectedData){
      let  filter_info  = JSON.parse(selectedData?.filter_info);
      let checkedOwnStores = filter_info?.store ;
      let store =  currentFormValues?.current?.store?.map(c=> (
        {...c,
          checked:c?.value?.chain_id === checkedOwnStores?.chainId && checkedOwnStores?.checkedStores?.includes(c?.value?.store_id) ,
          selected: c?.value?.store_id == checkedOwnStores?.selectedStoreForMeshIds ,
          value:{...c?.value}}) );
      // const checkCategories = (c)=>{
      //   c.map(n=>({
      //   checked:c?.[n?.groupKey].includes(n?.value) ,
      //   ...n,
      //   child: n?.child && checkCategories(n?.child)
      // })
      // )}
      let competingStores = filter_info?.competingStores;
      window.localStorage.setItem('competingStoresCheckIds', JSON.stringify(competingStores))
      const favouriteTargetsList = [...filter_info?.target].reduce((o,c)=> c.checked ?[...o,c?.value] :  o ,[])

      const target = currentFormValues?.current?.target?.map(c=> (
         {...c,
          checked:favouriteTargetsList?.includes(c?.value)}
        ) )
      const favouriteSegmentsList = [...filter_info?.segment]?.reduce((o,c)=> c.checked ?[...o,c?.value] :  o ,[])
      const segment = currentFormValues?.current?.segment?.map(c=> (
        {...c,
         checked:favouriteSegmentsList?.includes(c?.value)}
       ) );
      console.log({currentFormValues :  currentFormValues?.current })
      currentFormValues.current = {
        ...currentFormValues.current,
        ...filter_info, 
        min_date:minDate, 
        max_date:maxDate, 
        selectedFavourite:name,
        store,
        target,
        segment
       };
      setUpdateSelections(filter_info);
    }
    handleClose();
  };
  const deleteFavourite = async (name) => {
    handleCloseUserConsentDialog();
    let selectedData = favoriteHistory?.find(e=> e.favourite_name===name);
    if (name === selectedFavourite) setSelectedFavourite(null);
    try{
      await deleteDoc(doc(db, "filter_history", selectedData.id));
      getFavoritesHistory();

    }catch(e){
    
      setUpdateError("Not able to delete, system error!")
    }
  };

  const handleListItemDeleteClick = (name) => {
    setOpenUserConsentDialog(() => { setAboutToDeleteFavourite(name); return true });
  };

  const handleCloseUserConsentDialog = () => {
    setOpenUserConsentDialog(false);
  };

const  getFavoritesHistory  = async () => {
    const favoriteCollectRef = collection(db,"filter_history");
    let q = query(favoriteCollectRef, where("email",'==',user?.email));
    !isUpdating && setIsUpdating(true);
    await getDocs(q).then((snapshot)=>{
        let historyData = []
        if(!snapshot.empty) {
            snapshot.forEach((favoriteDoc)=>{
                historyData.push({
                    id:favoriteDoc.id,
                    ...favoriteDoc.data(),
                    min_date:minDate,
                    max_date:maxDate,
                  });
            })
        }
        currentFormValues.current.historyData = historyData;
        currentFormValues.current.listOfFavouritesNames = historyData.map((h,i)=>h?.favourite_name);
        setFavoriteHistory(historyData);
        setListOfFavouritesNames(()=>historyData.map((h,i)=>h?.favourite_name));

    }).catch((error)=>{
        setError(error.code)
        setFavoriteHistoryFetchError("Error occured while fetching favorite history, try again !")
    }).finally(()=>{
      setIsUpdating(false)
    })
}

const saveUserChanges = async (name,updateExistingFavouriteName=false,id=null) =>{
  // check if filters are selected 
  let groupId = user?.displayName?.slice(9);
  const favoriteCollectRef = collection(db,"filter_history");
  successMessage && setsuccessMessage(null);
  updateError && setUpdateError(null);
  setIsUpdating(true)
  let selectedOwnStores = currentFormValues?.current?.store?.reduce((o,n)=>{
    if(n?.selected){
      if(n.checked){
        return {chainId:n?.value?.chain_id,checkedStores:[...o?.checkedStores,n?.value?.store_id ], selectedStoreForMeshIds:n?.value?.store_id}
      }
      return {chainId:n?.value?.chain_id,checkedStores:[...o?.checkedStores ],selectedStoreForMeshIds:n?.value?.store_id}
    }
    if(n.checked){
      return {chainId:n?.value?.chain_id,checkedStores:[...o?.checkedStores,n?.value?.store_id ],selectedStoreForMeshIds:o?.selectedStoreForMeshIds}
    }
    return o
  },{chianId:null,checkedStores:[],selectedStoreForMeshIds:false}) ;
  let competingStores = currentFormValues?.current?.competingStores?.reduce((o,n)=>{
    let old = [...o];
    n?.child?.forEach(c=>{
      if(c?.checked){
        old.push(`${c?.competitor_store_chain_id}:${c?.competitor_store_id}`);
      }
    })
    return old
  },[]) || [];

  // this code is required in future so don't delete it.
  // let categories = currentFormValues?.current?.categories?.reduce((o,n)=>{
  //   let old = {...o};
  //   let recursion = (c) =>{
  //     if (c?.checked){
  //       old[c?.groupKey || "category_lv4_cd"] = old?.[c?.groupKey || "category_lv4_cd"] ? [...old?.[c?.groupKey || "category_lv4_cd"],c?.value] : [c?.value] ;
  //     }
  //     if(c?.child){
  //       recursion(c?.child)
  //     }
  //   }
  //   recursion(n);
  //   return old;
  // },{}) || {};
 

  const updates = {
      create_time:  Timestamp.now(),
      email: user?.email,
      favourite_flag: true,
      favourite_name: name.trim(),
      filter_info: JSON.stringify({...currentFormValues?.current,
        min_date:"",
         max_date:"", 
         store:selectedOwnStores, 
         competingStores: competingStores,
         tempCompetingStores:false,
         searchText:"",
         historyData:false,
         listOfFavouritesNames:false,
         selectedFavourite:false,
        }),
      group_id:groupId,
      page:  location.pathname,
  }

    if(updateExistingFavouriteName){
      await setDoc(doc(db,"filter_history",id),  {...updates}).then((snap)=>{
        getFavoritesHistory();
      });
      return;
    }
  await addDoc(favoriteCollectRef,  {...updates}).then(
    (snap) => {
      currentFormValues.current.listOfFavouritesNames = currentFormValues.current.listOfFavouritesNames ? [...currentFormValues.current.listOfFavouritesNames, name] : [name]
      setListOfFavouritesNames((old) => [...old, name]);
      currentFormValues.current.historyData =  currentFormValues.current.historyData ? [... currentFormValues.current.historyData, {id:snap.id,...updates}] : [{id:snap.id,...updates}];
      setFavoriteHistory((old) => [...old, {id:snap.id,...updates}]);
    }).catch((error) => {
      setUpdateError(error.code)
    })
    .finally(() => {
      setIsUpdating(false)
    })
   
}

const updateUserChanges= async (name)=>{
  let selectedData = favoriteHistory?.find(e=> e.favourite_name===name);
  if (name === selectedFavourite) setSelectedFavourite(null);
    saveUserChanges(name,true,selectedData.id)
    getFavoritesHistory();

}

useEffect(()=>{
  let currentForm = currentFormValues;
  if(!currentForm.current.historyData ||  !currentForm.current.listOfFavouritesNames ){
    getFavoritesHistory();
  }  
},[]);


  const updateOldFavouriteName = (name) => {
    updateUserChanges(name);
  }
  const createNewfavouriteName = async (name) => {
    await  Promise.resolve(saveUserChanges(name)).then(res =>res);
  }
  const deleteOldFavouriteName = (name) => {
    deleteFavourite(name);
  }
  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary" onClick={handleClick}
      >
      お気に入り条件{ <ExpandMoreRoundedIcon /> }
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List className={classes.favList} subheader={<li />}>
          {
            listOfFavouritesNames.length > 0 ?
              listOfFavouritesNames.sort((a,b)=> a > b ? 0 : -1).map((name) => (
                <MenuItem key={name} value={name} selected={selectedFavourite === name}
                  classes={{
                    root: classes.MenuItemRoot,
                    selected: classes.MenuItemSelected,
                  }}
                >
                  <ListItemText primary={name} onClick={() => handleListItemClick(name)} />
                  <IconButton onClick={() => { handleListItemDeleteClick(name) }} aria-label="delete">
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </MenuItem>
              ))
              :
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "50px" }}
              >
                <InfoOutlinedIcon style={{ fontSize: "40px", margin: "5px" }} color="disabled" />
                <ListItemText>No Data</ListItemText>
              </Grid>
          }
        </List>
        <Divider />
        <AddNewFavourite
          listOfFavouritesNames={listOfFavouritesNames}
          updateOldFavouriteName={updateOldFavouriteName}
          createNewfavouriteName={createNewfavouriteName}
        />
        {isUpdating && <Loading/>}
      </Menu>
      <UserConsentDialog
        aboutToDeleteOrUpdateFavouriteName={aboutToDeleteFavourite}
        open={openUserConsentDialog}
        close={handleCloseUserConsentDialog}

        handleDeleteClick={deleteOldFavouriteName}
      />
    </>
  );
}

const Loading = () => {
  const classes = useStyles();
  return (
      <div className={classes.loadingContainer}>
              <CircularProgress style={{
                  width : 16, 
                  height : 16, 
                  marginRight : 12,
                  color : 'rgba(255, 255, 255, 0.75)'
              }} />
      </div>
  )
}

export default React.memo(FavouriteEdit);
