import React,{ useContext, useState } from "react";
import { AuthContext } from "../../AuthenticationContext";
import { Form, FormGroup, Label, Col, Input, Button , InputGroup,  FormFeedback, FormText, Spinner} from "reactstrap";
import { Snackbar} from "@material-ui/core";


export default function EditUserInformation(){
    const {userProfile,
        updateUserName, 
        logout,
        setUserProfile,
        userNameUpdated,
        setUserNameUpdated,
        userNameUpdateError,
        setUserNameUpdateError
    } = useContext(AuthContext)
    const [editName, setEditName] = useState(false)
    const [newName, setNewName] = useState(null)
    const [invalid, setInvalid] = useState((newName && newName.length > 100) || newName === userProfile?.user_name)
    const [invalidMessage, setInvalidMessage]= useState(null);
    const [submitting, setSubmitting] = useState(false);


    console.log(userProfile)
    const handleSubmit = () =>{
        userNameUpdated && setUserNameUpdated(false);
        console.log("Handle sumbit called",newName, userProfile.user_name, !newName , newName === userProfile?.name , "hidden: ",!invalid && !invalidMessage)
        if(!newName) {
            setInvalid(true)
            setInvalidMessage("Name required to submit !")
            console.error("Name required to submit !")
            return
        }else if(newName.trim() === userProfile?.user_name){
            setInvalid(true)
            setInvalidMessage("New name and old name should not be same !")
            console.error("New name and old name should not be same !")
            return
        }else if(newName.length > 100){
            console.error("Invalid name")
            setInvalid(true)
            setInvalidMessage("New name should not be more than 100 character")
            console.error("New name length should not be more than 100 character !")
            return
        }
        setInvalid(false)
        setInvalidMessage(null)
        console.log("Updating name")
        setSubmitting(true)
        updateUserName(newName).then((snap)=>{
            setSubmitting(false)
            console.log("Update respon :",snap)
            !userNameUpdated && setUserNameUpdated(true)
            userNameUpdateError && setUserNameUpdateError(() =>{return null })
            setUserProfile((prevState) =>{return  {...prevState, 'user_name':newName}})
        }).catch((error) =>{
            if( error.code === "permission-denied") { 
                logout();
            }
            else{
                userNameUpdated && setUserNameUpdated(false)
                setUserNameUpdateError((c) =>{return JSON.stringify( "Error occured, try again affter some time !")})
            }
            setSubmitting(false)
        })
    }

    const onClickEdit = () =>{
        setEditName((prevState) => {return !prevState})
        userNameUpdated && setUserNameUpdated(false)
        userNameUpdateError && setUserNameUpdateError(false)

    }
    return(<>
    <h1>Profile</h1>
    <Form>
        <FormGroup row>
            <Label for="exampleEmail" sm={2} > Email </Label>
        <Col sm={5}>
            <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da" }} >{userProfile?.email}</div>      
        </Col>
        </FormGroup>

        <FormGroup row>
            <Label for="exampleEmail" sm={2} > Name </Label>
            <Col  sm={5}>
                <InputGroup>
                        <div className="form-control"  >{userProfile?.user_name}</div>   
                    <Button disabled={submitting} onClick={onClickEdit} color={!editName ? "primary" : "secondary"} >
                        { !editName ? "Edit" : "Cancel" }
                    </Button>
                </InputGroup>
                {editName &&  <> 
                <Input disabled={submitting} invalid={invalid} style={{marginTop:"5px"}} onChange={(e) => {setNewName(e.target.value )}} value={newName || ""} placeholder="Give a new name"  /> 
                    <FormFeedback  >
                        {invalidMessage}
                    </FormFeedback>
                <FormText>
                    New name should not be more than 100 characters
                </FormText>
                </>}
                
            </Col>
        </FormGroup>

        <FormGroup row>
            <Label for="exampleEmail" sm={2} > Role </Label>
        <Col sm={5}>
            <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da"}} >{userProfile?.role_id}</div>
        </Col>
        </FormGroup> 

        <FormGroup row>
            <Label for="exampleEmail" sm={2} > Group Id </Label>
        <Col sm={5}>
            <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da"}} >{userProfile?.group_id}</div>
        </Col>
        </FormGroup>
        <Snackbar
            anchorOrigin={{horizontal:"right", vertical:"top"}}
            open={userNameUpdated}
            autoHideDuration={5000}
            message="Name updated successfully !"
            key="success"
            onClose={()=>{setUserNameUpdated(false)}}
        />
        
        <Snackbar
            anchorOrigin={{horizontal:"right", vertical:"top"}}
            open={!userNameUpdated &&  userNameUpdateError}
            autoHideDuration={5000}
            message={userNameUpdateError}        
            key="error"
            onClose={()=>{setUserNameUpdateError(null)}}
        />
        <FormGroup row >
            <Col sm={7} >     
                <Button disabled={submitting} hidden={!editName}  style={{float: "right"}} color="primary" onClick={handleSubmit} >{ submitting ? <Spinner color="light" size="">Loading...</Spinner> : "Submit" }</Button> 
            </Col>
        </FormGroup> 
        
    </Form>
    </> )
}

