import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import InputBase from '@material-ui/core/InputBase';
import { Tooltip } from '@material-ui/core';
import SelectBox from '../SelectBox/SelectBox.jsx';
const useStyles = makeStyles({
    root: {
        borderTop: 'solid 1px #ddd',
        boxSizing : 'border-box',
        padding : '0 12px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        minHeight : 70,
        '& $gridContainer:first-child' : {
            borderRight : 'solid 1px #ddd',
        }
    },
    gridContainer : {
        display : 'flex',
        alignItems : 'center',
        padding : '0 12px',
    },
    gridBox : {
        padding : '0 4px',
    },
    input : {
        width : 90,
        transition : 'width .2s linear',
        border : 'solid 1px rgba(149, 165, 166,0)',
        display : 'flex',
        padding : '4px 12px',
        borderRadius : 4,
        display : 'flex',
        alignItems : 'center',
        transition : 'background .2s linear, border .2s linear',
        '& $input' : {
            flex : 1,
            color : '#34495e',
        },
        '&:focus-within, &:hover' : {
            backgroundColor : 'rgba(255,255,255,.75)',
            border : 'solid 1px rgba(149, 165, 166,1.0)',
        }
    },
    inputElement : {
        textAlign : 'right',
        color : '#34495e',
    },
    unit : {
        color : '#7f8c8d',
    },
    label : {
        paddingRight : 8,
        color : 'rgb(127, 140, 141)',
    }
});

export default function Condition(props) {
    const classes = useStyles();
    const {
        currentFormValues,
        isUpdate,
    } = props;
    const [paramBox, setParamBox] = React.useState([]);
    const unselectStoreLabel = '全てのストア';
    const getStoreSelectOptions = (paramKey) => {
        const {
            store=[],
            competingStores=[],
            conditions={},
        } = currentFormValues?.current || {};
        const chainNames = [...new Set([...store.filter(data => data.checked), ...competingStores.filter(data => data.checked)].map(data => {
            const {
                chain_name=null,
                competitor_store_company_name=null,
            } = data?.value || {};
            return chain_name || competitor_store_company_name || null;
        }).filter(name => name !== null && name !== ' '))];
        const selectedCustomerUnitPriceStore = conditions?.[paramKey]?.selectedStore || unselectStoreLabel;
        const storeOptions = ([{
            label : unselectStoreLabel,
            value : null,
        }].concat(chainNames.map(name => {
            return{
                label : name,
                value : name,
            }
        })))
        .map(option => {
            return {
                ...option,
                selected : option.label === selectedCustomerUnitPriceStore,
            }
        })
        
        return storeOptions;
    }
    

    const updateConditionParams = (paramKey, updateKey, value) => {
        const newConditions = {...currentFormValues?.current?.conditions || {}};
        if(newConditions[paramKey] === undefined){
            newConditions[paramKey] = {};
        }
        newConditions[paramKey][updateKey] = value;
        currentFormValues.current.conditions = newConditions;
    }
    const ConditionParams = (props) => {
        const {
            paramKey,
            options=[],
        } = props;
        const params = currentFormValues?.current?.['conditions']?.[paramKey] || {};
        const {
            title=null,
            unit=null,
            type='',
            comparison='below',
            value=0,
        } = params;
        
        const [storeOptions, setStoreOptions] = React.useState(options);
        // I think the general order is above(以上) -> below(以下)
        // But follow the order of existing apps and reverse it...
        const [conditionTypeOptions, setConditionTypeOptions] = React.useState([/*{
            label    : '条件未選択',
            value    : '',
            selected : type === '',
        },*/{
            label    : 'カスタム',
            value    : 'custom',
            selected : type === 'custom',
        },{
            label    : '平均以下',
            value    : 'below',
            selected : type === 'below',
        },{
            label    : '平均以上',
            value    : 'above',
            selected : type === 'above',
        }]);
        const [comparisonOptions, setComparisonOptions] = React.useState([{
            label    : '以下',
            value    : 'below',
            selected : comparison === 'below',
        },{
            label    : '以上',
            value    : 'above',
            selected : comparison === 'above',
        }]);
        const [inputValue, setInputValue] = React.useState(value);
        const handleInput = (event) => {
            const value = event.target.value;
            updateConditionParams(paramKey, 'value', value);
            setInputValue(value);
        }
        const changeCondition = (value) => {
            updateConditionParams(paramKey, 'type', value);
            setConditionTypeOptions(conditionTypeOptions.map(option => {
                return {
                    ...option,
                    selected : option.value === value,
                }
            }));
        }
        const changeComparison = (value) => {
            updateConditionParams(paramKey, 'comparison', value);
            setComparisonOptions(comparisonOptions.map(option => {
                return {
                    ...option,
                    selected : option.value === value,
                }
            }));
        }
        const changeSelectStore = (value) => {
            if(value === unselectStoreLabel) value = null;
            updateConditionParams(paramKey, 'selectedStore', value);
            setStoreOptions(storeOptions.map(option => {
                return {
                    ...option,
                    selected : option.value === value,
                }
            }));
        }
        const storeLabel = storeOptions.find(option => option.selected)?.label || null;
        const isShowCondition = storeLabel !== unselectStoreLabel;

        return (
            <div className={classes.gridContainer}>
                <Typography className={classes.label}>{title}</Typography>
                <SelectBox 
                    options={storeOptions} 
                    unselectLabel={unselectStoreLabel}
                    onChange={changeSelectStore} 
                    labelStyle={{
                        maxWidth : 160,
                        whiteSpace : 'nowrap',
                        textOverflow : 'ellipsis',
                        overflow : 'hidden',
                    }}
                />
                {
                    isShowCondition && (
                        <SelectBox 
                            options={conditionTypeOptions} 
                            onChange={changeCondition}
                            unselectLabel="条件未選択" 
                        />
                    )
                }


                {
                    isShowCondition && type === 'custom' && (
                        <>
                            <div className={classes.input}>
                                <InputBase
                                    value={inputValue}
                                    margin="dense"
                                    onInput={handleInput}
                                    inputProps={{
                                        type: 'number',
                                        'aria-labelledby': 'input',
                                        className : classes.inputElement
                                    }}
                                />
                                <div className={classes.unit}>{unit}</div>
                            </div>
                            <SelectBox 
                                options={comparisonOptions} 
                                onChange={changeComparison}
                                unselectLabel="条件未選択" 
                            />
                        </>
                    )
                }

            </div>
        )
    }

    React.useEffect(() => {
        setParamBox(['averageCustomerSpend', 'walletShare'].map(paramKey => {
            const _storeOptions = getStoreSelectOptions(paramKey);
            return {
                options : _storeOptions,
                paramKey,
            }
        }))
    }, [isUpdate, currentFormValues?.current?.['conditions']]);

    return (
        <div className={classes.root}>

            {
                paramBox.map(params => {
                    const {
                        paramKey,
                        options,
                    } = params;
                    return <ConditionParams key={`condition-${paramKey}`} options={options} paramKey={paramKey} />
                })
            }
        </div>
    );
}
