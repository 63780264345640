import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../common/Button/Button.jsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    toolFooter : {
        padding : '12px 0',
        borderTop : 'solid 1px #ddd',
        display : 'flex',
        justifyContent : 'center',
    },
}));
export default function ToolFooter(props) {
    const {
        to=null,
        text='submit',
        submitDisabled=true,
    } = props;
    const classes = useStyles();
    return (
        <div className={classes.toolFooter}>
            <Button 
                size="large" 
                color="primary" 
                variant='contained' 
                style={{minWidth : 240}}
                component={Link}
                to={to}
                disabled={submitDisabled}
            >{text}</Button>
        </div>
    );
}