import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/ja'; // 日本語ローカライズ

import Toolbar from '../../../components/Toolbar/Toolbar.jsx';
import FilterList from '../../../components/FilterList/FilterList.jsx';
import ToolFooter from '../../../components/ToolFooter/ToolFooter.jsx';
import ResultChart from '../../../components/Result/ResultChart.jsx';
import {config} from '../../../config.jsx';

const useStyles = makeStyles((theme) => ({

}));
export default function BoxPlot(props) {
    const {
        range={},
        masterData=null,
    } = props;
    
    const location = useLocation();

    const [storeJson, setStoreJson] = React.useState([]);
    const [segmentJson, setSegmentJson] = React.useState([]);
    const [targetJson, setTargetJson] = React.useState([]);
    const [competingStoresJSON, setCompetingStoresJSON] = React.useState([]);
    const [startDate, setStartDate]       = React.useState(range.start || moment('2021-01-01'));
    const [endDate, setEndDate]           = React.useState(range.end || moment('2022-01-01'));
    const [isShowResult, setIsShowResult] = React.useState(false);
    const [isShowFilter, setIsShowFilter] = React.useState(true);
    const [isTab, setIsTab] = React.useState(true);
    const [isIndicator, setIsIndicator] = React.useState(true);
    const [filterOptions, setFilterOptions] = React.useState([]);
    let showExtraLable =  Boolean(window.localStorage.getItem("groupID") == '569')

    const [categoryLevelOptions, setCategoryLevelOptions] = React.useState([{
        label : `レベル2${showExtraLable ? "(DPT枝番)" : ''}`,
        value : 2,
        selected : false,
    },{
        label : `レベル3${showExtraLable ? "(ライン)" : ''}`,
        value : 3,
        selected : false,
    },{
        label : `レベル4${showExtraLable ? "(クラス)" : ''}`,
        value : 4,
        selected : true,
    }])
    const classes = useStyles({
        isShowResult,
    });
    const toggleShowFilter = (flag) => {
        setIsShowFilter(flag);
    }
    const toggleIsTab = () => {
        setIsTab(!isTab);
    }
    const toggleIsIndicator = () => {
        setIsIndicator(!isIndicator);
    }

    const changeCategoryLevelOptions = (value) => {
        const _categoryLevelOptions = categoryLevelOptions.map(option => {
            return {
                ...option,
                selected : option.value === value,
            };
        })
        setCategoryLevelOptions(_categoryLevelOptions);
    }

    React.useEffect(()=>{
        if(!masterData) return;
        const {
            competingStores,
            segment,
            store,
            target,
        } = masterData;
        setStoreJson(store);
        setSegmentJson(segment);
        setTargetJson(target);
        setCompetingStoresJSON(competingStores);

    }, [masterData]);
    React.useEffect(()=>{
        setFilterOptions([{
            json  : storeJson,
            title : '対象店舗',
            searchInput : {
                placeholder : '店舗名検索',
            },
            isStoreFilter : true,
        },{
            json  : segmentJson,
            title : 'セグメント',
        },{
            json  : targetJson,
            title : '対象データ',
        },{
            type  : 'group',
            json  : competingStoresJSON,
            title : '競合店',
        }]);
    }, [storeJson, segmentJson, targetJson, competingStoresJSON])
    React.useEffect(()=>{
        const {
            pathname,
        } = location;
        const _isShowResult = Boolean(pathname.search(/result/) > -1);
        setIsShowResult(_isShowResult);
        !_isShowResult && toggleShowFilter(true);
    }, [location]);
    
    return (
        <>
            
            {
                isShowFilter && (
                    <>
                        <Toolbar 
                            showCategoryLevel={true}
                            categoryLevelOptions={categoryLevelOptions}
                            changeCategoryLevelOptions={changeCategoryLevelOptions}
                            isShowResult={isShowResult}
                            setIsShowResult={setIsShowResult}
                            toggleShowFilter={toggleShowFilter}
                        />
                        <FilterList 
                            filterOptions={filterOptions}
                            isShowResult={isShowResult}
                        />
                    </>
                )
            }
            
            {
                !isShowResult && (
                    <ToolFooter 
                        to={'/pricing/boxPlot/result'}
                        text='データ作成'
                    />
                )
            }

            <Route path='/pricing/boxPlot/result' render={() => {
                return (
                    <ResultChart 
                        isShowFilter={isShowFilter} 
                        toggleShowFilter={toggleShowFilter}
                        isTab={isTab}
                        toggleIsTab={toggleIsTab}
                        isIndicator={isIndicator}
                        toggleIsIndicator={toggleIsIndicator}
                    />
                )
            }} />
        </>
    );
}