import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useHistory, useLocation } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '../../../common/Button/IconButton.jsx';
const useStyles = makeStyles((theme) => ({
    Divider : {
        margin : '8px 0',
    }
}));

export default function StoreFilterOptions(props) {
    const {
        onChange,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (label) => () => {
        if(label){
            setOptions(options.map(option => {
                return {
                    ...option,
                    selected : option.label === label,
                }
            }));

        }else{
            setAnchorEl(null);
        }

    };
    const changeSelect = (label, selected) => () => {
        setOptions(options.map(option => {
            return {
                ...option,
                selected : option.label === label ? !option.selected : option.selected,
            }
        }));

    }


    return (
        <div style={{
            display : 'flex',
            alignItems : 'stretch',
        }}>
            <IconButton 
                aria-controls="store-filter-menu" 
                aria-haspopup="true" 
                onClick={handleClick}
                color='default'
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="store-filter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose(null)}
            >
                {
                    options.map(option => {
                        const {
                            label,
                            selected,
                        } = option;
                        return (
                            <MenuItem key={`storeFilter-${label}`} onClick={changeSelect(label, !selected)} selected={selected}>{label}</MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    );
}
