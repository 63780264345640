import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import ColumnChart from '../Charts/ColumnChart';
import PieChart from '../Charts/PieChart';
import Tabs from '../Tabs/Tabs.jsx';
import demographyOptions from './demography.json';
import { max } from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow : 1,
    },
    paper : {
        padding : '8px 0',
    },
    chartTitle : {
        color : '#7f8c8d',
        textAlign : 'center',
    },
    sectionTitle : {
        marginTop : 12,
        marginBottom : 8,
        color : '#34495e',
        fontWeight : 800,
    },
    tabContents : {
        height : 'calc(100vh - 240px)',
        overflowY : 'auto',
        overflowX : 'hidden',
    }
}));
export default function Demography(props) {
    const {
        options=[],
        currentFormValues={},
        tabIndex,
        setTabIndex
    } = props;
    const classes = useStyles();
    const [tabOptions, setTabOptions] = React.useState([]);
    const DemographyChart = (props) => {
        const {
            title,
            options,
            isBarChart=false,
            yAxisTitle = false,
            xAxisTitle = false,
        } = props;
        const singleParamNames = [
            'gender',
            'has_car',
            'has_child',
            'has_pet',
            'has_school_child',
            'is_homemaker',
            'is_married',
            'is_working',
        ]
        const chartConfig   = demographyOptions[title];
        const gridWidth     = chartConfig?.gridWidth || 2;
        const isPercentage = chartConfig?.isPercentage !== undefined ? chartConfig.isPercentage : true;
        
        //let params = singleParamNames.includes(title) ? options[0] : options;
        let params = options;
        // I don't like the idea of getting chart settings from JSON to create chart data.
        // Response data on the API side is insufficient, so we have no choice but to implement it.
        const defaultValues = chartConfig?.default || null;
        if(defaultValues && !isBarChart ){
            let tmp = [];
            if(defaultValues instanceof Array){
                tmp = defaultValues.map(d => {
                    const keys = Object.keys(d);
                    const valueKey = keys[0];
                    const defaultValue = d[valueKey];
                    const existParam = keys.length === 1 ? params[valueKey] !== undefined ? params : null : params.find(p => Number(p[valueKey]) === Number(defaultValue));
                    if(existParam){
                        return existParam;
                    }
                    return d;
                });
            }else{
                tmp = {};
                Object.keys(defaultValues).forEach(key => {
                    const val = (params[key] !== undefined) ? params[key] : defaultValues[key];
                    tmp[key] = val;
                });
            }

            params = tmp;
        }
        const categories = Object.keys(params);
        let groupedCategoriesData = false;
        if(isBarChart){
            let maxValue = max(params.map(c=>c?.freq));
            const grouRange=  maxValue <5  ? 5: Math.ceil((maxValue < 5 ? 5 : maxValue)/5);
            let groupedCategories = Array(maxValue <5 ? 2:6).fill(0).reduce((old,c,i)=>{
                if(i==0){
                    return [{min:0,max:0,value:0}]
                }
                let prevValues = old[i-1]
                return [...old, {min:prevValues?.max+1, max: prevValues?.max +grouRange,value:0} ]
            },[])

            params.forEach((unit)=>{
                let {
                    scaled_value,
                    freq,
                } = unit
                 let group = groupedCategories.find(c=> freq >= c.min && freq <= c.max )
                 
                if(group){
                    group.value += parseFloat(scaled_value) ;
                }
            })
            groupedCategoriesData = groupedCategories.map(c=>({...c,value: parseInt(c.value ) }))
        }
        const data = !isBarChart && categories.map((key, i) => {
            const value = params[key];
            const isNumber = isFinite(value);
            const y = isNumber ? value : value.val;
            // income etc...
            if(!isNumber){
                categories[i] = value?.age 
                || value?.ten_thousandth 
                || value?.score 
                || value?.freq 
                || categories[i];
            }
            return {
                y : Number((y * 100).toFixed(2)),
                color : chartConfig?.colors?.[key] || chartConfig?.colors?.['*'] || '#1abc9c'
            }
        });
        const chartOptions = {
            chart : {
                type :  isBarChart ? 'bar' : 'column',
                height : isBarChart ? 200 : 140,
                marginTop : 12,
                marginLeft : isBarChart ? 90 : 0,
                marginRight : isBarChart ? 10 : 0,
            },
            xAxis : isBarChart ? {
                categories: groupedCategoriesData?.map((c,i)=>  i === 0 ? `0`: c.min === c.max ? `${c.max}`: `${c.min}-${c.max}`),
                title:{
                    text: yAxisTitle
                }
            } : {
                categories : categories.map(name => {
                    return `${chartConfig?.categories?.[name]?.ja || name}${chartConfig?.unit?.ja || ''}`;
                }),
            },
            yAxis : isBarChart ? {
                min: 0,
                title: {
                    text: xAxisTitle,
                },
            } : {
                title : {
                    text :'',
                },
                labels : {
                    align : 'right',
                    x : -8,
                    y : -4,
                    style : {
                        color : '#7f8c8d',
                    },
                    enabled : false,

                },
                min : 0,
                //max : 1,
                gridLineWidth : 0,
                opposite : true,
            },
            series : 
            [{
                name : isBarChart ? "Total" : '割合' ,
                data : isBarChart  ? groupedCategoriesData?.map((c,i)=>{  return {y: Number((c.value).toFixed(2)), color : '#1abc9c'} }) :  data,
            }],
            tooltip : {
                valueSuffix : isPercentage && !isBarChart ? '%' : null,
            },
            plotOptions : {
                column : {
                    maxPointWidth : 18,
                }
            }
        }
        if(isPercentage && !isBarChart){
            chartOptions.yAxis.max =  100;
        }
        //console.log(demographyOptions[title]?.title?.ja || title, JSON.stringify(chartOptions, null, '\t'))
        return (
            <Grid item xs={isBarChart ? 4: gridWidth}>
                <Paper className={classes.paper}>
                    <Typography className={classes.chartTitle}>{
                        chartConfig?.title?.ja || title
                    }</Typography>
                    <ColumnChart options={chartOptions} />
                </Paper>
                
            </Grid>
        )
    }
    const SegmentChart = (props) => {
        const {
            options,
        } = props;
        const data = options.map(option => {
            const {
                seg,
                val,
                scaling_factor,
                scaled_value,
            } = option;
            const {
                segment=[],
            } = currentFormValues?.current || {};

            return {
                y : Number(Math.round(scaled_value)),
                name : segment.find(s => s.value === Number(seg))?.label || seg,
            }
        });
        const chartOptions = {
            chart : {
                type : 'pie',
                height : 140,
                margin : 0,
                padding : 0,
            },
            plotOptions : {
                pie : {
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f} %',
                        distance: -20,
                    }
                }
            },
            series : [{
                name : '人数',
                data : data,
            }]
        }
        return <PieChart options={chartOptions} />
    }
    const TabContents = () => {
        const contents = options[tabIndex];
        const {
            target : title,
            val,
        } = contents;
        const {
            segment_distribution,
            demography,
            visit_spots,
            retail_shop_use,
        } = val;
        return (
            <>
            <Typography className={classes.sectionTitle}>{demographyOptions['demography']?.title?.ja}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.chartTitle}>{
                            demographyOptions['segment']?.title?.ja || title
                        }</Typography>
                        <SegmentChart options={segment_distribution} />
                    </Paper>
                </Grid>
            {
                
                Object.keys(demography).map(key => {
                    return <DemographyChart title={key} options={demography[key]} />
                })
            }
            </Grid>
            <Typography className={classes.sectionTitle}>{demographyOptions['visit_spots']?.title?.ja}</Typography>
            <Grid container spacing={1}>
                {
                    Object.keys(visit_spots).map(key => {
                        return <DemographyChart title={key} options={visit_spots[key]} />
                    })
                }
            </Grid>
            <Typography className={classes.sectionTitle}>{demographyOptions['retail_shop_use']?.title?.ja}</Typography>
            <Grid container spacing={1}>
                {
                    Object.keys(retail_shop_use).map(key => {
                        let data = retail_shop_use[key].sort((a,b)=> a.freq >= b.freq ? 1:-1);
                        return    <DemographyChart title={key} options={retail_shop_use[key]} isBarChart={true} yAxisTitle="訪問回数" xAxisTitle="人数"/>
                    })
                }
            </Grid>
            </>
        )
    }
    const changeTabSelect = (selectedIndex) => {
        setTabIndex(selectedIndex);
        const _tabOptions = tabOptions.map((option, index) => {
            return {
                ...option,
                selected : index === selectedIndex,
            }
        })
        setTabOptions(_tabOptions);
    }
    React.useEffect(()=>{
        setTabOptions([...(options || [])].sort((a,b)=> demographyOptions?.[a?.target]?.order_id - demographyOptions?.[b?.target]?.order_id).map((option, index) => {
            const {
                target : title,
            } = option;
            return {
                name : demographyOptions[title]?.title?.ja || title,
                selected : index === tabIndex,
            }
        }))
    }, [options]);
    return (
        <div className={classes.root}>
            <Tabs tabOptions={tabOptions} changeTabSelect={changeTabSelect} />
            <div className={classes.tabContents}>
                <TabContents />
            </div>

        </div>
    )
}