import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { AuthContext } from '../../AuthenticationContext';
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import LogoutLink from '../Logout/LogoutLink.jsx';
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            //backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
            '& $label' : {
                backgroundColor: 'transparent !important',
            }
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        //borderTopRightRadius: theme.spacing(2),
        //borderBottomRightRadius: theme.spacing(2),
        paddingTop : 8,
        paddingBottom : 8,
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: 24,
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { 
        labelText, 
        labelIcon: LabelIcon, 
        labelInfo, 
        component="div",
        to="#",
        ...other 
    } = props;
    const {
        disabled,
    } = other;
    const color = 'rgb(63,81,181, 1)';
    const bgColor = 'rgb(63,81,181, 0.25)';
    return (
        <TreeItem
        key={`tree-view-item-${other.nodeId}`}
        label={
            <div className={classes.labelRoot} to={to}>
                {/*<LabelIcon color="inherit" className={classes.labelIcon} />*/}
                <div style={{
                    minWidth : 24,
                    fontSize : 20,
                    marginRight : 24,
                }}>
                    {props.labelIcon}
                </div>
                <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                </Typography>
                <Typography variant="caption" color="inherit">
                    {labelInfo}
                </Typography>
            </div>
        }
        style={{
            '--tree-view-color': color,
            '--tree-view-bg-color': bgColor,
        }}
        classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
        }}
        disabled={disabled}
        {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    //labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
}
const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
});

export default function AppMenu(props) {
    const classes = useStyles();
    const location = useLocation();
    const history  = useHistory();
    const {userProfile}  = useContext(AuthContext)
    const [appMenu, setAppMenu] = React.useState([]);

    const [treeSelected, setTreeSelected] = React.useState([]);
    const [treeExpanded, setTreeExpanded] = React.useState([]);
    const {
        open
    } = props;
    React.useEffect(() => {
        // set menu json 
        props.appMenu && setAppMenu(props.appMenu);
    }, [props.appMenu]);
    
    React.useEffect(() => {
        if(appMenu === null || !appMenu.length) return;
        const {
            pathname,
        } =location;
        const _defaultSelected = [];
        const _defaultExpanded = [];
        const flatNodes = [];
        appMenu.forEach(menu => {
            getFlatNodes(menu, flatNodes);
        });
        flatNodes.forEach(menu => {
            const searchRegExp = new RegExp(`^${menu.link}`);
            if(pathname.search(searchRegExp) !== -1){
                const {
                    parentIds=null,
                    nodeId,
                } = menu;
                _defaultSelected.push(String(nodeId));
                if(parentIds){
                    _defaultExpanded.push(parentIds.map(id => String(id)));
                }
                
            }
        });
        setTreeExpanded(_defaultExpanded.flat());
        setTreeSelected(_defaultSelected);
    }, [location, appMenu]);
    const getFlatNodes = (node, flatNodes, parentIds=[]) => {
        const {
            link=null,
            child=null,
        } = node;
        child && parentIds.push(node.nodeId);
        if(link){
            const _node = {...node};
            if(parentIds.length && !parentIds.includes(_node.nodeId)){
                _node.parentIds = parentIds;
            }
            flatNodes.push(_node);
        }
        
        Array.isArray(child) && child.map((node) => getFlatNodes(node, flatNodes, parentIds))
    }
    const renderTree = (nodes) => {
        const {
            label,
            iconClass,
            link=null,
            child=null,
            nodeId,
            disabled=false,
        } = nodes;
        return (
            <StyledTreeItem 
                //disabled={true} 
                key={`style-tree-item-${nodeId}`}
                nodeId={String(nodeId)}
                labelText={label} 
                labelIcon={<i className={iconClass}></i>}
                onLabelClick={link ? locationChange(link) : null}
                disabled={disabled}
            >
                {
                    Array.isArray(child) ? child.filter(menu => menu.required === undefined || menu.required.includes(roleId)).map((node) => renderTree(node)) : null
                }
            </StyledTreeItem>
        )
    }
    const locationChange = (to) => () => {
        history.push(to)
    }
    const toggleTreeExpand = (event, nodeIds) => {
        const currentTreeExpanded = [...treeExpanded];
        const _treeExpanded = nodeIds.map(id => {
            return currentTreeExpanded.includes(id) ? null : id;
        }).filter(id => id !== null);
        setTreeExpanded(_treeExpanded);
    }
    const nodeSelect = (event, selectId) => {
        setTreeSelected([selectId]);
    }
    const hasSelected = Boolean(treeSelected.length);
    const roleId      = userProfile?.role_id || null;
    //console.log({userProfile}, {roleId});
    return (

        hasSelected ? (
            <>
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    selected={treeSelected}
                    expanded={treeExpanded}
                    onNodeToggle={toggleTreeExpand}
                    onNodeSelect={nodeSelect}
                    style={{
                        minHeight : 'calc(100vh - (64px + 49px))',
                        overflowX : 'hidden',
                        overflowY : 'auto',
                    }}
                >
                    {
                        appMenu
                            .filter(menu => menu.required === undefined || menu.required.includes(roleId))
                            .map(node => renderTree(node))
                    }
                </TreeView>
                <LogoutLink shrink={!open} />
            </>
        ) : (
            <div style={{
                padding : '12px 24px',
            }}>
                {
                    new Array(3).fill(null).map((_, i) => <Skeleton height={36} key={`tree-view-skelton-${i}`} />)
                }
            </div>
        )

    );
}
