import React, {createContext, useRef, useEffect, useState, useCallback} from 'react';
import {getAuth,setPersistence, browserSessionPersistence, onAuthStateChanged, signOut, signInWithEmailAndPassword} from 'firebase/auth';
import {getFirestore ,  getDoc , doc, updateDoc} from 'firebase/firestore';
import {initializeApp, setLogLevel} from "firebase/app";
import { deleteDb } from './indexedDb.js';


import { useHistory } from 'react-router-dom';

const API_ENV = process.env.REACT_APP_ENV || "dev";
const API_KEY = ["demo","staging","dev"].includes(API_ENV) ? "AIzaSyBTG5EsRu7NqHCSuTP-76Mbu_t27hJ3p-I" : "AIzaSyDjL6mZKbtu9rP8XUNCugqxZviycjeZe1E"
const PJT_ID = ["demo","staging","dev"].includes(API_ENV) ? "gdsd-gsg-dwh-stg" : "gdsd-gsg-dwh-prd"

export const AuthContext = createContext({});
 
export function AuthProvider({children}) {

    const firebaseConfig = {
        apiKey: API_KEY,
        authDomain: `${PJT_ID}.firebaseapp.com`,
        projectId: PJT_ID,
      };
    
    const app = initializeApp(firebaseConfig)

    const auth = getAuth(app)
    const db = getFirestore(app);

    const apiGatewayURL = "https://dummy-gateway-4b4hcxda.an.gateway.dev/"

    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [token, setToken] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [initialError, setInitialError] = useState(null);
    const mountedRef = useRef(true);
    const history = useHistory();
    const [userNameUpdated, setUserNameUpdated] = useState(false);
    const [userNameUpdateError, setUserNameUpdateError] = useState(null) ;
    const [appPath , setAppPath ] =  useState(['/']) ;
    const [updateSelections,setUpdateSelections ] = useState(null)

function onAuthStateChangedFunc (newUser) {
        if(!mountedRef.current) return null;
        if(newUser){   
            setUser(newUser);
            const newGroupId = newUser?.displayName?.slice(9);  
            if( !newGroupId ) return null
            console.log("User from on AuthStateChanged: ", newUser);
            newUser.getIdTokenResult().then((jToken)=>{
                // document.cookie = `jwt=${jToken.token};secure`
                setToken(jToken.token)
            })
        } else{
            setUser(null);
            setToken(null);
        }
        setInitialError(null)
        setPassword(null)
        setIsLoading(false)        
    }
    useEffect(() => {
       const subscriber =  onAuthStateChanged(auth, onAuthStateChangedFunc);
       return subscriber; 
    }, []);
 
    useEffect(() => {
       return () => {
          mountedRef.current = false;
       };
    }, []);

    // // --------------------------- feetfch the user info from firestor ----------------------
    // useEffect(
    //      () => {
    //        if (!mountedRef.current)  return null;
    //        setInitialError(null)
    //        if (user) {
    //           setIsLoading(true);
    //                 console.log("User id: ", user?.uid)
    //                 const docRef = doc(db, "user_info", user?.uid);
    //                 getDoc(docRef).then((snap)=>{
    //                     if(snap.exists()){
    //                         console.log("dAata : ", snap.data(), "Type user dataa: ", typeof snap.data())
    //                         setUserProfile(snap.data())
    //                     }else{
    //                         console.log("No such document!");
    //                     }
    //                 }).catch((error) =>{
    //                     console.log("Error while fetching user data : ", error)
    //                 })
    //                 setIsLoading(false);
    //        } else {
    //            console.log("No user found to fetch the data")
    //           if (!mountedRef.current) {
    //              return null;
    //           }
    //           setUserProfile(null);
    //        }
    //     }, [user,db]
    // );
 
    function validateEmailAndPassword(){
        if(!email && !password){
            setErrorMessage("無効なメールアドレスとパスワード ! ")
            return false
        }
        if(!email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())){
            setErrorMessage("無効な電子メールアドレス ! ")
            return false
        }else if(!password || password.length === 0){
            setErrorMessage("無効なパスワード! ") 
            return false
        }
        setErrorMessage(null)
        return true
    }

    async function logout() { 
       if (!user) return null;
       setIsLoading(true);
       try {
          await signOut(auth);
       } catch (e) {
          console.error(e);
       }
    }

    async function updateUserName(user_name){
        const washingtonRef = doc(db, "user_info",user?.uid);
        return await updateDoc(washingtonRef, {
        user_name: user_name
        })
    }

    async function loginWithEmail(){
        if(isLoading) return null;
        if(!validateEmailAndPassword()) return null;
        if (!mountedRef.current) return null;
        setErrorMessage(null);
        setErrorCode(null);
        setError(null)
        setIsLoading(true) ;
        const result = await setPersistence(auth, browserSessionPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        }).catch((errorFromserver) => {
            if (!mountedRef.current) return null;
            setErrorCode(errorFromserver.code);
            setErrorMessage(()=>{
                return  errorFromserver.code === "auth/internal-error" ?  "内部エラーが発生しました。再試行してください。!" :
                errorFromserver.code === "auth/invalid-email" ? "メールIDが無効です !" :
                errorFromserver.code === "auth/invalid-password" ? "無効なパスワード !" :
                errorFromserver.code === "auth/wrong-password" ? "間違ったパスワード ！" :
                errorFromserver.code === "auth/user-not-found" ? "ユーザーが見つかりません ！" :  "不明なエラーが発生しました。再試行してください！";
            })
            return null;
        }).finally(()=>{
            setIsLoading(false)
        })
        return result;
    }

 
    return <AuthContext.Provider value = {
        {
            user,
            setUser,
            isLoading,
            setIsLoading,
            error,
            setError,
            email,
            setEmail,
            password,
            setPassword,
            errorMessage,
            setErrorMessage,
            logout,
            loginWithEmail,
            userProfile,
            setUserProfile,
            errorCode,
            initialError,
            token,
            apiGatewayURL,
            db,
            updateUserName,
            userNameUpdated,
            setUserNameUpdated,
            userNameUpdateError,
            setUserNameUpdateError,
            updateSelections,setUpdateSelections
        }
    }>{children} </AuthContext.Provider>;
 }