import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import InputBase from '@material-ui/core/InputBase';
import { Tooltip } from '@material-ui/core';
const useStyles = makeStyles({
    root: {
        padding : '0 12px',
        display : 'flex',
        alignItems : 'center',
    },
    label : {
        padding : '0 8px',
        color : '#7f8c8d',
        marginBottom : 4,
    },
    container : {
        display : 'flex',
        alignItems : 'center',
    },
    input : {
        width : 90,
        transition : 'width .2s linear',
        border : 'solid 1px rgba(149, 165, 166,0)',
        display : 'flex',
        padding : '4px 12px',
        borderRadius : 4,
        display : 'flex',
        alignItems : 'center',
        transition : 'background .2s linear, border .2s linear',
        '& $input' : {
            flex : 1,
            color : '#34495e',
        },
        '&:focus-within, &:hover' : {
            backgroundColor : 'rgba(255,255,255,.75)',
            border : 'solid 1px rgba(149, 165, 166,1.0)',
        }
    },
    inputElement : {
        textAlign : 'right',
        color : '#34495e',
    },
    sliderBox : {
        flex : 1,
        padding : '0 8px',
        minWidth : 120,
    },
    unit : {
        color : '#7f8c8d',
    }
});

export default function InputSlider(props) {
    const classes = useStyles();
    const {
        label='',
        step=0.5,
        min=0,
        max=10,
        unit=null,
        currentFormValues,
        onChange=null,
        title = null,
        formPropertyName = 'range',
    } = props;
    const [value, setValue] = React.useState((formPropertyName === "tradeAreaSlider" ? (currentFormValues?.current?.tradeAreaSlider ?? 1) : formPropertyName === "meshRadius" ?  (currentFormValues?.current?.[formPropertyName]/10) : currentFormValues?.current?.[formPropertyName])|| 10);
    const changeTimer = React.useRef(null);
    const callOnChange = (value) => {
        if(onChange){
            clearTimeout(changeTimer.current);
            changeTimer.current = setTimeout(()=>{
                onChange(value);
            }, 1000);
        }
    }
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        currentFormValues.current[formPropertyName] = formPropertyName === "meshRadius"  ? newValue*10 : newValue ;
        callOnChange(newValue);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? max : Number(event.target.value);
        setValue(newValue);
        currentFormValues.current[formPropertyName] = formPropertyName === "meshRadius"  ? newValue*10 : newValue;
        callOnChange(newValue);
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > max) {
            setValue(max);
        }
    };

    return (
        <div className={classes.root}>
            <Typography className={classes.label}>{label}</Typography>
            <Tooltip title={title}>
                
                <div className={classes.container}>
                    <div className={classes.sliderBox}>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                    </div>
                    <div className={classes.input}>
                        <InputBase
                            
                            value={value}
                            margin="dense"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step,
                                min,
                                max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                                className : classes.inputElement
                            }}
                        />
                        <div className={classes.unit}>{unit}</div>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}
