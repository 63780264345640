
import { useContext, useState,useEffect,useRef } from "react";
import { AuthContext } from "../../AuthenticationContext";
import { collection, query, where, getDocs } from "firebase/firestore"
import { DataGrid } from "@material-ui/data-grid";
import {Container,Button, } from "@material-ui/core";
import { Col, Input , InputGroup,  Row, InputGroupText, } from "reactstrap";
import Search from "@material-ui/icons/Search";
import  CreateMember from './CreateMember';
import EditUser from './EditUser';

export default function EditMember(){
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const {user,db, userProfile} = useContext(AuthContext);
    const [editUser, setEditUser] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [addMember, setAddMember] = useState(false)
    const [errorWhileFetching,setErrorWhileFetching] =useState(null)
    const searchRef = useRef(null)

    const userRef = collection(db, "user_info");

    async function fetchUsers(forSearchUser=false){
        if(user){
            users && setUsers([])
            errorWhileFetching && setErrorWhileFetching(null);
            const admin_email = userProfile?.email
            setLoading(true)
            const q = query(userRef, where("group_id", "==", userProfile?.group_id));
            return await getDocs(q).then((snapshot)=>{
                let userList= []
                !snapshot.empty && snapshot.forEach((user)=>{
                    admin_email !== user.data().email && userList.push({user_id:user.id, ...user.data()})
                })
                if(forSearchUser) return userList
                setUsers(userList)
            }).catch((error)=>{
              setErrorWhileFetching(error.code)
            }).finally(()=>{
                setLoading(false)
            })
            
        }
    }
    
    function refreshUsers(){
        fetchUsers(true).then((data)=>{
            setUsers(data)
        })
    }

    function searchUser  (userName) {
        if (!userName || (userName && userName.length) === 0) return null;
        fetchUsers(true).then((data)=>{
            const regs = new RegExp(userName, 'gi')
            const filteredUsersList = []
            if(data.length > 0){
                data.forEach((user)=>{
                    (regs.test(user.email) || regs.test(user.user_name)) && filteredUsersList.push(user)
                })
                setUsers(filteredUsersList)
            }
        })
    }

    useEffect(()=>{
        fetchUsers()
    }, [])

    const columns = [
        { field: 'email',
         headerName: 'Email',
         minWidth: 250,
         sortable:false
        },
        {
          field: 'user_name',
          headerName: 'Name',
          minWidth: 200,
          editable: false,
          sortable:false
        },
        {
          field: 'role_id',
          headerName: 'Role',
          minWidth: 200,
          editable:false,
          sortable:false,
        },
        {
          field: 'group_id',
          headerName: 'Group ID',
          minWidth: 150,
          editable: false,
          sortable:false
        },
        {
          field: 'edit',
          renderHeader: () => (<span></span> ),
          minWidth: 50,
          editable: false,
          sortable:false,
          renderCell: (params) => {
            return <strong>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setEditUser(params?.row)}}
                style={{ marginLeft: 16 }} >Edit</Button>
            </strong>
          }

        }
      ];

      const listener = (event) => {
        if (event.code === "Enter"  ) {
          event.preventDefault(); 
          searchRef.current && searchUser(searchRef.current.value)
        }
      };

      useEffect(() => {
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      }, []);

      const TopTools = () =>{
        return <div style={{margin:"10px"}}>
          <Row>
            <Col md={8}>
              <InputGroup>
              <InputGroupText>
                 <Search/>
              </InputGroupText>
                <Input disabled={loading} innerRef={searchRef}   type="text"  placeholder="Search a member by Email"   />
              </InputGroup>
            </Col>
            <Col md={2} >
              <Button disabled={loading}    variant="contained"
              onClick={() =>{setAddMember(()=>{return true})}}
              color="primary"
              size="small" style={{marginTop:"3px"}}>Add Member</Button> 
            </Col>
            <Col md={1} >
              <Button disabled={loading}    variant="contained"
              onClick={refreshUsers}
              color="primary"
              size="small" style={{marginTop:"3px"}}>Refresh</Button> 
            </Col>
          </Row>
       </div>
      }
      
      return (<>
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
           { addMember ? <CreateMember setAddMember={setAddMember}/> : editUser ?   <EditUser {...editUser} submitting={submitting} setSubmitting={setSubmitting} setEditUser={setEditUser}/> : 
          <div style={{ height: 480, width: '90%' }}>
            <h1>Member list</h1>
               { !errorWhileFetching ? <DataGrid
                  rows={users}
                  columns={columns}
                  row
                  autoHeight
                  loading={loading}
                  pageSize={7}
                  getRowId={(row)=>{return row['email']}}
                  rowsPerPageOptions={[7]}
                  disableColumnMenu={true}
                  isRowSelectable={false}
                  components={{ Toolbar:TopTools, NoRowsOverlay: CustomNoRowsOverlay }}
                  /> : <ErrorMessage errorWhileFetching={errorWhileFetching} fetchUsers={fetchUsers} />
                  }
          </div>}
          </Container> 
          </>)
}

function CustomNoRowsOverlay() {
  return (
    <div style={{ 
      marginTop:"200px",
      display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    }}>We couldn't find an account for given name/email <br/>
    Make sure the email or user UID is spelled and formatted correctly</div>
  );
}

function ErrorMessage(props){
  return(
    <div style={{ 
      marginTop:"200px",
      display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    }}>{props?.errorWhileFetching} <br/>
    <Button onClick={()=>{props?.fetchUsers()}}>Try Agrain !</Button>
    </div>
  )
}
