import React,{ useContext, useState } from "react";
import {Button } from "@material-ui/core";
import { Form, FormGroup, Label, Col, Input , FormText, Spinner, Dropdown,DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import { AuthContext } from "../../AuthenticationContext";
import {collection, addDoc,getDocs,query, where } from "firebase/firestore";

export default function CreateMember(props){
    const [email, setEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [roleId, setRoleId] = useState(null)
    const {userProfile,db} = useContext(AuthContext)
    const [invalidMessage, setInvalidMessage] = useState(null)
    const [isRoleDropdownOpen,setIsRoleDropdownOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [isActiveDropdownOpen, setIsActiveDropdownOpen] = useState(false)
  
    const createMember = async () =>{
        invalidMessage && setInvalidMessage(null)
        successMessage && setSuccessMessage(null)
      if(!email){
        setInvalidMessage("Email is required !")
        return false
      }
      if( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
        setInvalidMessage("Invalid Emial Address !")
        return false
      }
      if(!userName){
        setInvalidMessage("User name is required.")
        return
      }
      if(userName & userName.length > 100){
        setInvalidMessage(" Name length should not be more than 100 characters.")
        return
      }
      if(!roleId){
        setInvalidMessage("Select a Role of the member.")
        return
      }
      if(!['admin','viewer'].includes(roleId) ){
        setInvalidMessage("Please choose a valid roleId.")
        return
      }
      const userDocRef  = collection(db, "user_info");
      setSubmitting(true)
      const q = query(userDocRef, where("group_id", "==", userProfile?.group_id));
      await getDocs(q).then(
          async (snapShot)=>{
              if(!snapShot.empty){
                let duplicate = []
                snapShot.forEach((user)=>{
                  email === user.data().email  &&  duplicate.push(user.data())
                })
                if (duplicate.length === 0){
                  await  addDoc(userDocRef, {
                              email:email,
                              user_name:userName,
                              role_id:roleId,
                              group_id:userProfile?.group_id,
                              active:isActive
                              }).then((docSnap)=>{
                                  if(docSnap.id) setSuccessMessage("User created successfully.")
                              }).catch((error)=>{
                                  if(error.code === "permission-denied"){
                                      setInvalidMessage("You don't have permission to add a new member !")
                                  }else{
                                      setInvalidMessage(JSON.stringify(error.code))
                                  }      
                              })
                            }
                else{
                  setInvalidMessage("This email already taken.")
                }
              }else{
                setInvalidMessage("Something went wrong try again !")
              }
          }).catch((error)=>{
            setInvalidMessage(error.code)
          })
          .finally(
            ()=>{
              setSubmitting(false)
          }
          )
    }
  
    return(
    <>
    <h1>Add Member</h1>
    <Form hidden={props?.hidden} >
      <FormGroup row>
          <Label sm={2} > Email </Label>
          <Col sm={5}>
            <Input disabled={submitting} style={{marginTop:"5px"}} onChange={(e) => {setEmail(e.target.value )}} value={email || ""} placeholder="Give a new email address"  />
          </Col>
      </FormGroup>
  
      <FormGroup row>
          <Label sm={2} > Name </Label>
          <Col  sm={5}>
            <Input disabled={submitting}  style={{marginTop:"5px"}} onChange={(e) => {setUserName(e.target.value )}} value={userName || ""} placeholder="Give a new name"  />
            <FormText>
                Name length should not be more than 100 characters.
            </FormText>
          </Col>
      </FormGroup>
  
      <FormGroup row>
          <Label  sm={2} > Role </Label>
      <Col sm={5}>
      <Dropdown disabled={submitting}  direction="right" isOpen={isRoleDropdownOpen} toggle={()=>{setIsRoleDropdownOpen((prevState)=>{return !prevState})}}>
          <DropdownToggle caret>
            {roleId ? roleId[0].toUpperCase()+roleId.slice(1) : "Select Role" }
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>{setRoleId("admin")} } >Admin</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={()=>{setRoleId("viewer")} } >Viewer</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
      </FormGroup> 
      <FormGroup row>
          <Label  sm={2} > Active </Label>
      <Col sm={5}>
      <Dropdown disabled={submitting}  direction="right" isOpen={isActiveDropdownOpen} toggle={()=>{setIsActiveDropdownOpen((prevState)=>{return !prevState})}}>
          <DropdownToggle caret>
            {isActive ? "True" : "False"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=>{setIsActive(true)} } >True</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={()=>{setIsActive(false)} } >False</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
      </FormGroup> 
  
      <FormGroup row>
          <Label  sm={2} > Group Id </Label>
          <Col sm={5}>
              <div className="form-control" style={{backgroundColor:"#e9ecef", opacity:'1', border:"1px solid #ced4da"}} >{userProfile?.group_id}</div>
          </Col>
      </FormGroup>
      { invalidMessage &&  
      <FormGroup row>
          <Label sm={2} ></Label>
          <Col sm={5}>
          <div sm={5} className="form-control"  style={{border:"1px solid #dd3c10", backgroundColor:"#ffebe8"}}>{invalidMessage}</div>
          </Col>
      </FormGroup>}
      { successMessage &&  
      <FormGroup row>
          <Label sm={2} ></Label>
          <Col sm={5}>
          <div sm={5} className="form-control"  style={{border:"1px solid #2fc431", backgroundColor:"#ddffdd"}}>{successMessage}</div>
          </Col>
      </FormGroup>}
      <FormGroup row >
      <Col sm={7} >     
         <Button disabled={submitting}   variant="contained"
                  color="primary"
                  onClick={createMember}
                  size="small" style={{float: "right"}}  >{submitting ? <Spinner color="light" size="">Loading...</Spinner> : "Submit" }</Button> 
            <Button    variant="contained"
            disabled={submitting}
                  size="small" style={{float: "right",marginRight:"10px"}} color="default" onClick={() => {props?.setAddMember(false)}} >Back</Button> 
        </Col>
      </FormGroup> 
    </Form>
  </> )
  }
  
  