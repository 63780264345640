import { makeStyles } from '@material-ui/core/styles';
const chartColors = [
    'rgba(26, 188, 156,1.0)',
    'rgba(155, 89, 182,1.0)',
    'rgba(52, 152, 219,1.0)',
    'rgba(241, 196, 15,1.0)',
    'rgba(231, 76, 60,1.0)',

    'rgba(230, 126, 34,1.0)', // newcomer
    'rgba(179, 55, 113,1.0)', // repeat
    'rgba(127, 140, 141,1.0)', // lost
];

const commonStyles = makeStyles((theme) => ({
    listLabel : {
        display : 'flex',
        justifyContent : 'space-between',
    },
    main : {
        //whiteSpace : 'nowrap',
        width : 'calc(100% - 20%)',
    },
    sub : {
        opacity : 0.65,
        fontSize : 12,
        paddingLeft : 8,
        whiteSpace : 'nowrap',
        overflow : 'hidden',
        textOverflow : 'ellipsis',
    }
}));
export {
    chartColors,
    commonStyles,
}