import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Typography } from '@material-ui/core';
import Checkbox from '../../common/Checkbox/Checkbox.jsx';

import { commonStyles } from '../../css/css.jsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position : 'relative',
        zIndex : 1,
    },
    groupBox : {
        boxShadow: '0 4px 3px -3px rgb(0 0 0 / 5%) inset',
        backgroundColor: 'rgba(0,0,0,0.025)',
        contentVisibility : 'auto',
    }
}));

export default function NestedList(props) {
    const classes = useStyles();
    const commonClasses = commonStyles();
    const {
        group=[],
        onChange,
        onExpand,
        groupKey,
        filterId='unknown',
        isGroupCheckEnabled,
        indent,
    } = props;
    //console.log('props', props);
    const {
        groupName,
        open=false,
        child,
        groupPath=null,
        value,
    } = group; 
    const handleToggle = (_groupPath, value, isSingle) => () => {
        onChange && onChange(_groupPath, value, isSingle);
    };
    const handleClick = (_groupPath) => () => {
        onExpand && onExpand(_groupPath, groupKey);
    }
    const handleGroupChecked = (groupName, value=null, isSingle) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        onChange && onChange(groupName, value, isSingle);
        
    }
    const getIndent = () => {
        // I'm wondering if I should indent it
        return null;
    }
    const visibleChild = child.filter(menu => menu.visible);
    const checkedLength   = visibleChild.filter(c => c.checked).length;
    const allChecked      = visibleChild.length === checkedLength;
    const isIndeterminate = Boolean(!allChecked && checkedLength);
    const expandGroupPath = groupPath || [groupName];
    //console.log({expandGroupPath});
    return (
        <>

            <ListItem button onClick={handleClick([...expandGroupPath])}>
                {
                    isGroupCheckEnabled && (
                        <ListItemIcon>
                            <Checkbox
                                id={groupName}
                                name={groupName}
                                value={groupName}
                                edge="start"
                                checked={allChecked}
                                //tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': groupName }}
                                color="primary"
                                onClick={handleGroupChecked([...expandGroupPath], value)}
                                indeterminate={isIndeterminate}
                            />
                        </ListItemIcon>
                    )
                }

                <ListItemText primary={
                    <div style={{
                        display : 'flex',
                        alignItems : 'center',
                        justifyContent : 'space-between',
                    }}>

                        <Typography component="span">{groupName}</Typography>
                        <Typography component="span" style={{
                            paddingRight : 8,
                            fontSize : 14,
                            //opacity : 0.5
                        }}>
                            {
                                checkedLength > 0 && (
                                    <>
                                        <span style={{color : '#3f51b5'}}>{checkedLength}</span>
                                        <span style={{opacity : 0.5}}>/</span>
                                    </>
                                    
                                )
                            }
                            
                            <span style={{opacity : 0.5}}>{visibleChild.length}</span>
                        </Typography>
                    </div>} />
                    {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" className={classes.groupBox} unmountOnExit>
                {
                    open && visibleChild.map((options, i) => {
                        const {
                            label,
                            sub='',
                            checked,
                            key,
                            //groupName=null,
                            groupPath=[],
                            child=null,
                            value,
                            uniqueKey=null,
                        } = options;
                        //console.log(`groupPath : ${groupPath}`);
                        const id = `${label}${sub}`;
                        const hasChild = Boolean(groupName && child);
                        return (
                            
                            hasChild ? (
                                    <NestedList 
                                        key={`nestedList-${i}`}
                                        group={options}
                                        onChange={onChange}
                                        onExpand={onExpand}
                                        filterId={filterId}
                                        isGroupCheckEnabled={isGroupCheckEnabled}
                                        indent={indent + 1}
                                    />
                                ) : (
                                    <ListItem 
                                        key={`list-item-${groupName}-${label}-${i}`} 
                                        role={undefined} 
                                        dense 
                                        button 
                                        onClick={handleToggle([...groupPath], value, true)}
                                        >
                                        
                                        <ListItemIcon>

                                            <Checkbox
                                                id={id}
                                                name={filterId}
                                                //value={label}
                                                value={value}
                                                edge="start"
                                                checked={checked}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 
                                                    'aria-labelledby': id, 
                                                    'data-key' : key,
                                                }}
                                                color="primary"
                                            />
                                        </ListItemIcon>
                                        {
                                            getIndent()
                                        }
                                        <ListItemText id={id} primary={
                                            <div className={commonClasses.listLabel}>
                                                <span className={commonClasses.main}>{label}</span>
                                                {sub && <span className={commonClasses.sub}>{sub}</span>}
                                            </div>
                                        } />
                                    </ListItem>
                                )
                            

                        );
                    })
                }

            </Collapse>
        </>
    );
}
