import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Button from '../../common/Button/Button.jsx';
export default function SelectDateOptions(props) {
    const {
        datePeriodType=[],
        setDatePeriodType=null,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (label) => () => {
        if(label){
            const _datePeriodType = datePeriodType.map(option => {
                return {
                    ...option,
                    selected : option.label === label,
                }
            })
            setDatePeriodType(_datePeriodType);
        }
        setAnchorEl(null);
    };

    return (
        <div style={{
            display : 'flex',
            alignItems : 'stretch',
        }}>
            <Button 
                variant='text'
                aria-controls="select-date" 
                aria-haspopup="true" 
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{
                    paddingLeft : 24,
                    paddingRight : 24,
                }}
            >
                {datePeriodType.find(option => option.selected).label}
            </Button>
            <Menu
                id="select-date"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose(null)}
            >
                {
                    datePeriodType.map(option => {
                        const {
                            label,
                            selected,
                        } = option;
                        return (
                            <MenuItem key={`selectDate-${label}`} onClick={handleClose(label)} selected={selected}>{label}</MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    );
}
