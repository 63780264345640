import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Top from './Top.jsx';
// The main UI framework is Material-UI
// When applied together with other frameworks, both will be affected (especially CSS related)
//
//import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './AuthenticationContext';

const onUpdate = (registration) => {
    // console.log(registration);
    const request = indexedDB.deleteDatabase('masterDB');

    request.onsuccess = () => {
        console.log(`Database successfully deleted → masterDB`)
    }
    request.onblocked = () => {
        console.log(`Database deletion blocked → masterDB\n
Transaction processing may not have been closed properly.
→ https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase/close`);
        return resolve();
    }
    request.onerror = () => console.error(request.error);
}

const ROUTER_BASENAME = '/';
console.log('ROUTER_BASENAME', ROUTER_BASENAME);
ReactDOM.render(
    <AuthProvider>
        <Router basename={ROUTER_BASENAME}>
            <Top />
        </Router>
    </AuthProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register({ onUpdate });