import geojson2h3 from "geojson2h3";
import { geoToH3, kRing, h3ToGeo } from "h3-js";
import { jenksBreaks } from './jenksBreaks'

import {
  COLOR_SCALE,
  LAYER_IDS,
  COMPARISON_MAP_COLORS,
} from "../../constants/mapBuilder";

class MapBuilderHelper {
  static calculatePaintStops = (cellValues, comparisonMap) => {
    if (!comparisonMap) {
      const values = Object.values(cellValues)[1];
      const max = Math.max(...values);
      const min = Math.min(...values);
      const stops = Array.from(
        { length: 10 },
        (v, i) => min + Math.floor((max - min) * i) / 9
      );
      const uniqueStops = stops.filter((v, i, a) => a.indexOf(v) === i);
      return MapBuilderHelper.createFillColor(
        uniqueStops.map((v, i) => [v, COLOR_SCALE[i]])
      );
    } else {
      return [
        "case",
        ["==", ["get", "compare"], 0],
        COMPARISON_MAP_COLORS[0],
        ["==", ["get", "compare"], 1],
        COMPARISON_MAP_COLORS[1],
        ["==", ["get", "compare"], 2],
        COMPARISON_MAP_COLORS[2],
        COMPARISON_MAP_COLORS[3],
      ];
    }
  };

  static createFillColor = (stops) => ({
    property: "value",
    type: "interval",
    stops,
  });

  static compareCellColor = (cellValueArray) => {
    if (cellValueArray[0] > cellValueArray[1]) {
      return 1;
    } else if (cellValueArray[0] < cellValueArray[1]) {
      return 2;
    } else {
      return 0;
    }
  };

  static buildH3SourceData = (cellValues, comparisonMap) => {
    const cellIds = Object.keys(cellValues);
    return geojson2h3.h3SetToFeatureCollection(cellIds, (id) => {
      if (!comparisonMap) {
        return {
          id,
          value: cellValues[id],
        };
      } else {
        const compareValue = MapBuilderHelper.compareCellColor(cellValues[id]);
        return {
          id,
          value: cellValues[id],
          compare: compareValue,
        };
      }
    });
  };

  static transformEventToCellId = (clickEvent, enableMapClickEvents) => {
    if (!enableMapClickEvents) {
      if (!clickEvent.features || clickEvent.features.length === 0) {
        return;
      }
      const cell = clickEvent.features.find(
        (p) => p.layer.id === LAYER_IDS.cell
      );
      if (!cell) {
        return;
      }
      const { id } = cell.properties;
      return id;
    } else {
      const { lat, lng } = clickEvent.lngLat;
      const id = this.reverseH3GeoCoding([lat, lng], 10);
      return id;
    }
  };

  static buildH3SelectionSourceData = (cellIds) =>
    geojson2h3.h3SetToFeatureCollection(cellIds, (id) => ({
      id,
    }));

  static reverseH3GeoCoding = (latLng, res) => {
    return geoToH3(...latLng, res);
  };

  static buildQueryCellSourceData = (cellId) => geojson2h3.h3ToFeature(cellId);

  static calculatePeopleFlowPaintStops = (cells) => {
    if (!cells || Object.keys(cells).length === 0) {
      return undefined;
    }
    const values = [...new Set(Object.values(cells))] ;
    const breaks = values.length > 8 ? jenksBreaks(values, 8) : values.sort((a, b) => a - b);
    let colorScale = [...new Set(Object.values(breaks))].map((v, i) => [v, COLOR_SCALE[i]]);
    return colorScale;
  };

  /**
   * Get all hexagons in a k-ring around a given center. The order of the hexagons is undefined.
   * @param h3Index - H3 index of center hexagon
   * @param ringSize - Radius of k-ring
   * @returns H3 indexes for all hexagons in ring
   */
  static getKRingIndices = (h3Index, ringSize) => kRing(h3Index, ringSize);

  /**
   * Get latitude and longitude of a given h3Index
   * @param meshCd - mesh code of target mesh
   * @returns array containing latitude and longitude
   */
  static getLatLong = (meshCd) => h3ToGeo(meshCd);
}

export default MapBuilderHelper;
