import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../AuthenticationContext';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const useStyles = makeStyles((theme) => ({
    logoutContainer : {
        position : 'sticky',
        bottom : 0,
        zIndex : 9,
    },
    logoutButton : {
        //display : 'block',
        width : '100%',
        padding : '12px 24px',
    }
}))
export default function AccountOptions(props) {  
    const {logout}  = useContext(AuthContext)
    const classes = useStyles();
    const history = useHistory();

    const {
        shrink,
    } = props;

    const beforeLogout = async () => {
        await logout();
        history.push('/');
    }

    return (
        <div>
            <Divider />
            {
                shrink ? (
                    <Button 
                        color="secondary" 
                        className={classes.logoutButton}
                        onClick={beforeLogout}
                    ><ExitToAppIcon /></Button>
                ) : (
                    <Button 
                        endIcon={<ExitToAppIcon />}
                        color="secondary" 
                        className={classes.logoutButton}
                        onClick={beforeLogout}
                    >ログアウト</Button>
                )
            }

        </div>
    );
}
