import React,{useState} from 'react';
/*
You should use the genuine mapbox(https://www.npmjs.com/package/mapbox-gl) module
Wrapped modules are inconvenient for fine-tuning...
*/
import Map, { Source, Layer, Popup, Marker, NavigationControl } from 'react-map-gl';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteSweep';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import MapLegend from '../MapLegend/MapLegend';
import { CELL_LAYER_CONFIG, SELECTED_LAYER_CONFIG, LAYER_IDS } from '../../constants/mapBuilder';
import MapBuilderHelper from '../../common/MapBuilderHelper/mapBuilderHelper';
import StorefrontIcon from '@material-ui/icons/Storefront';
import mapboxgl from 'mapbox-gl';
__webpack_public_path__ = '/';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const useStyles = makeStyles(() => ({
    root:{
        position: "relative"
    },
    legendContainer: {
        position: 'absolute',
        top: '0',
        right: '0',
        border: '2px solid #FFF',
        borderRight: 'none',
        borderTop: 'none',
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
        backgroundColor: '#FFF'
    },
    mapControls: {
        position: 'absolute',
        top: '0',
        left: '0',
        background: '#fff',
        padding: '2px',
        margin: '8px',
        borderRadius: '4px',
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)'
    },
    clearButton: {
        display: 'flex',
        background: '#fff',
        border: 'none'
    },
    cellCount: {
        padding: '4px 0',
        fontSize: '16px'
    }
}));

const updateSelectedCellArray = (selectedId, cellArray) => {
    if (cellArray.includes(selectedId)) {
        return cellArray.filter((p) => p !== selectedId);
    }
    return [...cellArray, selectedId];
};

function MapBuilder(props) {
    const mapRef = React.useRef();
    const [cellLayer, setCellLayer] = React.useState(CELL_LAYER_CONFIG);
    const [cellSourceData, setCellSourceData] = React.useState();
    const [selectedSourceData, setSelectedSourceData] = React.useState();
    const [hoverInfo, setHoverInfo] = React.useState(null);
    const [mapCursor, setMapCursor] = React.useState('pointer');

    const [uncontrolledColorIntervals, setUncontrolledColorIntervals] = React.useState();
    const [uncontrolledSelection, setUncontrolledSelection] = React.useState([]);

    const {
        initialViewState,
        style,
        mapboxAccessToken,
        mapStyle,
        interactiveLayerIds,
        handleControlledCellSelection,
        controlledSelection,
        cellValues,
        colorIntervals,
        scrollZoom,
        selectedView,
        enableMapClickEvents,
        comparisonMap,
        markerIcon,
        handleDraw,
        showLegend,
        dragedPosition,
        onMoveEnd,
        popupText = false,
        valuePostfix = false
    } = props;

    const classes = useStyles({});
    const [viewState, setViewState] = React.useState(dragedPosition);

    React.useEffect(() => {
        if (cellValues) {
            const sourceData = MapBuilderHelper.buildH3SourceData(cellValues, comparisonMap);
            setCellSourceData(sourceData);
        } else {
            setCellSourceData(undefined);
        }
        setUncontrolledSelection([]);
    }, [cellValues, comparisonMap]);

    React.useEffect(() => {
        if (!colorIntervals) {
            const colorStops = MapBuilderHelper.calculatePaintStops(cellValues, comparisonMap);
            setUncontrolledColorIntervals(colorStops);
            setCellLayer({
                ...CELL_LAYER_CONFIG,
                paint: {
                    ...CELL_LAYER_CONFIG.paint,
                    'fill-color': colorStops
                }
            });
        }
        if (colorIntervals) {
            setCellLayer({
                ...CELL_LAYER_CONFIG,
                paint: {
                    ...CELL_LAYER_CONFIG.paint,
                    'fill-color': MapBuilderHelper.createFillColor(colorIntervals)
                }
            });
        }
    }, [colorIntervals, cellValues, comparisonMap, dragedPosition]);

    React.useEffect(() => {
        if (controlledSelection) {
            setSelectedSourceData(MapBuilderHelper.buildH3SelectionSourceData(controlledSelection));
        }
    }, [controlledSelection]);

    React.useEffect(() => {
        if (selectedView[0]) {
            const { latitude, longitude } = selectedView[0];
            if (mapRef.current) {
                mapRef.current.flyTo({
                    center: [longitude, latitude]
                });
            }
        }
    }, [selectedView]);

    const updateSelectedCell = (cellId) => {
        if (controlledSelection) {
            handleControlledCellSelection(cellId);
        } else {
            const newSelection = updateSelectedCellArray(cellId, uncontrolledSelection);
            setUncontrolledSelection(newSelection);
            setSelectedSourceData(MapBuilderHelper.buildH3SelectionSourceData(newSelection));
        }
    };

    const onMapClick = (event) => {
        const cellId = MapBuilderHelper.transformEventToCellId(event, enableMapClickEvents);
        if (cellId) {
            updateSelectedCell(cellId);
        }
    };
    const hoverRef = React.useRef(null);
    const onHover = (event) => {
        const { features, lngLat, originalEvent } = event;
        const { altKey, ctrlKey } = originalEvent;
        const hoveredFeature = features && features[0];
        if (hoveredFeature) {
            setHoverInfo({ feature: hoveredFeature, ...lngLat });
        }
        if (altKey || ctrlKey) {
            setMapCursor('crosshair');
            const cellId = MapBuilderHelper.transformEventToCellId(event, enableMapClickEvents);
            if (cellId && cellId !== hoverRef.current) {
                hoverRef.current = cellId;
                handleDraw(cellId, altKey);
            }
        } else {
            setMapCursor('pointer');
        }
    };

    const onMouseLeave = React.useCallback((props) => {
        setHoverInfo(null);
        setMapCursor('pointer');
    }, []);
    /*
  React.useEffect(()=>{
    if(mapRef.current){
      const map = mapRef.current.getMap();
      const language = 'ja';
      console.log({map})
      map.setLayoutProperty('country-label', 'text-field', [
        'get',
        `name_${language}`
      ])
    }
  }, [mapRef.current])
  */

    React.useEffect(() => {
        return setViewState(dragedPosition);
    }, [dragedPosition]);


    const storePins = React.useMemo(() => {
        return (selectedView &&
            markerIcon &&
            selectedView?.map((s, i) => {
                console.log({s})
                return(<Marker
                    key={`marker-${i}`}
                    longitude={s.longitude}
                    latitude={s.latitude}
                >
                    <StorefrontIcon
                        style={{
                            color: 'white',
                            backgroundColor: '#bf0000',
                            borderRadius: 16,
                            padding: 2,
                            border: '2px solid white'
                        }}
                    />
                </Marker>)
            }));
    }, []);
    return (
        <>
            <Map
                ref={mapRef}
                willReadFrequently={true}
                mapboxAccessToken={mapboxAccessToken}
                initialViewState={initialViewState}
                reuseMaps={true}
                interactiveLayerIds={enableMapClickEvents ? undefined : interactiveLayerIds}
                cursor={mapCursor}
                style={style}
                mapStyle={mapStyle}
                onClick={onMapClick}
                onMouseMove={onHover}
                doubleClickZoom={scrollZoom || false}
                scrollZoom={scrollZoom || false}
                onMouseEnter={() => setMapCursor('pointer')}
                onMouseLeave={onMouseLeave}
                lang="ja"
                viewState={viewState}
                attributionControl={false}
                onDragStart={(event) => {
                    onMoveEnd && setViewState(undefined);
                }}
                onZoomStart={(event) => {
                    onMoveEnd && setViewState(undefined);
                }}
                onZoomEnd={(event) => {
                    onMoveEnd && onMoveEnd(event.viewState);
                }}
                onDragEnd={(event) => {
                    onMoveEnd && onMoveEnd(event.viewState);
                }}
            >
                {cellSourceData && (
                    <Source id="h3-cell" type="geojson" data={cellSourceData}>
                        <Layer {...cellLayer} />
                    </Source>
                )}
                {selectedSourceData && (
                    <Source id={LAYER_IDS.selected} type="geojson" data={selectedSourceData}>
                        <Layer {...SELECTED_LAYER_CONFIG} />
                    </Source>
                )}
                {hoverInfo && (
                    <Popup
                        longitude={Number(hoverInfo.lng)}
                        latitude={Number(hoverInfo.lat)}
                        closeButton={false}
                    >
                        <div>
                            <h3>{hoverInfo.feature.properties.id}</h3>
                            <p>
                                {popupText ? popupText : '人口'}:{' '}
                                {hoverInfo.feature.properties.value.toLocaleString()}
                                {valuePostfix && valuePostfix}
                            </p>
                        </div>
                    </Popup>
                )}
                {storePins}
                <NavigationControl
                    position="top-left"
                    style={{
                        position: 'relative',
                        top: controlledSelection.length > 0 ? '38px' : '0px'
                    }}
                    showCompass={false}
                />

                {controlledSelection.length > 0 && (
                    <div className={classes.mapControls}>
                        <button
                            type="button"
                            className={classes.clearButton}
                            onClick={() => handleControlledCellSelection(null)}
                        >
                            <DeleteIcon />
                            <div className={classes.cellCount}>{controlledSelection.length}</div>
                        </button>
                    </div>
                )}
            </Map>

            {cellValues && Object.keys(cellValues).length && (
                <div className={classes.legendContainer}>
                    <MapLegend
                        comparisonMap={false}
                        colorIntervals={colorIntervals || uncontrolledColorIntervals}
                    />
                </div>
            )}
        </>
    );
}

MapBuilder.propTypes = {
    /**
     * Peopleflow: dictionary with keys H3 cells ids, and numeric value.
     * Heatmap: dictionary with keys H3 cells ids, and value of array of two numbers.
     */
    cellValues: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
    ).isRequired,
    /**
     * controlled component config - required for controlled component configuration,
     * an array of selected H3 cell ids
     */
    controlledSelection: PropTypes.arrayOf(PropTypes.string),
    /**
     * controlled component config - required for controlled component configuration,
     * https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-fill-fill-color
     * use 'value' if
     */
    colorIntervals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
    /**
     * callback function which returns the id of the clicked cell
     */
    handleControlledCellSelection: PropTypes.func,
    /**
     * required props for Mapbox react-map-gl
     * https://visgl.github.io/react-map-gl/docs
     */
    mapboxAccessToken: PropTypes.string.isRequired,
    /**
     * required props for Mapbox react-map-gl
     * https://visgl.github.io/react-map-gl/docs
     */
    initialViewState: PropTypes.objectOf(PropTypes.any).isRequired,
    /**
     * prop which controls the center of the map. Can be used to pan to a specific location.
     */
    selectedView:PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * required props for Mapbox react-map-gl
     * https://visgl.github.io/react-map-gl/docs
     */
    style: PropTypes.objectOf(PropTypes.any).isRequired,
    /**
     * required props for Mapbox react-map-gl
     * https://visgl.github.io/react-map-gl/docs
     */
    mapStyle: PropTypes.string.isRequired,
    /**
     * required props for Mapbox react-map-gl
     * https://visgl.github.io/react-map-gl/docs
     */
    interactiveLayerIds: PropTypes.array.isRequired,
    /**
     * optional prop
     * for enabling scroll zoom
     */
    scrollZoom: PropTypes.bool,
    /**
     * When enabled, clicking anywhere on the map will render the cell id and trigger
     * a callback function with the clicked cell id.
     */
    enableMapClickEvents: PropTypes.bool,
    /**
     * when the map is to be used as a comparison map
     */
    comparisonMap: PropTypes.bool,
      /**
     * function to call when the map is dragged 
     */
    onMoveEnd: PropTypes.func,
    /**
     * postion of the new dragged lat and long and zoom
     */
    dragedPosition: PropTypes.object

};

export default MapBuilder;
