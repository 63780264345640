import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../common/Button/Button.jsx';
import { DateRangePicker } from 'react-dates';
import { Route, Switch, useHistory, useLocation, Link } from 'react-router-dom';
import LoopIcon from '@material-ui/icons/Loop';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import SelectDateOptions from '../../components/SelectDate/SelectDateOptions.jsx';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.jsx';
import SelectBox from '../SelectBox/SelectBox.jsx';
import TimeInput from '../SelectDate/TimeInput.jsx';
import { Divider } from '@material-ui/core';
import FavouriteEdit from "../FavouriteEdit/FavouriteEdit";
import { 
    API_URL, 
    getNdJsonFetch,
} from '../../utility/fetchUtility.js';
import { AuthContext } from '../../AuthenticationContext.js';
import Slider from '../../components/Slider/Slider.jsx';

import '../../css/datePicker.css';

const useStyles = makeStyles((theme) => ({
    toolBox : {
        display : 'flex',
        paddingBottom : 24,
        position : 'relative',
        zIndex : 2,
        alignItems : 'stretch',
        justifyContent : 'space-between',
        '& .DayPickerKeyboardShortcuts_show' : {
            display : 'none',
        }
    }
}));
export default function Toolbar(props) {
    const {
        range={},
        isShowResult,
        setIsShowResult,
        categoryLevelOptions=[],
        changeCategoryLevelOptions,
        showDateRange=false,
        showTabSwitch=false,
        showIndicatorSwitch=false,
        showChainAndSegmentSwitch=false,
        showPerSegmentSwitch = false,
        showCategoryLevel=false,
        showTradeArea=false,
        toggleShowFilter,
        changeSubmitValues,
        currentFormValues,
        isTab,
        isIndicator,
        isPostBodyType,
        toggleIsTab,
        toggleIsIndicator,
        toggleIsPostBodyType,
        toggleIsReload,
        tradeAreaOptions,
        changeTradeAreaOptions,
        path,
        submitDisabled
    } = props;
    
    const classes = useStyles();
    const location = useLocation();
    const history  = useHistory();
    const defaultOptions = [{
        label : '月次',
        selected : false,
        value : 'monthly',
    },{
        label : '月次（平日/休日）',
        selected : false,
        value : 'monthly_split',
    },{
        label : '週次',
        selected : false,
        value : 'weekly',
    },{
        label : '週次（平日/休日）',
        selected : false,
        value : 'weekly_split',
    },{
        label : '日次',
        selected : false,
        value : 'daily',
    }];
    const {
        token, 
    } = useContext(AuthContext);

    const convertNumber2Time = (time) => {
        if(typeof time === 'string'){
            return time;
        }
        const hh = (time < 10) ? `0${time}` : time;
        return `${time}:00`;
    }
    const initDatePeriodOptions = () => {
        var datePeriodOptions = [];
        const checkPathName     = location.pathname.split('/').splice(0,3).join('/');
        if(!['/marketArea/profile',"/marketArea/heatMap"].includes(checkPathName)){
     
                datePeriodOptions = [...defaultOptions];
          
        }

        if(checkPathName.search(/^\/marketArea/) > -1 && !['/marketArea/profile',,"/marketArea/heatMap"].includes(checkPathName)){
            datePeriodOptions.push({
                label : '時間帯別',
                selected : false,
                value : 'hourly',
            });
        }
        if(['/marketArea/profile', "/marketArea/heatMap"].includes(checkPathName)){
            const weekdayOptions = [{
                label : '平日',
                selected : false,
                value : 'weekday',
            },{
                label : '休日',
                selected : false,
                value : 'weekend',
            },{
                label : '平日 + 休日',
                selected : false,
                value : 'all',
            }];
            weekdayOptions.forEach(option => datePeriodOptions.push(option));
        }
        const pastOptions = currentFormValues?.current?.datePeriodType || null;
        if(pastOptions){
            const selectedValue = pastOptions.find(option => option.selected)?.value || null;
            if(selectedValue){
                datePeriodOptions.forEach(option => {
                    if(option.value === selectedValue){
                        option.selected = true;
                    }
                })
            }
        }
        if(!datePeriodOptions.find(option => option.selected)){
            datePeriodOptions[datePeriodOptions.length-1].selected = true;
        }
        return datePeriodOptions;
    }

    const [focusedInput, setFocusedInput] = React.useState(null);

    const defaultStartDate            = currentFormValues.current.start_date/* || moment().add(-1, 'month')*/;
    const defaultEndDate              = currentFormValues.current.end_date/* || moment()*/;
    const defaultStartTime            = currentFormValues.current.from_time === undefined ? '00:00' : convertNumber2Time(currentFormValues.current.from_time);
    const defaultEndTime              = currentFormValues.current.to_time   === undefined ? '23:00' : convertNumber2Time(currentFormValues.current.to_time);
    const datePeriodOptions           = initDatePeriodOptions();
    const defaultMinDate              = currentFormValues.current?.min_date || null;
    const defaultMaxDate              = currentFormValues.current?.max_date || null;
    const [startDate, setStartDate]   = React.useState(moment(defaultStartDate) );
    const [endDate, setEndDate]       = React.useState( moment(defaultEndDate) );
    const [fromTime, setFromTime]     = React.useState(defaultStartTime);
    const [toTime, setToTime]         = React.useState(defaultEndTime);
    const [minDate, setMinDate]       = React.useState(!defaultMinDate ? moment().clone().add(-1, 'month'): moment(defaultMinDate) );
    const [maxDate, setMaxDate]       = React.useState(!defaultMaxDate ? moment() : moment(defaultMaxDate) );
    const [datePeriodType, setDatePeriodType] = React.useState(datePeriodOptions);
    const [availableDates, setAvailableDates] = React.useState(currentFormValues?.current?.availableDate);
    const isHiddenPath = !path.includes('pricing/summary') && !path.includes('assortment/competitor');

    const time2Number = (time) => {
        if(isFinite(time)) return time;
        return Number(time?.split(':')?.[0] || 0);
    }

    const changeDatePeriodType = (periodOptions) => {
        setDatePeriodType(periodOptions);
        let sd = moment(maxDate).add(-1, 'month');
        let dateType = periodOptions.find(p => p.selected).value;
        var newSD, newED
        if(dateType === "monthly" || dateType === "monthly_split"){
            newSD = sd.startOf('month');
            newED = moment(maxDate).endOf("month");
            currentFormValues.current.start_date = newSD.format("YYYY-MM-DD").toString();
            currentFormValues.current.end_date   = newED.format("YYYY-MM-DD").toString();
            setStartDate(newSD);
            setEndDate(newED);
          
        }
        if(dateType === "weekly" || dateType === "weekly_split"){
            newSD = moment(sd).day(1);
            newED = moment(sd).day(7);
            currentFormValues.current.start_date = newSD.format("YYYY-MM-DD").toString();
            currentFormValues.current.end_date   = newED.format("YYYY-MM-DD").toString();
            setStartDate(newSD);
            setEndDate(newED);
        }
        currentFormValues.current.datePeriodType = [...periodOptions];
    }
    const timeRangeComponent = () => {
        return (
            <div>
                <div style={{
                    display : 'flex',
                    alignItems : 'center',
                }}>
                    <Divider style={{flex : 1}} />
                    <div style={{
                        padding : '0 12px', 
                        display : 'flex',
                        alignItems : 'center',
                    }}>
                        <i className="far fa-clock" style={{marginRight : 4}}></i>
                        Time
                    </div>
                    <Divider style={{flex : 1}} />
                </div>
                <div style={{
                    display : 'flex',
                }}>
                    <div style={{padding : 12, flex : 1}}>
                        <TimeInput 
                            id="startDateTime"
                            label="from"
                            isSelectTime={isSelectTime} 
                            maxTime='23:00'
                            selectTime={fromTime}
                            onChange={(value)=>{
                                const minHour = time2Number(value);
                                let maxHour   = time2Number(toTime);
                                changeTime(value, 'from_time', setFromTime);
                                if(minHour >= maxHour){
                                    maxHour = minHour + 1;
                                    const maxHourText = `${maxHour < 10 ? '0' : ''}${maxHour}:00`;
                                    changeTime(maxHourText, 'to_time', setToTime);
                                }
                            }}
                        />
                    </div>
                    <div style={{padding : 12, flex : 1}}>
                        <TimeInput 
                            id='endDateTime'
                            label="to"
                            isSelectTime={isSelectTime} 
                            minTime={fromTime}
                            selectTime={toTime}
                            onChange={(value)=>changeTime(value, 'to_time', setToTime)}
                        />
                    </div>
                </div>
            </div>
        )
    }
    const changeTime = (value, key, setter) => {
        const defaultValue = key === 'from_time' ? '00:00' : '24:00';
        currentFormValues.current[key] = time2Number(value || defaultValue);
        setter(value);
    }
    React.useEffect(()=>{
        const {
            pathname,
        } = location;
        const _isShowResult = Boolean(pathname.search(/result/) > -1);
        setIsShowResult(_isShowResult);
        !_isShowResult && toggleShowFilter(true);
    }, [location]);


    React.useEffect( () => {
     currentFormValues.current.datePeriodType = [...datePeriodType];

        (async () => {
            if(!currentFormValues.current.max_date || !currentFormValues.current.min_date) {
                const availableDate = await getNdJsonFetch(token, history, `https://${API_URL}/date_list`);
                const start_date =  moment.min(availableDate.map(d => moment(d.start_date)));
                const end_date =  moment.max(availableDate.map(d => moment(d.end_date)));

                const formattedDate = () => {
                    let sd = moment(end_date).clone().add(-1, 'month');
                    let dateType =  datePeriodType.find(p => p.selected).value;
                    if(dateType === "monthly" || dateType === "monthly_split"){
                        return {startDate:sd.startOf('month'), endDate:moment(end_date).endOf("month") }
                    }
                    if(dateType === "weekly" || dateType === "weekly_split"){
                        return {startDate:moment(sd).day(1), endDate:moment(end_date).clone().day(7) }
                    }
                    return {startDate:sd, endDate:moment(end_date)}
                };

                    currentFormValues.current.start_date =  formattedDate()?.startDate?.format("YYYY-MM-DD").toString();
                    currentFormValues.current.end_date   =  formattedDate()?.endDate.format("YYYY-MM-DD").toString();
                    currentFormValues.current.min_date   = moment(start_date).format("YYYY-MM-DD").toString();
                    currentFormValues.current.max_date   = moment(end_date).format("YYYY-MM-DD").toString();
                    currentFormValues.current.availableDate = availableDate;
                    setAvailableDates(availableDate);
                    setStartDate(formattedDate()?.startDate);
                    setEndDate (formattedDate()?.endDate);
                    setMinDate(start_date);
                    setMaxDate(end_date);

            }
        })();
    }, []);

   

    const isOutsideRangeEnabled = false;

    // Prevent the calendar from closing on its own when the focus is moved to the time input
    const isSelectTime = React.useRef(null);
    const onFocusChangeRangeHandler = (focusedInput) => {
        if(!isSelectTime.current){
            setFocusedInput(focusedInput);
        }

    }
    const checkPathName        = location.pathname.split('/').splice(0,3).join('/');
    const isShowTimeRange      = ['/marketArea/share', '/marketArea/summary'].includes(checkPathName);
    const isTradeAreaSlider = ['/marketArea/summary'].includes(checkPathName);
  
    const isShowPeriodSelector = [
                                    "/sales",
                                    "/sales/result",

                                    "/assortment/summary",
                                    
                                    '/marketArea/summary',
                                    '/marketArea/share', 
                                    '/marketArea/profile', 
                                    '/marketArea/heatMap',
                                    '/marketArea/peopleFlow',   
                                ].includes(checkPathName);
    return (
        <div className={classes.toolBox}>
            <div style={{
                display : 'flex',
                alignItems : 'stretch',
                justifyContent:"center",
                gap : 12,
                gridGap : 12,
            }}>
                <div 
                style={{
                    display : 'flex',
                    alignItems : 'stretch',
                }}>
                    
                    {
                        showDateRange && (
                            <>
                                {
                                isShowPeriodSelector &&  <SelectDateOptions 
                                                            datePeriodType={datePeriodType}
                                                            setDatePeriodType={changeDatePeriodType}  />
                                } 
                                <DateRangePicker 
                                    startDate={ moment(startDate) } 
                                    endDate = {moment(endDate)}
                                    startDateId="startDate"
                                    endDateId="endDate"
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    enableOutsideDays={false}
                                    startDatePlaceholderText="開始日"
                                    endDatePlaceholderText="終了日"
                                    
                                    disabled={!Boolean(availableDates || (currentFormValues.current.max_date && currentFormValues.current.min_date) )}
                                    isOutsideRange={(day) => {
                                        if(!isOutsideRangeEnabled) return false;
                                        const {
                                            start,
                                            end,
                                        } = range;
                                        return !(start <= day &&  end >= day);
                                    }}
                                    isDayBlocked={(d)=>{
                                        let periodType =  datePeriodType.find(p => p.selected).value;
                                        let currentDate =  moment(d._d).format("YYYY-MM-DD")
                                        let availableDateList = availableDates ?? currentFormValues?.current?.availableDates ?? [];
                                        availableDateList = availableDateList?.map((de)=>({end_date:moment(de.end_date), start_date:moment(de.start_date)}));

                                        let inPeriod = ()=>{
                                            let defaultValue = true;
                                            availableDateList.forEach((de)=> {
                                                if( 
                                                    (((periodType === 'monthly' || periodType === 'monthly_split') && isShowPeriodSelector) ? de.start_date.clone().startOf('month') : de.start_date) <= moment(d._d) && 
                                                    moment(d._d) <= (((periodType === 'monthly' || periodType === 'monthly_split') && isShowPeriodSelector) ? de.end_date.clone().endOf('month') : de.end_date)){
                                                    defaultValue = false
                                                }})
                                                return defaultValue
                                        }
                                        if(!isShowPeriodSelector){
                                            return focusedInput === "endDate" && availableDateList?.map((de)=> de?.end_date?.format("YYYY-MM-DD")).includes( moment(d._d).format("YYYY-MM-DD"))  ? false : inPeriod()  ;
                                        }
                                            
                                        if(periodType === 'monthly' || periodType === 'monthly_split'){
                                            if(focusedInput === "endDate"){
                                                let endOfMonth =  moment(d._d).endOf("month").format("YYYY-MM-DD"); 
                                                return (currentDate !== endOfMonth) || (d._d <=  moment(startDate)) || inPeriod() ;     
                                            }
                                            let startOfMonth =   moment(d._d).startOf('month').format("YYYY-MM-DD");
                                            return (currentDate !== startOfMonth) || inPeriod();
                                        }
                                        if(periodType === "weekly" || periodType === "weekly_split"){
                                            let day =  moment(d._d).format("dddd");
                                            if(focusedInput === "endDate"){
                                                return (day !== "日曜日") || (d._d <=  moment(startDate)) || (d._d >= moment(maxDate)) || inPeriod()    //sunday or end-date
                                            }
                                            return (day !== "月曜日") || (d._d >= moment(maxDate).add(-5, 'days')) || inPeriod()  // monday
                                        }
                                        return focusedInput === "endDate" && availableDateList?.map((de)=> de?.end_date?.format("YYYY-MM-DD")).includes( moment(d._d).format("YYYY-MM-DD"))  ? false : inPeriod()  ;
                                    }}
                                    focusedInput={focusedInput}
                                    onFocusChange={onFocusChangeRangeHandler}
                                    onDatesChange={(selectedDates) => {
                                        let sd = selectedDates.startDate;
                                        let ed = selectedDates.endDate;

                                        let dateType =  datePeriodType.find(p => p.selected).value;
                                        if(!ed){
                                            if(dateType === "monthly" || dateType === "monthly_split"){
                                                ed = sd.clone().endOf("month");
                                            }
                                            else if(dateType === "weekly" || dateType === "weekly_split"){
                                                ed = sd.clone().add(6, 'days');
                                            }
                                            else{
                                                ed = sd.clone().add(1, 'days');
                                            }
                                        }
                                        setStartDate(sd);
                                        setEndDate(ed);
                                        currentFormValues.current.start_date = sd.format("YYYY-MM-DD").toString();
                                        currentFormValues.current.end_date   = ed.format("YYYY-MM-DD").toString();

                                    }}
                                    displayFormat="YYYY-MM-DD"
                                    renderCalendarInfo={isShowTimeRange ? timeRangeComponent : null}
                                    keepOpenOnDateSelect={true}
                                    readOnly
                                />
                            </>
                        )
                    }
                    {
                        showCategoryLevel && isHiddenPath &&  (
                            <SelectBox 
                                prefix="カテゴリレベル : "
                                options={categoryLevelOptions}
                                onChange={changeCategoryLevelOptions}
                            />
                        )
                    }
                    {
                        showTradeArea && (
                            <Slider 
                                style={{
                                    width: '45%',
                                }}
                                unit='km'
                                formPropertyName='tradeAreaSlider'
                                min = {1}
                                max={10}
                                step={1}
                                title="商圏"
                                label="商圏 : "
                                currentFormValues={currentFormValues}
                                onChange={changeTradeAreaOptions}
                            />
                        )
                    }
                </div>
                {
                    showTabSwitch && (
                        <ToggleSwitch 
                            labels={['シングル', 'タブ']} 
                            checked={isTab} 
                            onChange={toggleIsTab}
                            isDarkTheme={false}
                            //disabled={}
                        />
                    )
                }
                {
                    (showIndicatorSwitch || showChainAndSegmentSwitch) && (
                        <ToggleSwitch 
                            labels={['セグメント', showChainAndSegmentSwitch ? '企業名' : '指標']} 
                            checked={isIndicator} 
                            onChange={toggleIsIndicator}
                            isDarkTheme={false}
                            //disabled={}
                        />
                    )
                }    
                 {(showPerSegmentSwitch) && (
                    <ToggleSwitch
                        labels={['','セグメントごと']}
                        checked={isIndicator}
                        onChange={toggleIsIndicator}
                        isDarkTheme={false}
                    />
                    )}        
            <FavouriteEdit currentFormValues={currentFormValues}   minDate={moment(minDate)}
                                    maxDate={moment(maxDate)} />

            </div>
            {
                isShowResult && (
                        <Button 
                            color="primary" 
                            variant='contained' 
                            onClick={toggleIsReload}
                            startIcon={<LoopIcon />}
                            disabled={submitDisabled}
                        >データ再作成</Button>

                )
            }
        </div>
    );
}