import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root : {
        '& .MuiSnackbar-anchorOriginTopCenter' : (props) => ({
            top : props.isVerticalTop ? 0 : null,
        }),
        '& .MuiPaper-root' : (props) => ({
            borderRadius : props.isVerticalTop ? '0 0 4px 4px' : null,
        })
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));
const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function APP_Snackbar(props) {
    const {
        severity='info',
        message=undefined,
        action=null,
        autoHideDuration=null,
        vertical='top',
        horizontal='center',
    } = props;

    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState(undefined);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        action && action();
        setOpen(false);
    };

    const classes = useStyles({
        isVerticalTop : Boolean(vertical === 'top'),
    });

    React.useEffect(()=>{
        if(message){
            setMessageInfo({
                key : new Date().getTime(),
                message : message,
            });
            setOpen(true);
        }
    }, [message])
    return (
        <div className={classes.root}>
            <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                anchorOrigin={{
                    vertical: vertical,
                    horizontal: horizontal,
                }}
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
            >
                <Alert severity={severity} onClose={handleClose}>
                    {messageInfo ? messageInfo.message : undefined}
                </Alert>
            </Snackbar>
        </div>
    );
}
