import React,{useState, useContext, useEffect,} from "react";
import { doc, collection, getDocs, where,query, updateDoc } from "@firebase/firestore";
import { AuthContext } from "../../AuthenticationContext";
import { FavoriteRounded, RefreshRounded} from '@material-ui/icons';
import { CircularProgress, Grid, Button , Card, CardContent,Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Input } from "reactstrap";


export default function FavoriteEdit(){
    const [favoriteHistory, setFavoriteHistory] = useState(null);
    const [loading, setLoading ] = useState(true);
    const {db,userProfile} = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [favoriteHistoryFetchError,setFavoriteHistoryFetchError] = useState(null)
    const [favoriteInDetail,setFavoriteInDetail] = useState(null)
    const [favName, setFavName] = useState(null)
    const [favFlag, setFavFlag] = useState(null)
    const [updateError, setUpdateError] = useState(null);
    const [successMessage, setsuccessMessage] = useState(null)
    const [updating, setUpdating] = useState(false)


    const  getFavoritesHistory  = async () => {
        const favoriteCollectRef = collection(db,"filter_history");
        let q = query(favoriteCollectRef, where("email",'==',userProfile?.email))
        !loading && setLoading(true)
        await getDocs(q).then((snapshot)=>{
            let historyData = []
            if(!snapshot.empty) {
                snapshot.forEach((favoriteDoc)=>{
                    historyData.push({
                        id:favoriteDoc.id,...favoriteDoc.data(),
                        create_time: new Date(favoriteDoc.data().create_time.toMillis()).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric'}) +" "+ 
                        new Date(favoriteDoc.data().create_time.toMillis()).toLocaleTimeString('en-US')});
                })
                setFavoriteHistory(historyData)
            }else{
                setFavoriteHistory(null)
            }
        }).catch((error)=>{
            setError(error.code)
            setFavoriteHistoryFetchError("Error occured while fetching favorite history, try again !")
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        getFavoritesHistory();
    },[])

    const onClickFavButton =() =>{
        setFavFlag((prevState)=>{return !prevState})
    }

    const saveUserChanges = async () =>{
        if(!favoriteInDetail) return null;
        const docRef = doc(db, "filter_history", favoriteInDetail?.id);
        successMessage && setsuccessMessage(null)
        updateError && setUpdateError(null)
        setUpdating(true)
        const updates = {
            favourite_flag:favFlag,
            favourite_name:favName
        }
        await updateDoc(docRef,{...updates} ).then((snap)=>{
            setFavoriteHistory((prevState)=>{
                // const newFav = prevState;
                prevState.find((fav,i) => {
                    if(fav.id === favoriteInDetail?.id){
                        prevState[i].favourite_flag = favFlag;
                        prevState[i].favourite_name = favName;
                        return true;
                    }
                });
                return [...prevState]
            })
            setsuccessMessage("Updated successfully !")
        }).catch((error)=>{
            setUpdateError(error.code)
        }).finally(()=>{
            setUpdating(false)
        })
        
    }

    const rows = favoriteHistory ? favoriteHistory.map((data,i)=>{return {...data,sid:i+1}}) : []

    const columns = [
    { field: 'sid', headerName: "#", width: 30 },
    { field: 'create_time', headerName: 'Date Time', width: 200 },
    { field: 'favourite_flag', headerName: 'Favorite Flag', width: 150,
    renderCell:(params)=>{
       return <Button onClick={onClickFavButton} disabled={ updating || !favoriteInDetail?.id === params?.row.id || !favoriteInDetail?.settings }  > <FavoriteRounded color={favoriteInDetail && favoriteInDetail?.id === params?.row.id ? (favFlag ? "error" : "action" ): params.row?.favourite_flag ? "error" : "action"}  /> </Button>
    }
    },
        { field: 'favourite_name', headerName: 'Favorite Name', width: 250,
        renderCell: (params)=>{
            return(<>
                {favoriteInDetail?.id === params.row?.id && favoriteInDetail?.settings ? <Input disabled={updating} bsSize="lg" style={{width:"100%"}} placeholder="Give a name" onChange={(e)=>{setFavName(e.target.value)}} value={favName || ""}/> :
                <div>{params.row?.favourite_name}</div> 
                }
                </>
            )
        }
    },
    ]

    const onClickRow = (params)=>{
        if(updating || (favoriteInDetail && favoriteInDetail?.id ===  params?.row.id)) return
        setFavFlag(() =>{return params.row?.favourite_flag})
        setFavName(() =>{return params.row?.favourite_name} )
        setFavoriteInDetail(()=>{ return params?.row } )
        setsuccessMessage(null)
        setUpdateError(null)
      }

    return(
        <>
        <h2>Favorite Edit</h2>
        {!loading && favoriteHistoryFetchError ? <RefreshFavoriteHistory favoriteHistoryFetchError={favoriteHistoryFetchError} getFavoritesHistory={getFavoritesHistory} /> :
            loading ? <LoadingIndicator/> : favoriteHistory ?
            <>
            <div style={{display:"block"}} >
            <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="flex-start" spacing={2} >
                <Grid   item xs={12} md={6} >
                    <div style={{ height: 500,width:"100%" }}>
                        <div style={{ height: 500,}}>
                            <DataGrid
                            hideFooter 
                            hideFooterPagination rows={rows} 
                            columns={columns} 
                            onRowClick={(params)=>{onClickRow(params)}}/>
                        </div>
                    </div>
                </Grid>
                {favoriteInDetail && (favoriteInDetail.settings ? <DisplayCards data={favoriteInDetail.settings} saveUserChanges={saveUserChanges} successMessage={successMessage} updateError={updateError} updating={updating} setFavoriteInDetail={setFavoriteInDetail} /> : 
                <div style={{color:"red"}}>
                    No settings for sellected filter!
                </div>) }
                </Grid>
            </div>
            </> :  <div>No favorites to show !</div>
        }
    </>
    )

}

function DisplayCards(props){
   
    let data = props?.data &&  Object.keys(props?.data).map((key)=>{
        return {header:key, data: props?.data[key]}
    })
    const listOfFilters = data.map((favFilter,i)=>{
       return <Grid key={i} item xs={12} md={6}>
            <FavoriteCard {...favFilter} />
         </Grid>
    })
    return(
        <>
        <Grid  container item xs={12} md={6} spacing={2} style={{height:"500px", overflow:"scroll"}} >
            {listOfFilters}
        </Grid>
        
        {props?.updateError && 
            <Grid container direction="row-reverse" item xs={12} md={12}>
                <p style={{color:"red"}}>{props?.updateError}</p>
            </Grid>}
        {props?.successMessage &&
         <Grid container direction="row-reverse" item xs={12} md={12}><p style={{color:'green'}}>{props?.successMessage}</p></Grid>}

        <Grid container direction="row-reverse" item xs={12} md={12}>
        <div style={{textAlign:"center",display:"flex" }}> 
                <Button style={{marginRight:"5px"}} variant="contained" color="default" onClick={()=>{props?.setFavoriteInDetail(null)} } disabled={props?.updating}  >Cancel</Button>
                <Button style={{marginLeft:"5px"}} variant="contained" color="primary" onClick={()=>{props?.saveUserChanges()} } disabled={props?.updating} > {props?.updating ? <CircularProgress/> :  "Save" } </Button>
            </div>
        </Grid>
      
        </>
    )

}

 function FavoriteCard(props) {

    const data = props?.data

    const RenderData = ()=>{
        if (data &&  Array.isArray(data)){
            return data.map((ele,i)=>{
                return <div key={i}>{ele}</div>
            })
        }else if(data && typeof data === "object"){
           return  Object.keys(data).map((key,i)=>{
                return <div key={i} > {key} : {data[key]} </div>
            })
        }else if(data && typeof data === "string"){
            return <div > {data} </div>
        }else {
            return <div>{data}</div>
        }
    }
    return (
      <Card >
        <CardContent>
          <Typography style={{borderBottom:"1px solid gray"}} color="textSecondary" gutterBottom>
            {props?.header}
          </Typography>
              <RenderData/>
        </CardContent>
      </Card>
    );
  }
  
  
const LoadingIndicator = () =>{
    return(
        <div style={{position:"relative", textAlign:"center", top:"30%", width:"100%"}}>
            <CircularProgress 
                    style={{
                        position:"relative",
                        backgroundColor:"#fff", 
                        width:"50px",
                        height:"50px", 
                        borderRadius:"50%", 
                        boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        padding:"10px"}} size={30} thickness={4} color="inherit" />
        </div>
    )
}

const RefreshFavoriteHistory = (props)=>{
    return(
        <>
        <div style={{position:"relative", textAlign:"center", top:"30%"}}>
            <RefreshRounded  
                style={{
                    position:"relative", 
                    backgroundColor:"#fff", 
                    borderRadius:"50%", 
                    padding:"5px",
                    boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 4px", 
                    cursor:"pointer", 
                    fontSize:"50"
                    }} onClick={()=> {props?.getFavoritesHistory()}} />
        </div>
        <div style={{position:"relative",textAlign:"center" , top:"30%", width:"100%"}}>
            <p style={{color:"red"}}>{props?.favoriteHistoryFetchError}</p>
        </div>
        </>
    )
}

