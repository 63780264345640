import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    version : {
        position : 'fixed',
        bottom : 24,
        right : 24,
        color : 'rgba(127, 140, 141,1.0)',
    }
}));
export default function Version() {
    const classes = useStyles();
    const appVersion = process.env?.REACT_APP_VERSION || '0.2.0';

    return (
        <div className={classes.version}>
            v {appVersion}
        </div>
    );
}