import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import * as dfd from "danfojs";

import Button from '../../common/Button/Button.jsx';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.jsx';
import Tabs from '../Tabs/Tabs.jsx';
import {config} from '../../config.jsx';

// dummy data
import dataJSON from '../../json/MVP_API/BoxPlotChart.json';


const useStyles = makeStyles((theme) => ({

    
    root : (props) => ({
        borderTop : props.isShowFilter ? 'solid 1px #ddd' : null,
    }),
    tableBox : (props) => ({
        height : props.isShowFilter ? 'calc(100vh - 434px)' : 'calc(100vh - 180px)',
        //transition : 'height .1s linear',
        '& .MuiDataGrid-row' : {
            backgroundColor : '#fff',
        }
    }),
    withTabTableBox : (props) => ({
        height : props.isShowFilter ? 'calc(100vh - (434px + 48px))' : 'calc(100vh - (180px + 48px))',
        overflow : 'hidden',
    }),
    toolbar : {
        padding : '12px 0 12px 0',
        display : 'flex',
        justifyContent : 'space-between',
    }
}));

export default function Result(props) {
    const {
        isShowFilter,
        toggleShowFilter,
        isTab,
        toggleIsTab,
        isIndicator,
        toggleIsIndicator,
    } = props;
    const classes = useStyles({
        isShowFilter,
    });
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [tableRows, setTableRows] = React.useState([]);
    const [originalData, setOriginalData] = React.useState(null);
    const [tableColumns, setTableColumns] = React.useState([]);
    const [isDanfo, setIsDanfo] = React.useState(false);
    
    const [tableTabOptions, setTableTabOptions] = React.useState(null);
    const [tabColumnKey, setTabColumnKey] = React.useState(null);

    const delayTimer = React.useRef(null);
    const getData = async () => {
        const x = dataJSON.data.x[0];
        const y = dataJSON.data.y[0];

        const data = x.map((name, i) => {
            return {
                name,
                value : y[i]
            }
        });


        console.log('x', x);
        console.log('y', y);
        console.log('data', data);

        const sampleDataFrame = new dfd.DataFrame(data);
        sampleDataFrame.print();
        const groupByData = sampleDataFrame.groupby(['name']);
        sampleDataFrame.std().print();
        //console.log('groupByData', groupByData);

        
        // Since loading is instantaneous, use setTimeout to delay it
        // Not required if data is obtained from API
        //delayTimer.current = setTimeout(() => {
        //    setOriginalData(sampleDataFrame);
        //}, 1000);
    }
    const getGroupByKey = (newTabKey) => {
        const groupBy = isIndicator ? ['セグメント', '指標'] : ['指標', 'セグメント'];
        return groupBy;
    }
    const createTableData = (newTabKey) => {
        const groupByKeys = getGroupByKey();
        if(!isIndicator && !isTab && groupByKeys.length < 2) return;
        let _originalData = originalData.copy();
        
        if(isTab && newTabKey){
            const tabKey = newTabKey;
            const tabOptions = tableTabOptions[tabKey];
            
            const selectTabName = tabOptions.find(t => t.selected).name;
            
            _originalData = _originalData.query(_originalData[`${newTabKey}`].eq(`${selectTabName}`));

            _originalData.drop({ columns: [tabKey], inplace: true });
            const delIndex = groupByKeys.findIndex(key => key === tabKey);
            groupByKeys.splice(delIndex, 1);
        }
        const groupByDataFrame = _originalData.groupby(groupByKeys);
        const sumTable = groupByDataFrame.sum();
        
        isDanfo && sumTable.plot('result-table').table();
        
        const columns = sumTable.$columns.map(name => name.replace(/_sum/, ''));
        const rows    = sumTable.$data.map((col, i) => {
            const params = {
                id : i,
            };
            columns.map((name, j) => {
                params[name] = col[j];
            })
            return params;
        });

        setTableColumns(columns.map((name, j) => {
            return {
                field      : name,
                headerName : name,
                width      : name === 'セグメント' ? 400 : 200,
                type       : j < 2 ? "string" : "number",
            }
        }))
        setTableRows(rows);
        setIsLoading(false);
    }
    const closeResult = () => {
        toggleShowFilter(true);
        history.push('/sales');
    }
    const toggleIsDanfo = () => {
        setIsDanfo(!isDanfo);
    }
    const changeTabSelect = (index) => {
        const newTabOptions = {...tableTabOptions};
        newTabOptions[tabColumnKey] = newTabOptions[tabColumnKey].map((option, i) => {
            return {
                ...option,
                selected : i === index,
            }
        });
        
        setTableTabOptions(newTabOptions);
    }
    React.useEffect(()=>{
        
        toggleShowFilter(false);
        getData();
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
        }
    }, []);
    React.useEffect(()=>{
        
        const newTabColumnKey = isIndicator ? '指標' : 'セグメント';

        setTabColumnKey(newTabColumnKey);

        originalData && createTableData(newTabColumnKey);
    }, [isTab, isIndicator, originalData, isDanfo, tableTabOptions]);
    
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <div style={{
                    display : 'flex',
                    gap : 12,
                    gridGap : 12,
                }}>
                    <Button 
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={<i className="fas fa-file-download"></i>}
                    >CSV ダウンロード</Button>
                    {
                        !isShowFilter && (
                            <>
                                <ToggleSwitch 
                                    labels={['シングル', 'タブ']} 
                                    checked={isTab} 
                                    onChange={toggleIsTab}
                                    isDarkTheme={false}
                                    //disabled={}
                                />
                                <ToggleSwitch 
                                    labels={['セグメント', '指標']} 
                                    checked={isIndicator} 
                                    onChange={toggleIsIndicator}
                                    isDarkTheme={false}
                                    //disabled={}
                                />
                            </>
                        )
                    }

                    <Divider  orientation="vertical" flexItem/>
                    <ToggleSwitch 
                        labels={['DataGrid (MUI)', 'Table (Danfo.js)']} 
                        checked={isDanfo} 
                        onChange={toggleIsDanfo}
                        isDarkTheme={false}
                        color='secondary'
                        //disabled={}
                    />
                </div>
                <div style={{
                    display : 'flex',
                    gap : 12,
                    gridGap : 12,
                }}>
                    <Button 
                        color={isShowFilter ? 'secondary' : 'primary'}
                        variant="text"
                        size="large"
                        startIcon={isShowFilter ? <PublishIcon /> : <GetAppIcon />}
                        onClick={()=>toggleShowFilter(!isShowFilter)}
                    >{
                        isShowFilter ? 'フィルター非表示' : 'フィルター表示'
                    }</Button>
                    <Button 
                        color="secondary"
                        variant="text"
                        size="large"
                        startIcon={<HighlightOffIcon/>}
                        onClick={closeResult}
                    >結果を閉じる</Button>
                </div>

            </div>
            {
                isTab && tableTabOptions && (
                    <Tabs 
                        tabOptions={tableTabOptions[tabColumnKey]} 
                        changeTabSelect={changeTabSelect}
                    />
                )
            }
            <div className={clsx(classes.tableBox, isTab && classes.withTabTableBox)}>
                {
                    isDanfo ? (
                        <div id="result-table" style={{
                            height:'100%',
                            display : 'flex',
                            justifyContent : 'center',
                            alignItems : 'center',
                        }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (
                        <div>まだ</div>
                    )
                }
            </div>
        </div>
    );
}