import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Tabs from '../Tabs/Tabs.jsx';
import { Paper, Typography } from '@material-ui/core';
import LineChart from '../Charts/LineChart.jsx';
// import { HiChevronDoubleDown, HiChevronDoubleUp, HiChevronDoubleRight} from 'react-icons/hi';
const useStyles = makeStyles((theme) => ({
    tableBox : (props) => ({
        height : props.isShowFilter ? props.isShowChart ? `calc(100vh - (434px + 296px))` : `calc(100vh - 434px)` : props.isShowChart ? `calc(100vh - (228px + 296px))` : `calc(100vh - 228px)`,
        minHeight : 240,
        //transition : 'height .1s linear',
        '& .MuiDataGrid-row' : {
            backgroundColor : '#fff',
        }
    }),
    chartBox : {
        marginTop : 12,
        overflow : 'hidden',
    }
}));

export default function TabByTable(props) {
    const {
        options=[],
        isShowFilter=false,
        tabIndex=null,
        changeTabIndex,
        isChart=false,
        isShowChart=false,
        chartSeriesIgnoreKeys=[],
        hasShowChart,

        setTableRows,
        setTableColumns,
        setTableTabOptions,
        setTabColumnKey,
    } = props;

    const classes = useStyles({
        isShowFilter,
        isShowChart,
    });
    const [tabOptions, setTabOptions] = React.useState([]);
    React.useEffect(()=>{
        let tabColumnKey = null;
        const newTabOptions = options.map((option, i) => {
            const selected = i === tabIndex;
            if(selected) tabColumnKey = option.tabName;
            return {
                name : option.tabName,
                selected, 
            }
        });
        const selectTable = options?.[tabIndex] || {};
        const {
            rows = null,
            columns = null,
        } = selectTable;
        setTabOptions(newTabOptions);
    }, [options, tabIndex]);
    const createChartOptions = (rows, rowKeysForGraph) => {
        if(!isChart || !columns){
            return null;
        }
        let hourlyKeys = [];
        const tmp = {};
        rows.forEach((row, i) => {
            const keys       = Object.keys(row);
            const nameKeys   = [...chartSeriesIgnoreKeys].filter(key => key !== 'id');
            const seriesName = nameKeys.map(key => row[key]).filter(e=>Boolean(e)).join(' : ');
            if(i === 0){
                hourlyKeys = keys
                    .filter(key => !chartSeriesIgnoreKeys.includes(key))
                    .sort((a, b) => a > b ? 1 : -1);
            }
            tmp[seriesName] = hourlyKeys.map(key => row[key]);
        });
        const chartOptions = {
            chart : {
                type : 'line',
                height : 280,
            },
            xAxis : {
                categories : rowKeysForGraph,
            },
            series : Object.keys(tmp).map(name => {
                return {
                    name,
                    data : tmp[name],
                }
            }),
        }
        return chartOptions;
    }
    const selectTable = options?.[tabIndex] || {};
    let {
        rows = null,
        columns = null,
        dataForChart=null
    } = selectTable;
    let rowKeysForGraph = columns.map(c=>c.headerName).slice(2);
    rows = rows.map((r)=>{
        let row = {...r};
        delete row.segmentId;
        return row;
    })
    const numberFormatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
     });
    const getFormattedNumber = (num) => {
        return numberFormatter.format(num)
    }
    const customSorting = (v1, v2) => {
        if(!isNaN(v1) && !isNaN(v2)){
            return v1 - v2
        }
        else if(v1.includes('%') && v2.includes('%')){
            const v1WithoutPercentage = v1.slice(0, -1)
            const v2WithoutPercentage = v2.slice(0, -1)
            if(!isNaN(v1WithoutPercentage) && !isNaN(v2WithoutPercentage)){
                return Number(v1.slice(0, -1)) - Number(v2.slice(0, -1))
            }
        }
        return v1 - v2
    }
    columns = columns.map(col => {
        return {
            ...col,
            valueFormatter : (params) => {
                if(params?.value == Infinity){
                    return '-'
                }
                if((col?.['showPercentage'] || col?.['showPlusSign']) && !["セグメント","企業名"].includes(col?.['headerName'] )){
                    return  `${ (col?.['showPlusSign'] && (params?.value)) > 0 ? "+" : ""}${getFormattedNumber(params?.value)}${col?.['showPercentage'] ? "%":""}`
                }
                else{
                    if(!isNaN(params?.value)){
                        return getFormattedNumber(params.value)
                    }
                    else{
                        return params?.value
                    }
                }
            },
            // renderCell: (params) => {
            //     if(params.value == Infinity){
            //         return '-'
            //     }
            //     if((col['showPercentage'] || col['showPlusSign']) && !["セグメント","企業名"].includes(col['headerName'] )){
            //         return  <>{ ((col['showPercentage'] || col['showPlusSign']) && params.value == 0) ? <HiChevronDoubleRight style={{color:"#FFBF00"}}/> : (col['showPlusSign'] && params.value < 0 )? <HiChevronDoubleDown style={{color:"red"}}/> : <HiChevronDoubleUp style={{color:"green"}}/>} {`${getFormattedNumber(params.value)}${col['showPercentage'] ? "%":""}`}</>
            //     }
            //     else{
            //         if(!isNaN(params.value)){
            //             return  <>{getFormattedNumber(params.value)}</>
            //         }
            //         else{
            //             return <>{params.value}</>
            //         }
            //     }
            // },
            sortComparator: customSorting
        }
    })
        
        const chartOptions = hasShowChart && createChartOptions(dataForChart, rowKeysForGraph);
    return (

        <>
            {
                tabIndex !== null && (
                    <Tabs 
                        tabOptions={tabOptions} 
                        changeTabSelect={changeTabIndex}
                    />
                )
            }


        <div className={classes.tableBox}>
            {
                rows && columns ? (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pagination 
                        pageSize={100} 
                        rowsPerPageOptions={[100]}
                        disableColumnMenu={true}
                    />
                ) : (<Typography>データが存在しません</Typography>)
            }
        </div>
        {
            isShowChart && chartOptions && hasShowChart  && (
                <Paper className={classes.chartBox}>
                    <LineChart options={chartOptions} />
                </Paper>
            )
        }
        </>
    );
}