import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '../../common/Button/IconButton.jsx';
import SearchIcon from '@material-ui/icons/Search';
const useStyles = makeStyles((theme) => ({
    root : (props) => ({
        top: props.addTopPosition + 48,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#f1f3f4',
        padding : '0 12px',
    }),
    searchFrom : {
        border : 'solid 1px rgba(149, 165, 166,0)',
        flex : 1,
        display : 'flex',
        padding : '4px 12px',
        borderRadius : 4,
        transition : 'background .2s linear, border .2s linear',
        '& $input' : {
            flex : 1,
            color : '#34495e',
        },
        '&:focus-within, &:hover' : {
            backgroundColor : 'rgba(255,255,255,.75)',
            border : 'solid 1px rgba(149, 165, 166,1.0)',

        }
    },
    input : {
        transition : 'width .2s linear',
    },
    countBox : {
        color : '#7f8c8d',
        display : 'flex',
        justifyContent : 'flex-end',
        position : 'absolute',
        right : 10,
        backgroundColor: '#f1f3f4',
        padding : '0 4px',
        borderRadius : '0 0 2px 2px',
    }
}));

export default function UI_Button(props) {
    
    const {
        masterDataKey='',
        placeholder=null,
        addTopPosition=0,
        total=0,
        length=0,
        onChange=null,
        currentFormValues,
    } = props;
    
    const classes = useStyles({addTopPosition});
    const [searchText, setSearchText] = React.useState(currentFormValues.current.filterSearchText?.[masterDataKey] || null);
    const inputRef = React.useRef(null);
    const filterText = (event, isReset=false) => {
        event.stopPropagation();
        event.preventDefault();
        const value = inputRef.current.value || null;
        if(isReset && value) return;
        if(currentFormValues.current.filterSearchText === undefined){
            currentFormValues.current.filterSearchText = {};
            currentFormValues.current.filterSearchText[masterDataKey] = '';
        }
        currentFormValues.current.filterSearchText[masterDataKey] = value;
        setSearchText(value);
        onChange && onChange(value);
    }
    React.useEffect(()=>{

    }, [])
    return (
        <div className={classes.root}>
            <form className={classes.searchFrom} 
                onSubmit={filterText}
            >
                <InputBase
                    className={classes.input}
                    placeholder={placeholder}
                    inputProps={{ 'aria-label': placeholder }}
                    inputRef={inputRef}
                    defaultValue={searchText}
                    type="search"
                    //onReset={filterText}
                    onInput={(event)=>{
                        filterText(event, true);
                    }}
                />
                <IconButton size="small" type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </form>
            <div className={classes.countBox}>{length} / {(total).toLocaleString()}</div>
        </div>
    )
}
