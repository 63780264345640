import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Button from '../../common/Button/Button.jsx';
import { Tooltip, TextField, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', 
        alignItems: 'stretch',
    },
    MenuItemRoot : {
        color : '#34495e',
    },
    MenuItemSelected : {
        backgroundColor : '#3f51b5 !important',
        color : 'rgba(255, 255, 255, 0.75)',
    }
}));

const options = [
    'Show some love to Material-UI',
    'Show all notification content',
    'Hide sensitive notification content',
    'Hide all notification content',
];

export default function SelectBox(props) {
    const {
        options=[],
        onChange=null,
        prefix='',
        rootStyle=null,
        labelStyle=null,
        unselectLabel='未選択',
        id='select-box',
        enableFilter=false
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchKey,setSearchKey] = useState(''); 
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index) => () => {
        onChange && onChange(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const getSelectOption = () => {
        return options.find(option => option.selected)?.label || unselectLabel;
    }
    const getOptionKeyValue = (val, label) => {
        if(typeof(val) === 'object'){
            return label;
        }
        return val;
    }
    const selectedLabel = getSelectOption();
    return (
        <div className={classes.root} style={rootStyle}>
            <Tooltip title={selectedLabel}>
            <Button 
                aria-controls={id}
                aria-haspopup="true" 
                onClick={handleClick}
                endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                style={{flex : 1}}
            >
                <span style={{color : 'rgba(127, 140, 141,1.0)'}}>{prefix}</span>
                <span style={labelStyle}>{selectedLabel}</span>
            </Button>
            </Tooltip>
            <Menu
                id={id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    enableFilter && 
                    <TextField 
                        id="outlined-basic" 
                        style={{ padding: '0px 5px 0px 5px' , width:'100%'}} 
                        label="検索" variant="outlined" 
                        size="small" 
                        onChange={(event) => setSearchKey(event.target.value)} 
                        value={searchKey}
                        InputProps={{
                            endAdornment: <InputAdornment position="end" onClick={() => {setSearchKey('')}}>x</InputAdornment>
                        }}
                    />
                }
                {
                    options.filter(x =>   x.label.includes(searchKey)).map(option => {
                        const {
                            label,
                            value,
                            selected,
                        } = option;
                        const key = typeof(value)
                        return (
                            <MenuItem
                                key={`${id}-option-${getOptionKeyValue(value, label)}`}
                                classes={{
                                    root : classes.MenuItemRoot,
                                    selected : classes.MenuItemSelected,
                                }}
                                selected={selected}
                                onClick={handleMenuItemClick(value)}
                            >
                                {label}
                            </MenuItem>
                        )

                    })
                }
            </Menu>
        </div>
    );
}
