import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        '& .Mui-selected' : {
            
        },
        '& .MuiTab-wrapper' : {
            display: 'flex',
            //alignItems: 'flex-start',
            //justifyContent: 'flex-start',
            height: '100%',
        },
        '& .MuiTabs-root' : {
            '& .MuiTab-root' : {
                maxWidth: '272px',
                borderRight: 'solid 1px #ddd',
                borderImage: 'linear-gradient(0deg, rgba(224,224,224,1) 50%, rgba(224,224,224,0) 100%)',
                borderImageSlice: 1,
                '&:last-child' : {
                    borderRight: 'none',
                }
            }
        }
    },
}));

export default function APP_Tabs(props) {
    const {
        tabOptions=[],
        changeTabSelect=null,
    } = props;

    const classes = useStyles();
    //const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        changeTabSelect && changeTabSelect(newValue);
        //setValue(newValue);
    };
    const value = tabOptions.findIndex((option) => {
        return option.selected;
    })
    //console.log({value})
    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {
                    tabOptions.map((tab, index) => {
                        return <Tab 
                            key={`tab-${tab.name}`}
                            label={tab.name} 
                            {...a11yProps(index)}
                        />
                    })
                }

            </Tabs>
        </div>
    );
}
